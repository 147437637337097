import * as Yup from "yup";
import _ from "lodash";
import * as steps from "./steps";
import React, { useRef, useState } from "react";
import WizardLayout from "../WizardLayout";
import gql from "graphql-tag";
import { useMutateData } from "../../../hooks";
import Alert from "react-s-alert";
import {
	AUTHORIZATION_TYPES,
	TIMESTAMP_FORMAT_OPTIONS,
	TRANSFORMATIONS,
} from "../../../helpers/constants";

export default function RequestWizard(props) {
	const request = props.request;

	const [method, setMethod] = useState(request?.Method || "POST");
	const editorRef = useRef(null);

	const isSFTP = method === "SFTP";
	const isAdding = props.mode === "add";

	const requestBasedVariables = props.requests
		? _.uniqBy(
				_.flatten(
					props.requests
						.map((r) =>
							r.Response?.map((resp) => ({
								value: resp.Variable,
								label: resp.Label,
								description: resp.Description,
								type: resp.Type,
							}))
						)
						.filter((variable) => variable)
				),
				"value"
		  )
		: [];

	const headers = request?.Headers
		? request?.Headers?.map((param) => ({
				key: param.Key,
				value: param.Value,
				description: param.Description,
		  }))
		: [{ key: "Content-Type", value: "application/json", description: "" }];
	const initialValues = [
		{
			name: "name",
			value: request?.Name || "",
			validator: Yup.string(),
		},
		{
			name: "method",
			value: request?.Method || "POST",
			validator: Yup.string(),
		},
		{
			name: "url",
			value: request?.URL || "",
			validator: Yup.string(),
		},
		{
			name: "port",
			value: request?.Metadata?.Port || "22",
			validator: Yup.string(),
		},
		{
			name: "hasParams",
			value: request?.Params ? true : false,
			validator: Yup.string(),
		},
		{
			name: "params",
			value: request?.Params
				? request?.Params?.map((param) => ({
						key: param.Key,
						value: param.Value,
						description: param.Description,
				  }))
				: [{ key: "", value: "", description: "" }],
			validator: Yup.array(),
		},
		{
			name: "shouldAuthorize",
			value: request?.AuthorizationType ? true : false,
			validator: Yup.string(),
		},
		{
			name: "authorizationType",
			value: request?.AuthorizationType,
			validator: Yup.string().nullable(),
		},
		{
			name: "basicAuthUsername",
			value: request?.BasicAuthCredentials?.Username,
			validator: Yup.string(),
		},
		{
			name: "basicAuthPassword",
			value: request?.BasicAuthCredentials?.Password,
			validator: Yup.string(),
		},
		{
			name: "bearerToken",
			value: request?.BearerToken,
			validator: Yup.string().nullable(),
		},
		{
			name: "hasHeaders",
			value: request?.Headers ? true : false,
			validator: Yup.string(),
		},
		{
			name: "headers",
			value: request?.AuthorizationType
				? [
						{
							key: "Authorization",
							value: `${request?.AuthorizationType} ${
								request?.AuthorizationType === AUTHORIZATION_TYPES.BASIC
									? btoa(
											`${request?.BasicAuthCredentials?.Username}:${request?.BasicAuthCredentials?.Password}`
									  )
									: request?.BearerToken
							}`,
							readonly: true,
						},
						...headers,
				  ]
				: headers,
			validator: Yup.array(),
		},
		{
			name: "hasTransformations",
			value: request?.Transformations ? true : false,
			validator: Yup.string(),
		},
		{
			name: "transformations",
			value: request?.Transformations
				? request?.Transformations?.map((transform) => ({
						variable: {
							value: transform.Variable?.Name,
							label: transform.Variable?.Label,
							type: transform.Variable?.Type,
						},
						name: transform.Name,
						label: transform.Label,
						transformation: TRANSFORMATIONS[transform.Variable?.Type].find(
							(t) => t.value === transform.Transformation
						),
						template: transform.Template,
						format: TIMESTAMP_FORMAT_OPTIONS.find(
							(o) => o.value === transform.Format
						),
						hours: transform.Hours,
						characterLength: transform.CharacterLength,
						key: transform.Key,
						find: transform.Find,
						replace: transform.Replace,
				  }))
				: [
						{
							variable: null,
							name: "",
							label: "",
							transformation: "",
							template: null,
							format: null,
						},
				  ],
			validator: Yup.array(),
		},
		{
			name: "body",
			value: request?.Body
				? request?.BodyLanguage === "json"
					? JSON.stringify(JSON.parse(request?.Body), undefined, 4)
					: request?.Body
				: "",
			validator: Yup.string(),
		},
		{
			name: "languageMode",
			value: request?.BodyLanguage || "json",
			validator: Yup.string(),
		},
		{
			name: "responseMethod",
			value: request?.Response ? "Map" : "Nothing",
			validator: Yup.string(),
		},
		{
			name: "response",
			value: request?.Response
				? request?.Response?.map((resp) => ({
						path: resp.Path,
						variable: resp.Variable,
						label: resp.Label,
						description: resp.Description,
						type: resp.Type,
				  }))
				: [{ path: "", variable: "", label: "", description: "", type: "" }],
			validator: Yup.array(),
		},
		{
			name: "hasRules",
			value: request?.Rules ? true : false,
			validator: Yup.string(),
		},
		...[{ prefix: "Pre" }, { prefix: "Post" }].map((requestRule) => ({
			name: `${requestRule.prefix.toLowerCase()}RequestRules`,
			value: request?.Rules?.[`${requestRule.prefix}Request`]
				? request?.Rules?.[`${requestRule.prefix}Request`]?.map((rule) => ({
						type: rule.Type,
						variable: rule.Variable,
						responseCode: rule.ResponseCode,
						condition: rule.Condition,
						outcome: rule.Outcome,
						request: rule.Request,
						unsetVariable: rule.UnsetVariable,
				  }))
				: [],
			validator: Yup.array(),
		})),
		{
			name: "requests",
			value: props.requests
				? props.requests.map((r) => ({
						value: r.RequestID,
						label: r.Name,
				  }))
				: [],
			validator: Yup.array(),
		},
		{
			name: "variables",
			value: requestBasedVariables,
			validator: Yup.array(),
		},
		{
			name: "variableGroups",
			value: [
				{
					name: "Actions",
					variables: [
						{
							value: "CreateVariable",
							label: "Create Variable",
							type: "Action",
						},
					],
				},
				{
					name: "Requests",
					variables: requestBasedVariables,
					color: "orange",
				},
				...(props.variableGroups?.map((variableGroup) => ({
					name: variableGroup.Name,
					color: variableGroup.Name === "Custom" ? "blue" : "default",
					variables: variableGroup.Variables?.map((variable) => ({
						value: variable.Name,
						variableValue: variable.Value,
						label: variable.Label,
						description: variable.Description,
						type: variable.Type,
					})),
				})) || []),
			],
			validator: Yup.array(),
		},
		{
			name: "sftpAuthMethod",
			value: request?.SFTPConnection?.Password ? "Password" : "PrivateKey",
			validator: Yup.string(),
		},
		{
			name: "hostname",
			value: request?.URL,
			validator: Yup.string(),
		},
		{
			name: "username",
			value: request?.SFTPConnection?.Username,
			validator: Yup.string(),
		},
		{
			name: "password",
			value: request?.SFTPConnection?.Password,
			validator: Yup.string().nullable(),
		},
		{
			name: "privateKey",
			value: request?.SFTPConnection?.PrivateKey,
			validator: Yup.string().nullable(),
		},
		{
			name: "port",
			value: request?.SFTPConnection?.Port || "22",
			validator: Yup.string(),
		},
	];

	const createRequest = useMutateData(gql`
		mutation (
			$context: IntegrationContext!
			$organizationId: Int
			$name: String!
			$url: String!
			$method: String!
			$params: [RequestParamInput]
			$authorizationType: RequestAuthType
			$headers: [RequestParamInput]
			$transformations: [RequestTransformationInput]
			$body: String
			$bodyLanguage: String
			$response: [ResponseMapItemInput]
			$rules: RequestRulesInput
			$basicAuthCredentials: BasicAuthCredentialsInput
			$bearerToken: String
			$sftpConnection: SFTPConnectionInput
		) {
			createRequest(
				context: $context
				organizationId: $organizationId
				name: $name
				url: $url
				method: $method
				params: $params
				authorizationType: $authorizationType
				headers: $headers
				transformations: $transformations
				body: $body
				bodyLanguage: $bodyLanguage
				response: $response
				rules: $rules
				basicAuthCredentials: $basicAuthCredentials
				bearerToken: $bearerToken
				sftpConnection: $sftpConnection
			)
		}
	`);

	const updateRequest = useMutateData(gql`
		mutation (
			$requestId: Int!
			$name: String!
			$url: String!
			$method: String!
			$params: [RequestParamInput]
			$authorizationType: RequestAuthType
			$headers: [RequestParamInput]
			$transformations: [RequestTransformationInput]
			$body: String
			$bodyLanguage: String
			$response: [ResponseMapItemInput]
			$rules: RequestRulesInput
			$basicAuthCredentials: BasicAuthCredentialsInput
			$bearerToken: String
			$sftpConnection: SFTPConnectionInput
		) {
			updateRequest(
				requestId: $requestId
				name: $name
				url: $url
				method: $method
				params: $params
				authorizationType: $authorizationType
				headers: $headers
				transformations: $transformations
				body: $body
				bodyLanguage: $bodyLanguage
				response: $response
				rules: $rules
				basicAuthCredentials: $basicAuthCredentials
				bearerToken: $bearerToken
				sftpConnection: $sftpConnection
			)
		}
	`);

	const deleteRequest = useMutateData(gql`
		mutation ($requestId: Int!) {
			deleteRequest(requestId: $requestId)
		}
	`);

	const duplicateRequest = useMutateData(gql`
		mutation ($requestId: Int!) {
			duplicateRequest(requestId: $requestId)
		}
	`);

	const attemptFileTransferConnection = useMutateData(gql`
		mutation (
			$hostname: String!
			$username: String!
			$password: String
			$privateKey: String
			$port: String
		) {
			attemptFileTransferConnection(
				hostname: $hostname
				username: $username
				password: $password
				privateKey: $privateKey
				port: $port
			)
		}
	`);

	const onSubmit = async (values) => {
		try {
			if (props.mode === "delete") {
				await deleteRequest({
					variables: {
						requestId: request.RequestID,
					},
				});
				props.close();
				return;
			}

			if (props.mode === "duplicate") {
				await duplicateRequest({
					variables: {
						requestId: request.RequestID,
					},
				});
				props.close();
				return;
			}

			const isSFTPMethod = values.method === "SFTP";
			const base = { name: values.name, method: values.method };
			const model = isSFTPMethod
				? {
						...base,
						url: values.hostname,
						sftpConnection: {
							Username: values.username,
							Password:
								values.sftpAuthMethod === "Password" ? values.password : null,
							PrivateKey:
								values.sftpAuthMethod === "PrivateKey"
									? values.privateKey
									: null,
							Port: values.port,
							Directory: values.directory,
						},
				  }
				: {
						...base,
						url: values.url,
						params: values.hasParams
							? values.params.map((param) => ({
									Key: param.key,
									Value: param.value,
									Description: param.description,
							  }))
							: null,
						authorizationType: values.shouldAuthorize
							? values.authorizationType
							: null,
						headers: values.hasHeaders
							? values.headers
									.filter((h) => !h.readonly)
									.map((param) => ({
										Key: param.key,
										Value: param.value,
										Description: param.description,
									}))
							: null,
						transformations: values.hasTransformations
							? values.transformations.map((transformation) => ({
									Variable: {
										Name: transformation.variable?.value,
										Label: transformation.variable?.label,
										Type: transformation.variable?.type,
									},
									Name: transformation.name,
									Label: transformation.label,
									Transformation: transformation.transformation?.value,
									Template: transformation.template,
									Format: transformation.format?.value,
									Hours: transformation.hours
										? parseInt(transformation.hours)
										: null,
									CharacterLength: transformation.characterLength
										? parseInt(transformation.characterLength)
										: null,
									Key: transformation.key ? transformation.key : null,
									Find: transformation.find ? transformation.find : null,
									Replace: transformation.replace
										? transformation.replace
										: null,
							  }))
							: null,
						body: values.body,
						bodyLanguage: values.languageMode,
						response:
							values.responseMethod === "Map"
								? values.response?.map((resp) => ({
										Path: resp.path,
										Variable: resp.variable,
										Type: resp.type,
										Label: resp.label,
										Description: resp.description,
								  }))
								: null,
						rules: values.hasRules
							? {
									PreRequest: values.preRequestRules?.map((rule) => ({
										Type: rule.type,
										Variable: rule.variable,
										ResponseCode: rule.responseCode,
										Condition: rule.condition,
										Outcome: rule.outcome,
										Request: rule.request,
										UnsetVariable: rule.unsetVariable,
									})),
									PostRequest: values.postRequestRules?.map((rule) => ({
										Type: rule.type,
										Variable: rule.variable,
										ResponseCode: rule.responseCode,
										Condition: rule.condition,
										Outcome: rule.outcome,
										Request: rule.request,
										UnsetVariable: rule.unsetVariable,
									})),
							  }
							: null,
						basicAuthCredentials:
							values.shouldAuthorize &&
							values.authorizationType === AUTHORIZATION_TYPES.BASIC
								? {
										Username: values.basicAuthUsername,
										Password: values.basicAuthPassword,
								  }
								: null,
						bearerToken:
							values.shouldAuthorize &&
							values.authorizationType === AUTHORIZATION_TYPES.BEARER
								? values.bearerToken
								: null,
				  };
			if (props.mode === "add") {
				await createRequest({
					variables: {
						context: props.context,
						organizationId: props.organizationId,
						...model,
					},
				});
			} else if (props.mode === "edit") {
				await updateRequest({
					variables: {
						requestId: request.RequestID,
						...model,
					},
				});
			}

			Alert.success(
				`${isAdding ? "Added" : "Updated"} ${
					isSFTPMethod ? "SFTP Connection" : `${values.method} Request`
				}`
			);
		} catch (error) {
			console.log(error);
			Alert.error("Something went wrong.");
		}
		props.close();
	};

	const currentSteps = isSFTP
		? [
				steps.nameStep,
				steps.methodStep(setMethod),
				steps.suggestableInputStep(
					"hostname",
					"Hostname",
					"What is the hostname for this SFTP connection?"
				),
				steps.suggestableInputStep(
					"username",
					"Username",
					"What is the username for this SFTP Connection?"
				),
				steps.sftpPassKeyStep,
				steps.suggestableInputStep(
					"port",
					"Port",
					"What is the port for this SFTP Connection?"
				),
				steps.summaryStep(attemptFileTransferConnection),
		  ]
		: [
				steps.nameStep,
				steps.methodStep(setMethod),
				steps.suggestableInputStep(
					"url",
					"URL",
					"What is the URL for this request?"
				),
				steps.paramsStep,
				steps.authorizationStep,
				steps.headersStep,
				steps.transformStep,
				method === "POST" ? steps.bodyStep(editorRef) : null,
				steps.responseStep,
				steps.rulesStep,
				steps.summaryStep(),
		  ].filter((s) => s);

	if (props.mode === "delete") {
		return (
			<WizardLayout
				rootTabIndex={null}
				close={props.close}
				title={"Delete Request"}
				onSubmit={onSubmit}
				values={initialValues}
				steps={[steps.deleteStep]}
				initialStep={0}
				wizardProps={props}
			/>
		);
	}

	if (props.mode === "duplicate") {
		return (
			<WizardLayout
				rootTabIndex={null}
				close={props.close}
				title={"Duplicate Request"}
				onSubmit={onSubmit}
				values={initialValues}
				steps={[steps.duplicateStep]}
				initialStep={0}
				wizardProps={props}
			/>
		);
	}

	const width = "1300px";
	return (
		<WizardLayout
			widths={{
				name: "800px",
				method: "800px",
				url: "800px",
				hostname: "800px",
				username: "800px",
				privateKey: "800px",
				params: width,
				authorization: width,
				headers: width,
				transform: "1520px",
				body: width,
				response: width,
				rules: width,
			}}
			rootTabIndex={null}
			close={props.close}
			title={`${isAdding ? "Add" : "Edit"} ${
				isSFTP ? "SFTP Connection" : `${method} Request`
			}`}
			onSubmit={onSubmit}
			values={initialValues}
			steps={currentSteps}
			initialStep={isAdding ? 0 : currentSteps.length - 1}
			wizardProps={props}
		/>
	);
}
