import * as installationApi from "../../../api/installation";
import { StepText, StepTitle } from "../WizardLayout";
import { cloneDeep, find, map } from "lodash";
import BeaconLookup from "../../wizards/BeaconLookup";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import MultiChoiceButton from "../MultiChoiceButton";
import React from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";

import { useFetchData } from "../../../hooks";

function getButton(
	name,
	color,
	onClick,
	isDisabled,
	isEditing,
	goTo,
	isValidationType,
	keyStrokeHandler
) {
	let label = isEditing
		? isValidationType
			? "Next"
			: "Review"
		: `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
	return (
		<Button
			key={name}
			color={isEditing ? "blue" : color}
			onClick={
				isEditing
					? () => (isValidationType ? goTo("beaconValue") : goTo("summary"))
					: onClick
			}
			disabled={isDisabled}
			keyStrokeHandler={keyStrokeHandler}
		>
			{label}
		</Button>
	);
}

export function location({
	close,
	next,
	isSubmitting,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting || !values.location;

	return {
		id: "location",
		label: "Location",
		render: () => (
			<div>
				<StepTitle>What is the location of the beacon?</StepTitle>
				<EditableInputField
					type="text"
					name="location"
					value={values.location || ""}
					useLabel="Location"
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("cancel", "red", close)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function uniqueId({
	previous,
	next,
	isSubmitting,
	values,
	goTo,
	wizardProps,
	setFieldValue,
	keyStrokeHandler,
}) {
	const isIncomplete =
		isSubmitting ||
		!values.uniqueId ||
		!values.uniqueId === "" ||
		!values.uniqueIdValid;

	return {
		id: "uniqueId",
		label: "Unique ID",
		render: () => (
			<div>
				<StepTitle>What is the Unique ID of the beacon?</StepTitle>
				<BeaconLookup
					uniqueId={values.uniqueId || ""}
					organizationId={wizardProps.organizationId}
					mode={"validationBeacon"}
					onChange={(value) => {
						setFieldValue("uniqueId", value.uniqueId);
						setFieldValue("uniqueIdValid", value.uniqueIdValid);
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function selfValidation({
	previous,
	next,
	isSubmitting,
	setFieldValue,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting;

	return {
		id: "selfValidation",
		label: " Self Validation",
		render: () => (
			<div>
				<StepTitle>Is this beacon for self validation?</StepTitle>
				<div>
					If this validation should not stack with other validations then choose
					Yes. <br />
					<br />
					Note that this will only stop it stacking with other beacons with this
					setting turned on and that stacking rules only apply if the site has
					stacking validation enabled.
				</div>
				<MultiChoiceButton
					selected={!values.selfValidation}
					onClick={() => {
						setFieldValue("selfValidation", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.selfValidation}
					onClick={() => {
						setFieldValue("selfValidation", true);
					}}
				>
					Yes
				</MultiChoiceButton>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function beaconType({
	previous,
	next,
	isSubmitting,
	setFieldValue,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isGroupAccess = values.validationType === "GroupAccess";
	return {
		id: "beaconType",
		label: "Type",
		render: () => (
			<div>
				<StepTitle>Is this beacon to add users to a group?</StepTitle>
				<div>
					Users will be able to scan this beacon to be automatically added to a
					group, granting them access to private sites or special pricing.
				</div>
				<MultiChoiceButton
					selected={!isGroupAccess}
					onClick={() => {
						setFieldValue("validationType", "");
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={isGroupAccess}
					onClick={() => {
						setFieldValue("validationType", "GroupAccess");
					}}
				>
					Yes
				</MultiChoiceButton>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						() => {
							if (isGroupAccess) {
								next();
							} else {
								goTo("selfValidation");
							}
						},
						isSubmitting,
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function groupAccess({
	previous,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
	goTo,
	keyStrokeHandler,
}) {
	return {
		id: "beaconGroupAccess",
		label: "Group Access",
		render: () => (
			<div>
				<StepTitle>Select a group</StepTitle>
				<Dropdown
					options={wizardProps.organizationAccessGroups.map((group) => ({
						label: group.Name,
						value: group.OrganizationAccessGroupID,
					}))}
					value={values.accessGroup}
					onChange={(value) => {
						setFieldValue("accessGroup", value);
					}}
					isClearable
					isDisabled={values.beaconSites.length}
				/>
				<StepTitle style={{ marginTop: "24px" }}>
					Or a list of sites to include
				</StepTitle>
				<Dropdown
					isMulti={true}
					options={wizardProps.fileTransferSites.map((site) => ({
						label: site.Name,
						value: site.SiteID,
					}))}
					value={values.beaconSites.map((site) => ({
						value: site.id,
						label: site.name,
					}))}
					onChange={(value) => {
						setFieldValue(
							"beaconSites",
							value.map((option) => ({ id: option.value, name: option.label }))
						);
					}}
					isDisabled={!!values.accessGroup}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						() => goTo("summary"),
						isSubmitting || (!values.accessGroup && !values.beaconSites.length),
						values.editFromSummary,
						goTo,
						undefined,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

export function sites({
	next,
	previous,
	setFieldValue,
	isSubmitting,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const availableOptions = [{ label: "All Sites", value: 0 }].concat(
		values.availableSites.map((site) => ({
			label: site.name,
			value: site.id,
		}))
	);

	const isIncomplete = isSubmitting || !values.beaconSites.length;

	const getBeaconSiteWithRateId = (value) => {
		const beaconSites = cloneDeep(values.beaconSites);
		let result = [];

		value.forEach((d) => {
			beaconSites.forEach((beaconSite) => {
				if (beaconSite.id === d.value && beaconSite.rateId) {
					d.rateId = beaconSite.rateId;
				}
			});
			result.push({
				id: d.value || d.id,
				name: d.label || d.name,
				rateId: d.rateId,
			});
		});

		return result;
	};

	return {
		id: "sites",
		label: "Select Sites",
		render: () => (
			<div>
				<StepTitle>Select sites</StepTitle>

				<Dropdown
					isMulti={true}
					options={availableOptions}
					value={values.beaconSites.map((d) => ({
						label: d.name,
						value: d.id,
					}))}
					onChange={(options) => {
						find(options, ({ value }) => value === 0)
							? setFieldValue(
									"beaconSites",
									getBeaconSiteWithRateId(values.availableSites)
							  )
							: setFieldValue(
									"beaconSites",
									options ? getBeaconSiteWithRateId(options) : []
							  );
					}}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					<Button
						key="next"
						color="blue"
						onClick={() =>
							values.editFromSummary ? goTo("beaconValue") : next()
						}
						disabled={isSubmitting || isIncomplete}
						keyStrokeHandler={keyStrokeHandler}
					>
						Next
					</Button>,
				]}
			/>
		),
	};
}

export function validationType({
	previous,
	next,
	isSubmitting,
	handleChange,
	values,
	goTo,
	keyStrokeHandler,
}) {
	const isIncomplete = isSubmitting || !values.validationType;

	return {
		id: "validationType",
		label: "Validation Type",
		render: () => (
			<div>
				<StepTitle>What is the beacon type?</StepTitle>
				<Dropdown
					options={values.validationTypeOptions}
					value={
						values.validationTypeOptions.find(
							(e) => e.value === values.validationType
						) || ""
					}
					onChange={(value) =>
						handleChange({
							target: {
								name: "validationType",
								value: value.value,
							},
						})
					}
				/>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [getButton("back", "blue", previous)]
				}
				rightItems={[
					getButton(
						"next",
						"blue",
						next,
						isIncomplete,
						values.editFromSummary,
						goTo,
						true,
						keyStrokeHandler
					),
				]}
			/>
		),
	};
}

function ChooseValidationRate(props) {
	const { data: rates, isLoading } = useFetchData(
		[],
		installationApi.getCurrentRatesByOrganizationId,
		[props.organizationId],
		[]
	);

	const getRatesOptions = (siteId, _rates) => {
		let options = [];

		_rates
			.filter((rate) => rate.IsValidationRate && rate.SiteID === siteId)
			.forEach((rate) =>
				options.push({
					value: rate.RateID,
					label: `${rate.RateID} - ${rate.Name}`,
				})
			);

		return options;
	};

	const handleValueChange = (value, key) => {
		const _sites = cloneDeep(props.beaconSites);
		_sites[key].id = _sites[key].id || _sites[key].SiteID;
		_sites[key].rateId = value;

		return _sites;
	};

	return (
		<div>
			{!isLoading &&
				props.beaconSites.map((beaconSite, key) => {
					const options = getRatesOptions(beaconSite.id, rates);
					return (
						<div key={key} style={{ marginTop: "15px" }}>
							<StepText>{beaconSite.name}</StepText>
							{options.length ? (
								<div style={{ marginTop: "5px" }}>
									<Dropdown
										options={options}
										value={options.find((e) => e.value === beaconSite.rateId)}
										isDisabled={options.length === 0}
										onChange={(value) => {
											props.handleChange({
												target: {
													name: "beaconSites",
													value: handleValueChange(value.value, key),
												},
											});
										}}
									/>
								</div>
							) : (
								<p>
									No validation rate available. Please add a validation rate in{" "}
									the Rates page or remove the site from this beacon.
								</p>
							)}
						</div>
					);
				})}
		</div>
	);
}

export function beaconValue({
	values,
	errors,
	touched,
	previous,
	next,
	handleChange,
	isSubmitting,
	goTo,
	keyStrokeHandler,
}) {
	let isIncomplete;

	if (values.validationType === "Rate") {
		let isCompleted = null;
		map(values.beaconSites, (beaconSite) => {
			if (isCompleted === null) {
				isCompleted = !isNaN(beaconSite.rateId);
			} else {
				isCompleted = isCompleted && !isNaN(beaconSite.rateId);
			}
		});

		isIncomplete = isSubmitting || !isCompleted;
		return {
			id: "beaconValue",
			label: "Validation Rate",
			render: () => (
				<div>
					<StepTitle>
						Which validation rate should be applied to each site?
					</StepTitle>
					<ChooseValidationRate
						beaconSites={values.beaconSites}
						handleChange={handleChange}
						organizationId={values.organizationId}
					/>
				</div>
			),
			footer: () => (
				<WizardNavigation
					leftItems={getButton("back", "blue", previous)}
					rightItems={[
						getButton(
							"next",
							"blue",
							next,
							isIncomplete,
							values.editFromSummary,
							goTo,
							undefined,
							keyStrokeHandler
						),
					]}
				/>
			),
		};
	} else {
		isIncomplete = isSubmitting || !values.beaconValue;

		return {
			id: "beaconValue",
			label: `${
				values.validationType === "GroupAccess"
					? "Group Access"
					: values.validationType || "Value"
			}`,
			render: () => (
				<div>
					<StepTitle>
						What is the {values.validationType.toLowerCase() + " amount"}?
					</StepTitle>
					<EditableInputField
						type="number"
						min="0d"
						name="beaconValue"
						value={values.beaconValue || ""}
						useLabel={values.validationType}
						error={
							errors.beaconValue && touched.beaconValue
								? errors.beaconValue
								: null
						}
					/>
				</div>
			),
			footer: () => (
				<WizardNavigation
					leftItems={
						!values.editFromSummary && [getButton("back", "blue", previous)]
					}
					rightItems={[
						getButton(
							"next",
							"blue",
							next,
							isIncomplete,
							values.editFromSummary,
							goTo,
							undefined,
							keyStrokeHandler
						),
					]}
				/>
			),
		};
	}
}

export function summary({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps: { mode },
	keyStrokeHandler,
}) {
	const isGroupAccess = values.validationType === "GroupAccess";

	const items = [
		{
			title: "Location",
			value: values.location,
			key: "location",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("location");
			},
		},
		{
			title: "Unique ID",
			value: values.uniqueId,
			key: "uniqueId",
		},
		{
			title: "Beacon type",
			value:
				values.validationType === "GroupAccess"
					? "Group Access"
					: values.validationType,
			key: "validationType",
			edit: () => {
				setFieldValue("editFromSummary", true);
				if (isGroupAccess) {
					goTo("beaconType");
				} else {
					goTo("validationType");
				}
			},
		},
		{
			title: "Sites",
			value: values.beaconSites?.map((site) => site.name).join(", "),
			key: "sites",
			edit: () => {
				setFieldValue("editFromSummary", true);
				if (isGroupAccess) {
					goTo("beaconGroupAccess");
				} else {
					goTo("sites");
				}
			},
		},
	];

	if (isGroupAccess) {
		items.push({
			title: "Group",
			value: values.accessGroup?.label,
			key: "accessGroup",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("beaconGroupAccess");
			},
		});
	} else {
		items.push(
			{
				title: "Self Validation",
				value: values.selfValidation ? "Yes" : "No",
				key: "selfValidation",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("selfValidation");
				},
			},
			{
				title:
					values.validationType === "Rate"
						? "Rate"
						: `${values.validationType} Amount`,
				value:
					values.validationType === "Rate"
						? values.beaconSites
								.filter((d) => d.rateId)
								.map((site) => `${site.name} - ${site.rateId}`)
								.join(", ")
						: values.validationType === "Fixed"
						? `-$${values.beaconValue}`
						: `-${values.beaconValue}%`,
				key: "beaconValue",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("beaconValue");
				},
			}
		);
	}

	let button = getButton(
		"submit",
		"green",
		handleSubmit,
		isSubmitting,
		undefined,
		undefined,
		undefined,
		keyStrokeHandler
	);

	if (mode === "delete") {
		items.forEach((item) => delete item.edit);
		button = getButton("remove", "red", handleSubmit, isSubmitting);
	}

	return {
		id: "summary",
		label: "Summary",
		render: () => (
			<div>
				<StepTitle>Summary</StepTitle>
				<SummaryTable items={items} />
			</div>
		),
		footer: () => <WizardNavigation rightItems={[button]} />,
	};
}
