export const lightTheme = {
	red: "#d04a51",
	green: "#1bc88e",
	yellow: "#ffba00",
	orange: "#FF872F",
	darkerBlue: "#3f4c56",
	highlightBlue: "#495863",
	blue: "#4a90e2",
	lighterBlue: "#1ca8dd",
	darkGrey: "#444",
	midGrey: "#aaa",
	borderGrey: "#ccc",
	lightGrey: "#eae9e9",
	highlightGrey: "#f2f2f2",
	offWhite: "#fafafa",
	white: "#fff",
	background: "#fff",
	black: "#000000",

	//SECURE COLORS
	secureBlue: "#0193CF",

	// SIPPI COLORS
	sippiPurple: "#5f259f",
	sippiRed: "#ff0000",
};

export const darkTheme = {
	red: "#d04a51",
	green: "#1bc88e",
	yellow: "#ffba00",
	orange: "#FF872F",
	darkerBlue: "#21282d",
	highlightBlue: "#353f46",
	blue: "#4a90e2",
	lighterBlue: "#1ca8dd",
	darkGrey: "#fcfcfc",
	midGrey: "#aaa",
	borderGrey: "#ccc",
	lightGrey: "#5e5e5e",
	highlightGrey: "#444",
	offWhite: "#2f2f2f",
	white: "#fafafa",
	background: "#1f1f1f",
	black: "#000000",

	//SECURE COLORS
	secureBlue: "#0193CF",

	// SIPPI COLORS
	sippiPurple: "#5f259f",
	sippiRed: "#ff0000",
};

export const mediaSizes = {
	medium: 1100,
	small: 640,
};

export const boxShadows = {
	light: "0px 2px 5px rgba(0, 0, 0, 0.05)",
	medium: "0px 2px 5px rgba(0, 0, 0, 0.1)",
	standard: "0px 2px 10px rgba(0, 0, 0, 0.1)",
	right: "2px 0 5px rgba(0, 0, 0, 0.1)",
};

let theme = window.localStorage ? window.localStorage.getItem("theme") : "";
let themeColours = theme === "dark" ? darkTheme : lightTheme;

export const colours = themeColours;
