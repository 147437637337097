import Tooltip from "rc-tooltip";
import React, { useState } from "react";
import {
	DownloadCloud,
	FileText,
	MoreHorizontal,
	Plus,
	UploadCloud,
} from "react-feather";
import { Redirect, Route, Switch } from "react-router-dom";
import reactStringReplace from "react-string-replace";
import Button from "../../components/layout/Button";
import DropdownMenuBeta from "../../components/layout/DropdownMenuBeta";
import Label from "../../components/layout/Label";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import IntegrationWizard from "../../components/wizards/integration-wizard";
import RequestWizard from "../../components/wizards/request-wizard";
import VariableWizard from "../../components/wizards/variable-wizard";
import {
	COLORS_BY_REQUEST_METHOD,
	EVENT_TYPES_TRANSLATIONS,
	INTEGRATION_TYPES,
	INTEGRATION_TYPES_TRANSLATIONS,
	LOG_LEVEL_LABEL_BY_COLOUR,
} from "../../helpers/constants";
import { usePermissions } from "../../hooks";
import ErrorBoundary from "../ErrorBoundary";
import { useGetCamerasForOrg } from "./queries/cameras";
import {
	useGetIntegrations,
	useGetRequests,
	useGetVariables,
} from "./queries/integrations";
import { useGetAvailableSitesForOrg } from "./queries/sites";

const contextDescriptions = {
	Account: "Applies to all operators, clients and sites",
	Operator: "Applies to all clients and sites within the current operator",
	Client: "Applies to all sites",
};

const columns = (props) => [
	{
		id: "IntegrationTypeIcon",
		fixedWidth: 50,
		Cell: ({ row: { original: integration } }) => {
			switch (integration.IntegrationType) {
				case INTEGRATION_TYPES.WEBHOOK:
					return <UploadCloud style={{ marginTop: "5px" }} />;
				case INTEGRATION_TYPES.POLLING:
					return <DownloadCloud style={{ marginTop: "5px" }} />;
				case INTEGRATION_TYPES.FILE_TRANSFER:
					return <FileText style={{ marginTop: "5px" }} />;
				default:
					break;
			}
		},
	},
	{
		id: "IntegrationID",
		fixedWidth: 50,
		Header: "ID",
		accessor: (d) => d.IntegrationID,
	},
	{
		id: "IsEnabled",
		Header: "Status",
		fixedWidth: 100,
		accessor: (d) =>
			d?.IsEnabled ? (
				<Label style={{ fontFamily: "Arial" }} color="green">
					Enabled
				</Label>
			) : (
				<Label style={{ fontFamily: "Arial" }} color="yellow">
					Disabled
				</Label>
			),
	},
	{
		id: "LoggingEnabled",
		Header: "Logging Enabled",
		fixedWidth: 100,
		accessor: (d) =>
			d?.LoggingEnabled ? (
				<Label style={{ fontFamily: "Arial" }} color="green">
					Enabled
				</Label>
			) : (
				<Label style={{ fontFamily: "Arial" }} color="yellow">
					Disabled
				</Label>
			),
	},
	{
		id: "IntegrationType",
		Header: "Type",
		accessor: (d) => INTEGRATION_TYPES_TRANSLATIONS[d.IntegrationType],
		fixedWidth: 120,
	},
	{
		id: "EventType",
		Header: "Event",
		fixedWidth: 200,
		accessor: (d) => EVENT_TYPES_TRANSLATIONS[d.EventType]?.toLowerCase() || "",
	},
	{
		id: "Request",
		Header: "Request",
		fixedWidth: 400,
		accessor: (d) => (
			<>
				<Label
					style={{ marginRight: "10px", fontFamily: "Arial" }}
					color={COLORS_BY_REQUEST_METHOD[d.FetchRequest?.Method]}
				>
					{d.FetchRequest?.Method}
				</Label>{" "}
				{d.FetchRequest?.Name}
			</>
		),
	},
	{
		id: "Context",
		Header: "Context",
		fixedWidth: 600,
		accessor: (d) => (
			<>
				{props.context === "Client"
					? [
							{ property: "Sites", display: "Name" },
							{ property: "Cameras", display: "SerialNumber" },
					  ].map((model, mId) =>
							d[model.property]?.length ? (
								<div key={`model${mId}`}>
									<strong>{model.property}: </strong>
									<span>
										{d[model.property]
											?.map((item) => item[model.display])
											?.join(", ")}
									</span>
								</div>
							) : (
								""
							)
					  )
					: contextDescriptions[props.context]}
			</>
		),
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original: integration } }) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="edit-integration"
						onClick={() => props.openIntegrationWizard("edit", integration)}
					>
						Edit
					</div>,
					props.isAdmin ? (
						<div
							key="toggle-integration"
							onClick={() => props.openIntegrationWizard("toggle", integration)}
						>
							{integration?.IsEnabled ? "Disable" : "Enable"}
						</div>
					) : null,
					props.isAdmin ? (
						<div
							key="delete-integration"
							onClick={() => props.openIntegrationWizard("delete", integration)}
						>
							Delete
						</div>
					) : null,
				]}
			/>
		),
		resizable: false,
		fixedWidth: 50,
		sortable: false,
	},
];

const columnsForRequests = (props) => [
	{
		id: "Method",
		Header: "Method",
		fixedWidth: 100,
		accessor: (request) => (
			<Label
				style={{ marginRight: "10px", fontFamily: "Arial" }}
				color={COLORS_BY_REQUEST_METHOD[request.Method]}
			>
				{request.Method}
			</Label>
		),
	},
	{
		id: "Name",
		Header: "Name",
		fixedWidth: 400,
		accessor: (request) => request.Name,
	},
	{
		id: "URL",
		Header: "URL",
		accessor: (request) => (
			<>
				{reactStringReplace(request.URL, /{{{(.*?)}}}/, (match) => (
					<Tooltip
						key={`url${request.RequestID}`}
						overlay={
							props.variables?.find((variable) => variable.Name === match)
								?.Value
						}
						placement="top"
					>
						<Label
							style={{
								marginRight: "10px",
								fontFamily: "Arial",
								cursor: "pointer",
							}}
							color="green"
						>
							{match}
						</Label>
					</Tooltip>
				))}
			</>
		),
	},
	{
		id: "Rules",
		Header: "Rules",
		accessor: (request) => {
			const ruleCount =
				(request.Rules?.PreRequest?.length || 0) +
				(request.Rules?.PostRequest?.length || 0);

			return ruleCount
				? `${ruleCount} Rule${ruleCount === 1 ? "" : "s"} Set`
				: "None";
		},
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original: request } }) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="edit-request"
						onClick={() => props.openRequestWizard("edit", request)}
					>
						Edit
					</div>,
					props.isAdmin ? (
						<div
							key="delete-request"
							onClick={() => props.openRequestWizard("delete", request)}
						>
							Delete
						</div>
					) : null,
					<div
						key="duplicate-request"
						onClick={() => props.openRequestWizard("duplicate", request)}
					>
						Duplicate
					</div>,
				]}
			/>
		),
		resizable: false,
		fixedWidth: 50,
		sortable: false,
	},
];

const columnsForVariables = (props) => [
	{
		id: "Name",
		Header: "Name",
		accessor: (variable) => variable.Name,
	},
	{
		id: "Label",
		Header: "Label",
		accessor: (variable) => variable.Label,
	},
	{
		id: "Value",
		Header: "Value",
		accessor: (variable) =>
			variable.IsSecret ? (
				<strong>••••••••••••••••••••</strong>
			) : (
				variable.Value
			),
	},
	{
		id: "Type",
		Header: "Type",
		accessor: (variable) => variable.Type,
	},
	{
		id: "Description",
		Header: "Description",
		accessor: (variable) => variable.Description,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original: variable } }) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="edit-variable"
						onClick={() => props.openVariableWizard("edit", variable)}
					>
						Edit
					</div>,
					props.isAdmin ? (
						<div
							key="delete-variable"
							onClick={() => props.openVariableWizard("delete", variable)}
						>
							Delete
						</div>
					) : null,
				]}
			/>
		),
		resizable: false,
		fixedWidth: 50,
		sortable: false,
	},
];

function IntegrationContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		search: "",
		wizardMode: "",
		integrationWizardOpen: false,
		requestWizardOpen: false,
		variableWizardOpen: false,
		integration: null,
		request: null,
		variable: null,
	});

	const organizationId =
		props.context === "Client"
			? props.selectedOrganization.OrganizationID
			: null;

	const isAdmin = usePermissions("IsAdmin");
	// const isCSAdmin = usePermissions("IsCSAdmin");
	// const isSuperAdmin = isAdmin && !isCSAdmin;

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const openIntegrationWizard = (mode, integration) => {
		setState((_state) => ({
			..._state,
			integration,
			wizardMode: mode,
			integrationWizardOpen: true,
		}));
	};

	const openRequestWizard = (mode, request) => {
		setState((_state) => ({
			..._state,
			request,
			wizardMode: mode,
			requestWizardOpen: true,
		}));
	};

	const openVariableWizard = (mode, variable) => {
		setState((_state) => ({
			..._state,
			variable,
			wizardMode: mode,
			variableWizardOpen: true,
		}));
	};

	const { cameras, isLoading: camerasAreLoading } =
		useGetCamerasForOrg(organizationId);

	const { sites, isLoading: sitesAreLoading } =
		useGetAvailableSitesForOrg(organizationId);

	const {
		integrations,
		isLoading: integrationsAreLoading,
		refetch: refetchIntegrations,
	} = useGetIntegrations(props.context, organizationId);

	const {
		requests,
		isLoading: requestsAreLoading,
		refetch: refetchRequests,
	} = useGetRequests(props.context, organizationId);

	const {
		variableGroups,
		isLoading: variablesAreLoading,
		refetch: refetchVariables,
	} = useGetVariables(props.context, organizationId);

	if (state.integrationWizardOpen) {
		return (
			<IntegrationWizard
				{...props}
				mode={state.wizardMode}
				close={() => {
					refetchIntegrations();
					setState((_state) => ({
						..._state,
						integrationWizardOpen: false,
					}));
				}}
				organizationId={organizationId}
				cameras={cameras}
				sites={sites}
				requests={requests}
				integration={state.integration}
			/>
		);
	}

	if (state.requestWizardOpen) {
		return (
			<RequestWizard
				{...props}
				mode={state.wizardMode}
				close={() => {
					refetchRequests();
					refetchVariables();
					setState((_state) => ({
						..._state,
						requestWizardOpen: false,
					}));
				}}
				organizationId={organizationId}
				request={state.request}
				requests={requests}
				variableGroups={variableGroups}
			/>
		);
	}

	if (state.variableWizardOpen) {
		return (
			<VariableWizard
				{...props}
				mode={state.wizardMode}
				close={() => {
					refetchVariables();
					setState((_state) => ({
						..._state,
						variableWizardOpen: false,
					}));
				}}
				organizationId={organizationId}
				variableGroups={variableGroups}
				requests={requests}
				variable={state.variable}
			/>
		);
	}

	const variables =
		variableGroups?.find((group) => group.Name === "Custom")?.Variables || [];

	const dataIsLoading =
		camerasAreLoading ||
		sitesAreLoading ||
		integrationsAreLoading ||
		requestsAreLoading ||
		variablesAreLoading ||
		false;

	return (
		<ErrorBoundary>
			<Switch>
				<Route
					exact={true}
					path={`${props.match.url}`}
					render={() => (
						<div>
							<ReportWrapperBeta
								{...props}
								title={`${props.context} Integrations`}
								data={integrations}
								columns={columns({
									isAdmin,
									openIntegrationWizard,
									context: props.context,
								})}
								defaultSortBy={[
									{
										id: "IntegrationID",
										desc: false,
									},
								]}
								updateOptions={updateOptions}
								loading={dataIsLoading}
								showDateRangePicker={false}
								showSitePicker={false}
								showSearchBox={false}
								rightActions={
									<Button
										color="blue"
										style={{ marginLeft: 8 }}
										onClick={() => openIntegrationWizard("add")}
									>
										<Plus size={20} /> Add Integration
									</Button>
								}
							/>
						</div>
					)}
				/>
				<Route
					path={`${props.match.url}/requests`}
					render={() => (
						<div>
							<ReportWrapperBeta
								{...props}
								title={`${props.context} Requests`}
								data={requests}
								columns={columnsForRequests({
									isAdmin,
									openRequestWizard,
									variables,
								})}
								defaultSortBy={[
									{
										id: "RequestID",
										desc: false,
									},
								]}
								updateOptions={updateOptions}
								loading={dataIsLoading}
								showDateRangePicker={false}
								showSitePicker={false}
								showSearchBox={false}
								rightActions={
									<Button
										color="blue"
										style={{ marginLeft: 8 }}
										onClick={() => openRequestWizard("add")}
									>
										<Plus size={20} /> Add Request
									</Button>
								}
							/>
						</div>
					)}
				/>
				<Route
					path={`${props.match.url}/variables`}
					render={() => (
						<div>
							<ReportWrapperBeta
								{...props}
								title={`${props.context} Variables`}
								data={variables}
								columns={columnsForVariables({
									...props,
									isAdmin,
									openVariableWizard,
								})}
								defaultSortBy={[
									{
										id: "Name",
										desc: false,
									},
								]}
								updateOptions={updateOptions}
								loading={dataIsLoading}
								showDateRangePicker={false}
								showSitePicker={false}
								showSearchBox={false}
								rightActions={
									<Button
										color="blue"
										style={{ marginLeft: 8 }}
										onClick={() => openVariableWizard("add")}
									>
										<Plus size={20} /> Add Variable
									</Button>
								}
							/>
						</div>
					)}
				/>
				<Redirect to={`${props.match.url}`} />
			</Switch>
		</ErrorBoundary>
	);
}

export default IntegrationContainer;
