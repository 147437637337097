import * as Yup from "yup";
import * as installationApi from "../../api/installation";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import PageActions, {
	LeftActions,
	RightActions,
} from "../../components/layout/PageActions";
import React, { useContext, useEffect, useState } from "react";
import { useFetchData, usePermissions } from "../../hooks";
import Accordion from "../../components/layout/Accordion";
import Alert from "react-s-alert";
import { AppContext } from "../../context/app-context";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import Dropdown from "../../components/layout/Dropdown";
import EditableInputField from "../../components/layout/EditableInputField";
import FormLayout from "../../components/layout/FormLayout";
import LoadingPlaceholder from "../../components/report/LoadingPlaceholder";
import PageTitle from "../../components/layout/PageTitle";
import { Plus } from "react-feather";
import RateTesterWizard from "../../components/wizards/rate-tester-wizard";
import RateWizard from "../../components/wizards/rates-wizard";
import { isEqual } from "lodash";
import moment from "moment";
import styled from "styled-components";

moment.updateLocale("en", {
	week: {
		dow: 1,
		doy: 1,
	},
});

const localizer = momentLocalizer(moment);

const FieldWrapper = styled.div`
	margin: 16px 32px;
`;

const FieldContent = styled.div`
	> * {
		margin: 0;
		max-width: 600px;
		min-height: 32px;
	}
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;

export default function RatesContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		groups: [],
		events: null,
		rates: [],
		currentDate: new Date(),
		rateWizardOpen: false,
		rateTesterWizardOpen: false,
		mode: "add",
	});

	const dayIndex = {
		Mon: 0,
		Tue: 1,
		Wed: 2,
		Thu: 3,
		Fri: 4,
		Sat: 5,
		Sun: 6,
	};
	const calendarColors = {
		Complex: "#d04a51",
		EarlyBird: "#4a90e2",
		Daytime: "#4a90e2",
		Validation: "#f0ad4e",
		Group: "#000",
		Event: "#d04a51",
	};

	const canManageRates = usePermissions(null, "ManageRates", true);

	function getEventByDay(day, rate, startOfWeek) {
		let events = [];
		let isOpen = false;
		let start;
		let end;

		const date = startOfWeek.clone().add(dayIndex[day], "days");
		const beginingOfTheDay = moment(
			date.format("DD/MM/YYYY 00:00"),
			"DD/MM/YYYY HH:mm"
		);

		if (rate.RateClass === "Complex") {
			const enterOnDay = dayIndex[rate.EnterOnDay];
			const existOnDay = dayIndex[rate.ExitOnDay || rate.ExitOnOrBeforeDay];
			const currentDayIndex = dayIndex[day];

			//if the rate wrap two weeks
			if (enterOnDay > existOnDay) {
				isOpen =
					(currentDayIndex >= enterOnDay && currentDayIndex <= 6) ||
					(currentDayIndex >= 0 && currentDayIndex <= existOnDay);
			} else {
				isOpen = currentDayIndex >= enterOnDay && currentDayIndex <= existOnDay;
			}

			if (isOpen) {
				//if the advance rate enter and exist on the same day
				if (enterOnDay === currentDayIndex && existOnDay === currentDayIndex) {
					start = beginingOfTheDay.clone().add(rate.EntryLaterThan, "seconds");
					end = beginingOfTheDay.clone().add(rate.ExitEarlierThan, "seconds");
				}
				//if the rate enter on current day but exit on a different day
				if (enterOnDay === currentDayIndex && existOnDay !== currentDayIndex) {
					start = beginingOfTheDay.clone().add(rate.EntryLaterThan, "seconds");
					end = beginingOfTheDay.clone().add(86340, "seconds");
				}
				//if the rate endter on a different day but exit on current day
				if (enterOnDay !== currentDayIndex && existOnDay === currentDayIndex) {
					start = beginingOfTheDay;
					end = beginingOfTheDay.clone().add(rate.ExitEarlierThan, "seconds");
				}
				//if the rate endter and exit on a different day
				if (enterOnDay !== currentDayIndex && existOnDay !== currentDayIndex) {
					start = beginingOfTheDay;
					end = beginingOfTheDay.clone().add(86340, "seconds");
				}
			}
		} else {
			isOpen = rate[`${day}`];
			start = beginingOfTheDay.clone().add(rate[`${day}Start`], "seconds");
			const endSeconds = rate[`${day}End`];
			end = beginingOfTheDay.clone().add(endSeconds, "seconds");
		}

		rate.RateClassForCSS = rate.IsValidationRate
			? "Validation"
			: rate.CachedOrganizationAccessGroupIDs
			? "Group"
			: rate.RateClass;

		if (isOpen) {
			events = getEvent(rate, start, end);
		}

		// fix rates that wrap overnight from sunday to monday
		if (
			day === "Mon" &&
			rate[`${day}Start`] > rate[`${day}End`] &&
			rate.Sun === 1
		) {
			isOpen = 1;
			start = beginingOfTheDay;
			end = beginingOfTheDay.clone().add(rate[`${day}End`], "seconds");
			events = [...events, ...getEvent(rate, start, end)];
		}

		return events;
	}

	function getEvent(rate, start, end) {
		let event;
		if (start > end) {
			event = [
				{
					start: start.toDate(),
					end: start.clone().endOf("day").toDate(),
					endTime: start.clone().add(1, "day").startOf("day").toDate(),
					title: rate.Name,
					hexColor: calendarColors[rate.RateClassForCSS],
					rate,
				},
				{
					start: start.clone().add(1, "day").startOf("day").toDate(),
					end: end.clone().add(1, "day").toDate(),
					endTime: end.toDate(),
					title: rate.Name,
					hexColor: calendarColors[rate.RateClassForCSS],
					rate,
				},
			];
		} else {
			const clone = end.clone();
			let time = clone.format("HH:mm");
			//to fill an event to the end of the day in BigCalendar, the dates need to be from StartDate to 23:59
			//if we used 00:00 then BigCalendar will recognise that as an overlapping range and will try to display
			//the event on both days, which is visually incorrect for what we want to convey to the end user
			//if the end time is 00:00, we'll reduce it to 23:59 for display purposes
			if (time === "00:00") {
				time = "23:59";
			}
			event = [
				{
					start: start.toDate(),
					end: start
						.clone()
						.startOf("day")
						.add(moment.duration(time).asSeconds(), "seconds")
						.toDate(),
					endTime: end.toDate(),
					title: rate.Name,
					hexColor: calendarColors[rate.RateClassForCSS],
					rate,
				},
			];
		}

		return event;
	}

	function eventStyleGetter(event) {
		var backgroundColor = event.hexColor;
		var style = {
			backgroundColor: backgroundColor,
			borderRadius: "0px",
			opacity: 0.8,
			color: "white",
			border: "0px",
			display: "block",
		};
		return {
			style: style,
		};
	}

	function getWeeklyEvents(start, rates) {
		let events = [];

		const startOfWeek = moment(start).startOf("isoWeek");

		for (const rate of rates) {
			const eventsForRate = [
				...getEventByDay("Mon", rate, startOfWeek),
				...getEventByDay("Tue", rate, startOfWeek),
				...getEventByDay("Wed", rate, startOfWeek),
				...getEventByDay("Thu", rate, startOfWeek),
				...getEventByDay("Fri", rate, startOfWeek),
				...getEventByDay("Sat", rate, startOfWeek),
				...getEventByDay("Sun", rate, startOfWeek),
			];

			if (rate.RateClass === "Event") {
				const startDate = moment(rate.StartDate, "YYYYMMDDHHmm");
				const endDate = moment(rate.EndDate, "YYYYMMDDHHmm");
				//if the duration spans over to the next day, display two events
				if (startDate.clone().endOf("day") < endDate) {
					const days =
						endDate
							.clone()
							.startOf("days")
							.diff(startDate.clone().startOf("days"), "days") - 1;
					eventsForRate.push({
						start: startDate.toDate(),
						end: startDate.clone().endOf("day").toDate(),
						title: rate.Name,
						hexColor: calendarColors[rate.RateClassForCSS],
						rate,
					});

					if (days > 0) {
						Array(days)
							.fill(0)
							.forEach(() => {
								const currentDay = startDate.add(1, "day");
								eventsForRate.push({
									start: currentDay.startOf("day").toDate(),
									end: currentDay.endOf("day").toDate(),
									title: rate.Name,
									hexColor: calendarColors[rate.RateClassForCSS],
									rate,
								});
							});
					}

					eventsForRate.push({
						start: endDate.clone().startOf("day").toDate(),
						end: endDate.toDate(),
						title: rate.Name,
						hexColor: calendarColors[rate.RateClassForCSS],
						rate,
					});
				} else {
					eventsForRate.push({
						start: startDate.toDate(),
						end: endDate.toDate(),
						title: rate.Name,
						hexColor: calendarColors[rate.RateClassForCSS],
						rate,
					});
				}
			}

			events = events.concat(eventsForRate.filter((e) => e));
		}

		setState((_state) => ({
			..._state,
			events: events,
			currentDate: start,
		}));
	}

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;
	const {
		data: rates,
		isLoading,
		initialFetchComplete: ratesLoaded,
	} = useFetchData(
		[],
		installationApi.getCurrentRatesBySiteId,
		[props.selectedSite ? props.selectedSite.SiteID : null],
		[props.selectedSite, state.rates, state.groupId]
	);

	const { data: beacons } = useFetchData(
		[],
		installationApi.getValidationBeaconsBySiteId,
		[props.selectedSite ? props.selectedSite.SiteID : null],
		[props.selectedSite, state.rates, state.groupId]
	);

	const { data: groups, initialFetchComplete: groupsLoaded } = useFetchData(
		[],
		installationApi.getAccessGroupsAssignedToSite,
		[organizationId, props.selectedSite ? props.selectedSite.SiteID : null],
		[props.selectedSite]
	);

	const {
		dispatch: { getAvailableSites, setSite },
	} = useContext(AppContext);

	const byGroup = (rate) => {
		const foundGroup = JSON.parse(
			rate.CachedOrganizationAccessGroupIDs || "[]"
		).includes(state.groupId);
		if (state.groupId || rate.CachedOrganizationAccessGroupIDs) {
			if (state.groupId && !foundGroup) {
				return false;
			}
			return foundGroup;
		}

		return true;
	};
	const ratesForGroup = rates.filter(byGroup);
	if (
		ratesLoaded &&
		!isEqual(
			state.rates.map((r) => r.RateID),
			ratesForGroup.map((r) => r.RateID)
		)
	) {
		getWeeklyEvents(state.currentDate, ratesForGroup);
		setState((_state) => ({
			..._state,
			rates: ratesForGroup,
		}));
	}

	const mappedGroups = groups.map((group) => ({
		label: group.Name,
		value: group.OrganizationAccessGroupID,
	}));
	if (groupsLoaded && !isEqual(state.groups, mappedGroups)) {
		setState((_state) => ({
			..._state,
			groups: mappedGroups,
		}));
	}

	useEffect(() => {
		const events = document.getElementsByClassName("rbc-event");
		for (let event of events) {
			const content = event.getElementsByClassName("rbc-event-content")[0];
			if (content) {
				const span = content.getElementsByTagName("span")[0];
				if (span) {
					const attributes = JSON.parse(span.dataset.event);
					if (moment(attributes.event.endTime).format("HH:mm") === "00:00") {
						//should display as 12:00 AM
						const label = event.getElementsByClassName("rbc-event-label")[0];
						const match = " — 11:59 PM";
						if (label && label.innerText.includes(match)) {
							label.innerText = label.innerText.replace(match, " - 12:00 AM");
						}
					}
				}
			}
		}
	});

	function openRateWizard(rate, mode) {
		if (props.selectedSite) {
			setState((_state) => ({
				..._state,
				rateWizardOpen: true,
				rate,
				mode,
			}));
		} else {
			Alert.error("Please select a site");
		}
	}

	function openRateTesterWizard() {
		if (props.selectedSite) {
			setState((_state) => ({
				..._state,
				rateTesterWizardOpen: true,
			}));
		} else {
			Alert.error("Please select a site");
		}
	}

	if (state.rateWizardOpen) {
		return (
			<RateWizard
				close={() => {
					setState((_state) => ({
						..._state,
						rateWizardOpen: false,
						rates: [],
					}));

					getAvailableSites();
				}}
				remove={() => openRateWizard(state.rate, "remove")}
				site={props.selectedSite}
				mode={state.mode}
				rate={state.rate}
				groups={state.groups}
				canManageRates={canManageRates}
			/>
		);
	}

	if (state.rateTesterWizardOpen) {
		return (
			<RateTesterWizard
				close={() => {
					setState((_state) => ({
						..._state,
						rateTesterWizardOpen: false,
						rates: [],
					}));
				}}
				organization={props.selectedOrganization}
				site={props.selectedSite}
				mode={state.mode}
				rate={state.rate}
				groups={state.groups}
				beacons={beacons.map((beacon) => ({
					value: beacon.BeaconID,
					label: beacon.BeaconLocation,
				}))}
			/>
		);
	}

	const periods = [
		{ value: "None", label: "None" },
		{ value: "12Hours", label: "12 Hours" },
		{ value: "24Hours", label: "24 Hours" },
	];

	const gracePeriods = [
		{ value: null, label: "No grace period" },
		{ value: 60, label: "1 Minute" },
		{ value: 300, label: "5 Minutes" },
		{ value: 600, label: "10 Minutes" },
		{ value: 900, label: "15 Minutes" },
	];

	const nominated = (rates || []).map((rate) => ({
		value: rate.RateID,
		label: rate.Name,
	}));

	const schema = Yup.object().shape({
		feeCapType: Yup.string(),
		feeCapAmount: Yup.number().nullable(),
		feeCapRateIds: Yup.string(),
		gracePeriod: Yup.string().nullable(),
	});

	return (
		<div>
			<PageTitle>Transient Rates</PageTitle>
			<PageActions>
				<LeftActions>
					<div style={{ width: "240px", marginRight: "10px" }}>
						<Dropdown
							options={props.availableSites
								.filter((site) => site.SecureParkingExternalID === null)
								.map((site) => ({
									value: site.SiteID,
									label: site.IsDeactivated
										? `${site.Name} (Deactivated)`
										: site.Name,
									...site,
								}))}
							value={
								props.selectedSite && {
									...props.selectedSite,
									value: props.selectedSite.SiteID,
									label: props.selectedSite.Name,
								}
							}
							onChange={(site) => {
								setState((_state) => ({
									..._state,
									groupId: null,
								}));

								setSite(site);
							}}
						/>
					</div>

					<div style={{ width: "240px", marginRight: "10px" }}>
						<Dropdown
							options={[{ label: "No group (public)" }, ...state.groups]}
							value={
								state.groups.find((group) => group.value === state.groupId) || {
									label: "No group (public)",
								}
							}
							onChange={(group) => {
								setState((_state) => ({
									..._state,
									groupId: group.value,
								}));
							}}
						/>
					</div>
				</LeftActions>
				<RightActions>
					{canManageRates &&
						!(props.selectedSite && props.selectedSite.IsDeactivated) && (
							<Button
								style={{ marginRight: "10px" }}
								color="blue"
								onClick={() => openRateWizard({}, "add")}
							>
								<Plus size={20} />
								Add Rate
							</Button>
						)}
					{/* <Button style={{ marginRight: "10px" }} color="blue">
						Copy Rate
					</Button> */}
					<Button color="blue" onClick={() => openRateTesterWizard()}>
						Test Rates
					</Button>
				</RightActions>
			</PageActions>

			<FormLayout
				enableReinitialize={true}
				initialValues={{
					feeCapType: props.selectedSite ? props.selectedSite.FeeCapType : null,
					feeCapAmount: props.selectedSite
						? props.selectedSite.FeeCapAmount
						: null,
					feeCapRateIds: props.selectedSite
						? props.selectedSite.FeeCapRateIDs
						: null,
					gracePeriod: props.selectedSite
						? props.selectedSite.GracePeriodSeconds
						: null,
				}}
				validationSchema={schema}
				onSubmit={function (values, { setSubmitting }) {
					setSubmitting(true);
					if (props.selectedSite && props.selectedSite.SiteID) {
						installationApi
							.updateSite(props.selectedSite.SiteID, {
								FeeCapAmount: values.feeCapAmount,
								FeeCapRateIDs: values.feeCapRateIds,
								FeeCapType: values.feeCapType,
								GracePeriodSeconds: values.gracePeriod,
							})
							.then(() => {
								Alert.success("Settings saved");
								getAvailableSites();
							});
					} else {
						Alert.error("Please select a site");
					}

					setSubmitting(false);
				}}
				render={({ values, handleSubmit, setFieldValue }) => (
					<form
						className="form"
						onSubmit={(event) => {
							event.preventDefault();
						}}
					>
						<Card>
							<Accordion title="Settings" expanded={false}>
								<FieldWrapper>
									<Label>Maximum rate period</Label>
									<FieldContent>
										<Dropdown
											options={periods}
											isDisabled={!canManageRates}
											value={periods.find((e) => e.value === values.feeCapType)}
											onChange={(period) => {
												setFieldValue("feeCapType", period.value);
											}}
										/>
									</FieldContent>
								</FieldWrapper>
								<FieldWrapper>
									<Label>Maximum amount per period</Label>
									<FieldContent>
										<EditableInputField
											type="number"
											name="feeCapAmount"
											value={
												values.feeCapAmount === null ? "" : values.feeCapAmount
											}
											onChange={(event) => {
												setFieldValue(
													"feeCapAmount",
													event.target.value === 0
														? 0
														: event.target.value || null
												);
											}}
											disabled={!canManageRates}
										/>
									</FieldContent>
								</FieldWrapper>
								<FieldWrapper>
									<Label>Grace Period</Label>
									<FieldContent>
										<Dropdown
											options={gracePeriods}
											isDisabled={!canManageRates}
											value={gracePeriods.find(
												(e) => e.value === values.gracePeriod
											)}
											onChange={(gracePeriod) => {
												setFieldValue("gracePeriod", gracePeriod.value);
											}}
										/>
									</FieldContent>
								</FieldWrapper>
								<FieldWrapper>
									<Label>Nominated Rates</Label>
									<FieldContent>
										<Dropdown
											isDisabled={!canManageRates}
											isMulti={true}
											options={nominated}
											value={nominated.filter((rate) =>
												JSON.parse(values.feeCapRateIds || "[]").includes(
													rate.value
												)
											)}
											onChange={(_nominated) =>
												setFieldValue(
													"feeCapRateIds",
													JSON.stringify(
														(_nominated || []).map((rate) => rate.value)
													)
												)
											}
										/>
									</FieldContent>
								</FieldWrapper>
								<FieldWrapper>
									{canManageRates && (
										<Button color="blue" onClick={handleSubmit}>
											Update
										</Button>
									)}
								</FieldWrapper>
							</Accordion>
						</Card>
					</form>
				)}
			/>
			{isLoading ? (
				<LoadingPlaceholder />
			) : (
				<Card>
					<Calendar
						localizer={localizer}
						events={state.events || []}
						startAccessor="start"
						endAccessor="end"
						defaultDate={state.currentDate}
						defaultView={Views.WEEK}
						eventPropGetter={eventStyleGetter}
						onSelectEvent={(event) => openRateWizard(event.rate, "update")}
						views={[Views.WEEK]}
						onNavigate={(value) => getWeeklyEvents(value, state.rates)}
						formats={{
							dayRangeHeaderFormat: ({ start, end }) => {
								return (
									moment(start).format("DD MMM YYYY") +
									" - " +
									moment(end).format("DD MMM YYYY")
								);
							},
						}}
						components={{
							event: (event) => (
								<span data-event={JSON.stringify(event)}>{event.title}</span>
							),
						}}
					/>
				</Card>
			)}
		</div>
	);
}
