import { Calendar, Clock, XCircle } from "react-feather";
import { boxShadows, colours } from "../../styles";
import styled, { createGlobalStyle } from "styled-components";
import Flatpickr from "react-flatpickr";
import PropTypes from "prop-types";
import React from "react";

const GlobalStyle = createGlobalStyle`
	.flatpickr-months .flatpickr-prev-month:hover,
	.flatpickr-months .flatpickr-next-month:hover {
		color: ${colours.green};
	}

	.flatpickr-months .flatpickr-prev-month:hover svg,
	.flatpickr-months .flatpickr-next-month:hover svg {
		fill: ${colours.green};
	}

	.flatpickr-day.today {
		border-color: ${colours.green};
	}

	.flatpickr-day.today:hover,
	.flatpickr-day.today:focus {
		border-color: ${colours.green};
		background: ${colours.green};
	}

	span.flatpickr-day.today:not(.selected),
	span.flatpickr-day.prevMonthDay.today:not(.selected),
	span.flatpickr-day.nextMonthDay.today:not(.selected) {
		border-color: ${colours.green};
	}

	span.flatpickr-day.today:not(.selected):hover,
	span.flatpickr-day.prevMonthDay.today:not(.selected):hover,
	span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
		border: 1px solid ${colours.green};
	}
`;

const Wrapper = styled.div`
	position: relative;
	width: ${(props) => (props.range ? "260px" : "130px")};

	svg {
		position: absolute;
		left: 8px;
		top: 10px;
		z-index: 1;
	}

	input {
		background: ${(props) =>
			props.id ? colours.offWhite : colours.background};
		border: 0;
		border-radius: 4px;
		box-shadow: ${boxShadows.light};
		color: ${(props) => (props.disabled ? colours.midGrey : colours.darkGrey)};
		cursor: pointer;
		font-size: 18px;
		height: 40px;
		line-height: 24px;
		outline: 0;
		padding: 8px 8px 8px 36px;
		position: relative;
		text-align: right;
		width: 100%;
	}

	.clear {
		position: absolute;
		top: 8px;
		left: ${(props) => props.clearLeft || "200px"};
		cursor: pointer;
	}
`;

DatePicker.propTypes = {
	options: PropTypes.object,
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.instanceOf(Date)),
		PropTypes.instanceOf(Date),
	]),
	disabled: PropTypes.bool,
	whenCleared: PropTypes.func,
};

DatePicker.defaultProps = {
	options: {},
	onChange: () => {},
	whenCleared: () => {},
};

function DatePicker(props) {
	const { options, whenCleared, clearLeft, ...rest } = props;

	if (options.pickerMode === "timePicker") {
		return (
			<Wrapper id="timePicker" disabled={props.disabled} clearLeft={clearLeft}>
				<GlobalStyle />
				<Clock
					size={20}
					color={props.disabled ? colours.lightGrey : colours.darkGrey}
				/>
				<Flatpickr
					options={{
						enableTime: true,
						noCalendar: true,
						minuteIncrement: 1,
						dateFormat: "H:i",
						...options,
					}}
					{...rest}
				/>
			</Wrapper>
		);
	} else {
		return (
			<Wrapper
				range={options.pickerMode === "dateRangePicker"}
				style={
					options.pickerMode === "datePicker"
						? { ...(props.style || { width: "180px" }) }
						: {}
				}
				clearLeft={clearLeft}
			>
				<GlobalStyle />
				<Calendar size={20} />
				<Flatpickr
					options={{
						mode: options.pickerMode === "datePicker" ? "single" : "range",
						altInput: true,
						altFormat: "d M Y",
						dateFormat: "d M Y",
						minDate: new Date(2015, 0, 1),
						maxDate: new Date(2029, 11, 31),
						...options,
					}}
					{...rest}
				/>
				{options.showClearButton && (
					<XCircle
						className={"clear"}
						onClick={() => {
							whenCleared();
						}}
					/>
				)}
			</Wrapper>
		);
	}
}

export default DatePicker;
