import moment from "moment";

export const DEFAULT_RATE_TYPE = {
	value: "Normal",
	label: "Casual / Anytime Rate",
	proper: "Casual / Anytime",
};

export const RATE_TYPES = [
	DEFAULT_RATE_TYPE,
	{
		value: "Daytime",
		label: "Day-time Rate (e.g. 7am to 5pm)",
		proper: "Daytime",
	},
	{
		value: "AfterHours",
		label: "After Hours Rate (e.g. 5pm to 7am)",
		proper: "After Hours",
	},
	{ value: "EarlyBird", label: "Early Bird", proper: "Early Bird" },
	{
		value: "AfterhoursEarlyBird",
		label: "After Hours Early Bird",
		proper: "After Hours Early Bird",
	},
	{
		value: "Event",
		label: "One Off / Event Rate",
		proper: "One Off / Event",
	},
	{
		value: "Complex",
		label: "Advanced / Complex Rate",
		proper: "Advanced / Complex",
	},
];

export const DEFAULT_RATE_SCALE = {
	value: 60,
	label: "Hour",
	proper: "an hour",
};

export const RATE_SCALES = [
	{ value: 5, label: "5 Minutes" },
	{ value: 10, label: "10 Minutes" },
	{ value: 15, label: "15 Minutes" },
	{ value: 30, label: "30 Minutes" },
	DEFAULT_RATE_SCALE,
	{ value: 120, label: "2 Hours" },
	{ value: 720, label: "12 Hours" },
	{ value: 1440, label: "Day", proper: "a day" },
];

export const YES_OR_NO = [
	{ value: 0, label: "No" },
	{ value: 1, label: "Yes" },
];

export const FEE_CALCULATION_TYPES = [
	{ value: "Normal", label: "Standard Rate" },
	{ value: "Varied", label: "Varied Rate Over Time" },
];

export const APPLIES_TO = [
	{ value: "Public", label: "All Users" },
	{ value: "Groups", label: "Specific Groups" },
];

export const VARIED_RATE_DURATIONS = [
	{ value: 5, label: "5 Mins" },
	{ value: 10, label: "10 Mins" },
	{ value: 15, label: "15 Mins" },
	{ value: 30, label: "30 Mins" },
	{ value: 60, label: "1 Hour" },
	{ value: 120, label: "2 Hours" },
	{ value: 720, label: "12 Hours" },
	{ value: 1440, label: "1 Day" },
	{ value: null, label: "Once" },
];

export const VARIED_RATE_OFFSET_SCALES = [
	{ value: "minutes", label: "Mins", multiplier: 60 },
	{ value: "hours", label: "Hours", multiplier: 60 * 60 },
	{ value: "days", label: "Days", multiplier: 60 * 60 * 24 },
	{ value: "weeks", label: "Weeks", multiplier: 60 * 60 * 24 * 7 },
	{ value: "infinite", label: "∞" },
];

export const DAYS = [
	{ value: "Mon", label: "Monday" },
	{ value: "Tue", label: "Tuesday" },
	{ value: "Wed", label: "Wednesday" },
	{ value: "Thu", label: "Thursday" },
	{ value: "Fri", label: "Friday" },
	{ value: "Sat", label: "Saturday" },
	{ value: "Sun", label: "Sunday" },
];

export const DAYS_OR_EARLIER = DAYS.map((day) => ({
	value: day.value,
	label: `${day.label} (or earlier)`,
}));

export const EXIT_CONDITIONS = [
	{ value: "exitBetween", label: "Exit Between" },
	{ value: "exitOnOrBefore", label: "Exit Before" },
];

export const DEFAULT_TIMES = {
	Normal: {
		startTime: moment("00:00", "HH:mm"),
		endTime: moment("00:00", "HH:mm").add(24 * 60, "minutes"),
	},
	Daytime: {
		startTime: moment("07:00", "HH:mm"),
		endTime: moment("07:00", "HH:mm").add(10 * 60, "minutes"),
	},
	AfterHours: {
		startTime: moment("17:00", "HH:mm"),
		endTime: moment("17:00", "HH:mm").add(14 * 60, "minutes"),
	},
};

export const DEFAULT_DATES = {
	EarlyBird: {
		EntryLaterThan: moment().startOf("day").toDate(),
		EntryEarlierThan: moment().startOf("day").add(8, "hours").toDate(),
		ExitLaterThan: moment().startOf("day").add(15, "hours").toDate(),
		ExitEarlierThan: moment().startOf("day").add(18, "hours").toDate(),
	},
	AfterhoursEarlyBird: {
		EntryLaterThan: moment().startOf("day").add(13, "hours").toDate(),
		EntryEarlierThan: moment().endOf("day").toDate(),
		ExitLaterThan: moment().startOf("day").add(20, "hours").toDate(),
		ExitEarlierThan: moment().startOf("day").endOf("day").toDate(),
	},
	Complex: {
		EntryLaterThan: moment().startOf("day").add(6, "hours").toDate(),
		EntryEarlierThan: moment().startOf("day").add(8, "hours").toDate(),
		ExitLaterThan: moment().startOf("day").add(15, "hours").toDate(),
		ExitEarlierThan: moment().startOf("day").add(18, "hours").toDate(),
	},
};

export const MAXIMUM_SPECIAL_CONDITION = {
	label: "Maximum Duration",
	value: "maximum",
};

export const MINIMUM_SPECIAL_CONDITION = {
	label: "Minimum Duration",
	value: "minimum",
};
export const DEFAULT_SPECIAL_CONDITION = MINIMUM_SPECIAL_CONDITION;
export const SPECIAL_CONDITIONS = [
	MINIMUM_SPECIAL_CONDITION,
	MAXIMUM_SPECIAL_CONDITION,
];
