import { ChevronDown, ChevronUp, MoreHorizontal } from "react-feather";
import React, { Fragment, useState } from "react";
import Collapsible from "./Collapsible";
import DropdownMenu from "./DropdownMenu";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	position: relative;
`;

const Link = styled.div`
	transition: 0.2s ease;
	user-select: none;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
`;

const Title = styled.h2`
	margin: 16px 32px;
`;

const Toggle = styled.div`
	margin-bottom: 32px;
	margin-left: 32px;
	position: absolute;
	right: 24px;
	top: -12px;
`;

const SmallToggle = styled.div`
	margin-bottom: 32px;
	margin-left: 32px;
	position: absolute;
	right: 20px;
	top: -6px;
`;

const RightMenu = styled.div`
	margin-top: -25px;
	right: 17px;
	position: absolute;
`;

export default function Accordion(props) {
	const [expanded, setExpanded] = useState(props.expanded || false);

	let AccordionTitle = Title;
	let title = props.title;
	if (typeof props.title === "object") {
		AccordionTitle = props.title.component;
		title = props.title.text;
	}

	let chevron = props.chevron || { colour: colours.blue };
	chevron.size = props.size === "small" ? 36 : 48;

	let AccordionToggle = props.size === "small" ? SmallToggle : Toggle;

	let Header = () => (
		<Fragment>
			<AccordionTitle>{title}</AccordionTitle>
			{!props.noToggle && (
				<AccordionToggle>
					{expanded ? (
						<ChevronUp size={chevron.size} color={chevron.colour} />
					) : (
						<ChevronDown size={chevron.size} color={chevron.colour} />
					)}
				</AccordionToggle>
			)}
		</Fragment>
	);

	return (
		<Wrapper key={title}>
			{props.noToggle ? (
				<div>{Header()}</div>
			) : (
				<Link onClick={() => setExpanded(!expanded)}>{Header()}</Link>
			)}
			{props.menu && props.menu.length ? (
				<RightMenu>
					<DropdownMenu
						triggerContent={<MoreHorizontal size={24} />}
						items={props.menu}
					/>
				</RightMenu>
			) : null}

			<Collapsible isOpen={expanded}>{props.children}</Collapsible>
		</Wrapper>
	);
}
