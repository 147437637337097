import * as Yup from "yup";
import * as installationApi from "../../../api/installation";
import * as wizardSteps from "./booking-rate-steps";
import React, { useState } from "react";
import Alert from "react-s-alert";
import Button from "../../../components/layout/Button";
import { DURATION_TYPES } from "../../../helpers/constants";
import { RateTypeStep } from "./shared-steps";
import WizardLayout from "../WizardLayout";
import WizardNavigation from "../../../components/wizards/WizardNavigation";
import { intersection } from "lodash";
import moment from "moment";
import { rebuildGroups } from "./helper";

/**
 * Gets a set of step objects for a given Wizard mode.
 *
 * Each step is a struct containing rendering details for the
 * step and its navigation.
 *
 * @param {object} props
 * @param {string} props.mode
 */
const _getWizardPlan = (props, durationType) => {
	if (props.mode === "remove") {
		return {
			initialStep: 0,
			steps: [wizardSteps.remove],
		};
	}

	let plan = { initialStep: 0 };

	if (props.mode === "add" || props.mode === "update") {
		if (durationType === DURATION_TYPES.MONTHS) {
			plan.steps = [
				wizardSteps.name,
				wizardSteps.monthlyFee,
				wizardSteps.bookInAdvanceStep,
				wizardSteps.minimumDurationMonths,
				wizardSteps.unitToPayInAdvance,
				wizardSteps.earlyCancellationFee,
				wizardSteps.proRataDay,
				wizardSteps.availableDates,
				wizardSteps.groups,
				wizardSteps.leaseParks,
				wizardSteps.sublease,
				wizardSteps.summary,
			];
		} else if (durationType === "Days") {
			plan.steps = [
				wizardSteps.nonConsecutiveBooking,
				wizardSteps.name,
				wizardSteps.monthlyFee,
				wizardSteps.bookInAdvanceStep,
				wizardSteps.minimumDurationMonths,
				wizardSteps.availableDates,
				wizardSteps.groups,
				wizardSteps.leaseParks,
				wizardSteps.summary,
			];
		} else {
			plan.steps = [
				wizardSteps.name,
				wizardSteps.monthlyFee,
				wizardSteps.bookInAdvanceStep,
				wizardSteps.durationStep,
				wizardSteps.availableDates,
				wizardSteps.groups,
				wizardSteps.leaseParks,
				wizardSteps.summary,
			];
		}
	}

	if (props.mode === "update") {
		plan.initialStep = plan.steps.length - 1;
	}

	return plan;
};

/**
 * Gets the title for the Wizard depending on which mode it is in.
 *
 * @param {object} props
 * @param {string} props.mode
 */
const _getWizardTitle = (props) => {
	switch (props.mode) {
		case "add":
			return "Add booking rate";
		case "update":
			return "Update booking rate";
		case "remove":
			return "Remove booking rate";
	}
};

/**
 * Sets the initial form values, and validation rules, for the wizard.
 *
 * @param {object} props
 * @param {string} props.mode
 * @param {object} props.leaseRate The fixed term rate the Wizard is operating on.
 */
const _getValuesAndSchemaArray = (props) => {
	const mode = props.mode;
	const leaseRate = props.leaseRate;

	return [
		{ name: "mode", value: mode, validator: Yup.string() },
		{
			name: "editFromSummary",
			value: mode === "update",
			validator: Yup.boolean(),
		},
		{
			name: "leaseRateId",
			value: leaseRate.LeaseRateID ? leaseRate.LeaseRateID : null,
		},
		{
			name: "name",
			value: leaseRate.Name ? leaseRate.Name : "",
			validator: Yup.string().required("Rate name is a required field"),
		},
		{
			name: "durationType",
			value: leaseRate.DurationType
				? leaseRate.DurationType
				: props.newDurationType,
		},
		{
			name: "monthlyFee",
			value: leaseRate.MonthlyFee ? leaseRate.MonthlyFee : 0,
			validator: Yup.number()
				.min(0, "Please supply a number")
				.test("isCurrency", "Must be valid currency", () => {
					return true;
				})
				.required("Monthly fee is a required field"),
		},
		{
			name: "minimumDurationAmount",
			value: leaseRate.MinimumDurationMonths
				? leaseRate.MinimumDurationMonths
				: 1,
			validator: Yup.number()
				.integer()
				.min(1, "Please supply a number greater than 0")
				.test("noConflicts", "(empty)", function (val) {
					const dateRange = this.parent.dateRange;
					const durationType = this.parent.durationType;
					const leaseParkIds = this.parent.leaseParks
						? this.parent.leaseParks.map((item) => item.value)
						: []; //Note these are Value:Label pairings not database objects
					const leaseRateId = this.parent.leaseRateId
						? this.parent.leaseRateId
						: null;

					//Get if any other rates are assigned to the same parks,
					//and if so, that they're assigned at the same timeframe, duration and groups
					for (let _leaseRate of props.leaseRates) {
						if (leaseRateId === _leaseRate.LeaseRateID) {
							continue;
						} //Don't evaluate self

						if (_leaseRate.DurationType !== this.parent.durationType) {
							continue;
						} //Don't evaluate against rates that aren't the same duration as this one

						for (let leasePark of _leaseRate.leaseParks) {
							if (leaseParkIds.indexOf(leasePark.LeaseParkID) === -1) {
								continue;
							} //Don't evaluate if the lease park isn't also in use by this rate
							if (_leaseRate.MinimumDurationMonths !== val) {
								continue;
							} //Exit early if the durations don't match

							if (_leaseRate.DurationType !== durationType) {
								continue;
							} //Exist if the duration type doesn't match

							//Make sure the lease rates are in different groups to each other
							const localGroups = this.parent.organizationAccessGroups
								.filter((i) => i.value !== -1)
								.map((i) => i.value);
							const comparableGroups = _leaseRate.organizationAccessGroups.map(
								(i) => i.OrganizationAccessGroupID
							);
							if (localGroups.length !== comparableGroups.length) {
								continue;
							} //Ignore if obviously mismatched groups
							if (
								intersection(localGroups, comparableGroups).length !==
								localGroups.length
							) {
								continue;
							} //Ignore if equally sized groups do not match

							//Make sure the lease rates do not have intersecting availability times
							if (dateRange && _leaseRate.StartDate && _leaseRate.EndDate) {
								const startDate = parseInt(
									moment(dateRange[0]).format("YYYYMMDD")
								);
								const endDate = parseInt(
									moment(dateRange[1]).format("YYYYMMDD")
								);

								if (
									!(
										_leaseRate.EndDate >= startDate &&
										_leaseRate.StartDate <= endDate
									)
								) {
									continue;
								}
							}

							//If no conditions exist to prevent a conflict, throw an error
							return this.createError({
								path: "minimumDurationMonths",
								message: `Rate "${_leaseRate.Name}" already assigned to Space "${leasePark.Name}" at minimum duration ${val}`,
							});
						}
					}

					return true;
				}),
		},
		{
			name: "maximumDurationAmount",
			value: leaseRate.MaximumDurationAmount
				? leaseRate.MaximumDurationAmount
				: "",
			validator: Yup.number()
				.integer()
				.min(1, "Please supply a number greater than 0")
				.test("noExceeds", "(empty)", function (maxAmount) {
					const minAmount = this.parent.minimumDurationAmount;
					const minUnit = this.parent.durationType;
					const maxUnit = this.parent.maximumDurationType;

					const getValue = (amount, unit) => {
						if (unit === "Hours") {
							return amount * 60;
						} else {
							return amount;
						}
					};

					if (
						maxAmount &&
						getValue(minAmount, minUnit) > getValue(maxAmount, maxUnit)
					) {
						return this.createError({
							path: "exceedsMaxDuration",
							message:
								"Maximum duration cannot be smaller than the minimum duration.",
						});
					}
					return true;
				})
				.nullable(),
		},
		{
			name: "unitToPayInAdvance",
			value: leaseRate.UnitToPayInAdvance ? leaseRate.UnitToPayInAdvance : 1,
			validator: Yup.number()
				.integer()
				.min(1, "Please supply a number greater than 0")
				.required("Please supply a number greater than 0"),
		},
		{
			name: "earlyCancellationFee",
			value: leaseRate.EarlyCancellationFee
				? leaseRate.EarlyCancellationFee
				: 0,
			validator: Yup.number()
				.min(0, "Please supply a number")
				.required("Please supply a number"),
		},
		{
			name: "proRataDay",
			value: leaseRate.ProRataDay
				? { value: leaseRate.ProRataDay, label: `Day ${leaseRate.ProRataDay}` }
				: { value: null, label: "No pro rata day" },
			validator: Yup.object().nullable(),
		},
		{
			name: "startDate",
			value: leaseRate.StartDate
				? moment(leaseRate.StartDate, "YYYYMMDD").toDate()
				: null,
		},
		{
			name: "endDate",
			value: leaseRate.EndDate
				? moment(leaseRate.EndDate, "YYYYMMDD").toDate()
				: null,
		},
		{
			name: "sites",
			value:
				leaseRate.sites && leaseRate.sites.length > 0
					? leaseRate.sites.map((site) => {
							return { value: site.SiteID, label: site.Name };
					  })
					: [],
			validator: Yup.array(Yup.object())
				.nullable()
				.test(
					"mustHaveSites",
					"You must select some sites",
					(val) => val !== null
				),
		},
		{
			name: "leaseParks",
			value:
				leaseRate.leaseParks && leaseRate.leaseParks.length > 0
					? leaseRate.leaseParks.map((leasePark) => {
							return { value: leasePark.LeaseParkID, label: leasePark.Name };
					  })
					: [],
			validator: Yup.array(Yup.object())
				.nullable()
				.test(
					"mustHaveParkingBlocks",
					"You must select some spaces",
					(val) => val !== null
				),
		},
		{
			name: "organizationAccessGroups",
			value: rebuildGroups(leaseRate),
			validator: Yup.array(Yup.object()).nullable(),
		},
		{
			name: "maximumDurationType",
			value: leaseRate.MaximumDurationType,
			validator: Yup.string().nullable(),
		},
		{
			name: "bookInAdvanceDuration",
			value: leaseRate ? leaseRate.BookInAdvanceDuration : null,
			validator: Yup.number().integer().nullable(),
		},
		{
			name: "bookInAdvanceUnitType",
			value: leaseRate ? leaseRate.BookInAdvanceUnitType : null,
			validator: Yup.string().nullable(),
		},
		{
			name: "hourlyBillableDuration",
			value: leaseRate ? leaseRate.HourlyBillableDuration : null,
			validator: Yup.number().integer().nullable(),
		},
		{
			name: "supportSubleases",
			value: Boolean(leaseRate.SubletRateIDs && leaseRate.SubletRateIDs.length),
			validator: Yup.boolean(),
		},
		{
			name: "subletRateIds",
			value: leaseRate.SubletRateIDs || [],
			validator: Yup.array(),
		},
		{
			name: "nonConsecutiveBooking",
			value: !!leaseRate?.NonConsecutiveBooking,
			validator: Yup.boolean(),
		},
		{
			name: "variedDailyRate",
			value: !!leaseRate?.DailyFees,
			validator: Yup.boolean(),
		},
		{
			name: "dailyFees",
			value: leaseRate?.DailyFees || {},
			validator: Yup.object(),
		},
	];
};

/**
 * Event handler for submission.
 *
 * @param {object} values Values from the form
 * @param {object} props Props from the Formik form
 * @param {object} wizardProps props that were passed to the Wizard initially
 */
const _wizardSubmit = async (values, props, wizardProps) => {
	const setSubmitting = props.setSubmitting;
	const mode = wizardProps.mode;
	const organizationId = wizardProps.organization.OrganizationID;
	const leaseRateId = wizardProps.leaseRate.LeaseRateID;

	setSubmitting(true);

	const leaseRate = {
		name: values.name,
		monthlyFee: values.monthlyFee,
		minimumDurationMonths: values.minimumDurationAmount,
		maximumDurationAmount:
			values.maximumDurationAmount !== "" ? values.maximumDurationAmount : null,
		maximumDurationType: values.maximumDurationType,
		durationType: values.durationType,
		unitToPayInAdvance: values.unitToPayInAdvance,
		earlyCancellationFee: values.earlyCancellationFee,
		proRataDay: values.proRataDay.value, //pull value out of dropdown object
		startDate: values.startDate
			? moment(values.startDate).format("YYYYMMDD")
			: null,
		endDate: values.endDate ? moment(values.endDate).format("YYYYMMDD") : null,
		leaseParkIds: [],
		organizationAccessGroupIds: [],
		bookInAdvanceDuration: values.bookInAdvanceDuration,
		bookInAdvanceUnitType: values.bookInAdvanceUnitType,
		hourlyBillableDuration:
			values.durationType === "Hours"
				? values.hourlyBillableDuration || 60
				: null,
		subletRateIds:
			values.subletRateIds.length && values.supportSubleases
				? values.subletRateIds
				: null,
		nonConsecutiveBooking: values.nonConsecutiveBooking,
		dailyFees: values.variedDailyRate ? JSON.stringify(values.dailyFees) : null,
	};

	if (mode === "add") {
		leaseRate.durationType = values.durationType;
		leaseRate.maximumDurationType = values.maximumDurationType;
	}

	if (values && values.leaseParks && values.leaseParks.length > 0) {
		leaseRate.leaseParkIds = values.leaseParks.map((item) => item.value);
	}

	//Filter out any special items from the org group IDs
	leaseRate.organizationAccessGroupIds = values.organizationAccessGroups.reduce(
		(arr, item) => {
			if (item.value === -1) {
				return arr;
			}
			arr.push(item.value);
			return arr;
		},
		[]
	);

	if (mode === "add") {
		try {
			await installationApi.createLeaseRate(organizationId, leaseRate);
			Alert.success("Booking rate created");
			wizardProps.close(true);
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		} finally {
			setSubmitting(false);
		}
	} else if (mode === "update") {
		try {
			await installationApi.updateLeaseRate(
				organizationId,
				leaseRateId,
				leaseRate
			);
			Alert.success("Booking rate updated");
			wizardProps.close(true);
		} catch (error) {
			if (
				error.errors &&
				error.errors.length &&
				error.errors[0].code === "LeaseUsedAsSublet"
			) {
				Alert.error(
					"This lease is currently being used as a sublet rate. Plase remove it from the corresponding lease before editing either the duration type or the minimum/maximum duration."
				);
			} else {
				Alert.error("Something went wrong. Please try again.");
			}
		} finally {
			setSubmitting(false);
		}
	} else if (mode === "remove") {
		try {
			await installationApi.deleteLeaseRate(organizationId, leaseRateId);
			Alert.success("Booking rate removed");
			wizardProps.close(true);
		} catch (error) {
			if (
				error.errors &&
				error.errors.length &&
				error.errors[0].code === "LeaseUsedAsSublet"
			) {
				Alert.error(
					"This lease is currently being used as a sublet rate. Plase remove it from the corresponding lease before deleting."
				);
			} else {
				Alert.error("Something went wrong. Please try again.");
			}
		} finally {
			setSubmitting(false);
		}
	}
};

/**
 * React component for creating/editing/deleting Booking Rates.
 *
 * @param {object} props
 * @param {string} props.mode enum - 'add', 'update', 'remove'
 **/
export default function BookingRateWizard(props) {
	let durationType = props.leaseRate.DurationType
		? props.leaseRate.DurationType
		: null;
	const [state, setState] = useState({ durationType: durationType });
	if (state.durationType) {
		durationType = state.durationType;
	}

	//If in add mode, prompt for the Duration Type first
	if (props.mode === "add" && !state.durationType) {
		return (
			<WizardLayout
				key={1} //prevent react render reusing the wrong dom node
				close={props.close}
				title={"Add booking rate"}
				values={[{ name: "durationType", value: "Months" }]}
				onSubmit={(values) => {
					setState((_state) => ({
						..._state,
						durationType: values.durationType,
					}));
				}}
				steps={[
					({ values, setFieldValue, handleSubmit }) => {
						return {
							id: "durationType",
							label: "Duration",
							render: () => (
								<RateTypeStep
									setFieldValue={setFieldValue}
									durationType={values.durationType}
									title="What duration is this booking rate for?"
								/>
							),
							footer: () => {
								return (
									<WizardNavigation
										rightItems={[
											<Button key="submit" color="blue" onClick={handleSubmit}>
												Next
											</Button>,
										]}
									/>
								);
							},
						};
					},
				]}
				initialStep={0}
				wizardProps={props}
			/>
		);
	}

	const wizardPlan = _getWizardPlan(props, state.durationType);

	return (
		<WizardLayout
			key={2} //prevent react render reusing the wrong dom node
			close={props.close}
			title={() => _getWizardTitle(props)}
			values={_getValuesAndSchemaArray({
				...props,
				newDurationType: state.durationType,
			})}
			onSubmit={(values, innerprops) => {
				_wizardSubmit(values, innerprops, props);
			}}
			steps={wizardPlan.steps}
			initialStep={wizardPlan.initialStep}
			wizardProps={props}
		/>
	);
}
