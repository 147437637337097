import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import React, { useState } from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import Input from "../../layout/Input";
import { trimStart } from "lodash";
import MultiChoiceButton from "../MultiChoiceButton";
import {
	AUTHORIZATION_OPTIONS,
	AUTHORIZATION_TYPES,
	COLORS_BY_REQUEST_METHOD,
	RESPONSE_METHOD_LABELS,
	RULE_OPTIONS_BY_PROP,
	TIMESTAMP_FORMAT_OPTIONS,
	TRANSFORMATIONS,
} from "../../../helpers/constants";
import Repeater, { Icon } from "../../layout/Repeater";
import styled from "styled-components";
import Dropdown from "../../layout/Dropdown";
import { Editor } from "@monaco-editor/react";
import { Variables } from "./components/Variables";
import InputWithIcon from "../../layout/InputWithIcon";
import { PlusCircle } from "react-feather";
import { colours } from "../../../styles";
import Label from "../../layout/Label";
import reactStringReplace from "react-string-replace";
import Tooltip from "rc-tooltip";
import Alert from "react-s-alert";
import Loader from "../../layout/Loader";
import { InputWithVariables } from "./components/InputWithVariables";
import ReactSwitch from "react-switch";

const FlexRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: baseline;
	flex-wrap: wrap;
	margin-top: 10px;
	margin-bottom: 10px;
	gap: 20px;
`;

const ParameterItem = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: baseline;
	gap: 20px;
	flex-direction: row;
	margintop: 20px;
`;

export const nameStep = ({ values, setFieldValue, next, goTo, close }) => {
	return {
		id: "name",
		label: "Name",
		render: () => (
			<div>
				<StepTitle>What is the name of this request?</StepTitle>

				<Input
					type="text"
					name="name"
					value={values.name || ""}
					onChange={(event) => {
						setFieldValue("name", trimStart(event.target.value));
					}}
				/>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={close}>
										Cancel
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={!values.name}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const methodStep =
	(setMethod) =>
	({ values, setFieldValue, next, goTo, previous }) => {
		return {
			id: "method",
			label: "Type",
			render: () => (
				<div>
					<StepTitle>What type of request/connection is this?</StepTitle>

					{["GET", "POST", "SFTP"].map((method) => (
						<MultiChoiceButton
							key={method}
							selected={values.method === method}
							onClick={() => {
								setFieldValue("method", method);
								setMethod(method);
							}}
						>
							{method}
						</MultiChoiceButton>
					))}
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={previous}>
											Back
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="submit"
								color="blue"
								onClick={() => {
									if (values.editFromSummary) {
										goTo("summary");
									} else {
										next();
									}
								}}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const suggestableInputStep =
	(key, label, question) =>
	({ values, setFieldValue, next, goTo, previous }) => {
		return {
			id: key,
			label,
			render: () => (
				<div>
					<StepTitle>{question}</StepTitle>

					<InputWithVariables
						id={key}
						values={values}
						setFieldValue={setFieldValue}
					/>
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={previous}>
											Back
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="submit"
								color="blue"
								onClick={() => {
									if (values.editFromSummary) {
										goTo("summary");
									} else {
										next();
									}
								}}
								disabled={!values[key]}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const sftpPassKeyStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "sftpAuthMethod",
		label: values.sftpAuthMethod === "PrivateKey" ? "Private Key" : "Password",
		render: () => (
			<div>
				<StepTitle>Authenticate with a password or private key?</StepTitle>

				{[
					{ key: "Password", label: "Password" },
					{ key: "PrivateKey", label: "Private Key" },
				].map((option) => (
					<MultiChoiceButton
						key={option.key}
						selected={values.sftpAuthMethod === option.key}
						onClick={() => {
							setFieldValue("sftpAuthMethod", option.key);
						}}
					>
						{option.label}
					</MultiChoiceButton>
				))}

				{values.sftpAuthMethod === "Password" && (
					<>
						<StepText>Password</StepText>
						<InputWithVariables
							id="password"
							values={values}
							setFieldValue={setFieldValue}
						/>
					</>
				)}

				{values.sftpAuthMethod === "PrivateKey" && (
					<>
						<StepText style={{ marginBottom: "20px" }}>Private Key</StepText>
						<InputWithVariables
							id="privateKey"
							type="textarea"
							values={values}
							setFieldValue={setFieldValue}
						/>
					</>
				)}
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const paramsStep = ({ values, setFieldValue, next, goTo, previous }) => {
	return {
		id: "params",
		label: "Params",
		render: () => (
			<div>
				<StepTitle>Do you need to pass any params to this request?</StepTitle>
				<MultiChoiceButton
					key="No"
					selected={!values.hasParams}
					onClick={() => {
						setFieldValue("hasParams", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					key="Yes"
					selected={values.hasParams}
					onClick={() => {
						setFieldValue("hasParams", true);
					}}
				>
					Yes
				</MultiChoiceButton>

				{values.hasParams && (
					<>
						<StepText style={{ marginBottom: "20px" }}>Params</StepText>
						<Repeater
							minusMode="all"
							items={values.params}
							add={() => {
								setFieldValue("params", [
									...values.params,
									{ key: "", value: "", description: "" },
								]);
							}}
							subtract={(index) => {
								const items = [...values.params];
								if (index > -1) items.splice(index, 1);
								setFieldValue("params", items);
							}}
							template={(param, index) => {
								return (
									<ParameterItem>
										{["key", "value", "description"].map((prop) => (
											<Input
												key={prop}
												useLabel={prop}
												value={param[prop]}
												onChange={(event) => {
													setFieldValue(
														`params.${index}.${prop}`,
														event.target.value
													);
												}}
											/>
										))}
									</ParameterItem>
								);
							}}
						/>
					</>
				)}
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={values.hasParams && !values.params?.length}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const authorizationStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "authorization",
		label: "Authorization",
		render: () => {
			const authorizationOptions = AUTHORIZATION_OPTIONS;
			const credsOnChange = (auth) => {
				const headers = values.headers || [];
				setFieldValue("hasHeaders", true);
				setFieldValue("headers", [
					{
						key: "Authorization",
						value: auth,
						readonly: true,
					},
					...headers.filter((h) => h.key !== "Authorization"),
				]);
			};

			const updateAuthorization = (
				authorizationType,
				basicAuthUsername,
				basicAuthPassword,
				bearerToken
			) => {
				if (authorizationType === AUTHORIZATION_TYPES.BASIC) {
					credsOnChange(
						`Basic ${btoa(`${basicAuthUsername}:${basicAuthPassword}`)}`
					);
				} else if (authorizationType === AUTHORIZATION_TYPES.BEARER) {
					credsOnChange(`Bearer ${bearerToken}`);
				}
			};
			return (
				<div>
					<StepTitle>Does this request need authorization?</StepTitle>
					<MultiChoiceButton
						key="No"
						selected={!values.shouldAuthorize}
						onClick={() => {
							setFieldValue("shouldAuthorize", false);
							setFieldValue(
								"headers",
								values.headers?.filter((h) => h.key !== "Authorization") || []
							);
						}}
					>
						No
					</MultiChoiceButton>
					<MultiChoiceButton
						key="Yes"
						selected={values.shouldAuthorize}
						onClick={() => {
							setFieldValue("shouldAuthorize", true);
							updateAuthorization(
								values.authorizationType,
								values.basicAuthUsername,
								values.basicAuthPassword,
								values.bearerToken
							);
						}}
					>
						Yes
					</MultiChoiceButton>

					{values.shouldAuthorize && (
						<>
							<StepText style={{ marginBottom: "20px" }}>
								Authorization Type
							</StepText>
							<Dropdown
								name="authorizationType"
								options={authorizationOptions}
								value={authorizationOptions.find(
									(auth) => auth.value === values.authorizationType
								)}
								onChange={(value) => {
									setFieldValue("authorizationType", value?.value);
									updateAuthorization(
										value?.value,
										values.basicAuthUsername,
										values.basicAuthPassword,
										values.bearerToken
									);
								}}
							/>

							{values.authorizationType === AUTHORIZATION_TYPES.BASIC && (
								<>
									<StepText style={{ marginTop: "20px" }}>Username</StepText>
									<Input
										type="text"
										name="basicAuthUsername"
										value={values.basicAuthUsername || ""}
										onChange={(event) => {
											const username = trimStart(event.target.value);
											setFieldValue("basicAuthUsername", username);
											updateAuthorization(
												values.authorizationType,
												username,
												values.basicAuthPassword,
												values.bearerToken
											);
										}}
									/>
									<StepText style={{ marginTop: "20px" }}>Password</StepText>
									<Input
										type="password"
										name="basicAuthPassword"
										value={values.basicAuthPassword || ""}
										onChange={(event) => {
											const password = event.target.value;
											setFieldValue("basicAuthPassword", password);
											updateAuthorization(
												values.authorizationType,
												values.basicAuthUsername,
												password,
												values.bearerToken
											);
										}}
									/>
								</>
							)}

							{values.authorizationType === AUTHORIZATION_TYPES.BEARER && (
								<>
									<StepText style={{ marginTop: "20px" }}>Token</StepText>
									<Input
										type="text"
										name="bearerToken"
										value={values.bearerToken || ""}
										onChange={(event) => {
											const token = event.target.value;
											setFieldValue("bearerToken", token);
											updateAuthorization(
												values.authorizationType,
												values.basicAuthUsername,
												values.basicAuthPassword,
												token
											);
										}}
									/>
								</>
							)}
						</>
					)}
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={values.shouldAuthorize && !values.authorizationType}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const headersStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "headers",
		label: "Headers",
		render: () => (
			<div>
				<StepTitle>Do you need to pass any headers to this request?</StepTitle>
				<MultiChoiceButton
					key="No"
					selected={!values.hasHeaders}
					onClick={() => {
						setFieldValue("hasHeaders", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					key="Yes"
					selected={values.hasHeaders}
					onClick={() => {
						setFieldValue("hasHeaders", true);
					}}
				>
					Yes
				</MultiChoiceButton>
				{values.hasHeaders && (
					<>
						<StepText style={{ marginBottom: "20px" }}>Headers</StepText>
						<Repeater
							minusMode="all"
							items={values.headers}
							add={() => {
								setFieldValue("headers", [
									...values.headers,
									{ key: "", value: "", description: "" },
								]);
							}}
							subtract={() => {
								const items = [...values.headers];
								items.pop();
								setFieldValue("headers", items);
							}}
							template={(header, index) => {
								return (
									<ParameterItem
										key={`header${index}`}
										style={{ marginBottom: "15px" }}
									>
										{["key", "value", "description"].map((prop) => (
											<Input
												key={`header${index}${prop}`}
												disabled={header.readonly}
												useLabel={prop}
												value={header[prop]}
												onChange={(event) => {
													setFieldValue(
														`headers.${index}.${prop}`,
														event.target.value
													);
												}}
											/>
										))}
									</ParameterItem>
								);
							}}
						/>
					</>
				)}
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={values.hasParams && !values.params?.length}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const transformStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "transform",
		label: "Transform",
		render: () => {
			let variables = [
				{
					label: "Transform",
					options: values.transformations
						?.filter((trns) => trns.name)
						?.map((trns) => ({
							value: trns.name,
							label: trns.label || trns.name,
							type: "String",
						})),
				},
				...(values.variableGroups?.map((group) => ({
					label: group.name,
					options: group.variables.map((variable) => ({
						value: variable.value,
						label: variable.label || variable.value,
						type: variable.type,
					})),
				})) || []),
			];
			const isTimestamp = (transformation) =>
				transformation.variable?.type === "Timestamp";
			const isFormattingTimestamp = (transformation) =>
				isTimestamp(transformation) &&
				transformation.transformation?.value === "Format";
			const isAddingHours = (transformation) =>
				isTimestamp(transformation) &&
				transformation.transformation?.value === "AddHours";
			const actionIsCreatingVariable = (transformation) =>
				transformation.variable?.value === "CreateVariable";
			const actionIsGeneratingString = (transformation) =>
				actionIsCreatingVariable(transformation) &&
				transformation.transformation?.value === "GenerateRandomString";
			const actionIsFromTemplate = (transformation) =>
				actionIsCreatingVariable(transformation) &&
				transformation.transformation?.value === "FromTemplate";
			const isHashing = (transformation) =>
				transformation.transformation?.value === "SHA256";
			const isFindAndReplace = (transformation) =>
				transformation.transformation?.value === "FindAndReplace";
			const form = [
				{
					name: "variable",
					type: "dropdown",
					options: (transformation) => {
						const groups = [];

						for (let group of variables) {
							groups.push({
								label: group.label,
								options: group.options.filter(
									(o) => o.value !== transformation.name
								),
							});
						}

						return groups;
					},
				},
				{ name: "name", type: "input" },
				{ name: "label", type: "input" },
				{
					name: "transformation",
					type: "dropdown",
					options: (transformation) =>
						transformation.variable
							? TRANSFORMATIONS[transformation.variable?.type]
							: [],
				},
				{
					name: "template",
					type: "input",
					show: isFormattingTimestamp,
				},
				{
					name: "format",
					type: "dropdown",
					options: () => TIMESTAMP_FORMAT_OPTIONS,
					show: isFormattingTimestamp,
				},
				{
					name: "hours",
					type: "input",
					show: isAddingHours,
				},
				{
					name: "characterLength",
					label: "character length",
					type: "input",
					show: actionIsGeneratingString,
				},
				{
					name: "template",
					type: "input",
					show: actionIsFromTemplate,
				},
				{
					name: "key",
					type: "input",
					show: isHashing,
				},
				{
					name: "find",
					type: "input",
					show: isFindAndReplace,
				},
				{
					name: "replace",
					type: "input",
					show: isFindAndReplace,
				},
			];
			return (
				<div>
					<StepTitle>
						Do values in this request need to be transformed?
					</StepTitle>

					<MultiChoiceButton
						key="No"
						selected={!values.hasTransformations}
						onClick={() => {
							setFieldValue("hasTransformations", false);
						}}
					>
						No
					</MultiChoiceButton>
					<MultiChoiceButton
						key="Yes"
						selected={values.hasTransformations}
						onClick={() => {
							setFieldValue("hasTransformations", true);
						}}
					>
						Yes
					</MultiChoiceButton>
					{values.hasTransformations && (
						<>
							<StepText style={{ marginBottom: "20px" }}>
								Transformations
							</StepText>
							<Repeater
								minusMode="all"
								items={values.transformations || []}
								add={() => {
									setFieldValue("transformations", [
										...values.transformations,
										{
											variable: null,
											name: "",
											label: "",
											transformation: "",
											template: null,
											format: null,
										},
									]);
								}}
								subtract={(index) => {
									const items = [...values.transformations];
									if (index > -1) items.splice(index, 1);
									setFieldValue("transformations", items);
								}}
								template={(transformation, index) => {
									return (
										<ParameterItem>
											{form.map((prop) => {
												const options = prop.options?.(transformation) ?? [];
												const show = !prop.show || prop.show?.(transformation);
												return show ? (
													prop.type === "dropdown" ? (
														<Dropdown
															style={{ width: "200px" }}
															key={prop.name}
															placeholder={prop.label || prop.name}
															value={transformation[prop.name]}
															options={options}
															onChange={(value) => {
																setFieldValue(
																	`transformations.${index}.${prop.name}`,
																	value
																);
															}}
														/>
													) : (
														<Input
															key={prop.name}
															useLabel={prop.label || prop.name}
															value={transformation[prop.name]}
															onChange={(event) => {
																setFieldValue(
																	`transformations.${index}.${prop.name}`,
																	event.target.value
																);
															}}
														/>
													)
												) : null;
											})}
										</ParameterItem>
									);
								}}
							/>
						</>
					)}
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const bodyStep =
	(editorRef) =>
	({ values, setFieldValue, next, goTo, previous }) => {
		return {
			id: "body",
			label: "Body",
			render: () => {
				const formatDescription = {
					UTC: "in UTC",
					Timezone: "using Site Timezone",
				};
				const typeByTransformation = {
					ConvertToString: "String",
					Cents: "Float",
					Format: "String",
					AddHours: "Timestamp",
					GenerateRandomString: "String",
					FromTemplate: "String",
				};
				const descriptionByTransformation = {
					ConvertToString: (transform) =>
						`Converted ${
							transform.variable.label || transform.variable.value
						} to String`,
					Cents: (transform) =>
						`Converted ${
							transform.variable.label || transform.variable.value
						} from dollars to cents`,
					Format: (transform) =>
						`Formatted as ${transform.template} from ${
							transform.variable.label
						} ${formatDescription[transform.format.value]}`,
					AddHours: (transform) => {
						return `Adding ${transform.hours} Hour${
							transform.hours == 1 ? "" : "s"
						} to the ${transform.variable?.label}`;
					},
					GenerateRandomString: (transform) =>
						`Generating a random ${transform.characterLength} character string at runtime.`,
					FromTemplate: () => `Created from a string template.`,
				};
				const byTransformModel = (transform) => ({
					type: typeByTransformation[transform.transformation.value],
					description: `${
						descriptionByTransformation[transform.transformation.value]?.(
							transform
						) ||
						transform.label ||
						transform.value
					}`,
				});
				const transformModelByType = {
					Timestamp: byTransformModel,
					Integer: byTransformModel,
					Float: byTransformModel,
					Action: byTransformModel,
					String: (transform) => ({
						type: "String",
						description: `Base 64 encoding of ${
							transform.variable?.label || transform.variable?.name
						}`,
					}),
				};
				const isJSON = values.languageMode === "json";
				return (
					<div>
						<StepTitle>
							What do you want to send in the body of the request?
						</StepTitle>

						<FlexRow>
							<div>
								<StepText style={{ marginBottom: "10px" }}>Body</StepText>
								<Editor
									height="40vh"
									width="845px"
									language={values.languageMode}
									defaultValue={values.defaultLanguageModeValue}
									value={values.body}
									options={{ minimap: { enabled: false } }}
									onChange={(value) => {
										setFieldValue("body", value);
									}}
									onMount={(editor) => {
										editorRef.current = editor;
									}}
								/>
								<div style={{ marginTop: "20px" }}>
									<ReactSwitch
										checked={isJSON}
										onChange={(value) => {
											setFieldValue("languageMode", value ? "json" : "xml");
											setFieldValue(
												"defaultLanguageModeValue",
												value ? "{}" : '<?xml version="1.0" encoding="utf-8"?>'
											);
										}}
										handleDiameter={30}
										offColor="#fff"
										onColor="#fff"
										offHandleColor="#1BC88E"
										onHandleColor="#1BC88E"
										height={40}
										width={100}
										borderRadius={3}
										uncheckedHandleIcon={
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													color: "white",
													height: "100%",
													fontSize: "12px",
													fontWeight: "bold",
												}}
											>
												{"</>"}
											</div>
										}
										uncheckedIcon={
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													height: "100%",
													fontSize: 15,
													paddingRight: 2,
												}}
											>
												XML
											</div>
										}
										checkedHandleIcon={
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													color: "white",
													height: "100%",
													fontSize: "14px",
													fontWeight: "bold",
												}}
											>
												{"{ }"}
											</div>
										}
										checkedIcon={
											<div
												style={{
													display: "flex",
													justifyContent: "center",
													alignItems: "center",
													height: "100%",
													fontSize: 15,
													paddingRight: 2,
												}}
											>
												JSON
											</div>
										}
									/>
								</div>
							</div>
							<div>
								<StepText style={{ marginBottom: "10px" }}>Variables</StepText>
								<Variables
									variableGroups={[
										values.hasTransformations
											? {
													name: "Transform",
													color: "purple",
													variables: values.transformations?.map(
														(transform) => {
															const trns =
																transformModelByType[
																	transform.variable?.type
																]?.(transform);
															return {
																value: transform.name,
																label: transform.label,
																description: trns?.description
																	? ` ${trns?.description}`
																	: "",
																type: trns?.type || "String",
															};
														}
													),
											  }
											: null,
										...values.variableGroups.filter(
											(grp) => grp.name !== "Actions"
										),
									].filter((g) => g)}
									onVariableClicked={(variable) => {
										const selection = editorRef.current.getSelection();
										const { selectionStartColumn, selectionStartLineNumber } =
											selection;

										const start = selectionStartLineNumber - 1;

										const lines = values.body.split("\n");
										const line = lines[start];
										lines[start] =
											line.substring(0, selectionStartColumn) +
											`"{{{${variable.value}}}}"` +
											line.substring(selectionStartColumn);

										setFieldValue("body", lines.join("\n"));
									}}
								/>
							</div>
						</FlexRow>
					</div>
				);
			},
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={previous}>
											Back
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="submit"
								color="blue"
								onClick={() => {
									if (values.editFromSummary) {
										goTo("summary");
									} else {
										next();
									}
								}}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const responseStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "response",
		label: "Response",
		render: () => (
			<div>
				<StepTitle>What should we do with the response?</StepTitle>

				{Object.keys(RESPONSE_METHOD_LABELS).map((responseMethod) => (
					<MultiChoiceButton
						key={responseMethod}
						selected={values.responseMethod === responseMethod}
						onClick={() => {
							setFieldValue("responseMethod", responseMethod);
						}}
					>
						{RESPONSE_METHOD_LABELS[responseMethod]}
					</MultiChoiceButton>
				))}
				{values.responseMethod === "Map" && (
					<>
						<StepText style={{ marginBottom: "20px" }}>
							Map Response to Variables
						</StepText>
						<Repeater
							minusMode="all"
							items={values.response}
							add={() => {
								setFieldValue("response", [
									...values.response,
									{ path: "", variable: "", label: "", description: "" },
								]);
							}}
							subtract={(index) => {
								const items = [...values.response];
								if (index > -1) items.splice(index, 1);
								setFieldValue("response", items);
							}}
							template={(responseItem, index) => {
								const typeOptions = [
									"String",
									"Integer",
									"Float",
									"Timestamp",
								].map((t) => ({
									value: t,
									label: t,
								}));
								return (
									<ParameterItem>
										{["path", "variable", "type", "label", "description"].map(
											(prop) =>
												prop === "type" ? (
													<Dropdown
														key={prop}
														placeholder={prop}
														value={typeOptions.find(
															(o) => o.value === responseItem[prop]
														)}
														options={typeOptions ?? []}
														onChange={(value) => {
															setFieldValue(
																`response.${index}.${prop}`,
																value?.value
															);
														}}
													/>
												) : (
													<Input
														key={prop}
														useLabel={prop}
														value={responseItem[prop]}
														onChange={(event) => {
															setFieldValue(
																`response.${index}.${prop}`,
																event.target.value
															);
														}}
													/>
												)
										)}
									</ParameterItem>
								);
							}}
						/>
					</>
				)}
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const rulesStep = ({ values, setFieldValue, next, goTo, previous }) => {
	return {
		id: "rules",
		label: "Rules",
		render: () => (
			<div>
				<StepTitle>Does this request have any rules?</StepTitle>

				<MultiChoiceButton
					key="No"
					selected={!values.hasRules}
					onClick={() => {
						setFieldValue("hasRules", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					key="Yes"
					selected={values.hasRules}
					onClick={() => {
						setFieldValue("hasRules", true);
					}}
				>
					Yes
				</MultiChoiceButton>
				{values.hasRules && (
					<>
						{[
							{
								prefix: "Pre",
								runtime: "before",
								rules: values.preRequestRules,
							},
							{
								prefix: "Post",
								runtime: "after",
								rules: values.postRequestRules,
							},
						].map((ruleModel) => (
							<React.Fragment key={`ruleModel${ruleModel.prefix}`}>
								<StepText>
									<FlexRow style={{ alignItems: "center" }}>
										{ruleModel.prefix}-request Rules{" "}
										<span style={{ fontSize: "18px", fontWeight: "200" }}>
											(these rules are evaluated{" "}
											<strong>{ruleModel.runtime}</strong> the request is run)
										</span>
										{!ruleModel.rules?.length && (
											<Icon>
												<PlusCircle
													onClick={() => {
														setFieldValue(
															`${ruleModel.prefix.toLowerCase()}RequestRules`,
															[{ type: "", condition: "", outcome: "" }]
														);
													}}
													color={colours.blue}
												/>
											</Icon>
										)}
									</FlexRow>
								</StepText>
								<Repeater
									minusMode="all"
									canRemoveFirst={true}
									items={ruleModel.rules}
									add={() => {
										setFieldValue(
											`${ruleModel.prefix.toLowerCase()}RequestRules`,
											[
												...ruleModel.rules,
												{ type: "", condition: "", outcome: "" },
											]
										);
									}}
									subtract={(index) => {
										const items = [...ruleModel.rules];
										if (index > -1) items.splice(index, 1);
										setFieldValue(
											`${ruleModel.prefix.toLowerCase()}RequestRules`,
											items
										);
									}}
									template={(rule, index) => {
										return (
											<React.Fragment>
												<span
													style={{
														fontSize: "20px",
														marginTop: "10px",
														marginRight: "10px",
													}}
												>
													if
												</span>
												<ParameterItem>
													{[
														{
															key: "type",
															label: "type",
															options: RULE_OPTIONS_BY_PROP.TYPE,
															width: "180px",
														},
														{
															key: "variable",
															label: "variable",
															canShow: () => rule.type === "Variable",
															options: values.variables,
															width: "200px",
														},
														{
															key: "condition",
															label: "condition",
															canShow: () => rule.type !== "True",
															options: RULE_OPTIONS_BY_PROP.CONDTION,
														},
														{
															key: "responseCode",
															label: "response code",
															canShow: () =>
																rule.type === "ResponseCode" &&
																rule.condition === "Equals",
															options: [
																{ value: "400", label: "400 Bad Request" },
																{ value: "401", label: "401 Unauthorized" },
																{ value: "403", label: "403 Forbidden" },
																{ value: "404", label: "404 Not Found" },
																{ value: "408", label: "408 Request Timeout" },
															],
															width: "200px",
														},
														{
															key: "outcome",
															label: "outcome",
															options: RULE_OPTIONS_BY_PROP.OUTCOME,
														},
														{
															key: "request",
															label: "request",
															canShow: () => rule.outcome === "Request",
															options: values.requests,
															width: "200px",
														},
														{
															key: "unsetVariable",
															label: "variable",
															canShow: () => rule.outcome === "UnsetVariable",
															options: values.variables,
															width: "200px",
														},
													].map((prop) => (
														<React.Fragment
															key={`input${ruleModel.prefix}${prop.key}`}
														>
															{(!prop.canShow || prop.canShow()) &&
																(prop.input ? (
																	<InputWithIcon
																		value={rule[prop.key]}
																		onFormat={(value) => {
																			setFieldValue(
																				`${ruleModel.prefix.toLowerCase()}RequestRules.${index}.${
																					prop.key
																				}`,
																				value
																			);
																		}}
																	/>
																) : (
																	<Dropdown
																		style={{ width: prop.width ?? "150px" }}
																		key={prop.key}
																		placeholder={prop.label}
																		value={prop.options?.find(
																			(o) => o.value === rule[prop.key]
																		)}
																		options={prop.options ?? []}
																		onChange={(value) => {
																			setFieldValue(
																				`${ruleModel.prefix.toLowerCase()}RequestRules.${index}.${
																					prop.key
																				}`,
																				value?.value
																			);
																		}}
																	/>
																))}
														</React.Fragment>
													))}
												</ParameterItem>
											</React.Fragment>
										);
									}}
								/>
							</React.Fragment>
						))}
					</>
				)}
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const suggestableInputSummaryItem = (
	key,
	title,
	edit,
	customVariables,
	values,
	setFieldValue,
	goTo
) => ({
	title,
	value: (
		<>
			{reactStringReplace(values[key], /{{{(.*?)}}}/, (match) => {
				const variable = customVariables?.find((v) => v.value === match);
				return (
					<Tooltip
						key={key}
						overlay={
							variable?.variableValue ||
							variable?.value ||
							variable?.label ||
							variable?.name
						}
						placement="top"
					>
						<Label
							style={{
								marginRight: "10px",
								fontFamily: "Arial",
								cursor: "pointer",
							}}
							color="green"
						>
							{variable?.value || variable?.label || variable?.name}
						</Label>
					</Tooltip>
				);
			})}
		</>
	),
	key,
	edit: () => {
		setFieldValue("editFromSummary", true);
		goTo(edit || key);
	},
});

export const summaryStep =
	(attemptFileTransferConnection) =>
	({
		close,
		goTo,
		handleSubmit,
		isSubmitting,
		setFieldValue,
		values,
		wizardProps,
	}) => {
		const [connectionLoading, setConnectionLoading] = useState(false);
		const ruleCount =
			(values.preRequestRules?.length || 0) +
			(values.postRequestRules?.length || 0);
		const customVariables = values.variableGroups?.find(
			(group) => group.name === "Custom"
		)?.variables;

		const suggestableSummary = (key, title, edit) =>
			suggestableInputSummaryItem(
				key,
				title,
				edit,
				customVariables,
				values,
				setFieldValue,
				goTo
			);
		const isSFTP = values.method === "SFTP";

		const nameSummaryItem = {
			title: "Name",
			value: values.name,
			key: "name",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("name");
			},
		};
		const methodSummaryItem = {
			title: "Type",
			value: (
				<Label color={COLORS_BY_REQUEST_METHOD[values.method]}>
					{values.method}
				</Label>
			),
			key: "method",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("method");
			},
		};
		const items = isSFTP
			? [
					nameSummaryItem,
					methodSummaryItem,
					suggestableSummary("hostname", "Hostname"),
					suggestableSummary("username", "Username"),
					values.sftpAuthMethod === "PrivateKey"
						? suggestableSummary("privateKey", "Private Key", "sftpAuthMethod")
						: suggestableSummary("password", "Password", "sftpAuthMethod"),
					suggestableSummary("port", "Port"),
			  ]
			: [
					nameSummaryItem,
					methodSummaryItem,
					suggestableSummary("url", "URL"),
					{
						title: "Params",
						value:
							values.hasParams && values.params?.length
								? `${values.params?.length} Parameter${
										values.params?.length === 1 ? "" : "s"
								  } Set`
								: "None",
						key: "params",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("params");
						},
					},
					{
						title: "Authorization",
						value:
							values.shouldAuthorize && values.authorizationType
								? AUTHORIZATION_OPTIONS.find(
										(o) => o.value === values.authorizationType
								  )?.label
								: "No",
						key: "authorization",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("authorization");
						},
					},
					{
						title: "Headers",
						value:
							values.hasHeaders && values.headers?.length
								? `${values.headers?.length} Header${
										values.headers?.length === 1 ? "" : "s"
								  } Set`
								: "None",
						key: "headers",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("headers");
						},
					},
					{
						title: "Transform",
						value: values.hasTransformations
							? `${values.transformations?.length} Transformation${
									values.transformations?.length === 1 ? "" : "s"
							  } Set`
							: "No",
						key: "transform",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("transform");
						},
					},
					values.method === "POST"
						? {
								title: "Body",
								value: "Click edit to view details",
								key: "body",
								edit: () => {
									setFieldValue("editFromSummary", true);
									goTo("body");
								},
						  }
						: null,
					{
						title: "Response",
						value: RESPONSE_METHOD_LABELS[values.responseMethod],
						key: "response",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("response");
						},
					},
					{
						title: "Rules",
						value: values.hasRules
							? `${ruleCount} Rule${ruleCount === 1 ? "" : "s"} Set`
							: "None",
						key: "rules",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("rules");
						},
					},
			  ];

		return {
			id: "summary",
			label: "Summary",
			render: () => (
				<div>
					<StepTitle>Summary</StepTitle>
					<SummaryTable
						valueStyle={{ whiteSpace: "none" }}
						items={items.filter((i) => i)}
					/>
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={[
							<Button key="previous" onClick={close} color="blue">
								Cancel
							</Button>,
						]}
						rightItems={[
							isSFTP ? (
								<Button
									color="blue"
									disabled={connectionLoading}
									onClick={async () => {
										try {
											setConnectionLoading(true);
											await attemptFileTransferConnection({
												variables: {
													hostname: values.hostname,
													username: values.username,
													password:
														values.sftpAuthMethod === "Password"
															? values.password
															: null,
													privateKey:
														values.sftpAuthMethod === "PrivateKey"
															? values.privateKey
															: null,
													port: values.port,
												},
											});
											Alert.success("The connection attempt was successful");
										} catch (error) {
											Alert.error("The connection attempt was unsuccessful ");
										} finally {
											setConnectionLoading(false);
										}
									}}
								>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											justifyContent: "space-around",
											alignItems: "center",
											gap: "20px",
										}}
									>
										{connectionLoading && <Loader size={16} thickness={2} />}
										{connectionLoading
											? "Testing Connection"
											: "Test Connection"}
									</div>
								</Button>
							) : null,
							<Button
								key="submit"
								color="green"
								onClick={handleSubmit}
								disabled={isSubmitting}
							>
								{wizardProps.mode === "edit" ? "Update" : "Create"}
							</Button>,
						].filter((button) => button)}
					/>
				);
			},
		};
	};

export const deleteStep = ({ handleSubmit, isSubmitting, wizardProps }) => ({
	id: "delete",
	label: "Delete",
	render: () => (
		<div>
			<StepText>Are you sure you want to delete this request?</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Delete
				</Button>,
			]}
		/>
	),
});

export const duplicateStep = ({ handleSubmit, isSubmitting, wizardProps }) => ({
	id: "duplicate",
	label: "Duplicate",
	render: () => (
		<div>
			<StepText>Are you sure you want to duplicate this request?</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Duplicate
				</Button>,
			]}
		/>
	),
});
