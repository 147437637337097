import {
	COLORS_BY_REQUEST_METHOD,
	EVENT_TYPES,
	EVENT_TYPES_BY_INTEGRATION_TYPE,
	EVENT_TYPES_TRANSLATIONS,
	INTEGRATION_TYPES,
	INTEGRATION_TYPES_TRANSLATIONS,
} from "../../../helpers/constants";
import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import MultiChoiceButton from "../MultiChoiceButton";
import React from "react";
import SummaryTable from "../SummaryTable";
import TableLayout from "../../layout/TableLayout";
import WizardNavigation from "../WizardNavigation";
import Label from "../../layout/Label";
import Input from "../../layout/Input";
import Repeater, { Icon } from "../../layout/Repeater";
import { colours } from "../../../styles";
import { PlusCircle } from "react-feather";
import InputWithIcon from "../../layout/InputWithIcon";

export const integrationTypeStep =
	(setIntegrationType) =>
	({ values, setFieldValue, next, goTo, close }) => {
		return {
			id: "integrationType",
			label: "Integration Type",
			render: () => (
				<div>
					<StepTitle>What type of integration is this for?</StepTitle>

					{[
						INTEGRATION_TYPES.WEBHOOK,
						INTEGRATION_TYPES.POLLING,
						INTEGRATION_TYPES.FILE_TRANSFER,
					].map((integrationType) => (
						<MultiChoiceButton
							key={integrationType}
							selected={values.integrationType === integrationType}
							onClick={() => {
								setFieldValue("integrationType", integrationType);
								setIntegrationType(integrationType);
							}}
						>
							{INTEGRATION_TYPES_TRANSLATIONS[integrationType]}
						</MultiChoiceButton>
					))}
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={close}>
											Cancel
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="submit"
								color="blue"
								onClick={() => {
									if (values.editFromSummary) {
										goTo("summary");
									} else {
										next();
									}
								}}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const eventTypeStep =
	(setEventType) =>
	({ values, setFieldValue, next, previous, goTo, wizardProps }) => {
		const integrationType = values.integrationType;
		const groupedOptions = EVENT_TYPES_BY_INTEGRATION_TYPE[integrationType];
		const selectedEventType = groupedOptions
			?.reduce((acc, group) => [...acc, ...group.options], [])
			?.find((et) => et.value === values.eventType);
		return {
			id: "eventType",
			label: "Event Type",
			render: () => (
				<div>
					<StepTitle>What event is this integration for?</StepTitle>

					<Dropdown
						options={groupedOptions}
						value={selectedEventType}
						onChange={(value) => {
							setFieldValue("eventType", value?.value);
							setEventType(value?.value);
						}}
					/>
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={
							values.editFromSummary
								? []
								: [
										<Button key="submit" color="blue" onClick={previous}>
											Back
										</Button>,
								  ]
						}
						rightItems={[
							<Button
								key="right-button"
								color="blue"
								onClick={() => {
									if (values.editFromSummary) {
										if (wizardProps.context === "Client") {
											if (values.eventType === EVENT_TYPES.LPR) {
												if (!values.selectedCameras?.length) {
													setFieldValue("editFromSummary", true);
													goTo("selectCameras");
													return;
												}
											} else {
												if (!values.selectedSites?.length) {
													setFieldValue("editFromSummary", true);
													goTo("selectSites");
													return;
												}
											}
										}

										goTo("summary");
									} else {
										next();
									}
								}}
							>
								{values.editFromSummary ? "Review" : "Next"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const deleteStep = ({ handleSubmit, isSubmitting, wizardProps }) => ({
	id: "delete",
	label: "Delete",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to delete this integration? This action CANNOT be
				undone.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Delete
				</Button>,
			]}
		/>
	),
});

export const toggleStep =
	(isEnabled) =>
	({ handleSubmit, isSubmitting, wizardProps }) => ({
		id: "toggle",
		label: "Enable",
		render: () => (
			<div>
				<StepText>
					Are you sure you want to {isEnabled ? "disable" : "enable"} this
					integration?
				</StepText>
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={[
					<Button key="cancel" color="blue" onClick={wizardProps.close}>
						Cancel
					</Button>,
				]}
				rightItems={[
					<Button
						key="submit"
						color={isEnabled ? "yellow" : "green"}
						onClick={handleSubmit}
						disabled={isSubmitting}
					>
						{isEnabled ? "Disable" : "Enable"}
					</Button>,
				]}
			/>
		),
	});

export function selectSitesStep({
	keyStrokeHandler,
	setFieldValue,
	goTo,
	values,
	previous,
	next,
}) {
	return {
		id: "selectSites",
		label: "Sites",
		render: () => {
			return (
				<div>
					<StepTitle>What sites should apply to this integration?</StepTitle>
					<Dropdown
						isMulti={true}
						options={values.availableSites}
						value={values.selectedSites}
						onChange={(value) => {
							setFieldValue("selectedSites", value);
						}}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={values.selectedSites?.length ? false : true}
							keyStrokeHandler={keyStrokeHandler}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
}

export function selectCamerasStep({
	isSubmitting,
	keyStrokeHandler,
	setFieldValue,
	goTo,
	values,
	previous,
	next,
}) {
	return {
		id: "selectCameras",
		label: "Cameras",
		render: () => {
			return (
				<div>
					<StepTitle>What cameras should apply to this integration</StepTitle>
					<Dropdown
						isMulti={true}
						options={values.availableCameras}
						value={values.selectedCameras}
						onChange={(value) => {
							setFieldValue("selectedCameras", value);
						}}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={previous} color="blue">
							Back
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={isSubmitting || !values.selectedCameras.length}
							keyStrokeHandler={keyStrokeHandler}
						>
							Next
						</Button>,
					]}
				/>
			);
		},
	};
}

export function requestStep({ setFieldValue, goTo, values, previous, next }) {
	return {
		id: "request",
		label: "Request",
		render: () => {
			return (
				<div>
					<StepTitle>Request</StepTitle>
					<Dropdown
						options={values.requests}
						value={values.request}
						onChange={(value) => {
							setFieldValue("request", value);
						}}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
}

export const directoryStep = ({ values, setFieldValue, next, goTo, close }) => {
	return {
		id: "directory",
		label: "Directory",
		render: () => (
			<div>
				<StepTitle>What directory do you want us to pull from?</StepTitle>

				<Input
					type="text"
					name="directory"
					value={values.directory || ""}
					onChange={(event) => {
						setFieldValue("directory", event.target.value);
					}}
				/>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={close}>
										Cancel
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={!values.directory}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const headersStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	previous,
}) => {
	return {
		id: "headers",
		label: "Headers",
		render: () => (
			<div>
				<StepTitle>What are the expected CSV headers?</StepTitle>
				<>
					<div
						style={{
							display: "flex",
							marginBottom: "15px",
							width: "300px",
						}}
					>
						<StepText
							style={{
								marginBottom: "20px",
								width: "200px",
								marginRight: "20px",
							}}
						>
							Header
						</StepText>
						<StepText style={{ marginBottom: "20px" }}>Required</StepText>
					</div>
					<Repeater
						minusMode="all"
						items={values.headers || []}
						add={() => {
							setFieldValue("headers", [
								...values.headers,
								{ column: "", required: false },
							]);
						}}
						subtract={() => {
							const items = [...values.headers];
							items.pop();
							setFieldValue("headers", items);
						}}
						template={(header, index) => {
							return (
								<div
									key={`header${index}`}
									style={{
										display: "flex",
										marginBottom: "15px",
										width: "300px",
									}}
								>
									<Input
										style={{ width: "200px" }}
										key={`header${index}Column`}
										value={header.column}
										onChange={(event) => {
											setFieldValue(
												`headers.${index}.column`,
												event.target.value
											);
										}}
									/>
									<Input
										style={{
											width: "20px",
											height: "20px",
											marginLeft: "40px",
											marginTop: "15px",
										}}
										key={`header${index}Required`}
										type="checkbox"
										checked={header.required}
										onChange={(event) => {
											setFieldValue(
												`headers.${index}.required`,
												event.target.checked
											);
										}}
									/>
								</div>
							);
						}}
					/>
				</>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={values.hasParams && !values.params?.length}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export function mapperStep({ goTo, values, previous, next, setFieldValue }) {
	return {
		id: "mapper",
		label: "Mapper",
		render: () => {
			const headerOptions =
				values.headers?.map((header) => ({
					value: header.column,
					label: header.column,
				})) || [];
			return (
				<div>
					<StepTitle>Mapping required fields for CSV invitations</StepTitle>
					{[
						{ key: "externalUserCode", label: "External User Code" },
						{ key: "siteId", label: "Site ID" },
						{ key: "groupName", label: "Group Name" },
					].map((column) => {
						return (
							<div
								key={column.key}
								style={{ display: "flex", marginBottom: "10px" }}
							>
								<StepText
									style={{
										marginTop: "5px",
										marginRight: "15px",
										width: "200px",
										fontWeight: "normal",
										fontSize: "18px",
									}}
								>
									{column.label}
								</StepText>
								<Dropdown
									style={{ width: "200px" }}
									options={headerOptions}
									value={headerOptions?.find(
										(option) =>
											option.value === values.mapper?.[column.key]?.column
									)}
									onChange={(value) => {
										setFieldValue(`mapper.${column.key}.column`, value.value);
									}}
								/>
							</div>
						);
					})}
					<StepText>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								marginBottom: "20px",
								marginTop: "50px",
							}}
						>
							Value mapping
							{!values.valueMappings?.length && (
								<Icon>
									<PlusCircle
										onClick={() => {
											setFieldValue("valueMappings", [
												{ column: "", from: "", to: "" },
											]);
										}}
										color={colours.blue}
									/>
								</Icon>
							)}
						</div>
					</StepText>
					{values.valueMappings?.length ? (
						<>
							<div
								style={{ display: "flex", marginBottom: "10px", gap: "20px" }}
							>
								<StepText
									style={{
										fontSize: "18px",
										fontWeight: "normal",
										width: "200px",
									}}
								>
									Column
								</StepText>
								<StepText
									style={{
										fontSize: "18px",
										fontWeight: "normal",
										width: "170px",
									}}
								>
									From
								</StepText>
								<StepText
									style={{
										fontSize: "18px",
										fontWeight: "normal",
										width: "200px",
									}}
								>
									To
								</StepText>
							</div>
							<Repeater
								minusMode="all"
								canRemoveFirst={true}
								items={values.valueMappings}
								add={() => {
									setFieldValue("valueMappings", [
										...values.valueMappings,
										{ column: "", from: "", to: "" },
									]);
								}}
								subtract={(index) => {
									const items = [...values.valueMappings];
									if (index > -1) items.splice(index, 1);
									setFieldValue("valueMappings", items);
								}}
								template={(item, index) => {
									const isSiteIdColumn =
										values.mapper?.siteId?.column === item?.column;
									return (
										<div style={{ display: "flex", gap: "20px" }}>
											<Dropdown
												style={{ width: "200px" }}
												options={headerOptions}
												value={headerOptions.find(
													(option) => option.value === item?.column
												)}
												onChange={(value) => {
													setFieldValue(
														`valueMappings.${index}.column`,
														value.value
													);
												}}
											/>
											<InputWithIcon
												value={item?.from}
												onFormat={(value) => {
													setFieldValue(`valueMappings.${index}.from`, value);
												}}
											/>
											{isSiteIdColumn ? (
												<Dropdown
													style={{ width: "200px" }}
													options={values.selectedSites}
													value={values.selectedSites?.find(
														(site) => site.value?.toString() === item?.to
													)}
													onChange={(value) => {
														setFieldValue(
															`valueMappings.${index}.to`,
															value.value?.toString()
														);
													}}
												/>
											) : (
												<InputWithIcon
													style={{ width: "200px" }}
													value={item?.to}
													onFormat={(value) => {
														setFieldValue(`valueMappings.${index}.to`, value);
													}}
												/>
											)}
										</div>
									);
								}}
							/>
						</>
					) : null}
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
}

export function intervalStep({
	isSubmitting,
	keyStrokeHandler,
	goTo,
	values,
	next,
	previous,
	handleChange,
}) {
	return {
		id: "interval",
		label: "Interval",
		render: () => {
			return (
				<div>
					<StepTitle>
						How often should we poll this data for? (in minutes)
					</StepTitle>
					<Input
						type="number"
						min={2}
						name="interval"
						value={values.interval}
						onChange={handleChange}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="previous" onClick={previous} color="blue">
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
}

export function expiryStep({
	isSubmitting,
	keyStrokeHandler,
	goTo,
	values,
	next,
	previous,
	handleChange,
}) {
	return {
		id: "expiry",
		label: "Expiry Period",
		render: () => {
			return (
				<div>
					<StepTitle>How long should the exit plate reads be cached?</StepTitle>
					<Input
						type="number"
						min={24}
						name="expiry"
						value={values.expiry || ""}
						onChange={handleChange}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="previous" onClick={previous} color="blue">
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							disabled={isSubmitting}
							keyStrokeHandler={keyStrokeHandler}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
}

export const isEnabledStep = ({
	values,
	setFieldValue,
	next,
	previous,
	goTo,
	keyStrokeHandler,
}) => {
	return {
		id: "isEnabled",
		label: "State",
		render: () => (
			<div>
				<StepTitle>Should this integration be enabled now?</StepTitle>

				<MultiChoiceButton
					selected={!values.isEnabled}
					onClick={() => {
						setFieldValue("isEnabled", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.isEnabled}
					onClick={() => {
						setFieldValue("isEnabled", true);
					}}
				>
					Yes
				</MultiChoiceButton>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							keyStrokeHandler={keyStrokeHandler}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const loggingEnabledStep = ({
	values,
	setFieldValue,
	next,
	previous,
	goTo,
	keyStrokeHandler,
}) => {
	return {
		id: "loggingEnabled",
		label: "Logging Enabled",
		render: () => (
			<div>
				<StepTitle>Do you want logging enabled on this integration?</StepTitle>

				<MultiChoiceButton
					selected={!values.loggingEnabled}
					onClick={() => {
						setFieldValue("loggingEnabled", false);
					}}
				>
					No
				</MultiChoiceButton>
				<MultiChoiceButton
					selected={values.loggingEnabled}
					onClick={() => {
						setFieldValue("loggingEnabled", true);
					}}
				>
					Yes
				</MultiChoiceButton>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={previous}>
										Back
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
							keyStrokeHandler={keyStrokeHandler}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const summaryStep = ({
	close,
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
}) => {
	const showCameras =
		values.integrationType === INTEGRATION_TYPES.WEBHOOK &&
		values.eventType === EVENT_TYPES.LPR;

	const extraStepsByType = {
		[INTEGRATION_TYPES.WEBHOOK]: [],
		[INTEGRATION_TYPES.POLLING]: [
			{
				title: "Interval",
				value: `${values.interval} Minute${values.interval === 1 ? "" : "s"}`,
				key: "interval",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("interval");
				},
			},
			{
				title: "Expiry Period",
				value: `${values.expiry} Hour${values.expiry === 1 ? "" : "s"}`,
				key: "expiry",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("expiry");
				},
			},
		],
		[INTEGRATION_TYPES.FILE_TRANSFER]: [
			{
				title: "Directory",
				value: values.directory,
				key: "directory",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("directory");
				},
			},
			{
				title: "Headers",
				value: values.headers?.map((header) => header.column).join(", "),
				key: "headers",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("headers");
				},
			},
			{
				title: "Mapper",
				value: "Click Edit to view details",
				key: "mapper",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("mapper");
				},
			},
		],
	};
	const extraSteps = extraStepsByType[values.integrationType] || [];

	const items = [
		{
			title: "Integration Type",
			value: values.integrationType,
			key: "integrationType",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("integrationType");
			},
		},
		{
			title: "Event Type",
			value: EVENT_TYPES_TRANSLATIONS[values.eventType],
			key: "eventType",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("eventType");
			},
		},
		wizardProps.context === "Client"
			? showCameras
				? {
						title: "Cameras",
						value: values.selectedCameras.map((c) => c.label).join(", "),
						key: "selectCameras",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("selectCameras");
						},
				  }
				: {
						title: "Sites",
						value: values.selectedSites.map((s) => s.label).join(", "),
						key: "selectedSites",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("selectSites");
						},
				  }
			: null,
		{
			title: "Request",
			value: (
				<>
					<Label
						style={{ marginRight: "10px" }}
						color={COLORS_BY_REQUEST_METHOD[values.request?.method]}
					>
						{values.request?.method}
					</Label>
					{values.request?.label}
				</>
			),
			key: "request",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("request");
			},
		},
		...extraSteps,
		{
			title: "Status",
			value: values.isEnabled ? (
				<Label color="green">Enabled</Label>
			) : (
				<Label color="yellow">Disabled</Label>
			),
			key: "state",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("isEnabled");
			},
		},
		{
			title: "Logging Enabled",
			value: values.loggingEnabled ? (
				<Label color="green">Enabled</Label>
			) : (
				<Label color="yellow">Disabled</Label>
			),
			key: "loggingEnabled",
			edit: () => {
				setFieldValue("editFromSummary", true);
				goTo("loggingEnabled");
			},
		},
	];

	return {
		id: "summary",
		label: "Summary",
		render: () => (
			<div>
				<StepTitle>Summary</StepTitle>
				<SummaryTable
					valueStyle={{ whiteSpace: "none" }}
					items={items.filter((i) => i)}
				/>
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="blue">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="green"
							onClick={handleSubmit}
							disabled={isSubmitting}
						>
							{wizardProps.mode === "edit" ? "Update" : "Create"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const viewCacheStep =
	(cachedData) =>
	({ close }) => {
		const data = (cachedData || []).map((d) => JSON.parse(d));

		const columns = data.length
			? Object.keys(data[0]).map((k) => {
					return {
						id: k,
						Header: k,
						accessor: (d) => d[k],
					};
			  })
			: [
					{
						id: "cache",
						Header: "Cache",
						accessor: () => "No results found",
					},
			  ];
		return {
			id: "viewCache",
			label: "Cache",
			render: () => (
				<div>
					<StepTitle>Recent polling events</StepTitle>
					<TableLayout data={data} columns={columns} />
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={[
							<Button key="submit" color="blue" onClick={close}>
								Close
							</Button>,
						]}
					/>
				);
			},
		};
	};
