import * as sessionApi from "../../api/session";
import React, { useState } from "react";
import { filter, forEach } from "lodash";
import { useFetchData, usePermissions, useSearchFilter } from "../../hooks";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import DropdownMenu from "../../components/layout/DropdownMenu";
import FlexWrapper from "../../components/layout/FlexWrapper";
import { MoreHorizontal } from "react-feather";
import ParkingSessionFeePreview from "../../components/report/ParkingSessionFeePreview";
import ReportWrapper from "../../components/report/ReportWrapper";
import SessionWizard from "../../components/wizards/session-wizard/SessionWizard";
import StatCard from "../../components/layout/StatCard";
import TabSelector from "../../components/layout/TabSelector";
import { booleanValueFormatter } from "../../components/layout/TableLayout";
import { format } from "../../helpers";
import moment from "moment";

const columns = (props) => [
	{
		id: "IsActive",
		Header: "Active",
		accessor: (d) => d.IsActive,
		Cell: (cellProps) => booleanValueFormatter(cellProps, true),
		width: 80,
	},
	{
		id: "Booking",
		Header: "Booking",
		accessor: (d) => d.UserLeaseID,
		Cell: (cellProps) => booleanValueFormatter(cellProps, true),
		width: 80,
	},
	{
		id: "Source",
		Header: "Source",
		accessor: (d) => d.Source,
		highlightCell: (cellProps) => cellProps.original.Source,
		width: 100,
	},
	{
		id: "User",
		Header: "User",
		accessor: (d) => d.User,
		highlightCell: (cellProps) => cellProps.original.User,
		className: "bold",
	},
	{
		id: "Numberplate",
		Header: "Vehicle",
		accessor: (d) => d.Numberplate || "",
		highlightCell: (cellProps) => cellProps.original.Numberplate || "",
		width: 120,
	},
	{
		id: "SiteName",
		Header: "Site",
		accessor: (d) => d.SiteName,
		highlightCell: (cellProps) => cellProps.original.SiteName,
	},
	{
		id: "StartDate",
		Header: "Start Time",
		accessor: (d) => d.StartDate,
		highlightCell: (cellProps) => cellProps.original._startDate || "",
	},
	{
		id: "EndDate",
		Header: "End Time",
		accessor: (d) => d.EndDate,
		highlightCell: (cellProps) => cellProps.original._endDate || "",
	},
	{
		id: "ExpiresAt",
		Header: "Expiry Time",
		accessor: (d) => d.ExpiresAt,
		highlightCell: (cellProps) => cellProps.original._expiresAt || "",
	},
	{
		id: "Duration",
		Header: "Duration",
		accessor: (d) => d.Duration,
		Cell: (cellProps) =>
			cellProps.original.Duration &&
			cellProps.original._expiryDuration &&
			!cellProps.original.UserLeaseID
				? `${format.duration(
						cellProps.original.Duration,
						true,
						true
				  )} (${format.duration(
						cellProps.original._expiryDuration,
						true,
						true
				  )} limit)`
				: cellProps.original.Duration
				? format.duration(cellProps.original.Duration, true, true)
				: "Unknown",
	},
	{
		id: "Fee",
		Header: "Fee",
		accessor: (d) => d.Fee,
		Cell: (cellProps) => {
			if (!cellProps.original.IsActive) {
				return format.money(cellProps.original.Fee);
			} else {
				return (
					<ParkingSessionFeePreview
						key={cellProps.original.ParkingSessionID}
						parkingSessionId={cellProps.original.ParkingSessionID}
						siteId={cellProps.original.SiteID}
						fee={cellProps.original.Fee !== -1 ? cellProps.original.Fee : null}
						onChange={(fee) => {
							props.setState((_state) => ({
								..._state,
								sessionPreviewFees: {
									..._state.sessionPreviewFees,
									[cellProps.original.ParkingSessionID]: fee,
								},
							}));
						}}
					/>
				);
			}
		},
		className: "bold",
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) =>
			cellProps.original.IsActive &&
			props.canManageSessions &&
			// temporarily disable end session button for web pay sessions till we decide how to handle it
			!cellProps.original.IsWebPaySession ? (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="end-session"
							onClick={() =>
								props.openSessionWizard(cellProps.original, "end-session")
							}
						>
							End Session
						</div>,
					]}
				/>
			) : null,
		resizable: false,
		width: 50,
		sortable: false,
	},
];

const defaultSorting = [
	{
		id: "StartDate",
		desc: true,
	},
];

export default function TransientContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			sites: [],
			"start-date-time": null,
			"end-date-time": null,
			start: null,
			end: null,
		},
		search: "",
		sessionFilter: "active",
		sessionAdminWizardOpen: false,
		sessionPreviewFees: {},
	});

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	const canManageSessions = usePermissions(null, "SessionAdmin", true);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({
			..._state,
			initialLoad: false,
			search,
			options: {
				...options,
				start: moment(options["start-date-time"]).startOf("day").unix(),
				end: moment(options["end-date-time"]).endOf("day").unix(),
			},
		}));
	}

	let { data, isLoading } = useFetchData(
		{},
		sessionApi.getSessions,
		!state.initialLoad ? [organizationId, state.options] : null,
		[organizationId, ...Object.values(state.options)]
	);

	let filteredSessions = useSearchFilter(data.sessions || [], state.search, [
		"User",
		"Numberplate",
		"SiteName",
		"_startDate",
		"_endDate",
		"Fee",
	]);

	filteredSessions = forEach(filteredSessions, (session) => {
		if (state.sessionPreviewFees[session.ParkingSessionID] !== undefined) {
			session.Fee = state.sessionPreviewFees[session.ParkingSessionID];
		}

		if (!session.IsActive && session.Fee === null) session.Fee = 0;

		session.Fee = !isNaN(session.Fee) ? parseFloat(session.Fee) : -1;

		return session;
	});

	filteredSessions = filteredSessions.filter((e) => {
		if (state.sessionFilter === "all") return true;
		if (state.sessionFilter === "active") return e.IsActive;
		if (state.sessionFilter === "closed") return !e.IsActive;
	});

	function openSessionWizard(session, mode) {
		setState((_state) => ({
			..._state,
			sessionAdminWizardOpen: true,
			sessionAdminWizardSession: session,
			sessionAdminWizardMode: mode,
		}));
	}

	async function onSessionFilterChange(sessionFilter) {
		setState((_state) => ({ ..._state, sessionFilter }));
	}

	if (state.sessionAdminWizardOpen) {
		return (
			<SessionWizard
				close={() => {
					setState((_state) => ({ ..._state, sessionAdminWizardOpen: false }));
				}}
				mode={state.sessionAdminWizardMode}
				session={state.sessionAdminWizardSession}
				sites={filter(
					props.availableSites,
					(site) =>
						site.HasCasualParking &&
						!site.HasSessionlessAccess &&
						site.SiteType === "Gated"
				)}
			/>
		);
	}

	return (
		<div>
			<ReportWrapper
				{...props}
				title="Transient Parking"
				data={filteredSessions}
				availableSites={filter(
					props.availableSites,
					(site) =>
						(site.HasCasualParking && !site.HasSessionlessAccess) ||
						(site.SecureParkingExternalID && site.SiteType === "Gated")
				)}
				columns={columns({
					searchFilter: state.searchFilter,
					data: data,
					setState: setState,
					openSessionWizard: openSessionWizard,
					canManageSessions: canManageSessions,
				})}
				rightActions={
					canManageSessions ? (
						<Button
							color="blue"
							onClick={() => openSessionWizard(null, "start-session")}
						>
							Start Session
						</Button>
					) : null
				}
				defaultSorted={defaultSorting}
				updateOptions={updateOptions}
				loading={isLoading}
				stats={
					<div>
						<FlexWrapper style={{ marginBottom: -16 }}>
							<Card>
								<StatCard
									value={data.currentSessionCount || 0}
									title="Current Inugo Sessions"
									size="medium"
								/>
							</Card>
							<Card>
								<StatCard
									value={data.sessionCount || 0}
									title="Total Sessions"
									subTitle="Within Selected Dates"
									size="medium"
								/>
							</Card>
							<Card>
								<StatCard
									value={format.money(data.totalChargedAmount)}
									title="Total Charged"
									subTitle="Within Selected Dates"
									size="medium"
								/>
							</Card>
						</FlexWrapper>
						<div style={{ margin: 32 }}>
							<TabSelector
								items={[
									{ value: "all", label: "All" },
									{ value: "active", label: "Active" },
									{ value: "closed", label: "Closed" },
								]}
								value={state.sessionFilter}
								onChange={onSessionFilterChange}
							/>
						</div>
					</div>
				}
			/>
		</div>
	);
}
