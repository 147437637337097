import { StepText, StepTitle } from "../WizardLayout";
import Button from "../../layout/Button";
import React from "react";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import Input from "../../layout/Input";
import MultiChoiceButton from "../MultiChoiceButton";
import Dropdown from "../../layout/Dropdown";
import {
	suggestableInputStep,
	suggestableInputSummaryItem,
} from "../request-wizard/steps";
import _ from "lodash";

export const inputStep = ({
	key,
	question,
	validate = () => true,
	secrifiable,
	suggestable,
	options,
}) =>
	suggestable
		? suggestableInputStep(key.toLowerCase(), key, question)
		: ({ values, setFieldValue, next, goTo, previous }) => {
				return {
					id: key.toLowerCase(),
					label: key,
					render: () => (
						<div>
							<StepTitle>{question}</StepTitle>

							{options?.length ? (
								<Dropdown
									key={`option${key.toLowerCase()}`}
									placeholder={key}
									value={options?.find(
										(o) => o.value === values[key.toLowerCase()]
									)}
									options={options ?? []}
									onChange={(value) => {
										setFieldValue(key.toLowerCase(), value?.value);
									}}
								/>
							) : (
								<Input
									key={key.toLowerCase()}
									type={
										values.isSecret === "Yes" && secrifiable
											? "password"
											: "text"
									}
									name={key.toLowerCase()}
									value={values[key.toLowerCase()] || ""}
									onChange={(event) => {
										setFieldValue(key.toLowerCase(), event.target.value);
									}}
								/>
							)}
						</div>
					),
					footer: () => {
						return (
							<WizardNavigation
								leftItems={
									values.editFromSummary
										? []
										: [
												<Button key="submit" color="blue" onClick={previous}>
													Back
												</Button>,
										  ]
								}
								rightItems={[
									<Button
										key="submit"
										color="blue"
										onClick={() => {
											if (values.editFromSummary) {
												goTo("summary");
											} else {
												next();
											}
										}}
										disabled={!validate(values[key.toLowerCase()])}
									>
										{values.editFromSummary ? "Review" : "Next"}
									</Button>,
								]}
							/>
						);
					},
				};
		  };

export const secretStep = ({ values, setFieldValue, next, goTo, close }) => {
	return {
		id: "secret",
		label: "Secret",
		render: () => (
			<div>
				<StepTitle>Do you want this variable to be a secret?</StepTitle>

				{["No", "Yes"].map((value) => (
					<MultiChoiceButton
						key={value}
						selected={values.isSecret === value}
						onClick={() => {
							setFieldValue("isSecret", value);
						}}
					>
						{value}
					</MultiChoiceButton>
				))}
			</div>
		),
		footer: () => {
			return (
				<WizardNavigation
					leftItems={
						values.editFromSummary
							? []
							: [
									<Button key="submit" color="blue" onClick={close}>
										Cancel
									</Button>,
							  ]
					}
					rightItems={[
						<Button
							key="submit"
							color="blue"
							onClick={() => {
								if (values.editFromSummary) {
									goTo("summary");
								} else {
									next();
								}
							}}
						>
							{values.editFromSummary ? "Review" : "Next"}
						</Button>,
					]}
				/>
			);
		},
	};
};

export const summaryStep =
	(questions) =>
	({
		close,
		goTo,
		handleSubmit,
		isSubmitting,
		setFieldValue,
		values,
		wizardProps,
	}) => {
		const items = [
			{
				title: "Secret",
				value: values.isSecret,
				key: "secret",
				edit: () => {
					setFieldValue("editFromSummary", true);
					goTo("secret");
				},
			},
			...questions.map(({ key, secrifiable, suggestable }) =>
				suggestable
					? suggestableInputSummaryItem(
							key.toLowerCase(),
							key,
							key.toLowerCase(),
							_.flatten(values.variableGroups?.map((grp) => grp.variables)),
							values,
							setFieldValue,
							goTo
					  )
					: {
							title: key,
							value:
								secrifiable && values.isSecret === "Yes" ? (
									<strong>••••••••••••••••••••</strong>
								) : (
									values[key.toLowerCase()]
								),
							key: key.toLowerCase(),
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo(key.toLowerCase());
							},
					  }
			),
		];

		return {
			id: "summary",
			label: "Summary",
			render: () => (
				<div>
					<StepTitle>Summary</StepTitle>
					<SummaryTable
						valueStyle={{ whiteSpace: "none" }}
						items={items.filter((i) => i)}
					/>
				</div>
			),
			footer: () => {
				return (
					<WizardNavigation
						leftItems={[
							<Button key="previous" onClick={close} color="blue">
								Cancel
							</Button>,
						]}
						rightItems={[
							<Button
								key="submit"
								color="green"
								onClick={handleSubmit}
								disabled={isSubmitting}
							>
								{wizardProps.mode === "edit" ? "Update" : "Create"}
							</Button>,
						]}
					/>
				);
			},
		};
	};

export const deleteStep = ({ handleSubmit, isSubmitting, wizardProps }) => ({
	id: "delete",
	label: "Delete",
	render: () => (
		<div>
			<StepText>Are you sure you want to delete this variable?</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
				>
					Delete
				</Button>,
			]}
		/>
	),
});
