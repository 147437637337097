import PageActions, {
	LeftActions,
	RightActions,
} from "../../components/layout/PageActions";
import React, { useContext, useEffect, useState } from "react";
import TableLayout, {
	highlightedCell,
} from "../../components/layout/TableLayout";
import { debounce, find, isEqual, orderBy, split } from "lodash";
import { AppContext } from "../../context/app-context";
import Button from "../../components/layout/Button";
import { CSVLink } from "react-csv";
import Card from "../../components/layout/Card";
import DatePicker from "../../components/layout/DatePicker";
import { Download } from "react-feather";
import ErrorBoundary from "../../containers/ErrorBoundary";
import LoadingPlaceholder from "./LoadingPlaceholder";
import PageTitle from "../../components/layout/PageTitle";
import PropTypes from "prop-types";
import ReportPicker from "./ReportPicker";
import SearchBox from "../../components/layout/SearchBox";
import gql from "graphql-tag";
import moment from "moment";
import { useMutateData } from "../../hooks";

ReportWrapper.propTypes = {
	availableSites: PropTypes.arrayOf(PropTypes.object).isRequired,
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	loading: PropTypes.bool.isRequired,
	title: PropTypes.string,
	updateOptions: PropTypes.func.isRequired,
	data: PropTypes.arrayOf(PropTypes.object),
	defaultDateRange: PropTypes.arrayOf(PropTypes.object),
	defaultSorted: PropTypes.arrayOf(PropTypes.object),
	rightActions: PropTypes.object,
	showDateRangePicker: PropTypes.bool,
	showResultsLength: PropTypes.bool,
	showSearchBox: PropTypes.bool,
	showSitePicker: PropTypes.bool,
	stats: PropTypes.object,
	csvHeaders: PropTypes.arrayOf(PropTypes.object),
	csvName: PropTypes.string,
};

ReportWrapper.defaultProps = {
	availableSites: [],
	showResultsLength: true,
	defaultDateRange: [
		moment().startOf("day").toDate(),
		moment().startOf("day").toDate(),
	],
};

const updateOptions = debounce((props, state) => {
	const options = props.showDateRangePicker
		? {
				sites: state.siteFilter,
				"start-date-time": moment(state.dateRange[0]).format("YYYYMMDD"),
				"end-date-time": moment(state.dateRange[1]).format("YYYYMMDD"),
				search: state.searchFilter,
		  }
		: {
				sites: state.siteFilter,
				search: state.searchFilter,
		  };

	props.updateOptions(options);
}, 500);

function ReportWrapper({
	showDateRangePicker = true,
	showSearchBox = true,
	showSitePicker = true,
	...props
}) {
	const {
		dispatch: { setReportDateRange, setReportSiteFilter },
		state: { reportDateRange, reportSiteFilter },
	} = useContext(AppContext);

	const [state, setState] = useState({
		dateRange: showDateRangePicker
			? reportDateRange || props.defaultDateRange
			: null,
		searchFilter: "",
		availableSites: props.availableSites,
		siteFilter:
			reportSiteFilter || props.availableSites.map((site) => site.SiteID),
		sites: props.availableSites.map((site) => ({
			value: site.SiteID,
			label: site.Name,
		})),
		sort: props.defaultSorted,
	});

	if (!isEqual(props.availableSites, state.availableSites)) {
		setState((_state) => ({
			..._state,
			availableSites: props.availableSites,
			siteFilter: props.availableSites.map((site) => site.SiteID),
			sites: props.availableSites.map((site) => ({
				value: site.SiteID,
				label: site.Name,
			})),
		}));
	}

	async function onDateRangeChange(dateRange) {
		if (dateRange && dateRange.length > 1) {
			setState((_state) => ({ ..._state, dateRange }));
			setReportDateRange(dateRange);
		}
	}

	async function onSearchFilterChange(searchFilter) {
		setState((_state) => ({ ..._state, searchFilter }));
	}

	async function onSiteFilterChange(siteFilter) {
		setState((_state) => ({ ..._state, siteFilter }));
		setReportSiteFilter(siteFilter);
	}

	async function onSortingChange(sort) {
		setState((_state) => ({ ..._state, sort }));
	}

	useEffect(() => {
		updateOptions(
			{ showDateRangePicker, updateOptions: props.updateOptions },
			state
		);
	}, [
		props.selectedOrganization,
		state.dateRange,
		state.searchFilter,
		state.siteFilter,
		state.sites,
	]);

	const columns = props.columns.map((column) => {
		if (column.highlightCell) {
			return {
				...column,
				Cell: (cellProps) =>
					highlightedCell(column.highlightCell(cellProps), state.searchFilter),
			};
		}

		return column;
	});

	const addAuditLog = useMutateData(
		gql`
			mutation (
				$auditType: String!
				$sites: [Int!]
				$sort: String!
				$columns: [String!]
				$start: Timestamp
				$end: Timestamp
			) {
				addAuditLog(
					auditType: $auditType
					sites: $sites
					sort: $sort
					columns: $columns
					start: $start
					end: $end
				)
			}
		`,
		{
			auditType: `${split(props.title, " ", 1)}ReportCSVExported`,
			sites: state.siteFilter || [],
			sort: state.sort ? state.sort[0].id : null,
			columns: props.columns.map((column) => column.id),
			start: state.dateRange
				? moment(state.dateRange[0]).format("YYYYMMDD")
				: null,
			end: state.dateRange
				? moment(state.dateRange[1]).format("YYYYMMDD")
				: null,
		}
	);

	function getCsvData() {
		const sortColumnId = state.sort[0].id;
		const sortColumn = find(props.columns, (c) => c.id === sortColumnId);
		const sortAccessor = sortColumn.accessor;
		const sortDesc = state.sort[0].desc;

		const _csvData = orderBy(
			props.data,
			(d) => d[sortAccessor] || "",
			sortDesc ? "desc" : "asc"
		);

		return _csvData;
	}

	const DownloadCsv = () => (
		<CSVLink
			headers={props.csvHeaders}
			data={getCsvData()}
			onClick={async () => {
				await addAuditLog();
			}}
			filename={props.csvName || "csv-file"}
			target="_blank"
		>
			<Button color="blue">
				<Download size={20} /> Download
			</Button>
		</CSVLink>
	);

	return (
		<ErrorBoundary>
			<div style={props.style}>
				<PageTitle>{props.title}</PageTitle>

				{(showSitePicker ||
					showSearchBox ||
					showDateRangePicker ||
					props.csvHeaders ||
					props.rightActions) && (
					<PageActions>
						<LeftActions>
							{showSitePicker && (
								<ReportPicker
									name="sites"
									options={state.sites}
									selected={state.siteFilter}
									onSelectedChanged={onSiteFilterChange}
								/>
							)}
							{showSearchBox && (
								<SearchBox
									style={{ marginLeft: showSitePicker ? 16 : 0 }}
									onChange={onSearchFilterChange}
									value={state.searchFilter}
								/>
							)}
							{props.leftActions}
						</LeftActions>

						{(showDateRangePicker ||
							props.csvHeaders ||
							props.rightActions) && (
							<RightActions>
								{showDateRangePicker && (
									<div
										style={{
											marginRight:
												props.csvHeaders || props.rightActions ? 16 : 0,
										}}
									>
										<DatePicker
											options={{ pickerMode: "dateRangePicker" }}
											value={state.dateRange}
											onChange={onDateRangeChange}
										/>
									</div>
								)}

								{props.csvHeaders && <DownloadCsv />}
								{props.rightActions}
							</RightActions>
						)}
					</PageActions>
				)}

				{props.loading ? (
					<LoadingPlaceholder />
				) : (
					<div>
						{props.stats}
						<Card>
							<TableLayout
								data={props.data}
								onSortedChange={onSortingChange}
								columns={columns}
								defaultSorted={props.defaultSorted}
								showResultsLength={props.showResultsLength}
								getTrProps={props.getTrProps}
							/>
						</Card>
					</div>
				)}
			</div>
		</ErrorBoundary>
	);
}

export default ReportWrapper;
