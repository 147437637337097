import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	height: 64px;
	margin: 64px auto 32px;
	position: relative;
	width: ${(props) => props.steps * 120}px;

	&::after {
		background-color: ${colours.green};
		content: "";
		height: 4px;
		left: 60px;
		position: absolute;
		top: 36px;
		transition: 0.3s ease;
		width: calc(${(props) => props.current * 120}px);
	}
`;

const Step = styled.div`
	color: ${(props) => (props.current ? colours.green : colours.darkGrey)};
	display: inline-block;
	height: 80px;
	position: relative;
	text-align: center;
	transition: 0.3s ease;
	width: 120px;

	&::before {
		background-color: ${colours.borderGrey};
		border-radius: 50%;
		content: "";
		height: 12px;
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
		top: 32px;
		width: 12px;
		z-index: 1;
	}

	&:not(:first-child) {
		&::after {
			background-color: ${colours.borderGrey};
			content: "";
			height: 4px;
			position: absolute;
			right: calc(50% + 60px);
			top: 36px;
			transform: translateX(50%);
			width: 120px;
		}
	}

	&.selected {
		&::before {
			background-color: ${colours.green};
		}
	}

	span {
		white-space: nowrap;
	}
`;

export default function StepIndicator(props) {
	return (
		<Wrapper steps={props.steps.length} current={props.step}>
			{props.steps.map((step, key) => (
				<Step
					key={key}
					current={props.step === key}
					className={`${props.step >= key ? "selected" : ""}`}
				>
					<span>{step.label}</span>
				</Step>
			))}
		</Wrapper>
	);
}
