import * as Yup from "yup";
import React, { useContext } from "react";
import WizardLayout, { StepText } from "../WizardLayout";
import { AppContext } from "../../../context/app-context";
import Button from "../../layout/Button";
import MultiTextInput from "../../layout/MultiTextInput";
import WizardNavigation from "../WizardNavigation";

function step({ close, handleSubmit, isSubmitting, setFieldValue, values }) {
	return {
		id: "edit",
		label: "Bulk Download",
		render: () => {
			return (
				<div>
					<StepText>
						<div style={{ textAlign: "center", marginBottom: 16 }}>
							Enter the recipients of the report.
						</div>
					</StepText>
					<MultiTextInput
						isMulti={true}
						value={values.emails.map((e) => ({
							value: e,
							label: e,
						}))}
						options={values.options.map((e) => ({
							value: e,
							label: e,
						}))}
						isClearable={true}
						onChange={(value) =>
							setFieldValue("emails", value?.map((field) => field.value) || [])
						}
					/>
				</div>
			);
		},
		footer: () => {
			return (
				<WizardNavigation
					leftItems={[
						<Button key="previous" onClick={close} color="red">
							Cancel
						</Button>,
					]}
					rightItems={[
						<Button
							key="submit"
							color="green"
							onClick={handleSubmit}
							disabled={isSubmitting || !values.emails.length}
						>
							Submit
						</Button>,
					]}
				/>
			);
		},
	};
}

export default function BulkDownloadWizard(props) {
	const {
		state: { currentUser },
	} = useContext(AppContext);

	return (
		<WizardLayout
			close={props.onClose}
			title={"Bulk Download"}
			values={[
				{
					name: "emails",
					value: [],
					validator: Yup.array(Yup.string()),
				},
				{
					name: "options",
					value: [currentUser.Email],
					validator: Yup.array(Yup.string()),
				},
			]}
			onSubmit={(values) => {
				props.onSubmit(values.emails);
				props.onClose();
			}}
			steps={[step]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}
