import { MinusCircle, PlusCircle } from "react-feather";
import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";
export const Icon = styled.div`
	margin-top: 8px;
	margin-left: 10px;
	cursor: pointer;
`;
const Wrapper = styled.div`
	display: flex;
	margin-bottom: 10px;
`;
const Block = styled.div`
	display: block;
`;
export default function Repeater(props) {
	return (
		<Block style={props.style}>
			{props.items.map((item, index) => {
				const rule = props.actionRules?.[index];

				const isLastItem = index === props.items.length - 1;
				const isNotFirst = index > 0;
				let canMinus = isLastItem && isNotFirst;

				if (item.readonly) {
					canMinus = false;
				} else if (rule && typeof rule.minus === "function") {
					canMinus = rule.minus(props.items);
				} else if (rule) {
					canMinus = rule.minus;
				}

				if (props.minusMode === "all") {
					if (props.canRemoveFirst) {
						canMinus = true;
					} else {
						canMinus = isNotFirst || props.items.length > 1;
					}
				}

				let canPlus = props.disableAdd ? false : isLastItem;

				if (item.readonly) {
					canPlus = false;
				} else if (rule && typeof rule.plus === "function") {
					canPlus = rule.plus(props.items);
				} else if (rule) {
					canPlus = rule.plus;
				}

				return (
					<Wrapper key={`item${index}`}>
						{props.template(item, index)}
						<React.Fragment>
							{canMinus && (
								<Icon>
									<MinusCircle
										onClick={() => props.subtract(index)}
										color={colours.red}
									/>
								</Icon>
							)}
							{canPlus && (
								<Icon>
									<PlusCircle
										onClick={() => props.add(index)}
										color={colours.blue}
									/>
								</Icon>
							)}
						</React.Fragment>
					</Wrapper>
				);
			})}
		</Block>
	);
}
