import * as api from "./api";
import { StepText, StepTitle } from "../WizardLayout";
import { filter, find, isEmpty, isEqual, isNil, sortBy } from "lodash";
import Alert from "react-s-alert";
import AlertMessage from "../../layout/AlertMessage";
import Button from "../../layout/Button";
import DatePicker from "../../../components/layout/DatePicker";
import Dropdown from "../../layout/Dropdown";
import EmailLookup from "../EmailLookup";
import MultiChoiceButton from "../MultiChoiceButton";
import PermissionToggle from "./PermissionToggle";
import React from "react";
import Repeater from "../../../components/layout/Repeater";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import moment from "moment";
import styled from "styled-components";
import { user } from "../../../helpers";

let roles = [
	{ value: "Observer", label: "Observer" },
	{ value: "CustomerService", label: "Customer Service" },
	{ value: "SiteAdministration", label: "Site Administration" },
	{ value: "UserManagement", label: "User Management" },
	{ value: "Enforcement", label: "Enforcement" },
	{ value: "GateAdministration", label: "Gate Administration" },
	{ value: "Technician", label: "Technician" },
	{ value: "ThirdPartyInstaller", label: "Third Party Installer" },
	// { value: "Validation", label: "Validation" }
];

const tooltips = {
	NotifyNo:
		"By default a user will be notified by email. Selecting 'No' will prevent the email being sent to the user.",
	NotifyYes:
		"By default a user will not be notified by email. Selecting 'Yes' will send an email to the user.",
	Observer:
		"The user will have access to view site settings, access logs and any transactions.",
	CustomerService:
		"The user will have access to view site settings, transactions and issue refunds.",
	SiteAdministration: "The user will have access to change all site settings.",
	UserManagement:
		"The user will be able to create groups, add and remove users across all sites.",
	Enforcement:
		"The user will have access to view enforcement through the Inugo app for ungated sites.",
	GateAdministration:
		"The user will have access to remotely open gates through the Inugo app.",
	Technician:
		"The user will have access to manage and update gate controllers through the Inugo app.",
	ThirdPartyInstaller:
		"The user will be able to install and update hardware through the Inugo installer app.",
	Validation:
		"The user will have access to connect sites to a validation account.",
};

const getRoles = (values) => {
	let roleNames = roles
		.filter(
			(role) => values.roles[role.value] && values.roles[role.value].enabled
		)
		.map((role) => role.label);

	return roleNames.length ? roleNames.join(", ") : "None";
};

const getGroups = (values) => {
	const groupNames = values.groups.map((e) => e.label);

	return groupNames.length ? sortBy(groupNames).join(", ") : "None";
};

const Wrapper = styled.div`
	display: flex;
	width: 100%;
`;

export const removeStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "remove",
	label: "Remove",
	render: () => (
		<div>
			<StepText>
				{wizardProps.mode === "invite-remove" && (
					<div>
						This will remove the invitation to{" "}
						{user.fullNameWithEmail(values.user)}.
					</div>
				)}
				{wizardProps.mode !== "invite-remove" && (
					<div>
						This will remove {user.fullNameWithEmail(values.user)} from all
						groups, and remove all of their staff permissions.
					</div>
				)}
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Remove
				</Button>,
			]}
		/>
	),
});

export const userTypeStep = ({
	next,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "user-type",
	label: "User Type",
	render: () => (
		<div>
			<StepTitle>Will this user require special permissions?</StepTitle>
			<MultiChoiceButton
				selected={!values.generalUser}
				onClick={() => {
					setFieldValue("generalUser", false);
				}}
			>
				Special permissions
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.generalUser}
				onClick={() => {
					setFieldValue("generalUser", true);
				}}
			>
				General user
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="next"
					onClick={next}
					color="blue"
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const emailStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "email",
	label: "Email",
	render: () => (
		<div>
			<StepTitle>What is the email address of the user?</StepTitle>
			<EmailLookup
				email={values.email}
				user={values.user}
				allowNew={true}
				customStatus={values.customStatus}
				onChange={(value) => {
					let userInvitation = find(
						wizardProps.users,
						(_user) => isNaN(_user.UserID) && isEqual(_user.Email, value.email)
					);

					function userInvitationMessage(_userInvitation) {
						return _userInvitation.FirstName && _userInvitation.LastName ? (
							<div>
								User Invitation ({_userInvitation.FirstName}{" "}
								{_userInvitation.LastName}) exists with this email address.
							</div>
						) : (
							<div>User Invitation exists with this email address.</div>
						);
					}
					if (value.user && value.user.FirstName && value.user.LastName) {
						value.user.FirstName = value.user.FirstName.trim();
						value.user.LastName = value.user.LastName.trim();
					}

					if (userInvitation) {
						value.user = userInvitation;
						setFieldValue(
							"customStatus",
							userInvitationMessage(userInvitation)
						);
					} else {
						setFieldValue("customStatus", null);
					}

					setFieldValue("email", value.email);
					setFieldValue("user", value.user);

					if (value.user && value.user.UserID) {
						// restore existing user permissions
						let _mode = isNaN(value.user.UserID) ? "invite-update" : "update";
						setFieldValue("mode", _mode);
						setFieldValue(
							"roles",
							api.restoreRoles(
								wizardProps.roles,
								wizardProps.selectedOrganization.OrganizationID,
								wizardProps.sites,
								value.user.userRoles
							)
						);
						setFieldValue(
							"groups",
							api.restoreGroups(
								wizardProps.selectedOrganization.OrganizationID,
								value.user.organizationAccessGroups
							)
						);
						setFieldValue("notifyUserByEmail", true);
					} else {
						setFieldValue("mode", "add");
						setFieldValue("roles", {});
						setFieldValue("groups", []);
						setFieldValue("notifyUserByEmail", true);
					}
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				!wizardProps.spaceAccountOwnerOnly && (
					<Button key="back" onClick={previous} color="blue">
						Back
					</Button>
				),
			]}
			rightItems={[
				<Button
					key="next"
					onClick={() =>
						!wizardProps.spaceAccountOwnerOnly &&
						(!values.generalUser ||
							(!isEmpty(values.roles) && values.mode === "update"))
							? goTo("permissions")
							: goTo("groups")
					}
					color="blue"
					disabled={
						!values.user ||
						(values.user.UserID !== "Not registered" &&
							(!values.user.FirstName || !values.user.LastName))
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const permissionsStep = ({
	goTo,
	next,
	previous,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "permissions",
	label: "Permissions",
	render: () => {
		if (!wizardProps.currentUserIsAdmin) {
			if (!wizardProps.currentUserClientId) {
				roles = roles.filter(
					(r) =>
						r.value !== "ThirdPartyInstaller" &&
						r.value !== "Technician" &&
						r.value !== "Validation"
				);
			} else {
				roles = roles.filter(
					(r) => r.value !== "Technician" && r.value !== "Validation"
				);
			}
		}
		return (
			<div>
				<StepTitle>What roles will this user have?</StepTitle>
				{roles.map((role) => {
					let siteOptions = [];

					if (
						role.value === "GateAdministration" ||
						role.value === "Technician" ||
						role.value === "ThirdPartyInstaller"
					) {
						siteOptions = [{ value: 0, label: "All Sites" }].concat(
							wizardProps.sites
								.filter((e) => e.SiteType === "Gated")
								.map((e) => ({
									value: e.SiteID,
									label: e.Name,
								}))
						);
					} else if (role.value === "Enforcement") {
						siteOptions = [{ value: 0, label: "All Sites" }].concat(
							wizardProps.sites
								.filter((e) => e.SiteType === "Gateless")
								.map((e) => ({
									value: e.SiteID,
									label: e.Name,
								}))
						);
					} else if (role.value === "Validation") {
						siteOptions = [{ value: 0, label: "All Sites" }].concat(
							wizardProps.sites
								.filter((e) => e.HasValidation)
								.map((e) => ({
									value: e.SiteID,
									label: e.Name,
								}))
						);
					} else if (role.value === "UserManagement") {
						siteOptions = [{ value: 0, label: "All Sites" }];
					} else {
						siteOptions = [{ value: 0, label: "All Sites" }].concat(
							wizardProps.sites.map((e) => ({
								value: e.SiteID,
								label: e.Name,
							}))
						);
					}

					return (
						<PermissionToggle
							key={role.value}
							title={role.label}
							tooltip={tooltips[role.value]}
							value={
								values.roles[role.value] && values.roles[role.value].enabled
							}
							onChange={(value) => {
								if (value) {
									setFieldValue("roles", {
										...values.roles,
										[role.value]: {
											enabled: value,
											sites:
												values.roles[role.value] &&
												values.roles[role.value].sites
													? values.roles[role.value].sites
													: [{ value: 0, label: "All Sites" }],
										},
									});
								} else {
									delete values.roles[role.value];
									setFieldValue("roles", values.roles);
								}
							}}
						>
							{values.roles[role.value] && values.roles[role.value].enabled && (
								<Dropdown
									isMulti={true}
									options={siteOptions}
									value={values.roles[role.value].sites}
									onChange={(newValues) => {
										let sites = newValues;

										// check for 'all sites' toggle
										if (
											values.roles &&
											values.roles[role.value] &&
											values.roles[role.value].sites &&
											newValues &&
											newValues.length > 1
										) {
											if (
												values.roles[role.value].sites.find(
													(e) => e.value === 0
												)
											) {
												sites = newValues.filter((e) => e.value !== 0);
											} else if (newValues.find((e) => e.value === 0)) {
												sites = newValues.filter((e) => e.value === 0);
											}
										}

										setFieldValue("roles", {
											...values.roles,
											[role.value]: { enabled: true, sites },
										});
									}}
								/>
							)}
						</PermissionToggle>
					);
				})}
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					color="blue"
					disabled={!values.user}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const groupsStep = ({
	goTo,
	next,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "groups",
	label: "Groups",
	render: () => {
		return (
			<div>
				<StepTitle>Select the group(s) this user belongs to</StepTitle>
				<Dropdown
					isMulti={true}
					options={wizardProps.groups.map((e) => ({
						value: e.OrganizationAccessGroupID,
						label: e.Name,
					}))}
					value={values.groups}
					onChange={(value) => {
						setFieldValue("groups", value || []);

						if (value.length === 0) {
							setFieldValue("groupsExpirations", [{}]);
						} else {
							let groupIds = value.map((groups) => groups.value);
							let groupExpirations = filter(
								values.groupsExpirations,
								(groupExpiration) =>
									groupIds.includes(groupExpiration.OrganizationAccessGroupID)
							);

							setFieldValue(
								"groupsExpirations",
								groupExpirations.length === 0 ? [{}] : groupExpirations
							);
						}
					}}
				/>

				<StepTitle style={{ marginTop: "32px" }}>
					Set expiry from group
				</StepTitle>
				<Wrapper>
					<Repeater
						style={{ width: "100%" }}
						items={values.groupsExpirations}
						disableAdd={values.groupsExpirations.length >= values.groups.length}
						add={() => {
							let items = [...values.groupsExpirations];
							items.push({});
							setFieldValue("groupsExpirations", items);
						}}
						subtract={(index) => {
							let items = [...values.groupsExpirations];
							let filterItems = values.filterGroupIds;

							let filterIndex = filterItems.indexOf(
								items[index].OrganizationAccessGroupID
							);

							filterItems.splice(filterIndex, 1);
							setFieldValue("filterGroupIds", filterItems);
							items.splice(index, 1);

							setFieldValue("groupsExpirations", items);
						}}
						template={(expiry, index) => {
							let expiryDate = !isNil(expiry?.ExpirationDate)
								? moment(expiry?.ExpirationDate, "YYYYMMDD").toDate()
								: null;
							return (
								<div style={{ display: "flex", width: "90%" }}>
									<Dropdown
										style={{ width: "70%", marginRight: "20px" }}
										isClearable={true}
										isSearchable={false}
										isDisabled={values.groups.length === 0}
										options={values.groups.filter((option) => {
											let filterItems = values.filterGroupIds || [];
											return !filterItems.includes(option.value);
										})}
										value={values.groups.find(
											(option) =>
												option.value === expiry?.OrganizationAccessGroupID
										)}
										onChange={(selected) => {
											let items = [...values.groupsExpirations];
											let filterItems = values.filterGroupIds;
											if (selected?.value) {
												if (filterItems.length > 0) {
													let filterIndex = filterItems.indexOf(
														items[index].OrganizationAccessGroupID
													);

													if (filterIndex >= 0) {
														filterItems.splice(filterIndex, 1);
														setFieldValue("filterGroupIds", filterItems);
													}
												}
												filterItems.push(selected.value);
												setFieldValue("filterGroupIds", filterItems);

												items[index].OrganizationAccessGroupID = selected.value;
												items[index].Name = selected.label;
											} else {
												if (filterItems.length > 0) {
													let filterIndex = filterItems.indexOf(
														items[index].OrganizationAccessGroupID
													);

													if (filterIndex >= 0) {
														filterItems.splice(filterIndex, 1);
														setFieldValue("filterGroupIds", filterItems);
													}
												}
												items[index].OrganizationAccessGroupID = null;
												items[index].Name = null;
											}

											setFieldValue("groupsExpirations", items);
										}}
									/>
									<DatePicker
										options={{
											pickerMode: "datePicker",
											minDate: moment().startOf("day").toDate(),
											dateFormat: "d M Y",
										}}
										value={expiryDate}
										onChange={(date) => {
											let items = [...values.groupsExpirations];
											if (date) {
												items[index].ExpirationDate = moment(date[0]).format(
													"YYYYMMDD"
												);
												items[index].ExpirationTimestamp = moment(date[0])
													.endOf("day")
													.unix();
												setFieldValue("groupsExpirations", items);
											}
										}}
									/>
								</div>
							);
						}}
					/>
				</Wrapper>
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button
						key="back"
						onClick={() => {
							wizardProps.spaceAccountOwnerOnly || values.generalUser
								? goTo("email")
								: goTo("permissions");
						}}
						color="blue"
					>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					onClick={() => {
						const isEmptyExpirationDate = values.groupsExpirations.filter(
							(a) => {
								if (
									!isNil(a.OrganizationAccessGroupID) &&
									isNil(a.ExpirationDate)
								) {
									return a;
								}
							}
						);

						if (isEmptyExpirationDate.length > 0) {
							Alert.error("Please set an expiry date");
						} else {
							if (values.editFromSummary) {
								goTo("summary");
							} else {
								next();
							}
						}
					}}
					color="blue"
					disabled={!values.user}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const notifyStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "notify",
	label: "Notify",
	render: () => (
		<div>
			<StepTitle>Would you like the user to be notified by email?</StepTitle>
			<MultiChoiceButton
				selected={!values.notifyUserByEmail}
				onClick={() => {
					setFieldValue("notifyUserByEmail", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.notifyUserByEmail}
				onClick={() => {
					setFieldValue("notifyUserByEmail", true);
				}}
			>
				Yes
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="back" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					onClick={() => goTo("summary")}
					color="blue"
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const summaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => (
		<div>
			<StepTitle>Summary</StepTitle>
			{!values.groups.length && isEmpty(values.roles) && (
				<AlertMessage
					text="At least one role or group should be selected."
					borderColor="red"
					backgroundColor="red"
					textColor="white"
				/>
			)}
			<SummaryTable
				items={[
					{
						title: "Email",
						value: values.user.Email,
						key: "email",
					},
					{
						title: "Name",
						value: user.fullName(values.user),
						key: "name",
					},
					{
						title: "Groups",
						value: getGroups(values),
						key: "groups",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("groups");
						},
					},
					!wizardProps.spaceAccountOwnerOnly
						? {
								title: "Roles",
								value: getRoles(values),
								key: "permissions",
								edit: () => {
									setFieldValue("editFromSummary", true);
									goTo("permissions");
								},
						  }
						: null,
					{
						tooltip: tooltips.NotifyYes,
						title: "Notify",
						value: values.notifyUserByEmail ? "Yes" : "No",
						key: "notify",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("notify");
						},
					},
				].filter((e) => e)}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={
						isSubmitting || (!values.groups.length && isEmpty(values.roles))
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					Set Permissions
				</Button>,
			]}
		/>
	),
});
