import React, { useState } from "react";
import {
	booleanValueFormatter,
	booleanValueFormatterColour,
} from "../../components/layout/TableLayout";
import { fetchDataCallback, paginatedState } from "../../helpers/pagination";
import { useMutateData, usePermissions, useQueryData } from "../../hooks";
import Alert from "react-s-alert";
import BulkDownloadWizard from "../../components/wizards/bulk-download-wizard";
import Card from "../../components/layout/Card";
import FlexWrapper from "../../components/layout/FlexWrapper";
import ItemDropDownOptions from "../../components/billing/ItemDropDownOptions";
import Label from "../../components/layout/Label";
import RefundDetails from "../../components/details-pages/refund/RefundDetails";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import StatCard from "../../components/layout/StatCard";
import Toggle from "../../components/layout/Toggle";
import TransactionDetails from "../../components/details-pages/users/TransactionDetails";
import TransactionWizard from "../../components/wizards/transaction-wizard/TransactionWizard";
import { colours } from "../../styles";
import { format } from "../../helpers";
import gql from "graphql-tag";
import { map } from "lodash";
import moment from "moment";

const columns = (props) => [
	{
		id: "IsPaid",
		Header: "Paid",
		accessor: "IsPaid",
		Cell: (cellProps) =>
			cellProps.row.original.WasVoided ? (
				<Label color="blue">Void</Label>
			) : (
				booleanValueFormatterColour({
					value: cellProps.row.original.IsPaid,
				})
			),
		width: 60,
	},
	{
		id: "SiteNames",
		Header: "Site(s)",
		accessor: "_siteNames",
		highlightCell: (cellProps) => cellProps.row.original._siteNames,
	},
	{
		id: "_transactionId",
		Header: "Transaction ID",
		accessor: "_transactionId",
		highlightCell: (cellProps) => cellProps.row.original._transactionId,
	},
	{
		id: "ItemType",
		Header: "Type",
		accessor: "ItemTypeFormatted",
		highlightCell: (cellProps) => cellProps.row.original.ItemTypeFormatted,
		disableSortBy: true,
	},
	{
		id: "Validated",
		Header: "Validated",
		accessor: "_isValidatedSorting",
		Cell: (cellProps) => booleanValueFormatter(cellProps),
	},
	{
		id: "BaseAmount",
		Header: "Parking Charge",
		accessor: "_parkingChargeSorting",
		Cell: (cellProps) => format.money(cellProps.row.original.BaseAmount),
	},
	{
		id: "OperatorFees",
		Header: "Credit Card Fee",
		accessor: "OperatorFees",
		Cell: (cellProps) => format.money(cellProps.row.original.OperatorFees),
	},
	{
		id: "InugoFee",
		Header: "Convenience Fee",
		accessor: "InugoFee",
		Cell: (cellProps) => format.money(cellProps.row.original.InugoFee),
	},
	{
		id: "Promotion",
		Header: "Promotion Discount",
		accessor: "Promotion",
		Cell: (cellProps) =>
			cellProps.row.original.Promotion
				? format.promotion(
						cellProps.row.original.Promotion.PromotionValue,
						cellProps.row.original.Promotion.PromotionType
				  )
				: "",
	},
	{
		id: "TotalAmount",
		Header: "Total User Charge",
		accessor: "TotalAmount",
		Cell: (cellProps) => format.money(cellProps.row.original.TotalAmount),
		className: "bold",
	},
	{
		id: "PaymentProviderHandlingFee",
		Header: "Payment Processing Fee",
		accessor: "PaymentProviderHandlingFee",
		Cell: (cellProps) =>
			format.money(cellProps.row.original.PaymentProviderHandlingFee),
	},
	{
		id: "RefundedAmount",
		Header: "Refunded",
		accessor: "RefundedAmount",
		Cell: (cellProps) => format.money(cellProps.row.original.RefundedAmount),
	},
	{
		id: "CreditCardType",
		Header: "Credit Card Type",
		accessor: "CreditCardType",
		highlightCell: (cellProps) =>
			cellProps.row.original.CreditCardType || "Unknown",
	},
	{
		id: "PaidOn",
		Header: "Paid On",
		accessor: "PaidOn",
		Cell: (cellProps) => cellProps.row.original._paidOnDate || "",
		className: "bold",
	},
	{
		id: "RemittedOn",
		Header: "Settled On",
		accessor: "RemittedOn",
		Cell: (cellProps) => cellProps.row.original._remittedOnDate || "",
		className: "bold",
	},
	{
		id: "ReceiptID",
		Header: "Receipt",
		accessor: "transactionIdSorting",
		Cell: (cellProps) => cellProps.row.original.TransactionID || "",
	},
	{
		id: "InvoiceID",
		Header: "Invoice",
		accessor: "_invoiceIdSorting",
		Cell: (cellProps) => cellProps.row.original._invoiceId,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) =>
			cellProps.row.original._transactionId.startsWith("VP") ? null : (
				<ItemDropDownOptions
					isRevenue={true}
					cellProps={cellProps.row}
					permissions={props.permissions}
					showTransactionDetails={props.showTransactionDetails}
					showTransactionWizard={props.showTransactionWizard}
					showRefundDetails={props.showRefundDetails}
				/>
			),
		resizable: false,
		fixedWidth: 50,
		sortable: false,
	},
];

const csvHeaders = () => {
	let _csvHeaders = map(columns(), (c) => {
		let key = c.accessor;
		if (c.accessor === "IsPaid") key = "_paidStatus";
		if (c.accessor === "_isValidatedSorting") key = "_isValidated";
		if (c.accessor === "_parkingChargeSorting") key = "_parkingCharge";
		if (c.accessor === "OperatorFees") key = "_operatorFees";
		if (c.accessor === "InugoFee") key = "_inugoFee";
		if (c.accessor === "TotalAmount") key = "_totalAmount";
		if (c.accessor === "PaymentProviderHandlingFee")
			key = "_paymentProviderHandlingFee";
		if (c.accessor === "RefundedAmount") key = "_refundedAmount";
		if (c.accessor === "PaidOn") key = "_paidOnDate";
		if (c.accessor === "RemittedOn") key = "_remittedOnDate";
		if (c.accessor === "_invoiceIdSorting") key = "_invoiceId";

		return {
			label: c.Header,
			key: key,
		};
	});

	// remove tasks column
	_csvHeaders.pop();

	return _csvHeaders;
};

const defaultSorting = [
	{
		id: "PaidOn",
		desc: true,
	},
];

function getApiCall(isExperimental) {
	const endpoint = isExperimental
		? "getRevenueReportForOrganizationV2"
		: "getRevenueReportForOrganization";

	return gql`
	query (
		$organizationId: Int!
		$siteList: [Int!]
		$startDateTime: Timestamp!
		$endDateTime: Timestamp!
		$cursor: String
		$page: PageInfo!
		$bulkDownload: Boolean
	) {
		${endpoint}(
			organizationId: $organizationId
			siteList: $siteList
			startDateTime: $startDateTime
			endDateTime: $endDateTime
			cursor: $cursor
			page: $page
			bulkDownload: $bulkDownload
		) {
			trueLength
			previousEvent
			revenue {
				_transactionId
				BaseAmount
				CreditCardType
				duration
				Email
				FirstName
				InugoFee
				InvoiceID
				IsPaid
				ItemID
				ItemType
				ItemTypeFormatted
				LastName
				OperatorFees
				PaidOn
				paidOnDate
				PaymentProviderHandlingFee
				RankingScore
				RefundedAmount
				RefundRequestID
				RemittedOn
				SiteID
				SiteName
				SiteNames
				TotalAmount
				TransactionID
				TransactionType
				UserLeaseID
				Validations
				Promotion {
					PromotionValue
					PromotionType
				}
				WasBillable
				WasVoided
			}
			statistics {
				monthlyCount
				netUserCharges
				refundCount
				totalCount
				totalCreditCardFees
				totalFees
				totalInugoConsumerPaidFees
				totalInugoFees
				totalPaymentProcessingFees
				totalRefunded
				totalUnpaid
				totalUserCharges
				transientCount
				unpaidCount
			}
		}
	}
`;
}

export default function RevenueContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			sites: [],
			"start-date-time": null,
			"end-date-time": null,
			search: "",
		},
		selectedSiteId: null,
		reportStyleOverride: {},
		transactionWizardMode: null,
		transactionWizardTransaction: null,
		user: null,
		isExperimental: false,
		bulkDownloadWizardOpen: false,
	});

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	async function updateOptions(options) {
		setState((_state) => ({ ..._state, initialLoad: false, options }));
	}

	const [paginate, setPaginate] = useState(paginatedState);

	const fetchPaginatedData = fetchDataCallback(setPaginate);

	function formatData(_data) {
		return (
			map(_data, (d) => {
				const _paidStatus = d.WasVoided
					? "Void"
					: d.IsPaid
					? "Paid"
					: "Not Paid";
				const _paidOnDate = d.PaidOn
					? moment.unix(d.PaidOn).format("DD MMM YYYY")
					: null;
				const _remittedOnDate = d.RemittedOn
					? moment.unix(d.RemittedOn).format("DD MMM YYYY")
					: null;
				const _isValidatedSorting = d.Validations ? 1 : 0;
				const _isValidated = d.Validations ? "Yes" : "No";
				const _parkingChargeSorting = d.BaseAmount || 0;
				const _parkingCharge = format.money(d.BaseAmount);
				const _operatorFees = format.money(d.OperatorFees);
				const _inugoFee = format.money(d.InugoFee);
				const _totalAmount = format.money(d.TotalAmount);
				const _paymentProviderHandlingFee = format.money(
					d.PaymentProviderHandlingFee
				);
				const _refundedAmount = format.money(d.RefundedAmount);
				const _siteNames = d.SiteNames;
				const _invoiceIdSorting = d.InvoiceID || 0;
				const _invoiceId = d.InvoiceID || "";
				const transactionIdSorting = d.TransactionID || 0;
				const transactionIdCursor = d.TransactionID || "";

				return {
					...d,
					_paidStatus,
					_isValidatedSorting,
					_isValidated,
					_parkingChargeSorting,
					_parkingCharge,
					_operatorFees,
					_inugoFee,
					_totalAmount,
					_paymentProviderHandlingFee,
					_refundedAmount,
					_siteNames,
					_paidOnDate,
					_remittedOnDate,
					transactionIdCursor,
					_invoiceIdSorting,
					transactionIdSorting,
					_invoiceId,
					OrganizationID: organizationId,
				};
			}) || []
		);
	}

	const queryVariables = {
		organizationId,
		siteList: state.options.sites,
		startDateTime: state.options["start-date-time"],
		endDateTime: state.options["end-date-time"],
		page: {
			pageOffset: paginate.pageOffset,
			pageSize: paginate.pageSize,
			sortBy: paginate.sortBy,
			sortOrder: paginate.sortOrder,
			searchTokens: paginate.searchTokens,
			eventType: paginate.eventType,
			sortedVal: paginate.cursor.sortedVal,
		},
		cursor: paginate.cursor.cursor,
	};

	const bulkDownloadReport = useMutateData(gql`
		mutation ($args: BulkDownloadReport!) {
			bulkDownloadRevenueReport(args: $args)
		}
	`);

	const skipDataQuery =
		!state.options["start-date-time"] ||
		!state.options["end-date-time"] ||
		!paginate.pageSize ||
		!paginate.sortBy ||
		!paginate.sortOrder;

	const {
		data: revenueResult,
		isLoading,
		refetch,
	} = useQueryData(
		getApiCall(state.isExperimental),
		{
			organizationId,
			siteList: state.options.sites,
			startDateTime: state.options["start-date-time"],
			endDateTime: state.options["end-date-time"],
			page: {
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
			bulkDownload: false,
		},
		skipDataQuery
	);

	const data = state.isExperimental
		? revenueResult?.getRevenueReportForOrganizationV2
		: revenueResult?.getRevenueReportForOrganization;

	const revenueData = formatData(data?.revenue || []);

	async function getDownloadableData() {
		return [...revenueData];
	}

	const canRefund = usePermissions(null, "RefundUser", true);
	const canResendReceipt = usePermissions(null, "ResendReceipt", true);
	const canManageSessions = usePermissions(null, "SessionAdmin", true);
	const isAdmin = usePermissions("IsAdmin");

	function refetchData() {
		setTimeout(() => {
			refetch();
		}, 300);
	}

	function showTransactionDetails(
		siteId,
		sessionId,
		leaseId,
		productPurchaseId,
		user
	) {
		setState((_state) => ({
			..._state,
			selectedSiteId: siteId,
			selectedParkingSessionId: sessionId || null,
			selectedLeaseId: leaseId || null,
			selectedProductPurchaseId: productPurchaseId,
			user: user,
		}));
		refetchData();
	}

	function hideTransactionDetails() {
		setState((_state) => ({
			..._state,
			selectedSiteId: null,
			selectedParkingSessionId: null,
			selectedLeaseId: null,
			selectedProductPurchaseId: null,
			reportStyleOverride: {},
			user: null,
		}));
		refetchData();
	}

	function showRefundDetails(siteId, refundRequestId) {
		setState((_state) => ({
			..._state,
			selectedSiteId: siteId,
			selectedRefundRequestId: refundRequestId,
			reportStyleOverride: { display: "none" },
		}));
		refetchData();
	}

	function hideRefundDetails() {
		setState((_state) => ({
			..._state,
			selectedSiteId: null,
			selectedRefundRequestId: null,
			reportStyleOverride: {},
		}));
		refetchData();
	}

	function showTransactionWizard(mode, transaction) {
		setState((_state) => ({
			..._state,
			transactionWizardMode: mode,
			transactionWizardTransaction: transaction,
		}));
		refetchData();
	}

	const trueLength = data?.trueLength || 0;
	const stats = data?.statistics || {};

	if (state.bulkDownloadWizardOpen) {
		return (
			<BulkDownloadWizard
				onClose={() =>
					setState((_state) => ({ ..._state, bulkDownloadWizardOpen: false }))
				}
				onSubmit={async (emails) => {
					try {
						await bulkDownloadReport({
							variables: {
								args: {
									...queryVariables,
									siteIds: state.options.sites,
									siteList: undefined,
									emails,
									version: state.isExperimental ? "v2" : "v1",
									page: {
										sortBy: paginate.sortBy,
										sortOrder: paginate.sortOrder,
										searchTokens: paginate.searchTokens,
									},
								},
							},
						});

						Alert.success(
							"The report will be sent to the selected emails shortly"
						);
					} catch (error) {
						Alert.error("Something went wrong please try again");
					}
				}}
			/>
		);
	}

	return (
		<div>
			{isAdmin && (
				<div
					style={{
						display: "flex",
						justifyContent: "flex-end",
						marginRight: "64px",
					}}
				>
					<Toggle
						label="Experimental"
						onChange={(value) => {
							setState((_state) => ({ ..._state, isExperimental: value }));
						}}
						checked={state.isExperimental}
					/>
				</div>
			)}
			<ReportWrapperBeta
				key={`${state.isExperimental}`}
				{...props}
				style={state.reportStyleOverride}
				title="Transaction History"
				data={revenueData}
				csvHeaders={csvHeaders()}
				csvName={"inugo-revenue-report.csv"}
				columns={columns({
					permissions: {
						canRefund,
						canResendReceipt,
						isAdmin,
						canManageSessions,
					},
					showTransactionDetails,
					showRefundDetails,
					showTransactionWizard,
				})}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				loading={false}
				getTrProps={(_state, rowInfo) => {
					if (rowInfo && rowInfo.original) {
						if (rowInfo.original.TransactionType === "Refund") {
							return { style: { color: `${colours.red}` } };
						} else {
							return { style: { color: "inherit" } };
						}
					}
				}}
				dataTrueLength={trueLength}
				cursorColumn={(x) => `${x.ItemID}-${x.transactionIdCursor}`}
				paginationIsLoading={isLoading || skipDataQuery || props.isLoading}
				pageSize={state.pageSize}
				previousEvent={data?.getRevenueReportForOrganization?.previousEvent}
				searchTokens={paginate.searchTokens}
				fetchPaginatedData={fetchPaginatedData}
				getDownloadableData={getDownloadableData}
				onBulkDownloadClicked={async () =>
					// setState((_state) => ({ ..._state, bulkDownloadWizardOpen: true }))
					{
						try {
							await bulkDownloadReport({
								variables: {
									args: {
										...queryVariables,
										siteIds: state.options.sites,
										siteList: undefined,
										emails: [],
										version: state.isExperimental ? "v2" : "v1",
										page: {
											sortBy: paginate.sortBy,
											sortOrder: paginate.sortOrder,
											searchTokens: paginate.searchTokens,
										},
									},
								},
							});

							Alert.success("The report will be sent to your email shortly");
						} catch (error) {
							Alert.error("Something went wrong please try again");
						}
					}
				}
				stats={
					<div>
						<div>
							<FlexWrapper style={{ marginBottom: -16 }}>
								<Card>
									<StatCard
										value={trueLength}
										title="Total Transactions"
										subTitle="Within Selected Dates"
										size="medium"
									/>
								</Card>
								<Card>
									<StatCard
										value={stats.transientCount || 0}
										title="Transient Payments"
										subTitle="Within Selected Dates"
										size="medium"
									/>
								</Card>
								<Card>
									<StatCard
										value={stats.monthlyCount || 0}
										title="Booking Payments"
										subTitle="Within Selected Dates"
										size="medium"
									/>
								</Card>
								<Card>
									<StatCard
										value={stats.refundCount || 0}
										title="Refunds"
										subTitle="Within Selected Dates"
										size="medium"
										color={colours.red}
									/>
								</Card>
								<Card>
									<StatCard
										value={stats.unpaidCount || 0}
										title="Unpaid"
										subTitle="Within Selected Dates"
										size="medium"
										color={colours.red}
									/>
								</Card>
							</FlexWrapper>
						</div>
						<Card>
							<FlexWrapper style={{ marginBottom: -16 }}>
								<StatCard
									value={"+ " + format.money(stats ? stats.totalFees : 0)}
									title="Parking Charges"
									size="mini"
									color={colours.blue}
								/>
								<StatCard
									value={"+ " + format.money(stats.totalCreditCardFees || 0)}
									title="Credit Card Fees"
									size="mini"
									color={colours.blue}
								/>
								<StatCard
									value={
										"+ " + format.money(stats.totalInugoConsumerPaidFees || 0)
									}
									title="Convenience Fees"
									size="mini"
									color={colours.green}
								/>
								<StatCard
									value={"- " + format.money(Math.abs(stats.totalUnpaid || 0))}
									title="Unpaid"
									size="mini"
									color={colours.red}
								/>
								<StatCard
									value={
										"- " + format.money(Math.abs(stats.totalRefunded || 0))
									}
									title="Refunded"
									size="mini"
									color={colours.red}
								/>
								<StatCard
									value={format.money(stats.totalUserCharges || 0)}
									title="Total User Charges"
									size="mini"
									color={colours.darkGrey}
								/>
								<StatCard
									value={
										"- " + format.money(Math.abs(stats.totalInugoFees || 0))
									}
									title="Convenience Fees"
									size="mini"
									color={colours.green}
								/>
								<StatCard
									value={
										"- " +
										format.money(
											Math.abs(stats.totalPaymentProcessingFees || 0)
										)
									}
									title="Payment Processing Fees"
									size="mini"
									color={colours.red}
								/>
								<StatCard
									value={format.money(stats.netUserCharges || 0)}
									title="Net User Charges"
									size="mini"
									color={colours.darkGrey}
								/>
							</FlexWrapper>
						</Card>
					</div>
				}
			/>

			{state.transactionWizardTransaction && (
				<TransactionWizard
					close={() => {
						setState((_state) => ({
							..._state,
							transactionWizardTransaction: null,
						}));
						refetchData();
					}}
					mode={state.transactionWizardMode}
					transaction={state.transactionWizardTransaction}
					selectedOrganization={props.selectedOrganization}
				/>
			)}
			{(state.selectedParkingSessionId ||
				state.selectedLeaseId ||
				state.selectedProductPurchaseId) && (
				<TransactionDetails
					{...props}
					user={state.user}
					siteId={state.selectedSiteId}
					organizationId={organizationId}
					session={
						state.selectedParkingSessionId
							? { ParkingSessionID: state.selectedParkingSessionId }
							: null
					}
					lease={
						state.selectedLeaseId
							? { UserLeaseID: state.selectedLeaseId }
							: null
					}
					productPurchaseId={state.selectedProductPurchaseId}
					onClose={hideTransactionDetails}
				/>
			)}
			{state.selectedRefundRequestId && (
				<RefundDetails
					{...props}
					siteId={state.selectedSiteId}
					refundRequestId={state.selectedRefundRequestId}
					onClose={hideRefundDetails}
				/>
			)}
		</div>
	);
}
