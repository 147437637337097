import { deleteJSON, getJSON, postJSON } from "../index";
import qs from "qs";

const basePath = "/authentication/api";

export function createUserInvitation(organizationId, data) {
	return postJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user-invitation`,
		{ ...data, version: 2 }
	);
}

export function deleteAllRolesForUser(organizationId, userId) {
	return deleteJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user/${userId}/roles`
	);
}

export function getRolesForOrganization(organizationId) {
	return getJSON(`${basePath}/v1/admin/organization/${organizationId}/roles`);
}

export function getUserInvitations(organizationId) {
	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user-invitations`
	);
}

export function getUsersWithAnyPermissions(organizationId) {
	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/users-with-any-permissions`
	);
}

export function getUsersWithAnyAssignedAccessGroups(organizationId) {
	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/users-with-any-assigned-access-groups`
	);
}

export function getUserWithPrivileges(userId) {
	const params = qs.stringify({ "include-privileges": true });

	return getJSON(`${basePath}/v1/admin/user/${userId}?${params}`);
}

export function getUserWithoutPrivileges(userId) {
	const params = qs.stringify({ "include-privileges": false });

	return getJSON(`${basePath}/v1/admin/user/${userId}?${params}`);
}

export function getUserReportForOrganization(organizationId) {
	return getJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user-report`
	);
}

export function removeUserInvitation(organizationId, email) {
	return deleteJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user-invitation-for-email/${encodeURIComponent(
			email
		)}`
	);
}

export function setRolesForUserOnOrganization(organizationId, userId, data) {
	return postJSON(
		`${basePath}/v1/admin/organization/${organizationId}/user/${userId}/roles`,
		data
	);
}

export function updateUserInvitation(organizationId, data) {
	return postJSON(
		`${basePath}/v1/admin/organization/${organizationId}/update-user-invitation`,
		{ ...data, version: 2 }
	);
}

export function getNumberplatesByUser(siteId, userId) {
	const params = qs.stringify({
		siteId: siteId,
		userId: userId,
	});

	return getJSON(`${basePath}/v1/numberplates?${params}`);
}

export function userExistsForEmailAndCode(email, invitationCode) {
	return getJSON(
		`${basePath}/user/account-exists-for-email?email=${encodeURIComponent(
			email
		)}&invitationCode=${invitationCode}`
	);
}

export function redeemInvite(data) {
	return postJSON(`${basePath}/v1/admin/user-invitation/redeem`, data);
}

export function getUserInvitation(options) {
	const { email, code } = options;
	const query = `?${qs.stringify({
		email: email,
		code: code,
	})}`;

	return getJSON(`${basePath}/v1/admin/user-invitation${query}`);
}
