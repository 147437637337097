import * as Yup from "yup";
import * as installationApi from "../../../api/installation";
import * as rateSteps from "./steps";
import {
	DAYS,
	DAYS_OR_EARLIER,
	DEFAULT_DATES,
	DEFAULT_RATE_SCALE,
	DEFAULT_RATE_TYPE,
	EXIT_CONDITIONS,
	MAXIMUM_SPECIAL_CONDITION,
	MINIMUM_SPECIAL_CONDITION,
	RATE_SCALES,
	RATE_TYPES,
	SPECIAL_CONDITIONS,
	VARIED_RATE_DURATIONS,
	VARIED_RATE_OFFSET_SCALES,
} from "../../../helpers/organization";
import Alert from "react-s-alert";
import React from "react";
import WizardLayout from "../WizardLayout";
import { getFormattedRate } from "./helpers/format-rate-data";
import { isNil } from "lodash";
import moment from "moment";

const constructWizard = (props) => {
	let steps = [
		rateSteps.nameStep,
		rateSteps.appliesToStep,
		rateSteps.typeStep,
		rateSteps.validationStep,
		rateSteps.configurationStep,
		rateSteps.summaryStep,
	];
	if (props.mode === "add") {
		return {
			initialStep: 0,
			steps: steps,
		};
	} else if (props.mode === "update") {
		return {
			initialStep: steps.length - 1,
			steps: steps,
		};
	} else if (props.mode === "remove") {
		return {
			initialStep: 0,
			steps: [rateSteps.removeStep],
		};
	}
};

const allDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const getEnabledDays = (rate) => {
	const enabled = [];
	for (let day of allDays) {
		if (rate[day]) {
			enabled.push(day);
		}
	}
	return enabled;
};

const getTimesForFirstEnabled = (rate) => {
	for (let day of allDays) {
		if (rate[day]) {
			return {
				start: rate[`${day}Start`],
				end: rate[`${day}End`],
			};
		}
	}
};

function rebuildVariedRates(variedRates) {
	//Retrieves the scale multiplier for a given named scaling.
	const getScaleMultiplier = (scale) => {
		//Calculate internal offset
		switch (scale) {
			case "minutes":
				return 60;

			case "hours":
				return 60 * 60;

			case "days":
				return 60 * 60 * 24;

			case "weeks":
				return 60 * 60 * 24 * 7;
		}
	};

	if (!variedRates) {
		return [
			{
				StartOffset: 0,
				_scaledStartOffset: 0,
				_startOffsetScale: "minutes",
				EndOffset: 60 * 60,
				_scaledEndOffset: 60,
				_endOffsetScale: "minutes",
				FeePerUnit: 5,
				UnitStaggeringScale: 15,
				MaxFee: null,
			},
		];
	}

	for (var index in variedRates) {
		var variedRate = variedRates[index];

		//Since the unit size is not stored
		var divisors = {
			minutes: 60,
			hours: 60 * 60,
			days: 60 * 60 * 24,
			weeks: 60 * 60 * 24 * 7,
		};

		for (var scaleName in divisors) {
			var divisor = divisors[scaleName];

			if (variedRate.EndOffset % divisor === 0) {
				variedRate._endOffsetScale = scaleName;
				variedRate._scaledEndOffset = Math.round(
					variedRate.EndOffset / getScaleMultiplier(scaleName)
				);
			}

			if (variedRate.StartOffset % divisor === 0) {
				variedRate._startOffsetScale = scaleName;
				variedRate._scaledStartOffset = Math.round(
					variedRate.StartOffset / getScaleMultiplier(scaleName)
				);
			}
		}

		//Handle infinites
		if (variedRate.EndOffset === null) {
			variedRate._endOffsetScale = "infinite";
		}

		//Handle the start of the first varied rate
		if (variedRate.StartOffset === 0) {
			variedRate._startOffsetScale = "minutes";
		}
	}

	return variedRates;
}

export default function RateWizard(props) {
	const wizard = constructWizard(props);
	const defaultDates = props.rate.RateClass
		? DEFAULT_DATES[props.rate.RateClass]
		: null;

	const times = getTimesForFirstEnabled(props.rate);
	const specialConditionItems = [];

	if (props.rate.MinimumDuration) {
		specialConditionItems.push({
			minutes: props.rate.MinimumDuration / 60,
			SPECIAL_CONDITIONS,
			condition: MINIMUM_SPECIAL_CONDITION,
		});
	}
	if (props.rate.MaximumDuration) {
		specialConditionItems.push({
			minutes: props.rate.MaximumDuration / 60,
			SPECIAL_CONDITIONS,
			condition: MAXIMUM_SPECIAL_CONDITION,
		});
	}
	return (
		<WizardLayout
			//need to override the width of the wizard at a specific step
			//in order to support a varied rate
			//supports 2 levels of steps
			widths={{ configuration: { varied: "1025px", advanced: "800px" } }}
			close={props.close}
			title={(values) => {
				if (values.mode === "add") return "Add rate";
				if (values.mode === "update") return "Update rate";
				if (values.mode === "remove") return "Delete rate";
			}}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "rateId",
					value: props.rate.RateID,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "name",
					value: props.rate.Name || "",
					validator: Yup.string(),
				},
				{
					name: "days",
					value: getEnabledDays(props.rate),
					validator: Yup.array(),
				},
				{
					name: "appliesTo",
					value: props.rate.CachedOrganizationAccessGroupIDs
						? "Groups"
						: "Public",
					validator: Yup.string(),
				},
				{
					name: "groups",
					value: JSON.parse(
						props.rate.CachedOrganizationAccessGroupIDs || "[]"
					).map((id) => props.groups.find((group) => group.value === id)),
					validator: Yup.object().nullable(),
				},
				{
					name: "type",
					value: props.rate.RateClass
						? RATE_TYPES.find((type) => type.value === props.rate.RateClass)
						: DEFAULT_RATE_TYPE,
					validator: Yup.object(),
				},
				{
					name: "isValidation",
					value: props.rate.IsValidationRate ? 1 : 0,
					validator: Yup.string(),
				},
				{
					name: "configuration",
				},
				{
					name: "startTime",
					value: moment(
						times ? moment.utc(times.start, "X").format("HH:mm") : "00:00",
						"HH:mm"
					).toDate(),
					validator: Yup.date(),
				},
				{
					name: "endTime",
					value: moment(
						times ? moment.utc(times.end, "X").format("HH:mm") : "00:00",
						"HH:mm"
					).toDate(),
					validator: Yup.date(),
				},
				{
					name: "feePerUnit",
					value: props.rate.FeePerUnit || 0,
					validator: Yup.number().nullable(),
				},
				{
					name: "feeCalculationType",
					value: props.rate.FeeCalculationType,
					validator: Yup.string(),
				},
				{
					name: "unitStaggeringScale",
					value: props.rate.UnitStaggeringScale
						? RATE_SCALES.find(
								(scale) => scale.value === props.rate.UnitStaggeringScale
						  )
						: DEFAULT_RATE_SCALE,
					validator: Yup.object().nullable(),
				},
				{
					name: "maxFee",
					value: props.rate.MaxFee || null,
					validator: Yup.number().nullable(),
				},
				{
					name: "forceBlocksToUnitSize",
					value: props.rate.ForceBlocksToUnitSize ? 1 : 0,
					validator: Yup.boolean(),
				},
				{
					name: "appliedCrossSession",
					value: props.rate.AppliedCrossSession ? 1 : 0,
					validator: Yup.boolean(),
				},
				{
					name: "cachedVariedRates",
					value: rebuildVariedRates(
						JSON.parse(props.rate.CachedVariedRates || null)
					),
					validator: Yup.object().nullable(),
				},
				{
					name: "shapeVariedRates",
					value: true,
					validator: Yup.boolean(),
				},
				{
					name: "startTimestamp",
					value:
						props.rate.RateClass === "Event"
							? moment(props.rate.StartDate, "YYYYMMDDHHmm").toDate()
							: props.rate.startTimestamp ||
							  moment().startOf("day").add(7, "hours").toDate(),
					validator: Yup.date(),
				},
				{
					name: "endTimestamp",
					value:
						props.rate.RateClass === "Event"
							? moment(props.rate.EndDate, "YYYYMMDDHHmm").toDate()
							: props.rate.endTimestamp ||
							  moment().startOf("day").add(17, "hours").toDate(),
					validator: Yup.date(),
				},
				{
					name: "entryLaterThan",
					value: !isNil(props.rate.EntryLaterThan)
						? moment(
								moment.utc(props.rate.EntryLaterThan, "X").format("HH:mm"),
								"HH:mm"
						  ).toDate()
						: defaultDates && defaultDates.EntryLaterThan,
					validator: Yup.date().nullable(),
				},
				{
					name: "entryEarlierThan",
					value: !isNil(props.rate.EntryEarlierThan)
						? moment(
								moment.utc(props.rate.EntryEarlierThan, "X").format("HH:mm"),
								"HH:mm"
						  ).toDate()
						: defaultDates && defaultDates.EntryEarlierThan,
					validator: Yup.date().nullable(),
				},
				{
					name: "exitLaterThan",
					value: !isNil(props.rate.ExitLaterThan)
						? moment(
								moment.utc(props.rate.ExitLaterThan, "X").format("HH:mm"),
								"HH:mm"
						  ).toDate()
						: defaultDates && defaultDates.ExitLaterThan,
					validator: Yup.date().nullable(),
				},
				{
					name: "exitEarlierThan",
					value: !isNil(props.rate.ExitEarlierThan)
						? moment(
								moment.utc(props.rate.ExitEarlierThan, "X").format("HH:mm"),
								"HH:mm"
						  ).toDate()
						: defaultDates && defaultDates.ExitEarlierThan,
					validator: Yup.date().nullable(),
				},
				{
					name: "enterOnDay",
					value: props.rate.EnterOnDay
						? DAYS.find((day) => day.value === props.rate.EnterOnDay)
						: DAYS.find((day) => day.value === "Mon"),
					validator: Yup.object().nullable(),
				},
				{
					name: "exitOnDay",
					value: props.rate.ExitOnDay
						? DAYS.find((day) => day.value === props.rate.ExitOnDay)
						: DAYS.find((day) => day.value === "Fri"),
					validator: Yup.object().nullable(),
				},
				{
					name: "exitOnOrBefore",
					value: props.rate.ExitOnOrBeforeDay
						? DAYS_OR_EARLIER.find(
								(day) => day.value === props.rate.ExitOnOrBeforeDay
						  )
						: DAYS_OR_EARLIER.find((day) => day.value === "Fri"),
					validator: Yup.object().nullable(),
				},
				{
					name: "exitCondition",
					value: EXIT_CONDITIONS.find(
						(day) =>
							day.value ===
							(props.rate.ExitOnOrBeforeDay ? "exitOnOrBefore" : "exitBetween")
					),
					validator: Yup.object().nullable(),
				},
				{
					name: "specialConditionItems",
					value: specialConditionItems,
					validator: Yup.array().nullable(),
				},
				{
					name: "specialConditionsEnabled",
					value: !!(props.rate.MinimumDuration || props.rate.MaximumDuration),
					validator: Yup.boolean().nullable(),
				},
				{
					name: "conflictRateErrorMessage",
					value: null,
				},
				{ name: "incorrectEarlyBirdExitTime", value: false },
				{ name: "incorrectAfterHoursEarlyBirdEntryTime", value: false },
			]}
			onSubmit={async (values, { setSubmitting }) => {
				setSubmitting(true);
				const rate = getFormattedRate(values, props.site.SiteID);

				if (values.feeCalculationType === "Varied") {
					rate.variedRates = values.cachedVariedRates;
					rate.subrates = values.cachedVariedRates;
				}

				if (values.mode === "add") {
					try {
						await installationApi.createRate(props.site.SiteID, rate);

						setSubmitting(false);
						Alert.success("Rate created");
						props.close(true);
					} catch (error) {
						setSubmitting(false);

						if (
							error.errors &&
							error.errors[0].code === "HasConflictWithExistingRate"
						) {
							Alert.error("The rate overlaps an existing rate.");
						} else {
							Alert.error("Something went wrong. Please try again.");
						}
					}
				} else if (values.mode === "update") {
					try {
						await installationApi.updateRate(
							props.site.SiteID,
							values.rateId,
							rate
						);

						setSubmitting(false);
						Alert.success("Rate updated");
						props.close(true);
					} catch (error) {
						setSubmitting(false);

						if (
							error.errors &&
							error.errors[0].code === "HasConflictWithExistingRate"
						) {
							Alert.error("The rate overlaps an existing rate.");
						} else {
							Alert.error("Something went wrong. Please try again.");
						}
					}
				} else if (values.mode === "remove") {
					try {
						await installationApi.retireRate(props.site.SiteID, values.rateId);

						setSubmitting(false);
						Alert.success("Rate retired");
						props.close(true);
					} catch (error) {
						setSubmitting(false);
						Alert.error("Something went wrong. Please try again.");
					}
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={{
				...props,
				rateTypes: RATE_TYPES,
				rateScales: RATE_SCALES,
				variedRateDurations: VARIED_RATE_DURATIONS,
				variedRateOffsetScales: VARIED_RATE_OFFSET_SCALES,
				days: DAYS,
			}}
			constructWizard={constructWizard}
		/>
	);
}
