import AlertMessage from "../../../layout/AlertMessage";
import Button from "../../../layout/Button";
import React from "react";
import { StepTitle } from "../../WizardLayout";
import TimeRangePicker from "../../../layout/TimeRangePicker";

import { colours } from "../../../../styles";
import moment from "moment";
import styled from "styled-components";

const Separator = styled.div`
	display: inline-block;
	margin: 18px 4px 0;
	padding: 0 8px 0 8px;
`;

const Wrapper = styled(Button)`
	background: transparent;
	border: 2px solid
		${(props) => (props.selected ? colours.green : colours.borderGrey)};
	${(props) => (props.selected ? `color: ${colours.green};` : null)}
	${(props) => (props.selected ? `font-weight: 700 !important;` : null)}
	height: 80px;
	outline: none;
	width: 90px;
	margin-right: 4px;
	padding: 0;
`;

const Text = styled.div`
	text-align: center;
`;

export default ({ values, setFieldValue, goTo }) => {
	let selected = values.days || [];
	const startTime = moment(values.startTime).format("HH:mm");
	const endTime = moment(values.endTime).format("HH:mm");
	let hours = `${startTime} to ${
		endTime === "00:00" ? "00:00 (24 hours)" : endTime
	}`;
	if (values.type.value === "EarlyBird") {
		hours = (
			<React.Fragment>
				<span>
					Enter between {moment(values.entryLaterThan).format("HH:mm")} and{" "}
					{moment(values.entryEarlierThan).format("HH:mm")}
				</span>
				<br />
				<span>
					Exit between {moment(values.exitLaterThan).format("HH:mm")} and{" "}
					{moment(values.exitEarlierThan).format("HH:mm")}
				</span>
			</React.Fragment>
		);
	}
	return {
		id: "schedule",
		summary: [
			{
				id: "schedule",
				label: "Days of the Week",
				value: (values.days || []).join(", ") || "No days selected",
				edit: () => {
					setFieldValue("editFromSummary", true);
					setFieldValue("configuration", "schedule");
					goTo("configuration");
				},
			},
			{
				label: "Hours",
				value: hours,
				id: "hours",
				edit: () => {
					setFieldValue("editFromSummary", true);
					setFieldValue("configuration", "schedule");
					goTo("configuration");
				},
			},
		],
		render: () => (
			<React.Fragment>
				<div>
					{values.conflictRateErrorMessage && (
						<AlertMessage
							text={values.conflictRateErrorMessage}
							borderColor="red"
							backgroundColor="red"
							textColor="white"
						/>
					)}
					{values.incorrectEarlyBirdExitTime && (
						<AlertMessage
							text="The end time of the exit period should be later than the start time of the exit period for an early bird rate"
							borderColor="red"
							backgroundColor="red"
							textColor="white"
						/>
					)}
					{values.incorrectAfterHoursEarlyBirdEntryTime && (
						<AlertMessage
							text="The end time of the entry period should be later than the start time of the entry period for an after hours early bird rate"
							borderColor="red"
							backgroundColor="red"
							textColor="white"
						/>
					)}
					<StepTitle>When should this rate apply?</StepTitle>
					{["EarlyBird", "AfterhoursEarlyBird"].includes(values.type.value) ? (
						<React.Fragment>
							<div
								style={{
									marginLeft: "-10px",
									display: "flex",
								}}
							>
								<Separator>Enter between</Separator>
								<TimeRangePicker
									noLimits
									canEndAt24Hours
									dash="and"
									startTime={values.entryLaterThan}
									endTime={values.entryEarlierThan}
									noLeftPadding={true}
									onChange={({ from, to }) => {
										setFieldValue("entryLaterThan", from);
										setFieldValue("entryEarlierThan", to);
									}}
								/>
							</div>
							<div
								style={{
									paddingBottom: "24px",
									marginLeft: "-10px",
									display: "flex",
								}}
							>
								<Separator style={{ marginRight: "18px" }}>
									Exit between
								</Separator>
								<TimeRangePicker
									noLimits
									canEndAt24Hours
									dash="and"
									startTime={values.exitLaterThan}
									endTime={values.exitEarlierThan}
									noLeftPadding={true}
									onChange={({ from, to }) => {
										setFieldValue("exitLaterThan", from);
										setFieldValue("exitEarlierThan", to);
									}}
								/>
							</div>
						</React.Fragment>
					) : (
						<div style={{ paddingBottom: "24px", marginLeft: "-10px" }}>
							<TimeRangePicker
								noLimits
								canEndAt24Hours
								dash="to"
								startTime={values.startTime}
								endTime={values.endTime}
								noLeftPadding={true}
								onChange={({ from, to }) => {
									setFieldValue("startTime", from);
									setFieldValue("endTime", to);
								}}
							/>
						</div>
					)}

					{["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day) => (
						<Wrapper
							onClick={() => {
								const index = selected.indexOf(day);
								if (index > -1) {
									//it's present, remove it
									selected.splice(index, 1);
									setFieldValue("days", selected);
								} else {
									//isn't selected yet, add it
									setFieldValue("days", [...selected, day]);
								}
							}}
							selected={selected.includes(day)}
							key={day}
						>
							<Text>{day}</Text>
						</Wrapper>
					))}
					<p>Note: this will repeat every week.</p>
				</div>
			</React.Fragment>
		),
		isValid: selected.length > 0,
	};
};
