import React from "react";
import { SizeMe } from "react-sizeme";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	background: ${colours.highlightGrey};
	border-radius: 4px;
	display: flex;
	justify-content: flex-end;
	margin: 16px 0;
	padding: 16px;
	position: relative;

	button {
		svg {
			margin-right: 4px;
			vertical-align: sub;
		}
	}

	&.small {
		display: block;

		> div:last-child:not(:first-child) {
			margin-top: 16px;
		}
	}
`;

const Left = styled.div`
	align-self: flex-start;
	flex: 1;

	> * {
		display: inline-block;
		vertical-align: middle;
	}
`;

const Right = styled.div`
	align-self: flex-end;

	> * {
		display: inline-block;
		vertical-align: middle;
	}
`;

export function LeftActions(props) {
	return <Left {...props} />;
}

export function RightActions(props) {
	return <Right {...props} />;
}

export default function PageActions(props) {
	return (
		<SizeMe>
			{({ size }) => (
				<div>
					<Wrapper
						{...props}
						className={`${props.className} ${size.width < 1000 ? "small" : ""}`}
					/>
				</div>
			)}
		</SizeMe>
	);
}
