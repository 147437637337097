import _ from "lodash";
import moment from "moment";

export function getFormattedRate(values, siteId) {
	const allDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
	const exclusiveRates = [
		"EarlyBird",
		"AfterhoursEarlyBird",
		"Event",
		"Complex",
	];
	const accessRates = ["EarlyBird", "AfterhoursEarlyBird", "Complex"];
	const isComplex = values.type.value === "Complex";
	const isEvent = values.type.value === "Event";
	const isExitOnOrBefore =
		values.exitCondition && values.exitCondition.value === "exitOnOrBefore";
	const convertToSeconds = (date, isEnd) => {
		const seconds = date ? date.hour() * 60 * 60 + date.minute() * 60 : null;
		if (isEnd && seconds === 0) {
			//shouldn't set an end date as 0, should be 86400 (24:00)
			return 86400;
		}

		return seconds;
	};

	const convertToSecondsForEnd = (date) => convertToSeconds(date, true);
	const convertToSecondsWithEndOfDayCheck = (date) =>
		date
			? date.format("HH:mm") === "00:00"
				? 86400
				: convertToSeconds(date)
			: null;

	const entry = {
		earlierThan: moment(values.entryEarlierThan),
		laterThan: moment(values.entryLaterThan),
	};
	const exit = {
		earlierThan: moment(values.exitEarlierThan),
		laterThan: moment(values.exitLaterThan),
	};

	const transformDays = (_values) => {
		let start = moment(_values.startTime);
		let end = moment(_values.endTime);

		if (isComplex) {
			const days = [...allDays].splice(
				allDays.indexOf(_values.enterOnDay.value),
				allDays.indexOf(
					isExitOnOrBefore
						? _values.exitOnOrBefore.value
						: _values.exitOnDay.value
				) + 1
			);

			const difference = _.difference(allDays, days);
			const transformed = {};
			for (let [index, day] of days.entries()) {
				transformed[day] = 1;
				if (index === 0) {
					transformed[`${day}Start`] = convertToSeconds(
						entry.laterThan.clone()
					);
					transformed[`${day}End`] = 86400;
				}

				if (index > 0 && index < days.length) {
					transformed[`${day}Start`] = 0;
					transformed[`${day}End`] = 86400;
				}

				if (index === days.length - 1) {
					transformed[`${day}Start`] = 0;
					transformed[`${day}End`] = convertToSecondsForEnd(
						exit.earlierThan.clone()
					);
				}
			}

			return {
				...transformed,
				...difference.reduce(
					(original, day) => ({
						...original,
						[day]: 0,
						[`${day}Start`]: 0,
						[`${day}End`]: 86400,
					}),
					{}
				),
			};
		} else if (accessRates.includes(_values.type.value)) {
			start = entry.laterThan.clone();
			end = exit.earlierThan.clone();
		} else if (isEvent) {
			let weekDays = {};

			for (const day of allDays) {
				weekDays = {
					...weekDays,
					[`${day}`]: 0,
					[`${day}Start`]: 0,
					[`${day}End`]: 86400,
				};
			}

			return weekDays;
		}

		const difference = _.difference(allDays, _values.days);

		return {
			..._values.days.reduce(
				(original, day) => ({
					...original,
					[day]: 1,
					[`${day}Start`]: convertToSeconds(start),
					[`${day}End`]: convertToSecondsForEnd(end),
				}),
				{}
			),
			...difference.reduce(
				(original, day) => ({
					...original,
					[day]: 0,
					[`${day}Start`]: convertToSeconds(start),
					[`${day}End`]: convertToSecondsForEnd(end),
				}),
				{}
			),
		};
	};

	const isAccessRate = accessRates.includes(values.type.value);
	const specialConditions = (values.specialConditionItems || []).reduce(
		(original, item) => ({
			...original,
			[item.condition.value]: parseInt(item.minutes),
		}),
		{}
	);

	return {
		RateID: values.rateId,
		SiteID: siteId,

		StartDate:
			values.type.value === "Event"
				? moment(values.startTimestamp).format("YYYYMMDDHHmm")
				: null,
		EndDate:
			values.type.value === "Event"
				? moment(values.endTimestamp).format("YYYYMMDDHHmm")
				: null,
		EnterOnDay: values.enterOnDay && isComplex ? values.enterOnDay.value : null,
		EntryEarlierThan: isAccessRate
			? convertToSecondsWithEndOfDayCheck(entry.earlierThan)
			: null,
		EntryLaterThan: isAccessRate ? convertToSeconds(entry.laterThan) : null,
		ExitEarlierThan: isAccessRate
			? convertToSecondsWithEndOfDayCheck(exit.earlierThan)
			: null,
		ExitLaterThan:
			isAccessRate && !isExitOnOrBefore
				? convertToSeconds(exit.laterThan)
				: null,
		ExitOnDay:
			isComplex && !isExitOnOrBefore && values.exitOnDay
				? values.exitOnDay.value
				: null,
		ExitOnOrBeforeDay:
			isComplex &&
			values.exitCondition &&
			values.exitCondition.value === "exitOnOrBefore"
				? values.exitOnOrBefore.value
				: null,
		FeeCalculationType: values.feeCalculationType,
		FeePerUnit: values.feePerUnit || null,
		ForceBlocksToUnitSize:
			values.feeCalculationType !== "Varied" ? values.forceBlocksToUnitSize : 0,
		AppliedCrossSession:
			values.feeCalculationType === "Normal" &&
			values.unitStaggeringScale.value !== 1440
				? 0
				: values.appliedCrossSession,
		IsExclusive: exclusiveRates.includes(values.type.value),
		IsValidationRate: values.isValidation,
		MaxFee: values.maxFee,
		Name: values.name,
		RateClass: values.type.value,
		UnitStaggeringScale: values.unitStaggeringScale.value,
		accessType: values.appliesTo === "Groups" ? "private" : "public",
		organizationAccessGroupIds:
			values.appliesTo === "Groups"
				? values.groups
					? values.groups.map((group) => group.value)
					: null
				: null,
		MinimumDuration: specialConditions.minimum * 60,
		MaximumDuration: specialConditions.maximum * 60,
		maximumDurationMinutes: specialConditions.maximum,
		minimumDurationMinutes: specialConditions.minimum,

		allEndOffset: 86400,
		allStartOffset: 0,
		endDay: "Fri",
		startDay: "Mon",

		...transformDays(values),
	};
}
