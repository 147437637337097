import * as installationApi from "../../api/installation";
import React from "react";
import {
	Redirect,
	Route,
	Switch,
} from "react-router-dom/cjs/react-router-dom.min";
import Accordion from "../../components/layout/Accordion";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import Dropdown from "../../components/layout/Dropdown";
import ErrorBoundary from "../ErrorBoundary";
import Input from "../../components/layout/Input";
import PageTitle from "../../components/layout/PageTitle";
import SignageQRCodes from "../../components/report/signage/SignageQRCodes";
import SignageQRCodesHistory from "../../components/report/signage/SignageQRCodesHistory";
import TableLayoutBeta from "../../components/layout/TableLayoutBeta";
import gql from "graphql-tag";
import { useQueryData } from "../../hooks";
import Alert from "react-s-alert";

async function downloadZoneQR(site, codeOnly) {
	const webPayType =
		site.selectedWebPayConfigDomain?.value ||
		site.WebPayConfig?.Domains?.[0]?.Type;
	const security = site.selectSecurity;
	const format = site.selectedFormat ? site.selectedFormat.value : "svg";
	const uniqueCode = site.uniqueCode;

	const { error } = await installationApi.downloadZoneQrPdfUrl({
		siteId: site.SiteID,
		webPayType,
		codeOnly,
		format,
		security,
		uniqueCode,
	});

	if (error) {
		Alert.error(
			`Could not download ${codeOnly ? "QR Code" : "QR Code Signage"}, ${
				error.message
			}`
		);
	}
}

const sitesColumns = [
	{
		id: "siteName",
		Header: "Site Name",
		accessor: "Name",
		fixedWidth: 150,
	},
	{
		id: "domains",
		Header: "Domain(s)",
		accessor: (site) => {
			const options = site.WebPayConfig.Domains.map((d) => ({
				value: d.Type,
				label: d.Domain,
			}));
			return (
				<Dropdown
					menuPortalTarget={document.body}
					styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
					options={options}
					onChange={(value) => {
						site.selectedWebPayConfigDomain = value;
					}}
				/>
			);
		},
		fixedWidth: 250,
	},
	{
		id: "format",
		Header: "Format",
		accessor: (site) => {
			const options = [
				{
					value: "svg",
					label: "SVG",
				},
				{
					value: "pdf",
					label: "PDF",
				},
			];
			return (
				<Dropdown
					menuPortalTarget={document.body}
					styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
					options={options}
					onChange={(value) => {
						site.selectedFormat = value;
					}}
				/>
			);
		},
		fixedWidth: 250,
	},
	{
		id: "security",
		Header: "Security",
		accessor: (site) => {
			const options = [
				{
					value: "color",
					label: "Colour",
				},
				{
					value: "logo",
					label: "Logo",
				},
			];
			return (
				<Dropdown
					isMulti={true}
					menuPortalTarget={document.body}
					styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
					options={options}
					onChange={(values) => {
						const newSecurity = {};
						values.forEach((value) => {
							newSecurity[`${value.value}`] = true;
						});

						site.selectSecurity = newSecurity;
					}}
				/>
			);
		},
		fixedWidth: 250,
	},
	{
		id: "uniqueCode",
		Header: "Unique Code (optional)",
		accessor: (site) => {
			return (
				<Input
					type="text"
					onChange={(e) => {
						site.uniqueCode = e.target.value;
					}}
				/>
			);
		},
		fixedWidth: 150,
	},
	{
		id: "signage",
		Header: "",
		accessor: (site) => (
			<Button
				color="blue"
				style={{ width: 280 }}
				onClick={() => downloadZoneQR(site, false)}
			>
				Download QR Signage
			</Button>
		),
		fixedWidth: 300,
	},
	{
		id: "qrCode",
		Header: "",
		accessor: (site) => (
			<Button
				color="blue"
				style={{ width: 280 }}
				onClick={() => downloadZoneQR(site, true)}
			>
				Download QR Code
			</Button>
		),
		fixedWidth: 280,
	},
];

export default function SignageContainer(props) {
	const organizationId = props.selectedOrganization.OrganizationID;

	const {
		data: { getWebPayEnabledSitesForOrganization },
		// refetch: refetchWebPayEnabledSites,
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getWebPayEnabledSitesForOrganization(organizationId: $organizationId) {
					SiteID
					Name
					WebPayConfig {
						Domains {
							Type
							Domain
						}
					}
				}
			}
		`,
		{ organizationId }
	);

	return (
		<ErrorBoundary>
			<Switch>
				<Route
					exact
					path={`${props.match.url}/generate`}
					render={() => (
						<div>
							<PageTitle>QR Codes</PageTitle>
							<Card>
								<Accordion
									title="Sites with QR code payment enabled"
									expanded={true}
								>
									<TableLayoutBeta
										data={getWebPayEnabledSitesForOrganization}
										columns={sitesColumns.filter(
											(column) => column.id !== "signage"
										)}
										defaultSortBy={[]}
									/>
								</Accordion>
							</Card>
							<PageTitle>Signage</PageTitle>
							<Card>
								<Accordion
									title="Sites with QR code payment enabled"
									expanded={true}
								>
									<TableLayoutBeta
										data={getWebPayEnabledSitesForOrganization}
										columns={sitesColumns.filter(
											(column) => column.id !== "qrCode"
										)}
										defaultSortBy={[]}
									/>
								</Accordion>
							</Card>
						</div>
					)}
				/>
				<Route
					path={`${props.match.url}/unique-qr-codes`}
					render={(_props) => <SignageQRCodes {...props} {..._props} />}
				/>
				<Route
					path={`${props.match.url}/history`}
					render={(_props) => <SignageQRCodesHistory {...props} {..._props} />}
				/>
			</Switch>
			<Redirect to={`${props.match.url}/generate`} />
		</ErrorBoundary>
	);
}
