import * as Yup from "yup";
import WizardLayout, { StepTitle } from "../WizardLayout";
import Alert from "react-s-alert";
import Button from "../../layout/Button";
import EditableInputField from "../../layout/EditableInputField";
import PropTypes from "prop-types";
import React from "react";
import WizardNavigation from "../WizardNavigation";
import { useMutateData } from "../../../hooks";
import gql from "graphql-tag";

const name = ({
	close,
	handleSubmit,
	isSubmitting,
	values,
	keyStrokeHandler,
}) => ({
	id: "name",
	label: "Name",
	render: () => (
		<div>
			<StepTitle>What is the name of the client?</StepTitle>
			<EditableInputField
				type="text"
				name="name"
				value={values.name || ""}
				useLabel="Client Name"
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				<Button key="name" color="red" onClick={close}>
					Cancel
				</Button>
			}
			rightItems={[
				<Button
					key="create"
					color="blue"
					onClick={handleSubmit}
					disabled={isSubmitting || !values.name}
					keyStrokeHandler={keyStrokeHandler}
				>
					Create
				</Button>,
			]}
		/>
	),
});

CreateOrganizationWizard.propTypes = {
	close: PropTypes.func.isRequired,
};

CreateOrganizationWizard.defaultProps = {
	close: () => {},
};

function CreateOrganizationWizard(props) {
	const createOrganization = useMutateData(gql`
		mutation ($name: String) {
			createOrganization(name: $name) {
				OrganizationID
				Name
			}
		}
	`);

	const onSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);

		try {
			const response = await createOrganization({
				variables: {
					name: values.name,
				},
			});

			const organizationId = response?.data?.createOrganization?.OrganizationID;

			if (organizationId) {
				Alert.success("Client created");

				//wait a second before going to the new org so that the user can see Alert
				return new Promise(() =>
					setTimeout(() => {
						window.location.href = `/operator/${props.selectedOperator.OperatorID}/organization/${organizationId}`;
					}, 1000)
				);
			}
		} catch (error) {
			setSubmitting(false);
			Alert.error("Something went wrong. Please try again.");
		}
	};

	return (
		<WizardLayout
			close={props.close}
			title="Create Client"
			values={[
				{
					name: "name",
					validator: Yup.string(),
				},
			]}
			onSubmit={onSubmit}
			steps={[name]}
			initialStep={0}
			wizardProps={props}
		/>
	);
}

export default CreateOrganizationWizard;
