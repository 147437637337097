export const logType = {
	PublicSpaceCreated: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{ name: "spaces", label: "Spaces", value: null, hasChanges: false },
			{ name: "sites", label: "Sites", value: null, hasChanges: false },
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "hassignage",
				label: "Has signage",
				value: null,
				hasChanges: false,
			},
			{
				name: "billingDetailsName",
				label: "Billing",
				value: null,
				hasChanges: false,
			},
		],
	},
	PublicSpaceUpdated: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{ name: "spaces", label: "Spaces", value: null, hasChanges: false },
			{ name: "sites", label: "Sites", value: null, hasChanges: false },
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "hassignage",
				label: "Has signage",
				value: null,
				hasChanges: false,
			},
			{
				name: "billingDetailsName",
				label: "Billing",
				value: null,
				hasChanges: false,
			},
		],
	},
	PublicSpaceDeleted: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{ name: "spaces", label: "Spaces", value: null, hasChanges: false },
			{ name: "sites", label: "Sites", value: null, hasChanges: false },
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "hassignage",
				label: "Has signage",
				value: null,
				hasChanges: false,
			},
			{
				name: "billingDetailsName",
				label: "Billing",
				value: null,
				hasChanges: false,
			},
		],
	},
	CorporateSpaceCreated: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{ name: "spaces", label: "Spaces", value: null, hasChanges: false },
			{ name: "sites", label: "Sites", value: null, hasChanges: false },
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "hassignage",
				label: "Has signage",
				value: null,
				hasChanges: false,
			},
		],
	},
	CorporateSpaceUpdated: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{ name: "spaces", label: "Spaces", value: null, hasChanges: false },
			{ name: "sites", label: "Sites", value: null, hasChanges: false },
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "hassignage",
				label: "Has signage",
				value: null,
				hasChanges: false,
			},
		],
	},
	CorporateSpaceDeleted: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{ name: "spaces", label: "Spaces", value: null, hasChanges: false },
			{ name: "sites", label: "Sites", value: null, hasChanges: false },
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "hassignage",
				label: "Has signage",
				value: null,
				hasChanges: false,
			},
		],
	},
	BookingTimeUpdated: {
		fields: [
			{
				name: "UserEmail",
				label: "Customer Email",
				value: null,
				hasChanges: false,
			},
			{
				name: "LeaseParkName",
				label: "Space Name",
				value: null,
				hasChanges: false,
			},
			{
				name: "StartDateTime",
				label: "Booking Start",
				value: null,
				hasChanges: false,
			},
			{
				name: "EndDateTime",
				label: "Booking End",
				value: null,
				hasChanges: false,
			},
		],
	},
	PrivateSpaceCreated: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{ name: "spaces", label: "Spaces", value: null, hasChanges: false },
			{ name: "sites", label: "Sites", value: null, hasChanges: false },
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "hassignage",
				label: "Has signage",
				value: null,
				hasChanges: false,
			},
			{
				name: "billingDetailsName",
				label: "Billing",
				value: null,
				hasChanges: false,
			},
		],
	},
	PrivateSpaceUpdated: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{ name: "spaces", label: "Spaces", value: null, hasChanges: false },
			{ name: "sites", label: "Sites", value: null, hasChanges: false },
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "hassignage",
				label: "Has signage",
				value: null,
				hasChanges: false,
			},
			{
				name: "billingDetailsName",
				label: "Billing",
				value: null,
				hasChanges: false,
			},
		],
	},
	PrivateSpaceDeleted: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{ name: "spaces", label: "Spaces", value: null, hasChanges: false },
			{ name: "sites", label: "Sites", value: null, hasChanges: false },
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "hassignage",
				label: "Has signage",
				value: null,
				hasChanges: false,
			},
			{
				name: "billingDetailsName",
				label: "Billing",
				value: null,
				hasChanges: false,
			},
		],
	},
	ReservationParkCreated: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{ name: "spaces", label: "Spaces", value: null, hasChanges: false },
			{ name: "sites", label: "Sites", value: null, hasChanges: false },
		],
	},
	ReservationParkUpdated: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{ name: "spaces", label: "Spaces", value: null, hasChanges: false },
			{ name: "sites", label: "Sites", value: null, hasChanges: false },
		],
	},
	ReservationParkDeleted: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{ name: "spaces", label: "Spaces", value: null, hasChanges: false },
			{ name: "sites", label: "Sites", value: null, hasChanges: false },
		],
	},
	ReservationRateCreated: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{
				name: "monthlyfee",
				label: "Monthly Fees",
				value: null,
				hasChanges: false,
			},
			{
				name: "minimumdurationmonths",
				label: "Minimum Duration",
				value: null,
				hasChanges: false,
			},
			{
				name: "unittopayinadvance",
				label: "Pay in Advance",
				value: null,
				hasChanges: false,
			},
			{
				name: "earlycancellationfee",
				label: "Cancellation Fees",
				value: null,
				hasChanges: false,
			},

			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "prorataday",
				label: "Pro Rata Day",
				value: null,
				hasChanges: false,
			},

			{ name: "leaseparks", label: "Spaces", value: null, hasChanges: false },
			{
				name: "organizationaccessgroups",
				label: "Groups",
				value: null,
				hasChanges: false,
			},
		],
	},
	ReservationRateUpdated: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{
				name: "monthlyfee",
				label: "Monthly Fees",
				value: null,
				hasChanges: false,
			},
			{
				name: "minimumdurationmonths",
				label: "Minimum Duration",
				value: null,
				hasChanges: false,
			},
			{
				name: "unittopayinadvance",
				label: "Pay in Advance",
				value: null,
				hasChanges: false,
			},
			{
				name: "earlycancellationfee",
				label: "Cancellation Fees",
				value: null,
				hasChanges: false,
			},
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "prorataday",
				label: "Pro Rata Day",
				value: null,
				hasChanges: false,
			},
			{ name: "leaseparks", label: "Spaces", value: null, hasChanges: false },
			{
				name: "organizationaccessgroups",
				label: "Groups",
				value: null,
				hasChanges: false,
			},
		],
	},
	ReservationRateDeleted: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{
				name: "monthlyfee",
				label: "Monthly Fees",
				value: null,
				hasChanges: false,
			},
			{
				name: "minimumdurationmonths",
				label: "Minimum Duration",
				value: null,
				hasChanges: false,
			},
			{
				name: "unittopayinadvance",
				label: "Pay in Advance",
				value: null,
				hasChanges: false,
			},
			{
				name: "earlycancellationfee",
				label: "Cancellation Fees",
				value: null,
				hasChanges: false,
			},
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "prorataday",
				label: "Pro Rata Day",
				value: null,
				hasChanges: false,
			},

			{ name: "leaseparks", label: "Spaces", value: null, hasChanges: false },
			{
				name: "organizationaccessgroups",
				label: "Groups",
				value: null,
				hasChanges: false,
			},
		],
	},
	BookingRateCreated: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{
				name: "monthlyfee",
				label: "Monthly Fees",
				value: null,
				hasChanges: false,
			},
			{
				name: "minimumdurationmonths",
				label: "Minimum Duration",
				value: null,
				hasChanges: false,
			},
			{
				name: "unittopayinadvance",
				label: "Pay in Advance",
				value: null,
				hasChanges: false,
			},
			{
				name: "earlycancellationfee",
				label: "Cancellation Fees",
				value: null,
				hasChanges: false,
			},

			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "prorataday",
				label: "Pro Rata Day",
				value: null,
				hasChanges: false,
			},

			{ name: "leaseparks", label: "Spaces", value: null, hasChanges: false },
			{
				name: "organizationaccessgroups",
				label: "Groups",
				value: null,
				hasChanges: false,
			},
		],
	},
	BookingRateUpdated: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{
				name: "monthlyfee",
				label: "Monthly Fees",
				value: null,
				hasChanges: false,
			},
			{
				name: "minimumdurationmonths",
				label: "Minimum Duration",
				value: null,
				hasChanges: false,
			},
			{
				name: "unittopayinadvance",
				label: "Pay in Advance",
				value: null,
				hasChanges: false,
			},
			{
				name: "earlycancellationfee",
				label: "Cancellation Fees",
				value: null,
				hasChanges: false,
			},
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "prorataday",
				label: "Pro Rata Day",
				value: null,
				hasChanges: false,
			},
			{ name: "leaseparks", label: "Spaces", value: null, hasChanges: false },
			{
				name: "organizationaccessgroups",
				label: "Groups",
				value: null,
				hasChanges: false,
			},
		],
	},
	BookingRateDeleted: {
		fields: [
			{ name: "name", label: "Name", value: null, hasChanges: false },
			{
				name: "monthlyfee",
				label: "Monthly Fees",
				value: null,
				hasChanges: false,
			},
			{
				name: "minimumdurationmonths",
				label: "Minimum Duration",
				value: null,
				hasChanges: false,
			},
			{
				name: "unittopayinadvance",
				label: "Pay in Advance",
				value: null,
				hasChanges: false,
			},
			{
				name: "earlycancellationfee",
				label: "Cancellation Fees",
				value: null,
				hasChanges: false,
			},
			{
				name: "startdate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "enddate", label: "End Date", value: null, hasChanges: false },
			{
				name: "prorataday",
				label: "Pro Rata Day",
				value: null,
				hasChanges: false,
			},

			{ name: "leaseparks", label: "Spaces", value: null, hasChanges: false },
			{
				name: "organizationaccessgroups",
				label: "Groups",
				value: null,
				hasChanges: false,
			},
		],
	},
	Rate: {
		fields: [
			{ name: "Name", label: "Name", value: null, hasChanges: false },
			{ name: "RateClass", label: "Rate Type", value: null, hasChanges: false },
			{
				name: "AccessType",
				label: "Applies To",
				value: null,
				hasChanges: false,
			},
			{
				name: "DaysOfWeek",
				label: "Days of the week",
				value: null,
				hasChanges: false,
			},
			{ name: "Hours", label: "Hours", value: null, hasChanges: false },
			{
				name: "FeeCalculationType",
				label: "Rate",
				value: null,
				hasChanges: false,
			},
			{
				name: "MaxFee",
				label: "Maximum Price",
				value: null,
				hasChanges: false,
			},
			{
				name: "SpecialConditions",
				label: "Special Conditions",
				value: null,
				hasChanges: false,
			},
			{
				name: "IsValidationRate",
				label: "Validation Rate",
				value: null,
				hasChanges: false,
			},
			{
				name: "ForceBlocksToUnitSize",
				label: "Flat Rate",
				value: null,
				hasChanges: false,
			},
			{
				name: "AppliedCrossSession",
				label: "Charged Once",
				value: null,
				hasChanges: false,
			},
		],
	},
	RateCreated: {
		fields: [
			{ name: "Name", label: "Name", value: null, hasChanges: false },
			{ name: "RateClass", label: "Rate Type", value: null, hasChanges: false },
			{
				name: "AccessType",
				label: "Applies To",
				value: null,
				hasChanges: false,
			},
			{
				name: "DaysOfWeek",
				label: "Days of the week",
				value: null,
				hasChanges: false,
			},
			{ name: "Hours", label: "Hours", value: null, hasChanges: false },
			{
				name: "FeeCalculationType",
				label: "Rate",
				value: null,
				hasChanges: false,
			},
			{
				name: "MaxFee",
				label: "Maximum Price",
				value: null,
				hasChanges: false,
			},
			{
				name: "SpecialConditions",
				label: "Special Conditions",
				value: null,
				hasChanges: false,
			},
			{
				name: "IsValidationRate",
				label: "Validation Rate",
				value: null,
				hasChanges: false,
			},
			{
				name: "ForceBlocksToUnitSize",
				label: "Flat Rate",
				value: null,
				hasChanges: false,
			},
			{
				name: "AppliedCrossSession",
				label: "Charged Once",
				value: null,
				hasChanges: false,
			},
		],
	},
	RateEdited: {
		fields: [
			{ name: "Name", label: "Name", value: null, hasChanges: false },
			{ name: "RateClass", label: "Rate Type", value: null, hasChanges: false },
			{
				name: "AccessType",
				label: "Applies To",
				value: null,
				hasChanges: false,
			},
			{
				name: "DaysOfWeek",
				label: "Days of the week",
				value: null,
				hasChanges: false,
			},
			{ name: "Hours", label: "Hours", value: null, hasChanges: false },
			{
				name: "FeeCalculationType",
				label: "Rate",
				value: null,
				hasChanges: false,
			},
			{
				name: "MaxFee",
				label: "Maximum Price",
				value: null,
				hasChanges: false,
			},
			{
				name: "SpecialConditions",
				label: "Special Conditions",
				value: null,
				hasChanges: false,
			},
			{
				name: "IsValidationRate",
				label: "Validation Rate",
				value: null,
				hasChanges: false,
			},
			{
				name: "ForceBlocksToUnitSize",
				label: "Flat Rate",
				value: null,
				hasChanges: false,
			},
			{
				name: "AppliedCrossSession",
				label: "Charged Once",
				value: null,
				hasChanges: false,
			},
		],
	},
	RateRemoved: {
		fields: [
			{ name: "Name", label: "Name", value: null, hasChanges: false },
			{ name: "RateClass", label: "Rate Type", value: null, hasChanges: false },
			{
				name: "AccessType",
				label: "Applies To",
				value: null,
				hasChanges: false,
			},
			{
				name: "DaysOfWeek",
				label: "Days of the week",
				value: null,
				hasChanges: false,
			},
			{ name: "Hours", label: "Hours", value: null, hasChanges: false },
			{
				name: "FeeCalculationType",
				label: "Rate",
				value: null,
				hasChanges: false,
			},
			{
				name: "MaxFee",
				label: "Maximum Price",
				value: null,
				hasChanges: false,
			},
			{
				name: "SpecialConditions",
				label: "Special Conditions",
				value: null,
				hasChanges: false,
			},
			{
				name: "IsValidationRate",
				label: "Validation Rate",
				value: null,
				hasChanges: false,
			},
			{
				name: "ForceBlocksToUnitSize",
				label: "Flat Rate",
				value: null,
				hasChanges: false,
			},
			{
				name: "AppliedCrossSession",
				label: "Charged Once",
				value: null,
				hasChanges: false,
			},
		],
	},
	LeaseRate: {
		fields: [
			{ name: "Name", label: "Name", value: null, hasChanges: false },
			{
				name: "EarlyCancellationFee",
				label: "Cancellation Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "MonthlyFee",
				label: "Monthly Fees",
				value: null,
				hasChanges: false,
			},
			{
				name: "MinimumDurationMonths",
				label: "Minimum Duration",
				value: null,
				hasChanges: false,
			},
			{
				name: "UnitToPayInAdvance",
				label: "Months to Pay in Advance",
				value: null,
				hasChanges: false,
			},
			{
				name: "ProRataDay",
				label: "Pro Rata Day",
				value: null,
				hasChanges: false,
			},
		],
	},
	ParkingSessionRefund: {
		fields: [
			{
				name: "amountToRefund",
				label: "Amount Refunded",
				value: null,
				hasChanges: false,
			},
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{
				name: "userEmail",
				label: "User Email",
				value: null,
				hasChanges: false,
			},
			{
				name: "parkingSessionId",
				label: "Transaction ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "reasonToRefund",
				label: "Reason",
				value: null,
				hasChanges: false,
			},
		],
	},
	UserLeaseRefund: {
		fields: [
			{
				name: "amountToRefund",
				label: "Amount Refunded",
				value: null,
				hasChanges: false,
			},
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{
				name: "userEmail",
				label: "User Email",
				value: null,
				hasChanges: false,
			},
			{
				name: "userLeaseId",
				label: "User Lease ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "transactionId",
				label: "Transaction ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "reasonToRefund",
				label: "Reason",
				value: null,
				hasChanges: false,
			},
		],
	},
	ResendInvoice: {
		fields: [
			{ name: "fee", label: "Invoice Amount", value: null, hasChanges: false },
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{
				name: "userEmail",
				label: "User Email",
				value: null,
				hasChanges: false,
			},
			{
				name: "transactionId",
				label: "Transaction ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "invoiceId",
				label: "Invoice ID",
				value: null,
				hasChanges: false,
			},
		],
	},
	UserLeaseCancellationFeeRefund: {
		fields: [
			{
				name: "amountToRefund",
				label: "Amount Refunded",
				value: null,
				hasChanges: false,
			},
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{
				name: "userEmail",
				label: "User Email",
				value: null,
				hasChanges: false,
			},
			{
				name: "userLeaseId",
				label: "User Lease ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "reasonToRefund",
				label: "Reason",
				value: null,
				hasChanges: false,
			},
		],
	},
	SiteSettingsUpdated: {
		fields: [
			{ name: "Name", label: "Name", value: null, hasChanges: false },
			{ name: "SiteType", label: "Site Type", value: null, hasChanges: false },
			{
				name: "Description",
				label: "Description",
				value: null,
				hasChanges: false,
			},
			{ name: "Address", label: "Address", value: null, hasChanges: false },
			{ name: "Latitude", label: "Latitude", value: null, hasChanges: false },
			{ name: "Longitude", label: "Longitude", value: null, hasChanges: false },
			{
				name: "StreetViewRotation",
				label: "Street View Rotation",
				value: null,
				hasChanges: false,
			},
			{
				name: "HasPublicAccess",
				label: "Public Access",
				value: null,
				hasChanges: false,
			},
			{
				name: "HasSessionlessAccess",
				label: "Sessionless Access",
				value: null,
				hasChanges: false,
			},
			{ name: "Timezone", label: "Timezone", value: null, hasChanges: false },
			{
				name: "HasCasualParking",
				label: "Casual Parking",
				value: null,
				hasChanges: false,
			},
			{
				name: "HasLeaseParking",
				label: "Lease Parking",
				value: null,
				hasChanges: false,
			},
			{ name: "HasANPR", label: "ANPR", value: null, hasChanges: false },
			{
				name: "ValidationDiscountStacking",
				label: "Validation Discount Stacking",
				value: null,
				hasChanges: false,
			},
			{
				name: "HasITSIntegration",
				label: "ITS Integration",
				value: null,
				hasChanges: false,
			},
			{
				name: "HasSessionStartStop",
				label: "Session Start/Stop",
				value: null,
				hasChanges: false,
			},
			{
				name: "HasSessionExpiry",
				label: "Session Expiry",
				value: null,
				hasChanges: false,
			},
			{
				name: "EnquiryEmail",
				label: "Enquiry Email",
				value: null,
				hasChanges: false,
			},
			{
				name: "EnquiryPhone",
				label: "Enquiry Phone",
				value: null,
				hasChanges: false,
			},
			{
				name: "TaxRateCountryCode",
				label: "Tax Type",
				value: null,
				hasChanges: false,
			},
			{ name: "Currency", label: "Currency", value: null, hasChanges: false },
			{
				name: "TnCs",
				label: "Terms and Conditions",
				value: null,
				hasChanges: false,
			},
			{
				name: "groupNames",
				label: "Private Access Groups",
				value: null,
				hasChanges: false,
			},
			{
				name: "HasValidation",
				label: "Validation",
				value: null,
				hasChanges: false,
			},
			{
				name: "AutoLaneDetection",
				label: "Auto Lane Detection",
				value: null,
				hasChanges: false,
			},
			{
				name: "OfflineOpening",
				label: "Offline Openings",
				value: null,
				hasChanges: false,
			},
			{
				name: "HasPaidParking",
				label: "Has Paid Parking",
				value: null,
				hasChanges: false,
			},
			{
				name: "ChildSiteNames",
				label: "Child Sites",
				value: null,
				hasChanges: false,
			},
			{
				name: "MaximumCapacity",
				label: "Total parking spaces",
				value: null,
				hasChanges: false,
			},
		],
	},
	UserInvited: {
		fields: [
			{ name: "email", label: "User Email", value: null, hasChanges: false },
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{
				name: "accessGroups",
				label: "Access Groups",
				value: null,
				hasChanges: false,
			},
			{
				name: "roleAndSiteNames",
				label: "Roles",
				value: null,
				hasChanges: false,
			},
			{
				name: "notifyUserByEmail",
				label: "Email Notification",
				value: null,
				hasChanges: false,
			},
		],
	},
	UnregisteredUserInvitationRemoved: {
		fields: [
			{
				name: "email",
				label: "Invitation Email",
				value: null,
				hasChanges: false,
			},
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
		],
	},
	UserInvitationRemoved: {
		fields: [
			{ name: "email", label: "User Email", value: null, hasChanges: false },
			{ name: "userId", label: "User ID", value: null, hasChanges: false },
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{
				name: "roleAndSiteNames",
				label: "Roles",
				value: null,
				hasChanges: false,
			},
		],
	},
	UserInvitationUpdated: {
		fields: [
			{ name: "email", label: "User Email", value: null, hasChanges: false },
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{
				name: "accessGroups",
				label: "Access Groups",
				value: null,
				hasChanges: false,
			},
			{
				name: "roleAndSiteNames",
				label: "Roles",
				value: null,
				hasChanges: false,
			},
			{
				name: "notifyUserByEmail",
				label: "Email Notification",
				value: null,
				hasChanges: false,
			},
		],
	},
	UserRoleUpdated: {
		fields: [
			{ name: "email", label: "User Email", value: null, hasChanges: false },
			{ name: "userId", label: "User ID", value: null, hasChanges: false },
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{ name: "role", label: "Roles", value: null, hasChanges: false },
			{
				name: "notifyUserByEmail",
				label: "Email Notification",
				value: null,
				hasChanges: false,
			},
		],
	},
	UserAccessGroupUpdated: {
		fields: [
			{ name: "email", label: "User Email", value: null, hasChanges: false },
			{ name: "userId", label: "User ID", value: null, hasChanges: false },
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{
				name: "groupNames",
				label: "Group Names",
				value: null,
				hasChanges: false,
			},
			{
				name: "notifyUserByEmail",
				label: "Email Notification",
				value: null,
				hasChanges: false,
			},
		],
	},
	UsersImportedFromCSV: {
		fields: [
			{ name: "emails", label: "User Emails", value: null, hasChanges: false },
			{
				name: "organizationId",
				label: "Organization ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "accessGroupNames",
				label: "Group Names",
				value: null,
				hasChanges: false,
			},
			{
				name: "sendEmail",
				label: "Email Notification",
				value: null,
				hasChanges: false,
			},
		],
	},
	SiteCreated: {
		fields: [
			{ name: "Name", label: "Name", value: null, hasChanges: false },
			{ name: "SiteType", label: "Site Type", value: null, hasChanges: false },
			{
				name: "Description",
				label: "Description",
				value: null,
				hasChanges: false,
			},
			{ name: "Address", label: "Address", value: null, hasChanges: false },
			{ name: "Latitude", label: "Latitude", value: null, hasChanges: false },
			{ name: "Longitude", label: "Longitude", value: null, hasChanges: false },
			{
				name: "StreetViewRotation",
				label: "Street View Rotation",
				value: null,
				hasChanges: false,
			},
			{
				name: "HasPublicAccess",
				label: "Public Access",
				value: null,
				hasChanges: false,
			},
			{
				name: "HasSessionlessAccess",
				label: "Sessionless Access",
				value: null,
				hasChanges: false,
			},
			{ name: "Timezone", label: "Timezone", value: null, hasChanges: false },
			{
				name: "HasCasualParking",
				label: "Casual Parking",
				value: null,
				hasChanges: false,
			},
			{
				name: "HasLeaseParking",
				label: "Lease Parking",
				value: null,
				hasChanges: false,
			},
			{
				name: "EnquiryEmail",
				label: "Enquiry Email",
				value: null,
				hasChanges: false,
			},
			{
				name: "EnquiryPhone",
				label: "Enquiry Phone",
				value: null,
				hasChanges: false,
			},
			{
				name: "TaxRateCountryCode",
				label: "Tax Type",
				value: null,
				hasChanges: false,
			},
			{ name: "Currency", label: "Currency", value: null, hasChanges: false },
			{
				name: "openingHours",
				label: "Opening Hours",
				value: null,
				hasChanges: false,
			},
			{
				name: "MaximumCapacity",
				label: "Total parking spaces",
				value: null,
				hasChanges: false,
			},
		],
	},
	BillingAccountRemoved: {
		fields: [
			{ name: "Email", label: "Email", value: null, hasChanges: false },
			{
				name: "ExternalAccountID",
				label: "External Account ID",
				value: null,
				hasChanges: false,
			},
		],
	},
	BillingAccountAdded: {
		fields: [
			{ name: "Email", label: "Email", value: null, hasChanges: false },
			{
				name: "ExternalAccountID",
				label: "External Account ID",
				value: null,
				hasChanges: false,
			},
		],
	},
	BillingDetailsUpdated: {
		fields: [
			{
				name: "CompanyName",
				label: "Company Name",
				value: null,
				hasChanges: false,
			},
			{
				name: "TaxRegistration",
				label: "Tax Registration",
				value: null,
				hasChanges: false,
			},
			{
				name: "BillingAddress",
				label: "Billing Address",
				value: null,
				hasChanges: false,
			},
		],
	},
	ApplicationFeesUpdated: {
		fields: [
			{
				name: "internalFeeAmounts",
				label: "Convenience Fees",
				value: null,
				hasChanges: false,
			},
			{
				name: "operatorFeeAmount",
				label: "Operator Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "internalPercent",
				label: "Inugo Percentage",
				value: null,
				hasChanges: false,
			},
		],
	},
	PaymentExpressAccountUpdated: {
		fields: [
			{
				name: "PXPostUsername",
				label: "Payment Express User Name",
				value: null,
				hasChanges: false,
			},
			{
				name: "PXPostSecretKey",
				label: "Payment Express Secret Key",
				value: null,
				hasChanges: false,
			},
		],
	},
	PaymentProviderChanged: {
		fields: [
			{
				name: "PaymentProvider",
				label: "Payment Provider",
				value: null,
				hasChanges: false,
			},
		],
	},
	ParkingSessionManuallyStarted: {
		fields: [
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{ name: "email", label: "User Email", value: null, hasChanges: false },
			{
				name: "numberplate",
				label: "Numberplate",
				value: null,
				hasChanges: false,
			},
		],
	},
	ParkingSessionManuallyEnded: {
		fields: [
			{
				name: "parkingSessionId",
				label: "Parking Session ID",
				value: null,
				hasChanges: false,
			},
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{ name: "email", label: "User Email", value: null, hasChanges: false },
			{
				name: "numberplate",
				label: "Numberplate",
				value: null,
				hasChanges: false,
			},
		],
	},
	ParkingSessionForceClosed: {
		fields: [
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{ name: "email", label: "User Email", value: null, hasChanges: false },
			{
				name: "numberplate",
				label: "Numberplate",
				value: null,
				hasChanges: false,
			},
		],
	},
	GateManuallyOpened: {
		fields: [
			{ name: "siteId", label: "Site ID", value: null, hasChanges: false },
			{ name: "nodeId", label: "Node ID", value: null, hasChanges: false },
			{ name: "reason", label: "Reason", value: null, hasChanges: false },
		],
	},
	UserLeaseManuallyAdded: {
		fields: [
			{
				name: "registeredUser",
				label: "Registered User",
				value: null,
				hasChanges: false,
			},
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{ name: "email", label: "User Email", value: null, hasChanges: false },
			{
				name: "numberplate",
				label: "Numberplate",
				value: null,
				hasChanges: false,
			},
			{
				name: "startDate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "endDate", label: "End Date", value: null, hasChanges: false },
			{
				name: "notifyUserByEmail",
				label: "Email Notification",
				value: null,
				hasChanges: false,
			},
			{ name: "fee", label: "Payment Amount", value: null, hasChanges: false },
			{ name: "isPaid", label: "Paid", value: null, hasChanges: false },
		],
	},
	UserLeaseCancelled: {
		fields: [
			{
				name: "registeredUser",
				label: "Registered User",
				value: null,
				hasChanges: false,
			},
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{ name: "email", label: "User Email", value: null, hasChanges: false },
			{
				name: "numberplate",
				label: "Numberplate",
				value: null,
				hasChanges: false,
			},
			{
				name: "startDate",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{ name: "endDate", label: "End Date", value: null, hasChanges: false },
			{ name: "fee", label: "Payment Amount", value: null, hasChanges: false },
		],
	},
	ShortTermParkingSpacesUpdated: {
		fields: [
			{
				name: "MaximumCapacity",
				label: "Spaces",
				value: null,
				hasChanges: false,
			},
			{
				name: "ParkingSessionCountOffset",
				label: "Initial Occupancy",
				value: null,
				hasChanges: false,
			},
		],
	},
	LongTermParkingSpaces: {
		fields: [
			{ name: "Name", label: "Name", value: null, hasChanges: false },
			{ name: "Spaces", label: "Spaces", value: null, hasChanges: false },
			{
				name: "LeaseRateID",
				label: "Lease Rate ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "leaseRateName",
				label: "Lease Rate Name",
				value: null,
				hasChanges: false,
			},
			{
				name: "startTime",
				label: "Start Time",
				value: null,
				hasChanges: false,
			},
			{ name: "endTime", label: "End Time", value: null, hasChanges: false },
		],
	},
	MaximumChargePerPeriodUpdated: {
		fields: [
			{
				name: "GracePeriod",
				label: "Grace Period (Minutes)",
				value: null,
				hasChanges: false,
			},
			{
				name: "FeeCapType",
				label: "Maximum rate period",
				value: null,
				hasChanges: false,
			},
			{
				name: "FeeCapAmount",
				label: "Maximum amount per period",
				value: null,
				hasChanges: false,
			},
			{
				name: "rates",
				label: "Nominated Rates",
				value: null,
				hasChanges: false,
			},
		],
	},
	OpeningHoursUpdated: {
		fields: [
			{
				name: "openingHours",
				label: "Opening Hours",
				value: null,
				hasChanges: false,
			},
			{
				name: "customDate",
				label: "Custom Date",
				value: null,
				hasChanges: false,
			},
		],
	},
	SpecialInstruction: {
		fields: [
			{ name: "Type", label: "Type", value: null, hasChanges: false },
			{ name: "Title", label: "Title", value: null, hasChanges: false },
			{
				name: "Instruction",
				label: "Instruction",
				value: null,
				hasChanges: false,
			},
			{
				name: "groupNames",
				label: "Group Names",
				value: null,
				hasChanges: false,
			},
			{
				name: "order",
				label: "Titles by Order",
				value: null,
				hasChanges: false,
			},
		],
	},
	TransactionReportCSVExported: {
		fields: [
			{
				name: "sites",
				label: "Sites",
				value: null,
				hasChanges: false,
			},
			{
				name: "sort",
				label: "Sort Order",
				value: null,
				hasChanges: false,
			},
			{
				name: "columns",
				label: "Columns",
				value: null,
				hasChanges: false,
			},
			{
				name: "start",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{
				name: "end",
				label: "End Date",
				value: null,
				hasChanges: false,
			},
		],
	},
	UserRefundResendInvoice: {
		fields: [
			{ name: "fee", label: "Invoice Amount", value: null, hasChanges: false },
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{
				name: "userEmail",
				label: "User Email",
				value: null,
				hasChanges: false,
			},
			{
				name: "transactionId",
				label: "Transaction ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "userLeaseId",
				label: "Receipt ID",
				value: null,
				hasChanges: false,
			},
		],
	},
	UserGroupCreated: {
		fields: [
			{
				name: "groupName",
				label: "Group Name",
				value: null,
				hasChanges: false,
			},
			/*{
				name: "specialBehavior",
				label: "Special Behavior",
				value: null,
				hasChanges: false
			},*/
			{
				name: "sitesInGroup",
				label: "Sites",
				value: null,
				hasChanges: false,
			},
			{
				name: "leaseRatesInGroup",
				label: "Booking Rates",
				value: null,
				hasChanges: false,
			},
			{
				name: "forceSessionlessAccessOnSites",
				label: "Sessionless Access",
				value: null,
				hasChanges: false,
			},
			{
				name: "nodesInGroup",
				label: "Gates with Location Based Access",
				value: null,
				hasChanges: false,
			},
		],
	},
	UserGroupDeleted: {
		fields: [
			{
				name: "groupName",
				label: "Group Name",
				value: null,
				hasChanges: false,
			},
			/*{
				name: "specialBehavior",
				label: "Special Behavior",
				value: null,
				hasChanges: false
			},*/
			{
				name: "sitesInGroup",
				label: "Sites",
				value: null,
				hasChanges: false,
			},
			{
				name: "leaseRatesInGroup",
				label: "Booking Rates",
				value: null,
				hasChanges: false,
			},
			{
				name: "forceSessionlessAccessOnSites",
				label: "Sessionless Access",
				value: null,
				hasChanges: false,
			},
			{
				name: "nodesInGroup",
				label: "Gates with Location Based Access",
				value: null,
				hasChanges: false,
			},
		],
	},
	UserGroupEdited: {
		fields: [
			{
				name: "groupName",
				label: "Group Name",
				value: null,
				hasChanges: false,
			},
			/*{
				name: "specialBehavior",
				label: "Special Behavior",
				value: null,
				hasChanges: false
			},*/
			{
				name: "sitesInGroup",
				label: "Sites",
				value: null,
				hasChanges: false,
			},
			{
				name: "leaseRatesInGroup",
				label: "Booking Rates",
				value: null,
				hasChanges: false,
			},
			{
				name: "forceSessionlessAccessOnSites",
				label: "Sessionless Access",
				value: null,
				hasChanges: false,
			},
			{
				name: "nodesInGroup",
				label: "Gates with Location Based Access",
				value: null,
				hasChanges: false,
			},
		],
	},
	CustomDateDeleted: {
		fields: [
			{
				name: "customDate",
				label: "Custom Date",
				value: null,
				hasChanges: false,
			},
		],
	},
	CustomDateCreated: {
		fields: [
			{
				name: "customDate",
				label: "Custom Date",
				value: null,
				hasChanges: false,
			},
		],
	},
	GateManuallyClosed: {
		fields: [
			{ name: "siteId", label: "Site ID", value: null, hasChanges: false },
			{ name: "nodeId", label: "Node ID", value: null, hasChanges: false },
		],
	},
	RetryFailedPayment: {
		fields: [
			{ name: "userName", label: "Full Name", value: null, hasChanges: false },
			{ name: "userEmail", label: "Email", value: null, hasChanges: false },
			{
				name: "transactionId",
				label: "Transaction ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "paymentAmount",
				label: "Payment Amount",
				value: null,
				hasChanges: false,
			},
			{
				name: "paymentSucceeded",
				label: "Payment Succeeded",
				value: null,
				hasChanges: false,
			},
		],
	},
	NewValidationBeaconAdded: {
		fields: [
			{ name: "UniqueID", label: "Unique ID", value: null, hasChanges: false },
			{
				name: "BeaconDeviceID",
				label: "Beacon Device ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "BeaconType",
				label: "Beacon Type",
				value: null,
				hasChanges: false,
			},
			{
				name: "BeaconLocation",
				label: "Beacon Location",
				value: null,
				hasChanges: false,
			},
			{
				name: "ValidationType",
				label: "Validation Type",
				value: null,
				hasChanges: false,
			},
			{ name: "Value", label: "Value", value: null, hasChanges: false },
			{
				name: "IsSelfValidation",
				label: "Is Self Validation",
				value: null,
				hasChanges: false,
			},
			{ name: "Sites", label: "Sites", value: null, hasChanges: false },
			{ name: "Rates", label: "Rates", value: null, hasChanges: false },
		],
	},
	ValidationBeaconUpdated: {
		fields: [
			{
				name: "BeaconLocation",
				label: "Beacon Location",
				value: null,
				hasChanges: false,
			},
			{
				name: "ValidationType",
				label: "Validation Type",
				value: null,
				hasChanges: false,
			},
			{
				name: "ValidationValue",
				label: "Validation Value",
				value: null,
				hasChanges: false,
			},
			{
				name: "IsSelfValidation",
				label: "Is Self Validation",
				value: null,
				hasChanges: false,
			},
			{ name: "Sites", label: "Sites", value: null, hasChanges: false },
			{ name: "Rates", label: "Rates", value: null, hasChanges: false },
		],
	},
	NewPromotionAdded: {
		fields: [
			{
				name: "Name",
				label: "Name",
				value: null,
				hasChanges: false,
			},
			{
				name: "PromotionCode",
				label: "Promotion Code",
				value: null,
				hasChanges: false,
			},
			{
				name: "PromotionType",
				label: "Promotion Type",
				value: null,
				hasChanges: false,
			},
			{
				name: "PromotionValue",
				label: "Promotion Value",
				value: null,
				hasChanges: false,
			},
			{
				name: "StartTimestamp",
				label: "Start Timestamp",
				value: null,
				hasChanges: false,
			},
			{
				name: "EndTimestamp",
				label: "End Timestamp",
				value: null,
				hasChanges: false,
			},
			{
				name: "CodeLimit",
				label: "Code Limit",
				value: null,
				hasChanges: false,
			},
			{
				name: "UserLimit",
				label: "User Limit",
				value: null,
				hasChanges: false,
			},
		],
	},
	PromotionUpdated: {
		fields: [
			{
				name: "Name",
				label: "Name",
				value: null,
				hasChanges: false,
			},
			{
				name: "PromotionCode",
				label: "Promotion Code",
				value: null,
				hasChanges: false,
			},
			{
				name: "PromotionType",
				label: "Promotion Type",
				value: null,
				hasChanges: false,
			},
			{
				name: "PromotionValue",
				label: "Promotion Value",
				value: null,
				hasChanges: false,
			},
			{
				name: "StartTimestamp",
				label: "Start Timestamp",
				value: null,
				hasChanges: false,
			},
			{
				name: "EndTimestamp",
				label: "End Timestamp",
				value: null,
				hasChanges: false,
			},
			{
				name: "CodeLimit",
				label: "Code Limit",
				value: null,
				hasChanges: false,
			},
			{
				name: "UserLimit",
				label: "User Limit",
				value: null,
				hasChanges: false,
			},
		],
	},
	GateControllerCreated: {
		fields: [
			{
				name: "serialNumber",
				label: "Serial Number",
				value: null,
				hasChanges: false,
			},
			{ name: "gateName", label: "Name", value: null, hasChanges: false },
			{
				name: "accessType",
				label: "Access Type",
				value: null,
				hasChanges: false,
			},
			{
				name: "communicationMethod",
				label: "Communication Method",
				value: null,
				hasChanges: false,
			},
			{ name: "group", label: "Group", value: null, hasChanges: false },
		],
	},
	GateControllerDeleted: {
		fields: [
			{
				name: "serialNumber",
				label: "Serial Number",
				value: null,
				hasChanges: false,
			},
			{ name: "gateName", label: "Name", value: null, hasChanges: false },
			{
				name: "accessType",
				label: "Access Type",
				value: null,
				hasChanges: false,
			},
			{
				name: "communicationMethod",
				label: "Communication Method",
				value: null,
				hasChanges: false,
			},
			{ name: "group", label: "Group", value: null, hasChanges: false },
		],
	},
	GateControllerUpdated: {
		fields: [
			{
				name: "serialNumber",
				label: "Serial Number",
				value: null,
				hasChanges: false,
			},
			{ name: "gateName", label: "Name", value: null, hasChanges: false },
			{
				name: "accessType",
				label: "Access Type",
				value: null,
				hasChanges: false,
			},
			{
				name: "communicationMethod",
				label: "Communication Method",
				value: null,
				hasChanges: false,
			},
			{ name: "group", label: "Group", value: null, hasChanges: false },
			{
				name: "isAdmin",
				label: "Operate By Admin User",
				value: null,
				hasChanges: false,
			},
			{ name: "sites", label: "Sites & Gates", value: null, hasChanges: false },
			{ name: "nodeOrders", label: "Order", value: null, hasChanges: false },
		],
	},
	GateControllerGroupUpdated: {
		fields: [
			{ name: "name", label: "Group Name", value: null, hasChanges: false },
		],
	},
	GateControllerGroupDeleted: {
		fields: [
			{ name: "name", label: "Group Name", value: null, hasChanges: false },
		],
	},
	GateControllerGroupCreated: {
		fields: [
			{ name: "name", label: "Group Name", value: null, hasChanges: false },
		],
	},
	ParkingSessionVoided: {
		fields: [
			{
				name: "parkingSessionId",
				label: "Parking Session ID",
				value: null,
				hasChanges: false,
			},
			{ name: "UserID", label: "User ID", value: null, hasChanges: false },
		],
	},
	UserLeaseCancellationVoided: {
		fields: [
			{
				name: "userLeaseId",
				label: "User Lease ID",
				value: null,
				hasChanges: false,
			},
			{ name: "UserID", label: "User ID", value: null, hasChanges: false },
		],
	},
	LeasePaymentVoided: {
		fields: [
			{
				name: "leasePaymentId",
				label: "Lease Payment ID",
				value: null,
				hasChanges: false,
			},
			{ name: "UserID", label: "User ID", value: null, hasChanges: false },
		],
	},
	HardwareUploaded: {
		fields: [
			{
				name: "SerialNumber",
				label: "Serial Number",
				value: null,
				hasChanges: false,
			},
			{
				name: "HardwareID",
				label: "Hardware ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "BLEAddress",
				label: "BLE Address",
				value: null,
				hasChanges: false,
			},
			{
				name: "SoftwareVersion",
				label: "Software Version",
				value: null,
				hasChanges: false,
			},
			{
				name: "SystemFirmware",
				label: "System Firmware",
				value: null,
				hasChanges: false,
			},
			{
				name: "ProductVersion",
				label: "Product Version",
				value: null,
				hasChanges: false,
			},
			{
				name: "BeaconAttached",
				label: "Beacon Attached",
				value: null,
				hasChanges: false,
			},
			{
				name: "BeaconHardwareID",
				label: "Beacon Hardware ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "BeaconBLEAddress",
				label: "Beacon BLE Address",
				value: null,
				hasChanges: false,
			},
			{
				name: "BeaconSoftwareVersion",
				label: "Beacon Software Version",
				value: null,
				hasChanges: false,
			},
			{
				name: "BeaconSystemFirmware",
				label: "Beacon System Firmware",
				value: null,
				hasChanges: false,
			},
			{
				name: "Beacon Product Version",
				label: "Beacon Product Version",
				value: null,
				hasChanges: false,
			},
			{
				name: "PreAuthEnabled",
				label: "Pre-auth Enabled",
				value: null,
				hasChanges: false,
			},
			{
				name: "OfflineOnly",
				label: "Offline Only",
				value: null,
				hasChanges: false,
			},
			{
				name: "Notes",
				label: "Notes",
				value: null,
				hasChanges: false,
			},
			{
				name: "Status",
				label: "Status",
				value: null,
				hasChanges: false,
			},
			{
				name: "Client",
				label: "Client",
				value: null,
				hasChanges: false,
			},
		],
	},
	AdminUserAdded: {
		fields: [
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{ name: "userEmail", label: "Email", value: null, hasChanges: false },
			{
				name: "isSuperAdmin",
				label: "Super Admin",
				value: null,
				hasChanges: false,
			},
			{
				name: "isCSAdmin",
				label: "Customer Service Admin",
				value: null,
				hasChanges: false,
			},
			{
				name: "adminDashboardEnabled",
				label: "Admin Dashboard Enabled",
				value: null,
				hasChanges: false,
			},
			{
				name: "featuresPreview",
				label: "Features Preview",
				value: null,
				hasChanges: false,
			},
			{
				name: "debugEnabled",
				label: "Debug Enabled",
				value: null,
				hasChanges: false,
			},
			{
				name: "notifyUserByEmail",
				label: "Notify By Email",
				value: null,
				hasChanges: false,
			},
		],
	},
	AdminUserUpdated: {
		fields: [
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{ name: "userEmail", label: "Email", value: null, hasChanges: false },
			{
				name: "isSuperAdmin",
				label: "Super Admin",
				value: null,
				hasChanges: false,
			},
			{
				name: "isCSAdmin",
				label: "Customer Service Admin",
				value: null,
				hasChanges: false,
			},
			{
				name: "adminDashboardEnabled",
				label: "Admin Dashboard Enabled",
				value: null,
				hasChanges: false,
			},
			{
				name: "featuresPreview",
				label: "Features Preview",
				value: null,
				hasChanges: false,
			},
			{
				name: "debugEnabled",
				label: "Debug Enabled",
				value: null,
				hasChanges: false,
			},
			{
				name: "notifyUserByEmail",
				label: "Notify By Email",
				value: null,
				hasChanges: false,
			},
		],
	},
	AdminUserRemoved: {
		fields: [
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{ name: "userEmail", label: "Email", value: null, hasChanges: false },
			{
				name: "isSuperAdmin",
				label: "Super Admin",
				value: null,
				hasChanges: false,
			},
			{
				name: "isCSAdmin",
				label: "Customer Service Admin",
				value: null,
				hasChanges: false,
			},
			{
				name: "adminDashboardEnabled",
				label: "Admin Dashboard Enabled",
				value: null,
				hasChanges: false,
			},
			{
				name: "featuresPreview",
				label: "Features Preview",
				value: null,
				hasChanges: false,
			},
		],
	},
	ClientAdminUserAdded: {
		fields: [
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{ name: "userEmail", label: "Email", value: null, hasChanges: false },
			{
				name: "clientName",
				label: "Client Name",
				value: null,
				hasChanges: false,
			},
		],
	},
	ClientAdminUserRemoved: {
		fields: [
			{ name: "userName", label: "User Name", value: null, hasChanges: false },
			{ name: "userEmail", label: "Email", value: null, hasChanges: false },
			{
				name: "clientName",
				label: "Client Name",
				value: null,
				hasChanges: false,
			},
		],
	},
	OrgBillingDetailsCreated: {
		fields: [
			{
				name: "CompanyName",
				label: "Company Name",
				value: null,
				hasChanges: false,
			},
			{
				name: "TaxRegistration",
				label: "Tax Registration",
				value: null,
				hasChanges: false,
			},
			{
				name: "BillingAddress",
				label: "Billing Address",
				value: null,
				hasChanges: false,
			},
			{
				name: "PaymentProvider",
				label: "Payment Provider",
				value: null,
				hasChanges: false,
			},
			{
				name: "BillingAccountID",
				label: "Stripe Billing Account ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "PXPostUsername",
				label: "Payment Express Username",
				value: null,
				hasChanges: false,
			},
			{
				name: "PXPostSecretKey",
				label: "Payment Express Password",
				value: null,
				hasChanges: false,
			},
			{
				name: "MerchantAccountSettlement",
				label: "Merchant Account Settlement",
				value: null,
				hasChanges: false,
			},
			{
				name: "ConvenienceFee",
				label: "Convenience Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "ConvenienceFeePaidBy",
				label: "Convenience Fee Paid By",
				value: null,
				hasChanges: false,
			},
			{
				name: "PaymentProcessingFixedFee",
				label: "Payment Processing Fixed Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "PaymentProcessingPercentageFee",
				label: "Payment Processing Percentage Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "UserPaysCreditCardFee",
				label: "User Pays Credit Card Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "Sites",
				label: "Sites",
				value: null,
				hasChanges: false,
			},
		],
	},
	OrgBillingDetailsUpdated: {
		fields: [
			{
				name: "CompanyName",
				label: "Company Name",
				value: null,
				hasChanges: false,
			},
			{
				name: "TaxRegistration",
				label: "Tax Registration",
				value: null,
				hasChanges: false,
			},
			{
				name: "BillingAddress",
				label: "Billing Address",
				value: null,
				hasChanges: false,
			},
			{
				name: "PaymentProvider",
				label: "Payment Provider",
				value: null,
				hasChanges: false,
			},
			{
				name: "BillingAccountID",
				label: "Stripe Billing Account ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "PXPostUsername",
				label: "Payment Express Username",
				value: null,
				hasChanges: false,
			},
			{
				name: "PXPostSecretKey",
				label: "Payment Express Password",
				value: null,
				hasChanges: false,
			},
			{
				name: "MerchantAccountSettlement",
				label: "Merchant Account Settlement",
				value: null,
				hasChanges: false,
			},
			{
				name: "ConvenienceFee",
				label: "Convenience Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "ConvenienceFeePaidBy",
				label: "Convenience Fee Paid By",
				value: null,
				hasChanges: false,
			},
			{
				name: "PaymentProcessingFixedFee",
				label: "Payment Processing Fixed Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "PaymentProcessingPercentageFee",
				label: "Payment Processing Percentage Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "UserPaysCreditCardFee",
				label: "User Pays Credit Card Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "Sites",
				label: "Sites",
				value: null,
				hasChanges: false,
			},
		],
	},
	OrgBillingDetailsDeleted: {
		fields: [
			{
				name: "CompanyName",
				label: "Company Name",
				value: null,
				hasChanges: false,
			},
			{
				name: "TaxRegistration",
				label: "Tax Registration",
				value: null,
				hasChanges: false,
			},
			{
				name: "BillingAddress",
				label: "Billing Address",
				value: null,
				hasChanges: false,
			},
			{
				name: "PaymentProvider",
				label: "Payment Provider",
				value: null,
				hasChanges: false,
			},
			{
				name: "BillingAccountID",
				label: "Stripe Billing Account ID",
				value: null,
				hasChanges: false,
			},
			{
				name: "PXPostUsername",
				label: "Payment Express Username",
				value: null,
				hasChanges: false,
			},
			{
				name: "PXPostSecretKey",
				label: "Payment Express Password",
				value: null,
				hasChanges: false,
			},
			{
				name: "MerchantAccountSettlement",
				label: "Merchant Account Settlement",
				value: null,
				hasChanges: false,
			},
			{
				name: "ConvenienceFee",
				label: "Convenience Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "ConvenienceFeePaidBy",
				label: "Convenience Fee Paid By",
				value: null,
				hasChanges: false,
			},
			{
				name: "PaymentProcessingFixedFee",
				label: "Payment Processing Fixed Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "PaymentProcessingPercentageFee",
				label: "Payment Processing Percentage Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "UserPaysCreditCardFee",
				label: "User Pays Credit Card Fee",
				value: null,
				hasChanges: false,
			},
			{
				name: "Sites",
				label: "Sites",
				value: null,
				hasChanges: false,
			},
		],
	},
	HistoryReportCsvExported: {
		fields: [
			{
				name: "sites",
				label: "Sites",
				value: null,
				hasChanges: false,
			},
			{
				name: "sort",
				label: "Sort Order",
				value: null,
				hasChanges: false,
			},
			{
				name: "columns",
				label: "Columns",
				value: null,
				hasChanges: false,
			},
			{
				name: "start",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{
				name: "end",
				label: "End Date",
				value: null,
				hasChanges: false,
			},
		],
	},
	PermittedReportCSVExported: {
		fields: [
			{
				name: "sites",
				label: "Sites",
				value: null,
				hasChanges: false,
			},
			{
				name: "sort",
				label: "Sort Order",
				value: null,
				hasChanges: false,
			},
			{
				name: "columns",
				label: "Columns",
				value: null,
				hasChanges: false,
			},
			{
				name: "start",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{
				name: "end",
				label: "End Date",
				value: null,
				hasChanges: false,
			},
		],
	},
	ActivityReportCSVExported: {
		fields: [
			{
				name: "sites",
				label: "Sites",
				value: null,
				hasChanges: false,
			},
			{
				name: "sort",
				label: "Sort Order",
				value: null,
				hasChanges: false,
			},
			{
				name: "columns",
				label: "Columns",
				value: null,
				hasChanges: false,
			},
			{
				name: "start",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{
				name: "end",
				label: "End Date",
				value: null,
				hasChanges: false,
			},
		],
	},
	UsersReportCSVExported: {
		fields: [
			{
				name: "sites",
				label: "Sites",
				value: null,
				hasChanges: false,
			},
			{
				name: "sort",
				label: "Sort Order",
				value: null,
				hasChanges: false,
			},
			{
				name: "columns",
				label: "Columns",
				value: null,
				hasChanges: false,
			},
			{
				name: "start",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{
				name: "end",
				label: "End Date",
				value: null,
				hasChanges: false,
			},
		],
	},
	UnpermittedReportCSVExported: {
		fields: [
			{
				name: "sites",
				label: "Sites",
				value: null,
				hasChanges: false,
			},
			{
				name: "sort",
				label: "Sort Order",
				value: null,
				hasChanges: false,
			},
			{
				name: "columns",
				label: "Columns",
				value: null,
				hasChanges: false,
			},
			{
				name: "start",
				label: "Start Date",
				value: null,
				hasChanges: false,
			},
			{
				name: "end",
				label: "End Date",
				value: null,
				hasChanges: false,
			},
		],
	},
};
