import React, { useState } from "react";
import { fetchDataCallback, paginatedState } from "../../helpers/pagination";
import { filter, map } from "lodash";
import { usePermissions, useQueryData } from "../../hooks";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import DropdownMenuBeta from "../../components/layout/DropdownMenuBeta";
import FlexWrapper from "../../components/layout/FlexWrapper";
import { MoreHorizontal } from "react-feather";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import SessionWizard from "../../components/wizards/session-wizard/SessionWizard";
import StatCard from "../../components/layout/StatCard";
import UserDetails from "../../components/details-pages/users/UserDetails";
import gql from "graphql-tag";
import moment from "moment";

const columns = (props) => [
	{
		id: "Name",
		Header: "Name",
		accessor: "Name",
		highlightCell: (cellProps) => (
			<>
				<strong>{cellProps.row.original.Name}</strong>
			</>
		),
	},
	{
		id: "Email",
		Header: "Email",
		accessor: "Email",
		highlightCell: (cellProps) => cellProps.row.original.Email,
	},
	{
		id: "Numberplate",
		Header: "Last Used Plate",
		accessor: "Numberplate",
		highlightCell: (cellProps) => cellProps.row.original.Numberplate || "",
		width: 120,
		disableSortBy: true,
	},
	{
		id: "LastSiteParked",
		Header: "Last Site Parked",
		accessor: "LastSiteParked",
		highlightCell: (cellProps) => cellProps.row.original.LastSiteParked || "",
		disableSortBy: true,
	},
	{
		id: "LastDateParked",
		Header: "Last Date Parked",
		accessor: "LastParkTimestamp",
		Cell: (cellProps) => cellProps.row.original.LastParkFormatted,
		disableSortBy: true,
	},
	{
		id: "LastUsedClientCode",
		Header: "Last Used Client Code",
		accessor: "LastUsedClientCode",
	},
	{
		id: "LastUsedAppVersion",
		Header: "Last Used App Version",
		accessor: "LastUsedAppVersion",
	},
	{
		id: "NumberOfSessions",
		Header: "Number of Sessions",
		accessor: "SessionsCount",
		width: 120,
		disableSortBy: true,
	},
	{
		id: "NumberOfLeases",
		Header: "Number of Leases",
		accessor: "LeasesCount",
		width: 120,
		disableSortBy: true,
	},
	{
		id: "NumberOfInteractions",
		Header: "Number of Interactions",
		accessor: "InteractionsCount",
		width: 120,
		disableSortBy: true,
	},
	// {
	// 	id: "TotalCharge",
	// 	Header: "Total Charged",
	// 	accessor: "TotalCharged",
	// 	Cell: (cellProps) => format.money(cellProps.row.original.TotalCharged),
	// 	width: 120,
	// 	disableSortBy: true,
	// },
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="userDetails"
						onClick={() => props.openUserDetails(cellProps.row.original)}
					>
						More Info
					</div>,
				]}
			/>
		),
		resizable: false,
		fixedWidth: 50,
		sortable: false,
	},
];

const csvHeaders = () => {
	let _csvHeaders = map(columns(), (c) => {
		let key = c.accessor;
		if (c.accessor === "LastParkTimestamp") key = "LastParkFormatted";

		return {
			label: c.Header,
			key: key,
		};
	});

	// remove tasks column
	_csvHeaders.pop();

	return _csvHeaders;
};

const defaultSorting = [
	{
		id: "Name",
		desc: false,
	},
];

export default function UsersContainer(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			sites: [],
			"start-date-time": null,
			"end-date-time": null,
			search: "",
		},
		sessionAdminWizardOpen: false,
		userDetailsOpen: false,
	});

	const [paginate, setPaginate] = useState(paginatedState);
	const fetchPaginatedData = fetchDataCallback(setPaginate);

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	const skipQuery =
		!state.options["start-date-time"] ||
		!state.options["end-date-time"] ||
		!state.options.sites ||
		!paginate.pageSize ||
		!paginate.sortBy ||
		!paginate.sortOrder ||
		!organizationId;

	const {
		data: { getCustomerList: customerConnection },
		isLoading,
	} = useQueryData(
		gql`
			query (
				$organizationId: Int!
				$siteIds: [Int!]!
				$startTimestamp: Timestamp!
				$endTimestamp: Timestamp!
				$page: PageInfo!
				$cursor: Int
			) {
				getCustomerList(
					organizationId: $organizationId
					siteIds: $siteIds
					startTimestamp: $startTimestamp
					endTimestamp: $endTimestamp
					page: $page
					cursor: $cursor
				) {
					trueLength
					previousEvent
					users {
						UserID
						Email
						Name
						LastUsedAppVersion
						LastUsedClientCode
						LastParkTimestamp
						LastSiteParked
						Numberplate
						LeasesCount
						SessionsCount
						InteractionsCount
						TotalCharged
						RankingScore
					}
					statistics {
						totalCharged
						newUsersCount
						sessionCount
						leaseCount
					}
				}
			}
		`,
		{
			organizationId,
			siteIds: state.options.sites,
			startTimestamp: moment(state.options["start-date-time"])
				.startOf("day")
				.unix(),
			endTimestamp: moment(state.options["end-date-time"]).endOf("day").unix(),
			page: {
				next: paginate.next,
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		},
		skipQuery
	);

	const trueLength = customerConnection?.trueLength || 0;
	const users =
		customerConnection?.users?.map((u) => ({
			...u,
			LastParkFormatted:
				(u.LastParkTimestamp || "") &&
				moment(u.LastParkTimestamp, "X").format("HH:mm on DD MMM YYYY"),
		})) || [];
	const statistics = customerConnection?.statistics;

	// only download the current page for now.
	async function getDownloadableData() {
		return [...users];
	}

	const canManageSessions = usePermissions(null, "SessionAdmin", true);

	async function updateOptions(options) {
		setState((_state) => ({ ..._state, initialLoad: false, options }));
	}

	function openUserDetails(user) {
		setState((_state) => ({
			..._state,
			userDetailsOpen: true,
			userDetailsUser: user,
		}));
	}

	if (state.userDetailsOpen) {
		return (
			<UserDetails
				close={() => {
					setState((_state) => ({ ..._state, userDetailsOpen: false }));
				}}
				user={state.userDetailsUser}
				availableSites={props.availableSites}
				organizationId={props.selectedOrganization.OrganizationID}
			/>
		);
	}

	function openSessionWizard(mode) {
		setState((_state) => ({
			..._state,
			sessionAdminWizardOpen: true,
			sessionAdminWizardMode: mode,
		}));
	}

	if (state.sessionAdminWizardOpen) {
		return (
			<SessionWizard
				close={() => {
					setState((_state) => ({ ..._state, sessionAdminWizardOpen: false }));
				}}
				mode={state.sessionAdminWizardMode}
				sites={filter(
					props.availableSites,
					(site) => site.SiteType === "Gated"
				)}
			/>
		);
	}

	return (
		<div>
			<ReportWrapperBeta
				{...props}
				title="Users"
				data={users}
				columns={columns({ openUserDetails: openUserDetails })}
				csvHeaders={csvHeaders()}
				csvName={"inugo-users-report.csv"}
				loading={false}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				rightActions={
					canManageSessions ? (
						<Button
							style={{ marginLeft: "10px" }}
							color="blue"
							onClick={() => openSessionWizard("open-gate")}
						>
							Open Gate
						</Button>
					) : null
				}
				dataTrueLength={trueLength}
				cursorColumn="UserID"
				paginationIsLoading={isLoading || skipQuery || props.isLoading}
				pageSize={state.pageSize}
				previousEvent={customerConnection?.previousEvent}
				searchTokens={paginate.searchTokens}
				getDownloadableData={getDownloadableData}
				fetchPaginatedData={fetchPaginatedData}
				stats={
					<FlexWrapper style={{ marginBottom: -16 }}>
						<Card style={{ flex: 0.3 }}>
							<StatCard
								value={trueLength || 0}
								title="Total Customers"
								size="medium"
							/>
						</Card>
						<Card style={{ flex: 0.3 }}>
							<StatCard
								value={statistics?.newUsersCount || 0}
								title="New Customers"
								subTitle="Within Selected Dates"
								size="medium"
							/>
						</Card>
						<Card style={{ flex: 0.3 }}>
							<StatCard
								value={statistics?.sessionCount || 0}
								title="Total Sessions"
								subTitle="Within Selected Dates"
								size="medium"
							/>
						</Card>
						<Card style={{ flex: 0.3 }}>
							<StatCard
								value={statistics?.leaseCount || 0}
								title="Total Leases"
								subTitle="Within Selected Dates"
								size="medium"
							/>
						</Card>
						{/* <Card style={{ flex: 0.3 }}>
							<StatCard
								value={format.money(statistics?.totalCharged)}
								title="Total Charged"
								subTitle="Within Selected Dates"
								size="medium"
							/>
						</Card> */}
					</FlexWrapper>
				}
			/>
		</div>
	);
}
