import React, { useEffect } from "react";
import { boxShadows, colours } from "../../styles";
import styled from "styled-components";

const Item = styled.button`
	background-color: ${colours.lightGrey};
	border: 0;
	border-radius: 4px;
	box-shadow: ${boxShadows.light};
	color: ${colours.darkGrey};
	font-size: 18px;
	font-weight: 600;
	height: 40px;
	line-height: 24px;
	padding: 8px 32px;
	position: relative;
	text-align: center;
	transition: 0.2s ease;

	&.blue {
		background-color: ${colours.blue};
		color: ${colours.white};
	}

	&.green {
		background-color: ${colours.green};
		color: ${colours.white};
	}

	&.red {
		background-color: ${colours.red};
		color: ${colours.white};
	}

	&.yellow {
		background-color: ${colours.yellow};
		color: ${colours.white};
	}

	&.secureBlue {
		background-color: ${colours.secureBlue};
		color: ${colours.white};
	}

	&.sippiPurple {
		background-color: ${colours.sippiPurple};
		color: ${colours.white};
	}

	&.black {
		background-color: ${colours.black};
		color: ${colours.white};
	}

	&:hover {
		cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
		opacity: ${(props) => (props.disabled ? "1" : "0.8")};
	}

	&:focus {
		outline: none;
	}
`;

export default function Button(props) {
	useEffect(() => {
		if (props.keyStrokeHandler) {
			const { keyNext, setKeyNext } = props.keyStrokeHandler;
			if (keyNext) {
				setKeyNext(false);
				if (!props.disabled) props.onClick();
			}
		}
	}, [props.keyStrokeHandler]);

	return (
		<Item
			type="button"
			{...props}
			className={`${props.className || ""}
			${props.disabled ? "" : props.color}`}
		/>
	);
}
