import * as Yup from "yup";
import { MoreHorizontal, Plus } from "react-feather";
import React, { Fragment, useContext, useState } from "react";
import TableLayout, {
	booleanValueFormatter,
	booleanValueFormatterColour,
} from "../../components/layout/TableLayout";
import { cloneDeep, map, omit } from "lodash";
import { constants, format } from "../../helpers";
import {
	useHasClientAdmin,
	useMutateData,
	usePermissions,
	useQueryData,
} from "../../hooks";
import Accordion from "../../components/layout/Accordion";
import Alert from "react-s-alert";
import { AppContext } from "../../context/app-context";
import BeaconWizard from "../../components/wizards/beacon-wizard/BeaconWizard";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import Dropdown from "../../components/layout/Dropdown";
import DropdownMenu from "../../components/layout/DropdownMenu";
import EditableInputField from "../../components/layout/EditableInputField";
import FlexWrapper from "../../components/layout/FlexWrapper";
import FormLayout from "../../components/layout/FormLayout";
import InstallationWizard from "../../components/wizards/installation-wizard";
import PayStationWizard from "../../components/wizards/pay-station-wizard";
import StatCard from "../../components/layout/StatCard";
import Toggle from "../../components/layout/Toggle";
import { colours } from "../../styles";
import gql from "graphql-tag";
import styled from "styled-components";

const FieldWrapper = styled.div`
	margin: 16px 32px;
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;

const FieldContent = styled.div`
	> * {
		margin: 0;
		max-width: 600px;
		min-height: 32px;
	}
`;

const Wrapper = styled.div`
	margin: 16px 32px;
	position: relative;
`;

const ButtonGroup = styled.div`
	margin-bottom: 10px;
	display: flex;
	flex-direction: row-reverse;
`;

const LaneHeader = styled.div`
	background-color: ${colours.offWhite};
	border-radius: 4px;
	margin: 32px 0;
	padding: 16px;
`;

const PayStationHeader = styled.div`
	background-color: ${colours.offWhite};
	border-radius: 4px;
	margin: 32px 0;
	padding: 16px;
`;

const EmptyGroup = styled.div`
	font-size: 18px;
	text-align: center;
`;

const Gate = styled.div`
	background-color: ${colours.white};
	border-radius: 4px;
	margin: 32px 0;
	padding: 16px;
`;

const KioskWrapper = styled.div`
	background-color: ${colours.white};
	border-radius: 4px;
	margin: 32px 0;
	padding: 16px;
`;

const Title = styled.h3`
	margin: 0;
`;

const GroupHeading = styled.div`
	display: flex;
	border-bottom-style: solid;
	justify-content: space-between;
`;

const GateTitle = styled.h3`
	margin: 0;
`;

const KioskTitle = styled.h3`
	margin: 0;
`;

const BeaconsTitle = styled.h3`
	margin-top: 20px;
`;

const RightMenu = styled.div`
	margin-top: 25px;
	right: 32px;
	position: absolute;
`;

const NoInstallation = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin: 64px 0;
	text-align: center;
`;

const RightButtonWrapper = styled.div`
	margin-right: 10px;
	margin-top: 20px;
	float: right;
`;

const LanesWrapper = styled.div`
	margin-top: 80px;
`;

const PayStationsWrapper = styled.div`
	margin-top: 80px;
`;

const beaconColumns = (props) => [
	{
		Header: "Unique ID",
		id: "UniqueID",
		accessor: (d) => d.UniqueID,
	},
	{
		Header: "Beacon Device ID",
		id: "BeaconDeviceID",
		accessor: (d) => d.BeaconDeviceID,
	},
	{
		Header: "MAC Address",
		id: "MacAddress",
		accessor: (d) => d.MacAddress,
	},
	{
		Header: "Status",
		id: "Status",
		accessor: (d) => d.Status,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) =>
			props.siteOwnsInstallation ? (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="delete-beacon"
							onClick={() =>
								props.openBeaconWizard(
									props.group,
									props.lane,
									props.node,
									cellProps.original,
									"delete-beacon"
								)
							}
						>
							Remove Beacon
						</div>,
					]}
				/>
			) : null,
		resizable: false,
		width: 50,
	},
];

const gateColumns = (props) =>
	[
		{
			Header: "Enabled",
			id: "Enabled",
			width: 80,
			accessor: (d) =>
				d.Status === constants.NODE_STATUSES.INSTALLED ? d.IsEnabled : false,
			Cell: (cellProps) => booleanValueFormatterColour(cellProps),
		},
		{
			id: "IsOnline",
			Header: "Online",
			accessor: (d) => d.IsOnline,
			Cell: (cellProps) => (
				<div
					className={cellProps.original.IsOnline ? "led-cyan" : "led-white"}
				></div>
			),
			width: 80,
		},
		{
			Header: "Serial Number",
			id: "SerialNumber",
			accessor: (d) => d.SerialNumber,
		},
		{
			id: "ProductName",
			Header: "Product",
			accessor: (d) => d.ProductName || "",
		},
		{
			Header: "Name",
			id: "Name",
			width: 80,
			accessor: (d) => d.Name,
		},
		{
			Header: "Type",
			id: "Type",
			width: 80,
			accessor: (d) =>
				d.AccessType ? d.AccessType.split(/(?=[A-Z])/).join(" ") : "",
		},
		{
			Header: "Comm. Method",
			id: "CommunicationMethod",
			accessor: (d) =>
				d.CommunicationMethod
					? d.CommunicationMethod.split(/(?=[A-Z])/).join(" ")
					: "",
		},
		{
			Header: "Boat Ramp",
			id: "IsBoatRamp",
			width: 80,
			accessor: (d) => d.IsBoatRamp,
			Cell: booleanValueFormatter,
		},
		{
			Header: "Show Access Button",
			id: "ShowAccessButton",
			width: 80,
			accessor: (d) => d.ShowAccessButton,
			Cell: booleanValueFormatter,
		},
		{
			Header: "Pre-auth Enabled",
			id: "PreAuthEnabled",
			width: 80,
			accessor: (d) =>
				d.Status === constants.NODE_STATUSES.INSTALLED
					? d.PreAuthEnabled
						? "Yes"
						: "No"
					: "",
			Cell: (cellProps) =>
				booleanValueFormatter({
					value:
						cellProps.original.Status === constants.NODE_STATUSES.INSTALLED
							? cellProps.original.PreAuthEnabled
								? "Yes"
								: "No"
							: "",
				}),
			isAdmin: true,
		},
		{
			Header: "Arming Loop",
			id: "LoopDetection",
			width: 80,
			accessor: (d) =>
				d.Status === constants.NODE_STATUSES.INSTALLED
					? d.LoopDetection
						? "Yes"
						: "No"
					: "",
			Cell: (cellProps) =>
				booleanValueFormatter(
					{
						value:
							cellProps.original.Status === constants.NODE_STATUSES.INSTALLED &&
							cellProps.original.LoopDetection,
					},
					true
				),
			isAdmin: true,
		},
		{
			Header: "Gate Up Sensor",
			id: "GateOpenSignal",
			width: 80,
			accessor: (d) =>
				d.Status === constants.NODE_STATUSES.INSTALLED
					? d.GateOpenSignal
						? "Yes"
						: "No"
					: "",
			Cell: (cellProps) =>
				booleanValueFormatter(
					{
						value:
							cellProps.original.Status === constants.NODE_STATUSES.INSTALLED &&
							cellProps.original.GateOpenSignal,
					},
					true
				),
		},
		{
			Header: "Gate Close Relay",
			id: "GateCloseRelay",
			width: 80,
			accessor: (d) =>
				d.Status === constants.NODE_STATUSES.INSTALLED
					? d.GateCloseRelay
						? "Yes"
						: "No"
					: "",
			Cell: (cellProps) =>
				booleanValueFormatter(
					{
						value:
							cellProps.original.Status === constants.NODE_STATUSES.INSTALLED &&
							cellProps.original.GateCloseRelay,
					},
					true
				),
		},
		{
			Header: "Force Sessionless Access",
			id: "ForceSessionlessAccess",
			accessor: (d) => d.ForceSessionlessAccess,
			Cell: booleanValueFormatter,
			width: 80,
		},
		{
			Header: "Restricted Access",
			id: "RestrictedAccess",
			accessor: (d) => d.RestrictedAccess,
			Cell: booleanValueFormatter,
			width: 80,
		},
		{
			Header: "Last Opened",
			id: "LastTriggeredTimestamp",
			width: 80,
			accessor: (d) => format.localDate(d.LastTriggeredTimestamp),
			isAdmin: true,
		},
		{
			Header: "Last Opening Delay",
			id: "LastOpeningDelayDuration",
			width: 80,
			accessor: (d) => d.LastOpeningDelayDuration,
			isAdmin: true,
		},
		{
			Header: "QR Code",
			id: "QRCode",
			accessor: (d) => d.QRCode,
			Cell: booleanValueFormatter,
			width: 80,
		},
		{
			id: "tasks",
			Header: "",
			accessor: null,
			Cell: (cellProps) => {
				const addMockGateController = (
					<div
						key="add-mock-node"
						onClick={async () => {
							await props.addMockNode({
								variables: {
									siteId: props.site.SiteID,
									laneId: props.lane.LaneID,
									position: cellProps.original.Position,
								},
							});
							props.refetchData();
						}}
					>
						Add Mock Gate Controller
					</div>
				);

				const pingGate = (
					<div
						key="ping-gate"
						onClick={() =>
							props.openInstallationWizard(
								props.group,
								props.lane,
								cellProps.original,
								"ping-gate"
							)
						}
					>
						Ping Gate
					</div>
				);

				let items = props.siteOwnsInstallation
					? [
							props.isAdmin ? (
								<div
									key="add-beacon"
									onClick={() =>
										props.openBeaconWizard(
											props.group,
											props.lane,
											cellProps.original,
											null,
											"add-beacon"
										)
									}
								>
									Add Beacon
								</div>
							) : null,
							pingGate,
							<div
								key="open-gate"
								onClick={() =>
									props.openInstallationWizard(
										props.group,
										props.lane,
										cellProps.original,
										"open-gate"
									)
								}
							>
								Open Gate
							</div>,
							<div
								key="close-gate"
								onClick={() =>
									props.openInstallationWizard(
										props.group,
										props.lane,
										cellProps.original,
										"close-gate"
									)
								}
							>
								Close Gate
							</div>,
							<div
								key="edit"
								onClick={() =>
									props.openInstallationWizard(
										props.group,
										props.lane,
										cellProps.original,
										"edit-gate-controller"
									)
								}
							>
								Edit Gate
							</div>,
							props.isAdmin && cellProps.original.IsPedestal ? (
								<div
									key="edit-power-strength"
									onClick={() =>
										props.openInstallationWizard(
											props.group,
											props.lane,
											cellProps.original,
											"edit-power-strength"
										)
									}
								>
									Edit Power Strength
								</div>
							) : null,
							<div
								key={cellProps.original.IsEnabled ? "Disable" : "Enable"}
								onClick={() =>
									props.openInstallationWizard(
										props.group,
										props.lane,
										cellProps.original,
										"toggle-gate-controller"
									)
								}
							>
								{cellProps.original.IsEnabled ? "Disable Gate" : "Enable Gate"}
							</div>,
							<div
								key="delete"
								onClick={() =>
									props.openInstallationWizard(
										props.group,
										props.lane,
										cellProps.original,
										"delete-gate-controller"
									)
								}
							>
								Remove Gate
							</div>,
					  ].filter((item) => item)
					: [pingGate];
				const notInstalled = ["NotInstalled", "DidNotInstall"].includes(
					cellProps.original.Status
				);

				items = notInstalled
					? [
							<div
								key="not-installing"
								onClick={() =>
									props.openInstallationWizard(
										props.group,
										props.lane,
										cellProps.original,
										"not-installing"
									)
								}
							>
								{cellProps.original.Status === "NotInstalled"
									? "No Installation Required"
									: "Add Reason"}
							</div>,
							<div
								key="audit-logs"
								onClick={() =>
									props.openInstallationWizard(
										props.group,
										props.lane,
										cellProps.original,
										"audit-logs"
									)
								}
							>
								Audit Logs
							</div>,
					  ]
					: items;

				if (cellProps.original.Status === "DidNotInstall") {
					items.push(
						<div
							key="remove-status"
							onClick={() =>
								props.openInstallationWizard(
									props.group,
									props.lane,
									cellProps.original,
									"remove-status"
								)
							}
						>
							Remove &quot;Installation Not Required&quot; Status
						</div>
					);
				}
				return (
					<DropdownMenu
						triggerContent={<MoreHorizontal size={24} />}
						items={
							notInstalled
								? [
										props.isAdmin ? addMockGateController : null,
										...items,
								  ].filter((item) => item)
								: items
						}
					/>
				);
			},
			resizable: false,
			width: 50,
		},
	].filter((column) => !column.isAdmin || (column.isAdmin && props.isAdmin));

const kioskColumns = ({
	openPayStationWizard,
	payStation,
	siteOwnsInstallation,
}) => [
	{
		Header: "Enabled",
		id: "Enabled",
		accessor: (d) => d.IsEnabled,
		Cell: (cellProps) => booleanValueFormatterColour(cellProps),
	},
	{
		id: "IsOnline",
		Header: "Online",
		accessor: (d) => d.IsOnline,
		Cell: (cellProps) => (
			<div
				className={cellProps.original.IsOnline ? "led-cyan" : "led-white"}
			></div>
		),
	},
	{
		Header: "Serial Number",
		id: "SerialNumber",
		accessor: (d) => d.SerialNumber,
	},
	{
		Header: "Name",
		id: "Name",
		accessor: (d) => d.KioskName,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) =>
			openPayStationWizard && siteOwnsInstallation ? (
				<DropdownMenu
					triggerContent={<MoreHorizontal size={24} />}
					items={[
						<div
							key="remove-kiosk"
							onClick={() => {
								openPayStationWizard({
									mode: "remove-kiosk",
									kiosk: cellProps.original,
									payStation,
									groupName: payStation.GroupName,
								});
							}}
						>
							Remove Kiosk
						</div>,
					]}
				/>
			) : null,
		resizable: false,
		width: 50,
	},
];

const installationSchema = Yup.object().shape({
	TicketManufacturer: Yup.string().nullable(),
	GateManufacturer: Yup.string().nullable(),
	PreAuthEnabled: Yup.boolean(),
	SponsorID: Yup.number().integer().nullable(),
});

const manufacturers = map(constants.PARCS_MANUFACTURER, (val, key) => ({
	value: key,
	label: val,
}));

export default function InstallationContainer(props) {
	const [state, setState] = useState({
		wizardOpen: false,
		payStationWizardOpen: false,
		beaconWizardOpen: false,
		unsavedGroupsByInstallation: {},
		isLoading: false,
		installationWizardOpen: false,
	});

	const isAdmin = usePermissions("IsAdmin");
	const hasClientAdmin = useHasClientAdmin(props.selectedOrganization.ClientID);

	let groups = [];

	const {
		dispatch: { getAvailableSites, redirectToDefaultPage },
	} = useContext(AppContext);

	if (!(isAdmin || hasClientAdmin)) {
		redirectToDefaultPage(
			props.selectedOperator.OperatorID,
			props.selectedOrganization.OrganizationID,
			props.selectedOrganization.IsValidation
		);
	}

	let { data, refetch } = useQueryData(
		gql`
			query ($installationId: Int!) {
				getHardwareGroupsForInstallation(installationId: $installationId) {
					Name
					Lanes {
						LaneID
						InstallationID
						Name
						GroupOrder
						IsReversible
						Node1Status
						Node2Status
						Node1 {
							...NodeInfo
						}
						Node2 {
							...NodeInfo
						}
						Logs {
							OriginalData {
								description
								position
							}
							UserData {
								UserID
								Email
							}
							CreatedOn
						}
					}
					PayStations {
						PayStationID
						Name
						GroupName
						Kiosks {
							HardwareID
							PayStationKioskID
							KioskName
							IsEnabled
							IsOnline
							SerialNumber
						}
					}
				}

				getInstallation(installationId: $installationId) {
					InstallationID
					SiteID
					TicketManufacturer
					GateManufacturer
					PreAuthEnabled
					LatestSponsor {
						ClientID
						ClientName
					}
				}
			}

			fragment NodeInfo on Node {
				NodeID
				Name
				HardwareID
				LastTriggeredTimestamp
				LastHandshakeAt
				LastOpeningDelayDuration
				BLEAddress
				IsOnline
				SoftwareVersion
				SystemFirmware
				ProductVersion
				ProductName
				Notes
				SerialNumber
				CommunicationMethod
				CachedDiagnostics
				Status
				ClientID
				CreatedOn
				UpdatedOn
				IsEnabled
				AccessType
				LoopDetection
				GateOpenSignal
				GateCloseRelay
				BeaconAttached
				BeaconHardwareID
				BeaconBLEAddress
				BeaconSoftwareVersion
				BeaconSystemFirmware
				BeaconProductVersion
				PreAuthEnabled
				OfflineOnly
				IsPedestal
				GateTxPower
				TxPower
				Attenuation
				ForceSessionlessAccess
				RestrictedAccess
				QRCode
				IsBoatRamp
				ShowAccessButton
				Beacons {
					BeaconID
					BeaconDeviceID
					UniqueID
					MacAddress
					Status
					Manufacturer
				}
			}
		`,
		{ installationId: props.selectedSite.InstallationID },
		!props.selectedSite.InstallationID
	);

	const updateInstallation = useMutateData(
		gql`
			mutation (
				$installationId: Int!
				$ticketManufacturer: ParcsManufacturer
				$gateManufacturer: ParcsManufacturer
				$preAuthEnabled: Boolean
				$sponsorId: Int
			) {
				updateInstallation(
					installationId: $installationId
					ticketManufacturer: $ticketManufacturer
					gateManufacturer: $gateManufacturer
					preAuthEnabled: $preAuthEnabled
					sponsorId: $sponsorId
				)
			}
		`
	);

	const addMockNode = useMutateData(
		gql`
			mutation ($siteId: Int!, $laneId: Int!, $position: NodePosition!) {
				addMockNode(siteId: $siteId, laneId: $laneId, position: $position)
			}
		`
	);

	let installation = cloneDeep(data.getInstallation || {});
	const siteOwnsInstallation =
		props.selectedSite.SiteID === installation.SiteID;

	if (installation) {
		installation.SponsorID = installation.LatestSponsor
			? installation.LatestSponsor.ClientID
			: null;
	}

	let {
		data: { getClients: allClientsData },
	} = useQueryData(
		gql`
			query {
				getClients {
					ClientID
					Name
				}
			}
		`,
		{},
		!isAdmin || !props.selectedSite.InstallationID
	);

	const allClients = isAdmin
		? map(allClientsData, (c) => ({
				value: c.ClientID,
				label: c.Name,
		  }))
		: [];

	let laneCount = 0;
	let gateCount = 0;
	let beaconCount = 0;

	let laneGroups = cloneDeep(data.getHardwareGroupsForInstallation || []);

	for (let group of laneGroups) {
		for (let lane of group.Lanes) {
			laneCount++;
			const counter = (node) => {
				if (node) {
					gateCount++;
					//only count Kontakt beacons
					if (node.Beacons) {
						node.Beacons = node.Beacons.filter(
							(b) => b.Manufacturer === constants.BEACON_MANUFACTURERS.KONTAKT
						);

						if (node.Beacons.length) {
							beaconCount += node.Beacons.length;
						}
					}
				}
			};

			counter(lane.Node1);
			counter(lane.Node2);
		}
	}

	installation.TotalLanes = laneCount;
	installation.TotalGates = gateCount;
	installation.TotalBeacons = beaconCount;

	if (laneGroups) {
		groups = laneGroups;

		const currentUnsavedGroups =
			state.unsavedGroupsByInstallation[props.selectedSite.InstallationID];
		if (currentUnsavedGroups) {
			groups = [
				...laneGroups,
				...currentUnsavedGroups.filter(
					(ug) => !laneGroups.map((g) => g.Name).includes(ug.Name)
				),
			];
		}
	}

	const wizardGroupOptions = groups
		.filter((g) => g.Name !== "Ungrouped")
		.map((g) => ({
			value: g.Name,
			label: g.Name,
		}));

	function openInstallationWizard(group, lane, node, mode) {
		setState((_state) => ({
			..._state,
			wizardOpen: true,
			mode,
			group,
			lane,
			node,
		}));
	}

	function openPayStationWizard({ groupName, payStation, kiosk, mode }) {
		setState((_state) => ({
			..._state,
			payStationWizardOpen: true,
			mode,
			groupName,
			payStation: omit(payStation, ["Kiosks"]),
			kiosk,
		}));
	}

	function openBeaconWizard(group, lane, node, beacon, mode) {
		setState((_state) => ({
			..._state,
			beaconWizardOpen: true,
			mode,
			group,
			lane,
			node,
			beacon,
		}));
	}

	async function refetchData() {
		setTimeout(() => {
			if (props.selectedSite.InstallationID) refetch();

			getAvailableSites();
			setState((_state) => ({ ..._state, isLoading: false }));
		}, 300);
	}

	if (state.wizardOpen) {
		const previousPosition = { x: window.scrollX, y: window.scrollY };

		return (
			<InstallationWizard
				close={(group) => {
					if (group && group.Name) {
						const installationId = props.selectedSite.InstallationID;
						if (state.mode === "remove-group") {
							setState((_state) => ({
								..._state,
								unsavedGroupsByInstallation: {
									..._state.unsavedGroupsByInstallation,
									[installationId]: (
										_state.unsavedGroupsByInstallation[installationId] || []
									).filter((g) => g.Name !== group.Name),
								},
								wizardOpen: false,
								isLoading: true,
							}));
							refetchData();
						} else if (state.mode === "edit-group") {
							setState((_state) => ({
								..._state,
								unsavedGroupsByInstallation: {
									..._state.unsavedGroupsByInstallation,
									[installationId]: (
										_state.unsavedGroupsByInstallation[installationId] || []
									).map((ug) =>
										ug.Name === group.oldName
											? {
													...ug,
													Name: group.Name,
											  }
											: ug
									),
								},
								wizardOpen: false,
								isLoading: true,
							}));
							refetchData();
						} else {
							setState((_state) => ({
								..._state,
								unsavedGroupsByInstallation: {
									..._state.unsavedGroupsByInstallation,
									[installationId]: [
										...(_state.unsavedGroupsByInstallation[installationId] ||
											[]),
										group,
									],
								},
								wizardOpen: false,
								isLoading: true,
							}));
						}
					} else {
						setState((_state) => ({
							..._state,
							wizardOpen: false,
							isLoading: true,
						}));
						refetchData();
					}

					setTimeout(() => {
						window.scrollTo(previousPosition.x, previousPosition.y);
					}, 0);
				}}
				mode={state.mode}
				node={state.node}
				group={state.group}
				lane={state.lane}
				groups={groups}
				beacons={state.beacons}
				siteId={props.selectedSite.SiteID}
				installationId={props.selectedSite.InstallationID}
				site={props.selectedSite}
				isAdmin={isAdmin}
			/>
		);
	}

	if (state.payStationWizardOpen) {
		const previousPosition = { x: window.scrollX, y: window.scrollY };
		return (
			<PayStationWizard
				close={() => {
					setState((_state) => ({
						..._state,
						payStationWizardOpen: false,
					}));
					refetchData();
					setTimeout(() => {
						window.scrollTo(previousPosition.x, previousPosition.y);
					}, 0);
				}}
				mode={state.mode}
				kiosk={state.kiosk}
				payStation={state.payStation}
				groupName={state.groupName}
				installationId={props.selectedSite.InstallationID}
				groupOptions={wizardGroupOptions}
			/>
		);
	}

	if (state.beaconWizardOpen) {
		const previousPosition = { x: window.scrollX, y: window.scrollY };

		return (
			<BeaconWizard
				close={() => {
					setState((_state) => ({
						..._state,
						beaconWizardOpen: false,
					}));

					refetchData();

					setTimeout(() => {
						window.scrollTo(previousPosition.x, previousPosition.y);
					}, 0);
				}}
				mode={state.mode}
				beacon={state.beacon}
				node={state.node}
				group={state.prop}
				lane={state.lane}
				siteId={props.selectedSite.SiteID}
				currentUserIsAdmin={isAdmin}
				operator={props.selectedOperator}
			/>
		);
	}

	function Node({ node, lane, group }) {
		return (
			<Gate>
				<GateTitle>Gate Controller</GateTitle>
				<TableLayout
					data={[node]}
					columns={gateColumns({
						openInstallationWizard,
						openBeaconWizard,
						lane,
						group,
						isAdmin,
						hasClientAdmin,
						siteOwnsInstallation,
					})}
					isLoading={state.isLoading}
					sortable={false}
				/>
				{node.Beacons && node.Beacons.length && isAdmin ? (
					<Fragment>
						<BeaconsTitle>Beacons</BeaconsTitle>
						<TableLayout
							data={node.Beacons}
							columns={beaconColumns({
								openBeaconWizard,
								lane,
								group,
								node,
								siteOwnsInstallation,
							})}
							sortable={false}
						/>
					</Fragment>
				) : null}
			</Gate>
		);
	}

	function BlankNode({ group, lane, position }) {
		const positionValue = constants.NODE_POSITION_VALUES[position];
		const blankNode = {
			Status: lane[`Node${positionValue}Status`] || "NotInstalled",
			Position: position,
		};
		return (
			<Gate>
				<GateTitle>
					Gate Controller
					<Button
						style={{ marginRight: "10px", float: "right" }}
						color="blue"
						onClick={() =>
							openInstallationWizard(
								group,
								lane,
								blankNode,
								"add-gate-controller"
							)
						}
						disabled={!siteOwnsInstallation}
					>
						<Plus style={{ marginRight: 4, verticalAlign: "sub" }} size={20} />
						Add Gate Controller
					</Button>
				</GateTitle>

				<TableLayout
					style={{ marginTop: "30px" }}
					data={[blankNode]}
					columns={gateColumns({
						openInstallationWizard,
						openBeaconWizard,
						lane,
						group,
						isAdmin,
						hasClientAdmin,
						addMockNode,
						site: props.selectedSite,
						refetchData,
					})}
					sortable={false}
				/>
			</Gate>
		);
	}

	function Kiosks({ kiosks, payStation }) {
		return (
			<KioskWrapper>
				<KioskTitle>
					Kiosk
					{(!payStation.Kiosks || payStation.Kiosks.length === 0) && ( // for now we only allow one kiosk per pay station
						<Button
							style={{ marginRight: "10px", float: "right" }}
							color="blue"
							onClick={() =>
								openPayStationWizard({ payStation, mode: "add-kiosk" })
							}
							disabled={!siteOwnsInstallation}
						>
							<Plus
								style={{ marginRight: 4, verticalAlign: "sub" }}
								size={20}
							/>
							Add Kiosk
						</Button>
					)}
				</KioskTitle>
				<TableLayout
					style={{ marginTop: "30px" }}
					data={kiosks}
					columns={kioskColumns({
						openPayStationWizard,
						payStation,
						siteOwnsInstallation,
					})}
					sortable={false}
				/>
			</KioskWrapper>
		);
	}

	function Lane({ lane, group }) {
		const menuItems = siteOwnsInstallation
			? [
					<div
						key="edit-lane"
						onClick={() =>
							openInstallationWizard(group, lane, null, "edit-lane")
						}
					>
						Edit Lane
					</div>,
					<div
						key="remove-lane"
						onClick={() =>
							openInstallationWizard(group, lane, null, "remove-lane")
						}
					>
						Remove Lane
					</div>,
			  ]
			: [];

		return (
			<LaneHeader>
				<Accordion
					title={{ text: lane.Name || "<Unnamed Lane>", component: Title }}
					chevron={{ colour: colours.darkGrey }}
					menu={menuItems}
					size="small"
					expanded={true}
				>
					{lane.Node1Status === constants.LANE_STATUSES.INSTALLED &&
					lane.Node1 ? (
						<Node
							node={{
								...lane.Node1,
								Position: constants.NODE_POSITIONS.ONE,
							}}
							lane={lane}
							group={group}
						/>
					) : //only show the first blank node in these conditions
					// - the lane is reversible and Node1Status is null
					// - the lane one-way and Node1Status is null and Node2Status is null
					// - Node1Status is `DidNotInstall`
					(lane.IsReversible && !lane.Node1Status) ||
					  (!lane.IsReversible && !lane.Node1Status && !lane.Node2Status) ||
					  lane.Node1Status === constants.LANE_STATUSES.DID_NOT_INSTALL ? (
						<BlankNode
							group={group}
							lane={lane}
							position={constants.NODE_POSITIONS.ONE}
						/>
					) : null}
					{lane.Node2Status === constants.LANE_STATUSES.INSTALLED ? (
						<Node
							node={{
								...lane.Node2,
								Position: constants.NODE_POSITIONS.TWO,
							}}
							lane={lane}
							group={group}
						/>
					) : //only show the second blank node in these conditions
					// - the lane is reversible and Node2Status is null
					// - Node2Status is `DidNotInstall`
					(lane.IsReversible && !lane.Node2Status) ||
					  lane.Node2Status === constants.LANE_STATUSES.DID_NOT_INSTALL ? (
						<BlankNode
							group={group}
							lane={lane}
							position={constants.NODE_POSITIONS.TWO}
						/>
					) : null}
				</Accordion>
			</LaneHeader>
		);
	}

	function PayStation({ payStation }) {
		return (
			<PayStationHeader>
				<Accordion
					title={{
						text: payStation.Name || "<Unnamed Pay Station>",
						component: Title,
					}}
					chevron={{ colour: colours.darkGrey }}
					menu={
						siteOwnsInstallation
							? [
									<div
										key="edit-pay-station"
										onClick={() =>
											openPayStationWizard({
												mode: "edit-pay-station",
												payStation,
												groupName: payStation.GroupName,
											})
										}
									>
										Edit Pay Station
									</div>,
									<div
										key="remove-pay-station"
										onClick={() =>
											openPayStationWizard({
												mode: "remove-pay-station",
												payStation,
											})
										}
									>
										Remove Pay Station
									</div>,
							  ]
							: []
					}
					size="small"
					expanded={true}
				>
					{payStation.Kiosks.length ? (
						<Kiosks kiosks={payStation.Kiosks} payStation={payStation} />
					) : (
						<Kiosks
							kiosks={[{ IsEnabled: false, IsOnline: false }]} // Empty kiosk row
							payStation={payStation}
						/>
					)}
				</Accordion>
			</PayStationHeader>
		);
	}

	function Group({ group }) {
		const menuItems = siteOwnsInstallation
			? [
					<div
						key="edit-group"
						onClick={() =>
							openInstallationWizard(group, null, null, "edit-group")
						}
					>
						Edit Group
					</div>,
					<div
						key="remove-group"
						onClick={() =>
							openInstallationWizard(group, null, null, "remove-group")
						}
					>
						Remove Group
					</div>,
			  ]
			: [];
		return (
			<Fragment>
				<GroupHeading>
					<h2>{group.Name}</h2>
					{group.Name !== "Ungrouped" && menuItems.length ? (
						<RightMenu>
							<DropdownMenu
								triggerContent={<MoreHorizontal size={24} />}
								items={menuItems}
							/>
						</RightMenu>
					) : null}
				</GroupHeading>
				<RightButtonWrapper>
					<Button
						color="blue"
						onClick={() =>
							openInstallationWizard(group, null, null, "add-lane")
						}
						disabled={!siteOwnsInstallation}
					>
						<Plus style={{ marginRight: 4, verticalAlign: "sub" }} size={20} />
						Add Lane
					</Button>
				</RightButtonWrapper>
				<RightButtonWrapper>
					<Button
						color="blue"
						onClick={() =>
							openPayStationWizard({
								mode: "add-pay-station",
								groupName: group.Name === "Ungrouped" ? null : group.Name,
							})
						}
						disabled={!siteOwnsInstallation}
					>
						<Plus style={{ marginRight: 4, verticalAlign: "sub" }} size={20} />
						Add Pay Station
					</Button>
				</RightButtonWrapper>
				<LanesWrapper>
					{group.Lanes && group.Lanes.length ? (
						group.Lanes.map((lane, index) => (
							<Lane key={index} lane={lane} group={group} />
						))
					) : (
						<LaneHeader>
							<EmptyGroup>No lanes in this group.</EmptyGroup>
						</LaneHeader>
					)}
				</LanesWrapper>
				<PayStationsWrapper>
					{group.PayStations && group.PayStations.length ? (
						group.PayStations.map((payStation, index) => (
							<PayStation key={index} payStation={payStation} />
						))
					) : (
						<PayStationHeader>
							<EmptyGroup>No pay stations in this group.</EmptyGroup>
						</PayStationHeader>
					)}
				</PayStationsWrapper>
			</Fragment>
		);
	}

	async function _handleSubmit(values, { setSubmitting }) {
		setSubmitting(true);
		if (!siteOwnsInstallation) return;

		const { TicketManufacturer, GateManufacturer, PreAuthEnabled, SponsorID } =
			values;

		try {
			await updateInstallation({
				variables: {
					installationId: props.selectedSite.InstallationID,
					ticketManufacturer: TicketManufacturer,
					gateManufacturer: GateManufacturer,
					preAuthEnabled: PreAuthEnabled,
					sponsorId: SponsorID,
				},
			});

			Alert.success(
				isAdmin
					? "Installation details and settlement updated"
					: "Installation details updated"
			);
		} catch (error) {
			Alert.error("Something went wrong. Please try again.");
		}

		setSubmitting(false);
	}

	if (!props.selectedSite.InstallationID) {
		return (
			<Card>
				<NoInstallation>
					No installation has been added to this site yet. This site will not be
					operational until an installation is set up and completed.
				</NoInstallation>
				<Wrapper style={{ textAlign: "center" }}>
					<Button
						style={{ marginRight: "10px" }}
						color="blue"
						onClick={async () =>
							openInstallationWizard(null, null, null, "create-installation")
						}
					>
						<Plus style={{ marginRight: 4, verticalAlign: "sub" }} size={20} />
						Create Installation
					</Button>
				</Wrapper>
			</Card>
		);
	}

	return (
		<div>
			<FormLayout
				enableReinitialize={true}
				initialValues={installation}
				validationSchema={installationSchema}
				onSubmit={_handleSubmit}
				render={({ values, handleSubmit, setFieldValue }) => (
					<form
						className="form"
						onSubmit={(event) => {
							event.preventDefault();
						}}
					>
						<FlexWrapper style={{ marginBottom: -16 }}>
							<Card>
								<StatCard
									value={installation.TotalLanes}
									title="Total amount of lanes"
									size="medium"
								/>
							</Card>
							<Card>
								<StatCard
									value={installation.TotalGates}
									title="Total amount of gate kits"
									size="medium"
								/>
							</Card>
							<Card>
								<StatCard
									value={installation.TotalBeacons}
									title="Total amount of beacons"
									size="medium"
								/>
							</Card>
						</FlexWrapper>

						<Card>
							<Accordion title="Details" expanded={true}>
								<FieldWrapper>
									<Label>{`Installation ID: ${props.selectedSite.InstallationID}`}</Label>
								</FieldWrapper>
								<FieldWrapper>
									<Label>Ticket Manufacturer</Label>
									<FieldContent>
										<Dropdown
											isClearable={true}
											onChange={(value) =>
												setFieldValue(
													"TicketManufacturer",
													value ? value.value : null
												)
											}
											options={manufacturers}
											value={
												manufacturers.find(
													(e) => e.value === values.TicketManufacturer
												) || null
											}
											isDisabled={
												!(hasClientAdmin || isAdmin) || !siteOwnsInstallation
											}
										/>
									</FieldContent>
								</FieldWrapper>

								<FieldWrapper>
									<Label>Gate Manufacturer</Label>
									<FieldContent>
										<Dropdown
											isClearable={true}
											onChange={(value) =>
												setFieldValue(
													"GateManufacturer",
													value ? value.value : null
												)
											}
											options={manufacturers}
											value={
												manufacturers.find(
													(e) => e.value === values.GateManufacturer
												) || null
											}
											isDisabled={
												!(hasClientAdmin || isAdmin) || !siteOwnsInstallation
											}
										/>
									</FieldContent>
								</FieldWrapper>

								{isAdmin && (
									<FieldWrapper>
										<Toggle
											label="Pre-auth Enabled"
											onChange={(value) =>
												setFieldValue("PreAuthEnabled", value)
											}
											checked={values.PreAuthEnabled}
											disabled={!siteOwnsInstallation}
										/>
									</FieldWrapper>
								)}

								{(isAdmin || hasClientAdmin) && (
									<Fragment>
										<h2 style={{ margin: "16px 32px" }}>Settlement</h2>
										<FieldWrapper>
											<Label>Sponsor</Label>
											<FieldContent>
												{isAdmin ? (
													<Dropdown
														isClearable={true}
														onChange={(value) =>
															setFieldValue(
																"SponsorID",
																value ? value.value : null
															)
														}
														options={allClients}
														value={allClients.find(
															(e) => e.value === values.SponsorID
														)}
														isDisabled={!isAdmin || !siteOwnsInstallation}
													/>
												) : (
													<EditableInputField
														disabled={true}
														value={
															installation && installation.LatestSponsor
																? installation.LatestSponsor.ClientName
																: ""
														}
													/>
												)}
											</FieldContent>
										</FieldWrapper>
									</Fragment>
								)}

								<FieldWrapper>
									<Button
										color="blue"
										style={{ marginTop: "16px" }}
										onClick={handleSubmit}
										disabled={!siteOwnsInstallation}
									>
										Update
									</Button>
								</FieldWrapper>
							</Accordion>
						</Card>
					</form>
				)}
			/>

			<Card>
				<Accordion title="Hardware" expanded={true}>
					<Wrapper>
						<ButtonGroup>
							<Button
								style={{ marginRight: "10px" }}
								color="blue"
								onClick={() =>
									openInstallationWizard(null, null, null, "add-group")
								}
								disabled={!siteOwnsInstallation}
							>
								<Plus
									style={{ marginRight: 4, verticalAlign: "sub" }}
									size={20}
								/>
								Add Group
							</Button>
						</ButtonGroup>
						{groups.map((group, index) => (
							<Group
								key={index}
								group={group}
								siteOwnsInstallation={siteOwnsInstallation}
							/>
						))}
					</Wrapper>
				</Accordion>
			</Card>
			{isAdmin && !siteOwnsInstallation && (
				<Button
					color="red"
					onClick={() =>
						openInstallationWizard(
							null,
							null,
							null,
							"remove-installation-from-site"
						)
					}
					style={{ float: "right", marginBottom: "32px" }}
				>
					Remove Installation
				</Button>
			)}
		</div>
	);
}
