import * as billingApi from "../../../api/billing";
import * as sessionApi from "../../../api/session";
import Alert from "react-s-alert";
import { some } from "lodash";

export async function submitRefundPayment(
	props,
	values,
	setSubmitting,
	mutations
) {
	const data = {
		amount: values.amount,
		reason: values.reason,
	};

	try {
		if (
			["ParkingSession", "MooringSession"].includes(values.transaction.ItemType)
		) {
			await sessionApi.createRefundForParkingSession(
				values.transaction.SiteID,
				values.transaction.ItemID,
				data
			);
		} else if (values.transaction.ItemType === "UserLeaseCancellation") {
			await billingApi.createRefundForUserLease(
				values.transaction.OrganizationID,
				values.transaction.ItemID,
				data
			);
		} else if (values.transaction.ItemType === "LeasePayment") {
			await billingApi.createRefundForLeasePayment(
				values.transaction.OrganizationID,
				values.transaction.UserLeaseID,
				values.transaction.ItemID,
				data
			);
		} else if (values.transaction.ItemType === "ProductPurchase") {
			await mutations.refundProductPurchase({
				variables: {
					productPurchaseId: parseInt(values.transaction.ItemID),
					amount: parseFloat(data.amount),
					reason: data.reason,
				},
			});
		}

		setSubmitting(false);
		Alert.success("Payment refunded");
		props.close(true);
	} catch (error) {
		if (
			error &&
			error.errors &&
			some(error.errors, (e) => e.code === "CannotRefundDeletedUser")
		) {
			Alert.error("You cannot refund a transaction for a deleted user.");
		} else if (
			some(error?.errors, (e) => e.code === "TransactionNotCompleted")
		) {
			Alert.error(
				"This transaction cannot be refunded as it is not completed yet."
			);
		} else {
			Alert.error("Something went wrong. Please try again.");
		}
		setSubmitting(false);

		props.close(true);
	}
}

export async function submitResendReceipt(
	props,
	values,
	setSubmitting,
	mutations
) {
	try {
		switch (values.transaction.ItemType) {
			case "ParkingSession":
			case "MooringSession":
				await billingApi.resendParkingSessionReceipt(
					values.transaction.SiteID,
					values.transaction.ItemID
				);
				break;
			case "LeasePayment":
				await billingApi.resendLeasePaymentReceipt(
					values.transaction.OrganizationID,
					values.transaction.UserLeaseID,
					values.transaction.ItemID
				);
				break;
			case "UserLeaseCancellation":
				await billingApi.resendUserLeaseCancellationReceipt(
					values.transaction.OrganizationID,
					values.transaction.ItemID
				);
				break;
			case "ParkingSessionRefund":
			case "MooringSessionRefund":
				await billingApi.resendParkingSessionRefundReceipt(
					values.transaction.SiteID,
					values.transaction.RefundRequestID
				);
				break;
			case "LeasePaymentRefund":
				await billingApi.resendLeasePaymentRefundReceipt(
					values.transaction.OrganizationID,
					values.transaction.RefundRequestID
				);
				break;
			case "UserLeaseCancellationRefund":
				await billingApi.resendUserLeaseCancellationRefundReceipt(
					values.transaction.OrganizationID,
					values.transaction.RefundRequestID
				);
				break;
			case "ProductPurchase":
				await mutations.resendProductPurchaseReceipt({
					variables: { productPurchaseId: parseInt(values.transaction.ItemID) },
				});
				break;
			case "ProductPurchaseRefund":
				await mutations.resendProductPurchaseRefundReceipt({
					variables: { refundRequestId: values.transaction.RefundRequestID },
				});
				break;
		}

		setSubmitting(false);
		Alert.success("The receipt was resent");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Something went wrong. Please try again.");
		props.close(true);
	}
}

export async function submitRetryPayment(props, values, setSubmitting) {
	try {
		switch (values.transaction.ItemType) {
			case "ParkingSession":
			case "MooringSession":
				await billingApi.retryParkingSessionPayment(
					values.transaction.SiteID,
					values.transaction.ItemID
				);
				break;
			case "LeasePayment":
				await billingApi.retryLeasePayment(
					values.transaction.SiteID,
					values.transaction.UserLeaseID,
					values.transaction.ItemID
				);
				break;
			case "UserLeaseCancellation":
				await billingApi.retryUserLeaseCancellationFee(
					values.transaction.SiteID,
					values.transaction.UserLeaseID
				);
				break;
		}

		setSubmitting(false);
		Alert.success("Retry payment succeeded");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Retry payment failed.");
		props.close(true);
	}
}

export async function submitVoidPayment(props, values, setSubmitting) {
	try {
		switch (values.transaction.ItemType) {
			case "ParkingSession":
			case "MooringSession":
				await billingApi.voidParkingSessionPayment(
					values.transaction.SiteID,
					values.transaction.ItemID
				);
				break;
			case "LeasePayment":
				await billingApi.voidLeasePayment(
					values.transaction.SiteID,
					values.transaction.UserLeaseID,
					values.transaction.ItemID
				);
				break;
			case "UserLeaseCancellation":
				await billingApi.voidUserLeaseCancellationFee(
					values.transaction.SiteID,
					values.transaction.UserLeaseID
				);
				break;
		}

		setSubmitting(false);
		Alert.success("Marking payment succeeded");
		props.close(true);
	} catch (error) {
		setSubmitting(false);
		Alert.error("Marking payment failed.");
		props.close(true);
	}
}
