import { StepText, StepTitle } from "../WizardLayout";
import { cloneDeep, keys } from "lodash";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import React from "react";
import TimeRangePicker from "../../layout/TimeRangePicker";
import Toggle from "../../layout/Toggle";
import Tooltip from "../../layout/Tooltip";
import WizardNavigation from "../WizardNavigation";
import { XCircle } from "react-feather";
import { colours } from "../../../styles";
import moment from "moment";
import styled from "styled-components";

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 16px;
	margin-top: 16px;
`;

const DayLabel = styled.div`
	font-size: 18px;
	font-weight: 700;
	position: relative;
	width: 100px;

	> div {
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
	}
`;

const Wrapper = styled.div`
	display: flex;
	position: relative;
	margin-left: 30px;

	> * {
		margin: 0 10px;
	}

	> div {
		.tooltip-icon {
			margin-top: 17px;
			cursor: pointer;
		}
	}
`;

export const editStep = ({
	errors,
	handleBlur,
	handleChange,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	touched,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "edit",
	label: "Edit",
	render: () => {
		const days = [
			"Monday",
			"Tuesday",
			"Wednesday",
			"Thursday",
			"Friday",
			"Saturday",
			"Sunday",
		];

		const defaultStartTime = moment("00:00", "HH:mm").toDate();

		const accountOptions = wizardProps.spaceAccounts
			.filter((account) => {
				if (wizardProps.spaceAccountOwnerOnly) {
					if (
						wizardProps.user.SpaceAccountOwners.find(
							(e) => e.SpaceAccountID === account.SpaceAccountID
						)
					) {
						return true;
					} else {
						return false;
					}
				}

				return true;
			})
			.map((account) => ({
				label: account.Name,
				value: account.SpaceAccountID,
			}));

		const siteOptions = wizardProps.sites.map((site) => ({
			label: site.Name,
			value: site.SiteID,
		}));
		siteOptions.unshift({ label: "All Sites", value: 0 });
		siteOptions.unshift({ label: "No site", value: -1 });

		const rateOptions = wizardProps.leaseRates.map((leaseRate) => {
			return { label: leaseRate.Name, value: leaseRate.LeaseRateID };
		});
		rateOptions.unshift({ label: "No rate", value: -1 });

		const locationBasedNodeOptions = wizardProps.nodes.map((node) => {
			return { label: node.Name || node.SerialNumber, value: node.NodeID };
		});

		const restrictedAccessNodeOptions = wizardProps.nodes
			.filter((node) => node.RestrictedAccess)
			.map((node) => {
				return { label: node.Name || node.SerialNumber, value: node.NodeID };
			});

		function handleTimeChange(group, day, time, isOpen) {
			const _group = cloneDeep(group);
			if (time) {
				if (time.from) {
					_group[`${day}OpeningTimestamp`] =
						moment(time.from).unix() - moment(defaultStartTime).unix();
				} else {
					_group[`${day}OpeningTimestamp`] = null;
				}
				if (time.to) {
					_group[`${day}ClosingTimestamp`] =
						moment(time.to).unix() - moment(defaultStartTime).unix();
				} else {
					_group[`${day}ClosingTimestamp`] = null;
				}
			} else {
				_group[`${day}Open`] = isOpen ? 1 : 0;
			}

			setFieldValue("group", _group);
		}

		return (
			<div>
				<StepTitle>Group details:</StepTitle>

				<EditableInputField
					type="text"
					name="name"
					onChange={handleChange}
					onBlur={handleBlur}
					value={values.name || ""}
					useLabel="Name"
					error={errors.name && touched.name ? errors.name : null}
				/>

				<Label>Account</Label>
				<Dropdown
					isClearable={!wizardProps.spaceAccountOwnerOnly}
					options={accountOptions}
					value={values.account}
					onChange={(value) => {
						setFieldValue("account", value);
					}}
				/>

				<Label>Sites In Group</Label>
				<Dropdown
					isMulti={true}
					options={siteOptions}
					value={values.sites}
					onChange={(value, event) => {
						let model = "sites";

						//If nulling out values completely, revert to 'no site'
						if (
							(value === null && event.action === "remove-value") ||
							event.action === "clear"
						) {
							setFieldValue(model, [{ label: "No site", value: -1 }]);
						}
						//If removing a value, proceed as normal
						else if (event.action === "remove-value") {
							setFieldValue(model, value);
						}
						//If adding public group, remove all other groups
						else if (event.option.value === -1) {
							setFieldValue(model, [{ label: "No site", value: -1 }]);
						}
						//If adding All Sites, add everything but the special options
						else if (event.option.value === 0) {
							setFieldValue(model, siteOptions.slice(2));
						}
						//Else, add the option and remove "No site" if applicable
						else {
							const newValues = value.reduce((arr, val) => {
								if (val.value !== -1) {
									arr.push(val);
								}

								return arr;
							}, []);

							setFieldValue(model, newValues);
						}
					}}
				/>

				<Label>Booking Rates In Group</Label>
				<Dropdown
					isMulti={true}
					options={rateOptions}
					value={values.leaseRates}
					onChange={(value, event) => {
						let model = "leaseRates";

						//If nulling out values completely, revert to 'no site'
						if (
							(value === null && event.action === "remove-value") ||
							event.action === "clear"
						) {
							setFieldValue(model, [{ label: "No rate", value: -1 }]);
						}
						//If removing a value, proceed as normal
						else if (event.action === "remove-value") {
							setFieldValue(model, value);
						}
						//If adding public group, remove all other groups
						else if (event.option.value === -1) {
							setFieldValue(model, [{ label: "No rate", value: -1 }]);
						}
						//Else, add the option and remove "No site" if applicable
						else {
							const newValues = value.reduce((arr, val) => {
								if (val.value !== -1) {
									arr.push(val);
								}

								return arr;
							}, []);

							setFieldValue(model, newValues);
						}
					}}
				/>

				<Label>Gates with Location Based Access</Label>
				<Dropdown
					isMulti={true}
					options={locationBasedNodeOptions}
					value={values.locationBasedNodes}
					onChange={(value) => {
						setFieldValue("locationBasedNodes", value);
					}}
				/>

				<Label>Gates with Restricted Access</Label>
				<Dropdown
					isMulti={true}
					options={restrictedAccessNodeOptions}
					value={values.restrictedAccessNodes}
					onChange={(value) => {
						setFieldValue("restrictedAccessNodes", value);
					}}
				/>

				<Toggle
					label="Sessionless Access"
					onChange={(value) => setFieldValue("sessionlessAccess", value)}
					checked={values.sessionlessAccess}
				/>

				{wizardProps.mode === "update" && (
					<Toggle
						label="Exclude From ANPR Openings"
						onChange={(value) => setFieldValue("group.HasANPRAccess", !value)}
						checked={!values.group.HasANPRAccess}
					/>
				)}

				<Toggle
					label="Specific Site Access Hours"
					onChange={(value) => setFieldValue("overridesOpeningHours", value)}
					checked={values.overridesOpeningHours}
				/>

				{wizardProps.mode === "update" && wizardProps.user.IsAdmin && (
					<Toggle
						label="Waive Convenience Fees"
						onChange={(value) =>
							setFieldValue("group.WaiveConvenienceFee", value)
						}
						checked={values.group.WaiveConvenienceFee}
					/>
				)}

				{values.overridesOpeningHours && (
					<div>
						{days.map((day) => {
							return (
								<Wrapper key={day}>
									<DayLabel>
										<div>{day}</div>
									</DayLabel>
									<TimeRangePicker
										startTime={
											values.group[`${day}OpeningTimestamp`] ||
											values.group[`${day}OpeningTimestamp`] === 0
												? moment(defaultStartTime)
														.add(
															values.group[`${day}OpeningTimestamp`],
															"seconds"
														)
														.toDate()
												: null
										}
										endTime={
											values.group[`${day}ClosingTimestamp`] ||
											values.group[`${day}ClosingTimestamp`] === 0
												? moment(defaultStartTime)
														.add(
															values.group[`${day}ClosingTimestamp`],
															"seconds"
														)
														.toDate()
												: null
										}
										disabled={!!(values.group && !values.group[`${day}Open`])}
										onChange={(value) =>
											handleTimeChange(values.group, day, value)
										}
										onOpen={{
											start: moment("08:00", "HH:mm").toDate(),
											end: moment("17:00", "HH:mm").toDate(),
										}}
									/>
									<div
										onClick={() =>
											values.group && values.group[`${day}Open`]
												? handleTimeChange(values.group, day, {
														from: null,
														to: null,
												  })
												: null
										}
									>
										<Tooltip
											tooltipContent={"Clear input"}
											render={
												<XCircle
													color={
														values.group && values.group[`${day}Open`]
															? colours.red
															: colours.midGrey
													}
												/>
											}
										/>
									</div>

									<Toggle
										onChange={(value) =>
											handleTimeChange(values.group, day, null, value)
										}
										checked={!!(values.group && values.group[`${day}Open`])}
									/>
								</Wrapper>
							);
						})}
					</div>
				)}
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting || keys(errors).length > 0 || !values.name}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.mode === "add" ? "Create" : "Update"}
				</Button>,
			]}
		/>
	),
});

export const removeStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "remove",
	label: "Remove",
	render: () => (
		<div>
			<StepText>
				Delete {values.name}? All Users in the group will have their site access
				removed.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Remove Group
				</Button>,
			]}
		/>
	),
});
