import { MoreHorizontal, Plus } from "react-feather";
import React, { useState } from "react";
import { usePermissions, useQueryData } from "../../hooks";
import Button from "../../components/layout/Button";
import DropdownMenuBeta from "../../components/layout/DropdownMenuBeta";
import ProductWizard from "../../components/wizards/product-wizard/ProductWizard";
import ReportWrapperBeta from "../../components/report/ReportWrapperBeta";
import _ from "lodash";
import { booleanValueFormatter } from "../../components/layout/TableLayout";
import { format } from "../../helpers";
import gql from "graphql-tag";

const columns = (props) => [
	{
		Header: "Name",
		accessor: "Name",
	},
	{
		Header: "Description",
		accessor: "Description",
	},
	{
		id: "IsDonation",
		Header: "Donation",
		accessor: (d) => d.IsDonation,
		Cell: (cellProps) => booleanValueFormatter(cellProps, true),
		fixedWidth: 80,
	},
	{
		id: "Fee",
		Header: "Fee",
		accessor: (d) => d.Fee,
		Cell: (cellProps) => {
			if (_.isNil(cellProps.row.original.Fee)) {
				return "";
			}

			return format.money(cellProps.row.original.Fee);
		},
	},
	{
		id: "MinFee",
		Header: "Min Fee",
		accessor: (d) => d.MinFee,
		Cell: (cellProps) => {
			if (_.isNil(cellProps.row.original.MinFee)) {
				return "";
			}

			return format.money(cellProps.row.original.MinFee);
		},
	},
	{
		id: "MaxFee",
		Header: "Max Fee",
		accessor: (d) => d.MaxFee,
		Cell: (cellProps) => {
			if (_.isNil(cellProps.row.original.MaxFee)) {
				return "";
			}

			return format.money(cellProps.row.original.MaxFee);
		},
	},
	{
		id: "Sites",
		Header: "Sites",
		accessor: (d) => d.Sites?.map((site) => site.Name).join(", "),
		fixedWidth: 250,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: ({ row: { original } }) => (
			<DropdownMenuBeta
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="edit"
						onClick={() => props.openProductWizard(original, "edit")}
					>
						Edit
					</div>,

					props.isAdmin ? (
						<div
							key="delete"
							onClick={() => props.openProductWizard(original, "delete")}
						>
							Delete
						</div>
					) : null,
				]}
			/>
		),
		fixedWidth: 50,
	},
];

export default function ProductsContainer(props) {
	const [state, setState] = useState({
		productWizardOpen: false,
		productWizardMode: "add",
	});

	const organizationId = props.selectedOrganization.OrganizationID;
	const isAdmin = usePermissions("IsAdmin");

	function openProductWizard(product, mode) {
		setState((_state) => ({
			..._state,
			productWizardOpen: true,
			productWizardProduct: product,
			productWizardMode: mode,
		}));
	}

	const {
		data: { getProductsForOrganization: productsForOrganization },
		isLoading: productsLoading,
		refetch: refetchProducts,
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getProductsForOrganization(organizationId: $organizationId) {
					ProductID
					Name
					Description
					IsDonation
					Fee
					MinFee
					MaxFee
					MetadataFields
					SuccessText
					Sites {
						SiteID
						Name
					}
				}
			}
		`,
		{
			organizationId,
		}
	);

	if (state.productWizardOpen) {
		return (
			<ProductWizard
				close={() => {
					setState((_state) => ({ ..._state, productWizardOpen: false }));
					refetchProducts();
				}}
				mode={state.productWizardMode}
				product={state.productWizardProduct}
				isAdmin={isAdmin}
				organizationId={organizationId}
				availableSites={props.availableSites}
			/>
		);
	}

	return (
		<div>
			<ReportWrapperBeta
				{...props}
				title="Products"
				data={productsForOrganization}
				columns={columns({
					openProductWizard: openProductWizard,
					isAdmin: isAdmin,
				})}
				filterOptions={["Name", "Description", "Fee", "MinFee", "MaxFee"]}
				defaultSortBy={[
					{
						id: "Name",
					},
				]}
				loading={productsLoading}
				showDateRangePicker={false}
				showSitePicker={false}
				updateOptions={() => {}}
				rightActions={
					<Button color="blue" onClick={() => openProductWizard(null, "add")}>
						<Plus size={20} /> Add Product
					</Button>
				}
			/>
		</div>
	);
}
