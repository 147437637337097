import * as InstallationApi from "../../../api/installation";
import * as Yup from "yup";
import * as steps from "./steps";
import Alert from "react-s-alert";
import ErrorBoundary from "../../../containers/ErrorBoundary";
import PropTypes from "prop-types";
import React from "react";
import WizardLayout from "../WizardLayout";

function getWizard(mode) {
	switch (mode) {
		case "add":
			return {
				title: "Add Validation Beacon",
				initialStep: 0,
				steps: [
					steps.location,
					steps.uniqueId,
					steps.beaconType,
					steps.groupAccess,
					steps.selfValidation,
					steps.validationType,
					steps.sites,
					steps.beaconValue,
					steps.summary,
				],
			};
		case "update": {
			const updateSteps = [
				steps.location,
				steps.uniqueId,
				steps.beaconType,
				steps.groupAccess,
				steps.selfValidation,
				steps.validationType,
				steps.sites,
				steps.beaconValue,
				steps.summary,
			];
			return {
				title: "Update Validation Beacon",
				initialStep: updateSteps.length - 1,
				steps: updateSteps,
			};
		}

		case "delete":
			return {
				title: `Delete Validation Beacon`,
				initialStep: 0,
				steps: [steps.summary],
			};
	}
}

ValidationBeaconWizard.propTypes = {
	mode: PropTypes.oneOf(["add", "update", "delete"]).isRequired,
	availableSites: PropTypes.arrayOf(PropTypes.object).isRequired,
	close: PropTypes.func.isRequired,
	beacon: PropTypes.object,
};

ValidationBeaconWizard.defaultProps = {
	beacon: {},
};

function ValidationBeaconWizard(props) {
	const wizard = getWizard(props.mode, props.beacon);

	const accessGroup = props.organizationAccessGroups.find(
		(group) =>
			group.OrganizationAccessGroupID ===
			props.beacon?.OrganizationAccessGroupID
	);

	const fields = [
		{
			name: "organizationId",
			value: props.organizationId,
			validator: Yup.number(),
		},
		{
			name: "beaconId",
			value: props.beacon.BeaconID,
			validator: Yup.number(),
		},
		{
			name: "location",
			value: props.beacon.Location,
			validator: Yup.string().nullable(),
		},
		{
			name: "uniqueId",
			value: props.beacon.UniqueID,
			validator: Yup.string(),
		},
		{
			name: "selfValidation",
			value: !!props.beacon.IsSelfValidation,
			validator: Yup.boolean(),
		},
		{
			name: "beaconSites",
			value: props.beacon.sites || [],
			validator: Yup.array(),
		},
		{
			name: "availableSites",
			value: props.availableSites,
			validator: Yup.array(),
		},
		{
			name: "validationType",
			value: props.beacon.ValidationType,
			validator: Yup.string(),
		},
		{
			name: "validationTypeOptions",
			value: props.validationTypeOptions,
			validator: Yup.array(),
		},
		{
			name: "beaconValue",
			value: props.beacon.ValidationValue,
			validator: Yup.mixed().when("validationType", {
				is: (val) => val === "Rate" || val === "GroupAccess",
				then: Yup.mixed().notRequired(),
				otherwise: Yup.number()
					.positive()
					.required("Please supply a value for this beacon")
					.typeError("Please specify a number"),
			}),
		},
		{
			name: "rateData",
			value: props.rateData,
			validator: Yup.array(),
		},
		{
			name: "rateName",
			value: props.beacon.rate || "",
			validator: Yup.string(),
		},
		{
			name: "uniqueIdValid",
			value: true,
		},
		{
			name: "accessGroup",
			value: accessGroup
				? {
						value: accessGroup.OrganizationAccessGroupID,
						label: accessGroup.Name,
				  }
				: null,
			validator: Yup.object().nullable(),
		},
	];

	const onSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true);

		const beacon = {
			isSelfValidation: !!values.selfValidation,
			beaconLocation: values.location,
			validationType: values.validationType,
			accessGroupId: values.accessGroup?.value,
		};

		let message = "";

		if (values.validationType === "Rate") {
			beacon.sitesWithRates = [];
			values.beaconSites.map((site) => {
				beacon.sitesWithRates.push({
					id: site.id || site.SiteID,
					rateId: site.rateId,
				});
			});
		} else {
			beacon.validationValue = values.beaconValue;
			beacon.siteIds = values.beaconSites.map((site) => site.id);
		}

		try {
			if (props.mode === "add") {
				beacon.beaconId = values.uniqueId;
				message = "added";

				await InstallationApi.createValidationBeacon(
					values.organizationId,
					beacon
				);
			}

			if (props.mode === "update") {
				message = "updated";

				await InstallationApi.updateValidationBeacon(
					values.organizationId,
					values.beaconId,
					beacon
				);
			}

			if (props.mode === "delete") {
				message = "deleted";

				await InstallationApi.deleteValidationBeaconById(
					values.organizationId,
					values.beaconId
				);
			}

			setSubmitting(false);
			Alert.success(`Beacon ${message}`);
			props.close(true);
		} catch (error) {
			setSubmitting(false);
			Alert.error("Something went wrong. Please try again.");
		}
	};

	return (
		<ErrorBoundary>
			<WizardLayout
				close={props.close}
				title={wizard.title}
				values={fields}
				onSubmit={onSubmit}
				steps={wizard.steps}
				initialStep={wizard.initialStep}
				wizardProps={props}
			/>
		</ErrorBoundary>
	);
}

export default ValidationBeaconWizard;
