import * as installationApi from "../../../api/installation";
import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { site as siteConstants, user as userHelpers } from "../../../helpers";
import { sortBy, trimEnd, trimStart } from "lodash";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import GeoCodeFinder from "../../../components/settings/GeoCodeFinder";
import Input from "../../layout/Input";
import MultiChoiceButton from "../MultiChoiceButton";
import React, { useCallback, useState } from "react";
import Slider from "../../../components/layout/Slider";
import { StepTitle } from "../WizardLayout";
import SummaryTable from "../SummaryTable";
import WizardNavigation from "../WizardNavigation";
import { colours } from "../../../styles";
import environment from "../../../api/environment";
import styled from "styled-components";
import { useFetchData, useMutateData } from "../../../hooks";
import {
	IMPORT_TYPES,
	IMPORT_TYPES_TRANSLATIONS,
} from "../../../helpers/constants";
import { useDropzone } from "react-dropzone";
import { validateCSV } from "../../../helpers/validate";
import TableLayout from "../../layout/TableLayout";

const googleKey = environment.config.googleKey;

const ImgWrapper = styled.img`
	object-fit: cover;
	width: 100%;
`;

const Note = styled.p`
	color: ${colours.midGrey};
`;

const Wrapper = styled.div`
	a {
		color: ${colours.blue};
		text-decoration: none;
		transition: 0.2s ease;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
`;

const FormatLink = styled.div`
	font-size: 16px;
	margin: 16px 0;
`;

const ErrorText = styled.div`
	color: ${colours.red};
	font-size: 18px;
	font-weight: 600;
	margin: 16px 0 32px;
	text-align: center;
`;

const DropBox = styled.div`
	background: ${colours.highlightGrey};
	border: 4px dashed ${colours.borderGrey};
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 60px 10px;
	text-align: center;
	width: 100%;

	p {
		font-size: 18px;
		font-weight: 600;
	}

	&.dragover-err {
		border: 5px dashed $red;
	}

	&.dragover {
		border: 5px dashed $green;
	}
`;

const FlexBox = styled.div`
	display: flex;
	gap: 16px;
	margin-bottom: 10px;
`;

const FlexGrow = styled.div`
	flex-grow: 1;
`;

const MapWithMarker = withGoogleMap((props) => (
	<GoogleMap
		defaultZoom={19}
		defaultTilt={0}
		defaultMapTypeId={"satellite"}
		center={{
			lat: props.siteLat || null,
			lng: props.siteLng || null,
		}}
	>
		<Marker
			position={{
				lat: props.siteLat || null,
				lng: props.siteLng || null,
			}}
			draggable={true}
			onDragEnd={(event) => {
				props.setFieldValue(
					"Latitude",
					parseFloat(event.latLng.lat().toFixed(6))
				);
				props.setFieldValue(
					"Longitude",
					parseFloat(event.latLng.lng().toFixed(6))
				);
			}}
			name={"Site Location"}
		/>
	</GoogleMap>
));

function OrgAccessGroup(props) {
	const { data: orgAccessGroups } = useFetchData(
		[],
		installationApi.getOrganizationAccessGroups,
		[props.organizationId],
		[props.organizationId]
	);

	return (
		<Dropdown
			isMulti={true}
			options={orgAccessGroups.map((e) => ({
				value: e.OrganizationAccessGroupID,
				label: e.Name,
			}))}
			value={props.values.groups}
			onChange={(value) => {
				props.setFieldValue("groups", value);
			}}
		/>
	);
}

export const nameStep = ({
	values,
	goTo,
	wizardProps,
	next,
	setFieldValue,
	keyStrokeHandler,
}) => ({
	id: "name",
	label: "Name",
	render: () => (
		<div>
			<StepTitle>
				What is the name and description of your parking site?
			</StepTitle>
			<div>
				<h3>Site Name</h3>
				<Input
					type="text"
					name={"Name"}
					value={values.Name || ""}
					onChange={(event) =>
						setFieldValue("Name", trimStart(event.target.value))
					}
				/>
				<h3>Site Description</h3>
				<Input
					textArea={true}
					name={"Description"}
					value={values.Description || ""}
					onChange={(event) =>
						setFieldValue("Description", trimStart(event.target.value))
					}
				/>
			</div>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={wizardProps.close} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						setFieldValue("Name", trimEnd(values.Name));
						setFieldValue("Description", trimEnd(values.Description));
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={!values.Name || !values.Description}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const addressStep = ({
	values,
	setFieldValue,
	previous,
	next,
	keyStrokeHandler,
}) => ({
	id: "address",
	label: "Address",
	render: () => (
		<div>
			<StepTitle>What is the address of your parking site?</StepTitle>
			<GeoCodeFinder
				address={values.Address}
				mode={"Input"}
				onChange={(event) => {
					setFieldValue("validAddress", !event.isDirty);
					if (event.suggestion) {
						setFieldValue("Address", event.suggestion.Name);
						setFieldValue("Country", event.suggestion.Country);
						setFieldValue(
							"Latitude",
							parseFloat(event.suggestion.Latitude.toFixed(6))
						);
						setFieldValue(
							"Longitude",
							parseFloat(event.suggestion.Longitude.toFixed(6))
						);
					}
				}}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={next}
					disabled={!values.validAddress}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const mapStep = ({
	values,
	setFieldValue,
	previous,
	next,
	keyStrokeHandler,
}) => ({
	id: "map",
	label: "Map",
	render: () => (
		<div>
			<StepTitle>Select the location of your carpark</StepTitle>

			<FlexBox>
				<FlexGrow>
					<h3>Latitude</h3>
					<Input
						type="number"
						name={"Latitude"}
						step="0.000001"
						min={-90}
						max={90}
						value={values.Latitude || ""}
						onChange={(event) => {
							let val = parseFloat(event.target.value);

							if (val > 90) {
								val = 90;
							} else if (val < -90) {
								val = -90;
							}

							const stringValue = parseFloat(val).toFixed(6);
							setFieldValue("Latitude", parseFloat(stringValue));
						}}
						onBlur={() => {
							const stringValue = parseFloat(values.Latitude).toFixed(6);
							setFieldValue("Latitude", parseFloat(stringValue));
						}}
					/>
				</FlexGrow>
				<FlexGrow>
					<h3>Longitude</h3>
					<Input
						type="number"
						name={"Longitude"}
						step="0.000001"
						min={-180}
						max={180}
						value={values.Longitude || ""}
						onChange={(event) => {
							let val = parseFloat(event.target.value);

							if (val > 180) {
								val = 180;
							} else if (val < -180) {
								val = -180;
							}

							const stringValue = parseFloat(val).toFixed(6);
							setFieldValue("Longitude", parseFloat(stringValue));
						}}
						onBlur={() => {
							const stringValue = parseFloat(values.Longitude).toFixed(6);
							setFieldValue("Longitude", parseFloat(stringValue));
						}}
					/>
				</FlexGrow>
			</FlexBox>

			<MapWithMarker
				siteLat={values.Latitude}
				siteLng={values.Longitude}
				googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${googleKey}`}
				setFieldValue={setFieldValue}
				loadingElement={<div style={{ height: "385px" }} />}
				containerElement={
					<div
						style={{
							height: "385px",
							width: "100%",
						}}
					/>
				}
				mapElement={<div style={{ height: "385px" }} />}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={next}
					disabled={!values.Latitude || !values.Longitude}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const viewStep = ({
	values,
	setFieldValue,
	previous,
	next,
	keyStrokeHandler,
	goTo,
}) => ({
	id: "view",
	label: "View",
	render: () => (
		<div>
			<StepTitle>Rotate the camera to get an appropriate street view</StepTitle>
			<ImgWrapper
				src={`https://maps.googleapis.com/maps/api/streetview?size=520x300&location=${values.Latitude},${values.Longitude}&heading=${values.StreetViewRotation}&key=${googleKey}`}
			/>
			<Slider
				style={{ marginTop: "30px" }}
				min={0}
				max={359}
				onChange={(value) => setFieldValue("StreetViewRotation", value)}
				value={values.StreetViewRotation}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const taxStep = ({
	values,
	setFieldValue,
	goTo,
	next,
	previous,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "tax",
	label: "Tax",
	render: () =>
		wizardProps.clientAdmin ? (
			<div>
				<StepTitle>Select timezone </StepTitle>
				<h3>Timezone</h3>
				<Dropdown
					options={siteConstants.TIME_ZONES}
					value={siteConstants.TIME_ZONES.find(
						(e) => e.value === values.Timezone
					)}
					onChange={(value) => {
						setFieldValue(
							"TaxRateCountryCode",
							siteConstants.TAX_RATES[0].value
						);
						setFieldValue("Timezone", value.value);
						setFieldValue("Currency", siteConstants.CURRENCIES[0].value);
					}}
				/>
			</div>
		) : (
			<div>
				<StepTitle>Select tax type, timezone, and currency</StepTitle>
				<h3>Tax Type</h3>
				<Dropdown
					options={siteConstants.TAX_RATES}
					value={siteConstants.TAX_RATES.find(
						(e) => e.value === values.TaxRateCountryCode
					)}
					onChange={(value) => setFieldValue("TaxRateCountryCode", value.value)}
				/>
				<h3>Timezone</h3>
				<Dropdown
					options={siteConstants.TIME_ZONES}
					value={siteConstants.TIME_ZONES.find(
						(e) => e.value === values.Timezone
					)}
					onChange={(value) => setFieldValue("Timezone", value.value)}
				/>
				<h3>Currency</h3>
				<Dropdown
					options={siteConstants.CURRENCIES}
					value={siteConstants.CURRENCIES.find(
						(e) => e.value === values.Currency
					)}
					onChange={(value) => setFieldValue("Currency", value.value)}
				/>
			</div>
		),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={
						!values.TaxRateCountryCode || !values.Timezone || !values.Currency
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const contactStep = ({
	values,
	setFieldValue,
	goTo,
	next,
	previous,
	keyStrokeHandler,
}) => ({
	id: "contact",
	label: "Contact",
	render: () => (
		<div>
			<StepTitle>What are the contact details for your parking site?</StepTitle>
			<EditableInputField
				type="text"
				name={"EnquiryPhone"}
				useLabel={"Phone Number"}
				value={values.EnquiryPhone || ""}
				onChange={(event) =>
					setFieldValue("EnquiryPhone", trimStart(event.target.value))
				}
			/>
			<EditableInputField
				type="text"
				name={"EnquiryEmail"}
				useLabel={"Email Address"}
				value={values.EnquiryEmail || ""}
				onChange={(event) => {
					const result = userHelpers.emailValidator(
						String(event.target.value).toLowerCase()
					);

					setFieldValue("EnquiryEmail", event.target.value);
					setFieldValue("validEmail", result);
				}}
				error={
					!values.EnquiryEmail || values.validEmail
						? null
						: "Please supply a valid email address"
				}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						setFieldValue("EnquiryPhone", trimEnd(values.EnquiryPhone));
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={
						!values.EnquiryPhone || !values.EnquiryEmail || !values.validEmail
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const publicAccessStep = ({
	values,
	setFieldValue,
	goTo,
	previous,
	keyStrokeHandler,
}) => ({
	id: "publicAccess",
	label: "Public Access",
	render: () => (
		<div>
			<StepTitle>What type of parking does this site have ?</StepTitle>
			<MultiChoiceButton
				selected={!values.HasPublicAccess}
				onClick={() => {
					setFieldValue("HasPublicAccess", false);
					setFieldValue("TnCs", null);
				}}
			>
				Private
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.HasPublicAccess}
				onClick={() => {
					setFieldValue("HasPublicAccess", true);
					setFieldValue("MaximumCapacity", null);
					setFieldValue("groups", null);
				}}
			>
				Public
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.HasPublicAccess) {
							goTo("shortTermParking");
						} else {
							goTo("privateParking");
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const privateParkingStep = ({
	values,
	setFieldValue,
	handleChange,
	goTo,
	previous,
	keyStrokeHandler,
}) => ({
	id: "privateParking",
	label: "Private Parking",
	render: () => (
		<div>
			<StepTitle>What is the availability of your private parking?</StepTitle>
			<h3>How many parks are available for private parking?</h3>
			<Input
				type="number"
				min={1}
				name={"MaximumCapacity"}
				value={values.MaximumCapacity || ""}
				onChange={handleChange}
			/>
			<h3>What groups can access this private carpark?</h3>
			<Note>Note: You can always change this later</Note>
			<OrgAccessGroup
				setFieldValue={setFieldValue}
				organizationId={values.OrganizationID}
				values={values}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" onClick={previous} color="blue">
					Back
				</Button>,
			]}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editFromSummary) {
							goTo("summary");
						} else {
							goTo("gate");
						}
					}}
					disabled={
						!values.MaximumCapacity ||
						values.MaximumCapacity < 0 ||
						values.MaximumCapacity > 999999
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const shortTermParkingStep = ({
	values,
	setFieldValue,
	next,
	goTo,
	keyStrokeHandler,
}) => ({
	id: "shortTermParking",
	label: "Short Term Parking",
	render: () => (
		<div>
			<StepTitle>Does your site offer short term parking?</StepTitle>
			<MultiChoiceButton
				selected={!values.HasCasualParking}
				onClick={() => {
					setFieldValue("HasCasualParking", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.HasCasualParking}
				onClick={() => {
					setFieldValue("HasCasualParking", true);
				}}
			>
				Yes
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" onClick={() => goTo("publicAccess")} color="blue">
					Back
				</Button>,
			]}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={next}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const monthlyParkingStep = ({
	values,
	setFieldValue,
	next,
	previous,
	keyStrokeHandler,
}) => ({
	id: "bookings",
	label: "Bookings",
	render: () => (
		<div>
			<StepTitle>Does your site offer bookings?</StepTitle>
			<MultiChoiceButton
				selected={!values.HasLeaseParking}
				onClick={() => {
					setFieldValue("HasLeaseParking", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.HasLeaseParking}
				onClick={() => {
					setFieldValue("HasLeaseParking", true);
				}}
			>
				Yes
			</MultiChoiceButton>
			{!values.HasCasualParking && !values.HasLeaseParking && (
				<div style={{ color: `${colours.red}` }}>
					Your site must support at least one method of parking.
				</div>
			)}
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" onClick={previous} color="blue">
					Back
				</Button>,
			]}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={next}
					disabled={!values.HasCasualParking && !values.HasLeaseParking}
					keyStrokeHandler={keyStrokeHandler}
				>
					Next
				</Button>,
			]}
		/>
	),
});

export const maximumCapacityStep = ({
	values,
	handleChange,
	next,
	previous,
	goTo,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "maximumCapacity",
	label: "Total Parking Spaces",
	render: () => (
		<div>
			<StepTitle>What is the total parking spaces for this site?</StepTitle>
			<Input
				type="number"
				min={1}
				name={"MaximumCapacity"}
				value={values.MaximumCapacity || ""}
				onChange={handleChange}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!(wizardProps.clientAdmin && values.editFromSummary) && (
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>
				)
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (wizardProps.clientAdmin && values.editFromSummary) {
							goTo("summary");
						} else {
							next();
						}
					}}
					disabled={
						!values.MaximumCapacity ||
						values.MaximumCapacity < 0 ||
						values.MaximumCapacity > 999999
					}
					keyStrokeHandler={keyStrokeHandler}
				>
					{wizardProps.clientAdmin && values.editFromSummary
						? "Review"
						: "Next"}
				</Button>,
			]}
		/>
	),
});

export const termsAndConditionsStep = ({
	values,
	handleChange,
	next,
	previous,
	goTo,
	setFieldValue,
	keyStrokeHandler,
}) => ({
	id: "termsAndConditions",
	label: "Terms And Conditions",
	render: () => (
		<div>
			<StepTitle>
				What are the terms and conditions for your parking site?
			</StepTitle>
			<Note>Note: you can always add these later</Note>
			<Input
				textArea={true}
				name={"TnCs"}
				value={values.TnCs || ""}
				onChange={handleChange}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editTnCs && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.editTnCs) {
							goTo("summary");
							setFieldValue("editTnCs", false);
						} else {
							next();
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editTnCs ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

export const gateStep = ({
	values,
	setFieldValue,
	goTo,
	next,
	previous,
	keyStrokeHandler,
}) => ({
	id: "gate",
	label: "Gate",
	render: () => (
		<div>
			<StepTitle>Does your site have gates or barrier arms?</StepTitle>
			<MultiChoiceButton
				selected={values.SiteType === "Gateless"}
				onClick={() => {
					setFieldValue("SiteType", "Gateless");
					setFieldValue("HasSessionlessAccess", false);
					setFieldValue("AutoLaneDetection", false);
					setFieldValue("OfflineOpening", false);
				}}
			>
				No
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.SiteType === "Gated"}
				onClick={() => {
					setFieldValue("SiteType", "Gated");
					setFieldValue("AutoLaneDetection", true);
					setFieldValue("OfflineOpening", true);
				}}
			>
				Yes
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button
						key="cancel"
						onClick={() => {
							if (!values.HasPublicAccess) {
								goTo("privateParking");
							} else {
								previous();
							}
						}}
						color="blue"
					>
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						if (values.SiteType === "Gateless") {
							goTo("summary");
						} else {
							next();
						}
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary && values.SiteType === "Gateless"
						? "Review"
						: "Next"}
				</Button>,
			]}
		/>
	),
});

export const sessionlessStep = ({
	values,
	setFieldValue,
	goTo,
	previous,
	keyStrokeHandler,
}) => ({
	id: "sessionless",
	label: "Sessionless",
	render: () => (
		<div>
			<StepTitle>Would you like to create parking sessions?</StepTitle>
			<Note>
				Note: Parking sessions record the duration a user has parked, however a
				user in a parking session must exit before they can enter again.
			</Note>
			<MultiChoiceButton
				selected={!values.HasSessionlessAccess}
				onClick={() => {
					setFieldValue("HasSessionlessAccess", false);
				}}
			>
				Yes
			</MultiChoiceButton>
			<MultiChoiceButton
				selected={values.HasSessionlessAccess}
				onClick={() => {
					setFieldValue("HasSessionlessAccess", true);
				}}
			>
				No
			</MultiChoiceButton>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={
				!values.editFromSummary && [
					<Button key="cancel" onClick={previous} color="blue">
						Back
					</Button>,
				]
			}
			rightItems={[
				<Button
					key="next"
					color="blue"
					onClick={() => {
						goTo("summary");
					}}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.editFromSummary ? "Review" : "Next"}
				</Button>,
			]}
		/>
	),
});

/* ---------- Import Steps ---------- */

const schema = [
	{ name: "LocationName", required: true },
	{ name: "LocationCode", required: false },
	{ name: "LocationAddressLine1", required: true },
	{ name: "LocationAddressLine2", required: false },
	{ name: "LocationAddressLine3", required: false },
	{ name: "LocationCity", required: true },
	{ name: "LocationPostCode", required: true },
	{ name: "LocationCountry", required: true },
	{ name: "Longitude", required: true },
	{ name: "Latitude", required: true },
	{ name: "Timezone", required: true },
	{ name: "Currency", required: true },
	{ name: "TaxType", required: true },
	{ name: "TotalParkingSpaces", required: true },
	{ name: "ContactPhone", required: true },
	{ name: "ContactEmail", required: true },
	{ name: "CarParkDescription", required: false },
	{ name: "ExternalReference", required: false },
	{ name: "EnforcementReference", required: false },
	{ name: "IsPublic", required: true },
	{ name: "SiteType", required: true },
	{ name: "ParkingSessions", required: true },
	{ name: "Bookings", required: true },
	{ name: "SessionlessAccess", required: true },
	{ name: "PayOnExit", required: true },
	{ name: "PayAfterExit", required: true },
	{ name: "Validation", required: true },
	{ name: "ValidationStacking", required: true },
	{ name: "OutstandingBalanceExpiry", required: false },
	{ name: "SessionOpenReminder", required: false },
	{ name: "AutomaticallyCloseSession", required: false },
	{ name: "MondayHours", required: false },
	{ name: "TuesdayHours", required: false },
	{ name: "WednesdayHours", required: false },
	{ name: "ThursdayHours", required: false },
	{ name: "FridayHours", required: false },
	{ name: "SaturdayHours", required: false },
	{ name: "SundayHours", required: false },
];

async function importCSV(file, setSuccess, setError, opts = {}) {
	const { createSiteViaCsv, organizationId } = opts;
	try {
		await createSiteViaCsv({
			variables: {
				file: file,
				organizationId,
			},
			onCompleted: (result) => {
				if (result.createSiteViaCsv) {
					setSuccess(result.createSiteViaCsv);
				}
			},
		});
	} catch (error) {
		setError();
	}
}

export const createTypeStep =
	(setImportType) =>
	({ values, close, next, setFieldValue, keyStrokeHandler }) => ({
		id: "createType",
		label: "Create Type",
		render: () => (
			<div>
				<StepTitle>How will you create your site(s)?</StepTitle>

				{[IMPORT_TYPES.WIZARD, IMPORT_TYPES.IMPORT].map((importType) => (
					<MultiChoiceButton
						key={importType}
						selected={values.importType === importType}
						onClick={() => {
							setFieldValue("importType", importType);
							setImportType(importType);
						}}
					>
						{IMPORT_TYPES_TRANSLATIONS[importType]}
					</MultiChoiceButton>
				))}
			</div>
		),
		footer: () => (
			<WizardNavigation
				leftItems={
					!values.editFromSummary && [
						<Button key="cancel" onClick={close} color="red">
							Cancel
						</Button>,
					]
				}
				rightItems={[
					<Button
						key="next"
						color="blue"
						onClick={() => {
							next();
						}}
						disabled={!values.importType}
						keyStrokeHandler={keyStrokeHandler}
					>
						Next
					</Button>,
				]}
			/>
		),
	});

function ImportSites({ state, setState }) {
	const onDrop = useCallback((acceptedFiles) => {
		setState((_state) => ({
			..._state,
			file: acceptedFiles ? acceptedFiles[0] : null,
			error: false,
		}));
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	return (
		<Wrapper>
			<StepTitle>How will you create your site(s)?</StepTitle>

			{!state.submitted && (
				<FormatLink>
					The file must be in the following{" "}
					<a
						href={`data:text/csv;charset=utf-8,${encodeURI(
							schema.map((column) => column.name).join(",")
						)}`}
						download="format.csv"
					>
						CSV format
					</a>
					.
				</FormatLink>
			)}

			{!state.submitted && (
				<DropBox {...getRootProps()}>
					<input {...getInputProps()} multiple={false} accept="text/csv" />
					{isDragActive && <p>Drop the file here...</p>}
					{!state.file && !isDragActive && (
						<p>Click to choose a file or drag one here...</p>
					)}
					{state.file && !isDragActive && <p>File chosen: {state.file.name}</p>}
				</DropBox>
			)}

			{state.error && (
				<ErrorText>The file does not match the required format</ErrorText>
			)}
		</Wrapper>
	);
}

export const importSitesStep =
	(state, setState, createSiteViaCsv) =>
	({ previous, next, keyStrokeHandler, values }) => {
		const isIncomplete = state.submitted || state.error || !state.file;

		return {
			id: "importSites",
			label: "Import Sites",
			render: () => <ImportSites state={state} setState={setState} />,
			footer: () => (
				<WizardNavigation
					leftItems={
						<Button
							key="back"
							color="blue"
							onClick={previous}
							keyStrokeHandler={keyStrokeHandler}
						>
							Back
						</Button>
					}
					rightItems={[
						<Button
							key="import"
							color="green"
							onClick={() => {
								setState((_state) => ({
									..._state,
									submitting: true,
									submitted: true,
									error: false,
								}));

								validateCSV(
									state.file,
									schema,
									(results) => {
										setState((_state) => ({
											..._state,
											submitting: false,
											submitted: true,
											error: false,
											results,
										}));
										next();
									},
									() => {
										setState((_state) => ({
											..._state,
											submitting: false,
											submitted: false,
											error: true,
										}));
									},
									importCSV,
									{
										createSiteViaCsv,
										organizationId: values.OrganizationID,
									}
								);
							}}
							disabled={isIncomplete}
							keyStrokeHandler={keyStrokeHandler}
						>
							Import
						</Button>,
					]}
				/>
			),
		};
	};

export const resultsStep =
	(state) =>
	({ close, keyStrokeHandler }) => ({
		render: () => (
			<div>
				{state.results && (
					<div>
						<StepTitle>Results</StepTitle>
						<TableLayout
							data={sortBy(state.results, "index")}
							columns={[
								{
									id: "index",
									Header: "Row",
									accessor: "index",
									Cell: (props) => {
										return props.index + 1;
									},
									width: 50,
								},
								{
									id: "ErrorMessages",
									Header: "ErrorMessages",
									accessor: (d) => d.ErrorMessages.join(", "), //for sorting
									Cell: (props) =>
										props.original.ErrorMessages.map((errorMessage, index) => (
											<span key={index}>
												<strong>{index + 1}. </strong>
												{errorMessage}
												<br />
											</span>
										)),
									width: 250,
								},
								...schema.map((column) => ({
									id: column.name,
									Header: column.name,
									accessor: column.name,
									width: 200,
								})),
							]}
							sortable={false}
							resizable={false}
							showResultsLength={true}
							showPagination={state.results?.length > 10}
							defaultPageSize={10}
						/>
					</div>
				)}
			</div>
		),
		footer: () => (
			<WizardNavigation
				rightItems={[
					<Button
						key="back"
						color="blue"
						onClick={close}
						keyStrokeHandler={keyStrokeHandler}
					>
						Close
					</Button>,
				]}
			/>
		),
	});

export const summaryStep = ({
	goTo,
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => (
		<div>
			<StepTitle>Summary</StepTitle>
			<SummaryTable
				items={[
					{
						title: "Name",
						value: values.Name,
						key: "Name",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("name");
						},
					},
					{
						title: "Address",
						value: values.Address,
						key: "Address",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("address");
						},
					},
					{
						title: "Coordinates",
						value: `Lat: ${values.Latitude} Lng: ${values.Longitude}`,
						key: "Coordinates",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("map");
						},
					},
					{
						title: "Contact Number",
						value: values.EnquiryPhone,
						key: "ContactNumber",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("contact");
						},
					},
					{
						title: "Contact Email",
						value: values.EnquiryEmail,
						key: "ContactEmail",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("contact");
						},
					},
					{
						title: "Parking Spaces",
						value: values.MaximumCapacity,
						key: "ParkingSpaces",
						hide: !wizardProps.clientAdmin,
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("maximumCapacity");
						},
					},
					{
						title: "Open To",
						value: values.HasPublicAccess ? "Public" : "Private",
						key: "OpenTo",
						hide: wizardProps.clientAdmin,
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("publicAccess");
						},
					},
					{
						title: "Terms and Conditions",
						value: values.TnCs ? "Provided" : "Not Provided",
						key: "TermsAndConditions",
						hide: wizardProps.clientAdmin || !values.HasPublicAccess,
						edit: () => {
							setFieldValue("editTnCs", true);
							goTo("termsAndConditions");
						},
					},
					{
						title: "Gated Access",
						value:
							values.SiteType === "Gated"
								? "Gates or Barriers"
								: "No Gates or Barriers",
						key: "Gated Access",
						hide: wizardProps.clientAdmin,
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("gate");
						},
					},
					{
						title: "Parking Sessions",
						value: values.HasSessionlessAccess ? "Sessionless" : "Yes",
						key: "parkingSessions",
						hide: wizardProps.clientAdmin || values.SiteType === "Gateless",
						edit: () => {
							setFieldValue("editFromSummary", true);
							goTo("sessionless");
						},
					},
				]}
			/>
		</div>
	),
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					{wizardProps.mode === "edit-special-instruction"
						? "Update"
						: "Create"}
				</Button>,
			]}
		/>
	),
});
