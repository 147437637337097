import * as installationApi from "../../../../api/installation";
import Button from "../../../layout/Button";
import React from "react";
import WizardNavigation from "../../WizardNavigation";
import { getFormattedRate } from "./format-rate-data";
/**
 * Footer for Wizards
 *
 * @param {Object} values - All values collected by the Wizard
 * @param {Function} previous - Go to the previous step
 * @param {Function} goTo - Go to a step
 * @param {String|Function} next - The name of the step to go to next or a custom handler
 * @param {Object} wizardProps - React properties of the Wizard
 * @param {Boolean} isValid - Whether or not the step is valid, used for enabling/disabling the next button
 * @param {Boolean} isFirstStep - Only the first step has a cancel button
 */
function footer(
	values,
	previous,
	goTo,
	next,
	wizardProps,
	isValid,
	isFirstStep = false,
	setFieldValue,
	isScheduleStep,
	keyStrokeHandler
) {
	const showNavigation = !values.editFromSummary && values.mode === "add";
	let right = showNavigation
		? { key: "next", text: "Next", next: next }
		: { key: "review", text: "Review", next: "summary" };
	let clickEvents = {
		back: previous,
		cancel: wizardProps.close,
	};
	let left = isFirstStep ? "cancel" : "back";
	let leftItem = (
		<Button key={left} onClick={clickEvents[left]} color="blue">
			{left.charAt(0).toUpperCase()}
			{left.slice(1)}
		</Button>
	);

	return (
		<WizardNavigation
			leftItems={showNavigation && [leftItem]}
			rightItems={[
				<Button
					disabled={!isValid}
					key={right.key}
					onClick={async () => {
						let errorMessage = null;
						let incorrectEarlyBirdExitTime = false;
						let incorrectAfterHoursEarlyBirdEntryTime = false;

						if (isScheduleStep) {
							const rate = getFormattedRate(values, wizardProps.site.SiteID);
							const response = await installationApi.getRateConflict(
								wizardProps.site.SiteID,
								rate
							);

							errorMessage = response ? response.errorMessage : null;

							if (rate.RateClass === "EarlyBird") {
								incorrectEarlyBirdExitTime =
									rate.ExitLaterThan >= rate.ExitEarlierThan;
							}

							if (rate.RateClass === "AfterhoursEarlyBird") {
								incorrectAfterHoursEarlyBirdEntryTime =
									rate.EntryLaterThan >= rate.EntryEarlierThan;
							}
						}

						setFieldValue("conflictRateErrorMessage", errorMessage);
						setFieldValue(
							"incorrectEarlyBirdExitTime",
							incorrectEarlyBirdExitTime
						);
						setFieldValue(
							"incorrectAfterHoursEarlyBirdEntryTime",
							incorrectAfterHoursEarlyBirdEntryTime
						);

						if (
							!errorMessage &&
							!incorrectEarlyBirdExitTime &&
							!incorrectAfterHoursEarlyBirdEntryTime
						) {
							typeof right.next === "string" ? goTo(right.next) : right.next();
						}
					}}
					color="blue"
					keyStrokeHandler={keyStrokeHandler}
				>
					{right.text}
				</Button>,
			]}
		/>
	);
}

export default footer;
