import * as Icon from "react-feather";
import * as api from "../api";
import React, { useContext, useRef, useState } from "react";
import { routing, user } from "../helpers";
import { useHasClientAdmin, useMutateData, usePermissions } from "../hooks";
import { AppContext } from "../context/app-context";
import AppMenu from "../components/menu/AppMenu";
import CreateOperatorWizard from "../components/wizards/create-operator-wizard/CreateOperatorWizard";
import CreateOrganizationWizard from "../components/wizards/create-organization-wizard/CreateOrganizationWizard";
import CreateSiteWizard from "../components/wizards/create-site-wizard/CreateSiteWizard";
import Dropdown from "../components/layout/SiteSelectDropdown";
import { MenuContext } from "../context/menu-context";
import MenuItem from "../components/menu/MenuItem";
import MenuSubItem from "../components/menu/MenuSubItem";
import { NavLink } from "react-router-dom";
import gql from "graphql-tag";
import integrationIcon from "../images/streamline-coding-apps-website-web-dev-cog-api.svg";
import signageIcon from "../images/streamline-icon-business-crossroad.svg";
import { useTranslation } from "react-i18next";
import _ from "lodash";

export default function MenuContainer(props) {
	const { t: translate } = useTranslation();

	const [state, setState] = useState({
		createOperatorWizardOpen: false,
		createOrganizationWizardOpen: false,
		createSiteWizardOpen: false,
	});

	const {
		state: {
			availableOperators,
			availableOrganizations,
			availableSites,
			currentUser,
			selectedOrganization,
			selectedSite,
			spaceAccountOwnerOnly,
		},
		dispatch: { setSite, setOrganization, setOperator },
	} = useContext(AppContext);

	const selectedOperator = props.selectedOperator;

	const {
		state: { expandedItem, menuCollapsed },
		dispatch: { collapse, expandItem },
	} = useContext(MenuContext);

	const logoutMutation = useMutateData(gql`
		mutation ($sessionKey: String!) {
			logout(sessionKey: $sessionKey)
		}
	`);

	async function logOut() {
		if (api.hasSessionKey()) {
			await logoutMutation({ variables: { sessionKey: api.getSessionKey() } });
			props.history.push("/login");
			api.clearSessionKey();
		} else {
			props.history.push("/login");
		}
	}

	const availableSitesMap = availableSites.map((site) => {
		const displayLabel = `${site.SecureParkingExternalID ? "(V) " : " "}${
			site.Name
		}`;

		return {
			...site,
			value: site.SiteID,
			label: displayLabel,
		};
	});

	const availableOperatorsMap = _.orderBy(
		availableOperators?.map((operator) => ({
			...operator,
			value: operator.OperatorID,
			label: operator.Name,
		})) || [],
		["label", "asc"]
	);

	const availableOrganizationsMap = (availableOrganizations || []).map(
		(org) => ({
			...org,
			value: org.OrganizationID,
			label: org.Name,
		})
	);

	function closeWizard() {
		if (state.createOrganizationWizardOpen || state.createSiteWizardOpen) {
			setState((_state) => ({
				..._state,
				createOrganizationWizardOpen: false,
				createSiteWizardOpen: false,
			}));
		}
	}

	const isAdmin = usePermissions("IsAdmin");
	const hasClientAdmin = useHasClientAdmin(
		selectedOrganization?.ClientID || selectedOperator?.ClientID
	);
	const hasAnyClientAdmin = useHasClientAdmin(0);

	const viewHistory = usePermissions(null, "ViewHistory", true);
	const canEditSettings = usePermissions(null, "EditSettings", true);

	const siteSelectRef = useRef(null);
	const operatorSelectRef = useRef(null);
	const organizationSelectRef = useRef(null);

	return (
		<div>
			{state.createOperatorWizardOpen && (
				<CreateOperatorWizard
					close={() =>
						setState((_state) => ({
							..._state,
							createOperatorWizardOpen: false,
						}))
					}
				/>
			)}
			{state.createOrganizationWizardOpen && (
				<CreateOrganizationWizard
					selectedOperator={selectedOperator}
					close={() =>
						setState((_state) => ({
							..._state,
							createOrganizationWizardOpen: false,
						}))
					}
				/>
			)}
			{state.createSiteWizardOpen && selectedOrganization && (
				<CreateSiteWizard
					close={() =>
						setState((_state) => ({
							..._state,
							createSiteWizardOpen: false,
						}))
					}
					operatorId={selectedOperator.OperatorID}
					organizationId={selectedOrganization.OrganizationID}
					clientAdmin={!isAdmin && hasClientAdmin}
				/>
			)}
			<AppMenu
				hasEditPermission={isAdmin || hasClientAdmin}
				menuCollapsed={menuCollapsed}
				collapse={collapse}
				operatorMenu={{
					expand: expandedItem === "Operator",
					selected: selectedOperator,
					hide:
						!(isAdmin || hasClientAdmin) && availableOperatorsMap?.length === 1,
					select: () => {
						expandItem("Operator");
						setTimeout(() => {
							operatorSelectRef.current?.focus();
						}, 300);
					},
					items:
						availableOperatorsMap?.length >= 1 ||
						isAdmin ||
						hasAnyClientAdmin ? (
							<React.Fragment>
								{(isAdmin || hasAnyClientAdmin) && (
									<MenuSubItem
										itemName={
											<div style={{ display: "flex" }}>
												<Icon.Plus size={16} />
												Create Operator
											</div>
										}
										isCreate={true}
										onClick={() => {
											setState((_state) => ({
												..._state,
												createOperatorWizardOpen: true,
											}));
										}}
									/>
								)}
								<Dropdown
									ref={operatorSelectRef}
									isSearchable={true}
									menuIsOpen={true}
									options={availableOperatorsMap}
									value={
										selectedOperator
											? {
													value: selectedOperator.OperatorID,
													label: selectedOperator.Name,
											  }
											: null
									}
									onChange={(value) => {
										setOperator(value);
										props.history.push(`/operator/${value.OperatorID}`);
										expandItem(null);
										closeWizard();
									}}
								/>
							</React.Fragment>
						) : null,
					submenu:
						isAdmin || hasClientAdmin
							? [
									<NavLink
										key="/integrations"
										to={routing.getOperatorRoute(
											selectedOperator,
											"integrations"
										)}
									>
										<MenuItem
											key={"Integrations"}
											itemName={translate("Menu.Integrations")}
											leftIcon={
												<img
													src={integrationIcon}
													height={"24px"}
													width={"24px"}
												/>
											}
											menuCollapsed={menuCollapsed}
										/>
									</NavLink>,
							  ]
							: [],
				}}
				clientMenu={{
					expand: expandedItem === "Organization",
					selected: selectedOrganization,
					select: () => {
						expandItem("Organization");
						setTimeout(() => {
							organizationSelectRef.current?.focus();
						}, 300);
					},
					items:
						availableOrganizationsMap.length !== 1 ||
						isAdmin ||
						hasAnyClientAdmin ? (
							<React.Fragment>
								{isAdmin || hasAnyClientAdmin ? (
									<MenuSubItem
										itemName={
											<div style={{ display: "flex" }}>
												<Icon.Plus size={16} />
												Create Client
											</div>
										}
										isCreate={true}
										onClick={() => {
											setState((_state) => ({
												..._state,
												createOrganizationWizardOpen: true,
											}));
										}}
									/>
								) : null}
								<Dropdown
									ref={organizationSelectRef}
									isSearchable={true}
									menuIsOpen={true}
									options={availableOrganizationsMap}
									value={
										selectedOrganization
											? {
													value: selectedOrganization.OrganizationID,
													label: selectedOrganization.Name,
											  }
											: null
									}
									onChange={(value) => {
										//direct to the same page of that org
										const pathname = props.history.location.pathname;
										let page = pathname.replace(
											`/operator/${selectedOperator.OperatorID}/organization/${selectedOrganization.OrganizationID}/`,
											""
										);
										if (page.includes("site")) page = "";

										setOrganization(value);
										props.history.push(
											`/operator/${selectedOperator.OperatorID}/organization/${
												value.OrganizationID
											}${page ? `/${page}` : ""}`
										);

										expandItem(null);
										closeWizard();
									}}
								/>
							</React.Fragment>
						) : null,
				}}
				itemOnClick={closeWizard}
				topItems={
					spaceAccountOwnerOnly
						? selectedOrganization && [
								<NavLink
									key="/permissions"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"permissions"
									)}
								>
									<MenuItem
										itemName={translate("Menu.Permissions")}
										leftIcon={<Icon.Shield size={24} />}
										menuCollapsed={menuCollapsed}
									/>
								</NavLink>,
						  ]
						: selectedOrganization && [
								<NavLink
									key="/dashboard"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"dashboard"
									)}
								>
									<MenuItem
										itemName={translate("Menu.Dashboard")}
										leftIcon={<Icon.BarChart2 size={24} />}
										menuCollapsed={menuCollapsed}
									/>
								</NavLink>,
								<NavLink
									key="/revenue"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"revenue"
									)}
								>
									<MenuItem
										itemName={translate("Menu.Revenue")}
										leftIcon={<Icon.DollarSign size={24} />}
										menuCollapsed={menuCollapsed}
									/>
								</NavLink>,
								<NavLink
									key="/users"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"users"
									)}
								>
									<MenuItem
										itemName={translate("Menu.Users")}
										leftIcon={<Icon.Users size={24} />}
										menuCollapsed={menuCollapsed}
									/>
								</NavLink>,
								<NavLink
									key="/activity"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"activity"
									)}
								>
									<MenuItem
										itemName={translate("Menu.Activity")}
										leftIcon={<Icon.Activity size={24} />}
										menuCollapsed={menuCollapsed}
									/>
								</NavLink>,
								<NavLink
									key="/transient"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"transient"
									)}
								>
									<MenuItem
										itemName={translate("Menu.Transient")}
										leftIcon={<Icon.Clock size={24} />}
										menuCollapsed={menuCollapsed}
									/>
								</NavLink>,
								<NavLink
									key="/bookings"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"bookings"
									)}
								>
									<MenuItem
										itemName={translate("Menu.Bookings")}
										leftIcon={<Icon.Calendar size={24} />}
										menuCollapsed={menuCollapsed}
									/>
								</NavLink>,
								<NavLink
									key="/validation"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"validation"
									)}
								>
									<MenuItem
										itemName={translate("Menu.Validation")}
										leftIcon={<Icon.Star size={24} />}
										menuCollapsed={menuCollapsed}
									/>
								</NavLink>,
								<NavLink
									key="/rates"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"rates"
									)}
								>
									<MenuItem
										itemName={translate("Menu.Rates")}
										leftIcon={<Icon.Sliders size={24} />}
										menuCollapsed={menuCollapsed}
									/>
								</NavLink>,
								<NavLink
									key="/space-management"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"space-management"
									)}
								>
									<MenuItem
										itemName={translate("Menu.SpaceManagement")}
										leftIcon={<Icon.Grid size={24} />}
										menuCollapsed={menuCollapsed}
									/>
								</NavLink>,
								<NavLink
									key="/permissions"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"permissions"
									)}
								>
									<MenuItem
										itemName={translate("Menu.Permissions")}
										leftIcon={<Icon.Shield size={24} />}
										menuCollapsed={menuCollapsed}
									/>
								</NavLink>,
								<NavLink
									key="/history"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"history"
									)}
								>
									{viewHistory && (
										<MenuItem
											key={"History"}
											itemName={translate("Menu.History")}
											leftIcon={<Icon.List size={24} />}
											menuCollapsed={menuCollapsed}
										/>
									)}
								</NavLink>,
								<NavLink
									key="/billing"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"billing"
									)}
								>
									{(isAdmin || canEditSettings) && (
										<MenuItem
											key={"billing"}
											itemName={translate("Menu.Billing")}
											leftIcon={<Icon.CreditCard size={24} />}
											menuCollapsed={menuCollapsed}
										/>
									)}
								</NavLink>,
								<NavLink
									key="/brands"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"brands"
									)}
								>
									{(isAdmin || hasClientAdmin) && (
										<MenuItem
											key={"Brands"}
											itemName={translate("Menu.Brands")}
											leftIcon={<Icon.PenTool size={24} />}
											menuCollapsed={menuCollapsed}
										/>
									)}
								</NavLink>,
								<NavLink
									key="/signage"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"signage"
									)}
								>
									{(isAdmin || hasClientAdmin) && (
										<MenuItem
											key={"Signage"}
											itemName={translate("Menu.Signage")}
											leftIcon={
												<img src={signageIcon} height={"24px"} width={"24px"} />
											}
											menuCollapsed={menuCollapsed}
										/>
									)}
								</NavLink>,
								<NavLink
									key="/integrations"
									to={routing.getOrganizationRoute(
										selectedOperator,
										selectedOrganization,
										"integrations"
									)}
								>
									{(isAdmin || hasClientAdmin) && (
										<MenuItem
											key={"Integrations"}
											itemName={translate("Menu.Integrations")}
											leftIcon={
												<img
													src={integrationIcon}
													height={"24px"}
													width={"24px"}
												/>
											}
											menuCollapsed={menuCollapsed}
										/>
									)}
								</NavLink>,
								<div key={"Site Settings"}>
									<MenuItem
										itemName={translate("Menu.SiteSettings")}
										leftIcon={<Icon.MapPin size={24} />}
										subItems={
											<React.Fragment>
												{(isAdmin || hasClientAdmin) && (
													<MenuSubItem
														key={"Create Site"}
														itemName={
															<div style={{ display: "flex" }}>
																<Icon.Plus
																	style={{
																		margin: "2px 5px 0 0",
																		verticalAlign: "sub",
																	}}
																	size={16}
																/>
																Create Site
															</div>
														}
														onClick={() =>
															setState((_state) => ({
																..._state,
																createSiteWizardOpen: true,
															}))
														}
													/>
												)}
												<Dropdown
													ref={siteSelectRef}
													isSearchable={true}
													menuIsOpen={true}
													options={availableSitesMap}
													value={
														selectedSite
															? {
																	value: selectedSite.SiteID,
																	label: selectedSite.Name,
															  }
															: null
													}
													onChange={(value) => {
														let tab = "";
														//direct to the same tab of that site
														if (selectedSite) {
															const pathname = props.history.location.pathname;
															tab = pathname.replace(
																`/organization/${selectedOrganization.OrganizationID}/site/${selectedSite.SiteID}/`,
																""
															);
														}

														setSite(value)
															.then(() => {
																if (
																	tab === "installation" &&
																	value.SiteType !== "Gated"
																)
																	tab = "";

																routing.goToSite(
																	props.history,
																	selectedOperator,
																	selectedOrganization,
																	value.value,
																	tab
																);
															})
															.then(closeWizard());
													}}
												/>
											</React.Fragment>
										}
										onClick={() => {
											expandItem("Site Settings");
											setTimeout(() => {
												siteSelectRef.current.focus();
											}, 300);
										}}
										expand={expandedItem === "Site Settings"}
										menuCollapsed={menuCollapsed}
									/>
								</div>,
						  ]
				}
				bottomItems={[
					(isAdmin || hasAnyClientAdmin) && (
						<MenuItem
							key={"Administration"}
							itemName={translate("Menu.Administration")}
							leftIcon={<Icon.Settings size={24} />}
							subItems={[
								isAdmin && (
									<NavLink key="/admin-integrations" to="/admin-integrations">
										<MenuSubItem
											key={"AdminIntegrations"}
											itemName={translate("Menu.AdminIntegrations")}
										/>
									</NavLink>
								),
								(isAdmin || hasAnyClientAdmin) && (
									<NavLink key="/admin-hardware" to="/admin-hardware">
										<MenuSubItem
											key={"Hardware"}
											itemName={translate("Menu.Hardware")}
										/>
									</NavLink>
								),
								<NavLink key="/admin-permissions" to="/admin-permissions">
									<MenuSubItem
										key={"Admin Permissions"}
										itemName={translate("Menu.AdminPermissions")}
									/>
								</NavLink>,
								isAdmin && (
									<NavLink key="/admin-activity" to="/admin-activity">
										<MenuSubItem
											key={"Admin Activity"}
											itemName={translate("Menu.AdminActivity")}
										/>
									</NavLink>
								),
								isAdmin && (
									<NavLink key="/admin-audit" to="/admin-audit">
										<MenuSubItem
											key={"Admin Audit History"}
											itemName={translate("Menu.AdminAuditHistory")}
										/>
									</NavLink>
								),
								isAdmin && (
									<NavLink key="/admin-users" to="/admin-users">
										<MenuSubItem
											key={"Global Users"}
											itemName={translate("Menu.GlobalUsers")}
										/>
									</NavLink>
								),
							].filter((e) => e)}
							onClick={() => expandItem("Administration")}
							expand={expandedItem === "Administration"}
							menuCollapsed={menuCollapsed}
						/>
					),
					<MenuItem
						key={"Profile"}
						itemName={user.fullName(currentUser)}
						leftIcon={<Icon.User size={24} />}
						subItems={[
							<MenuSubItem
								key={"Log out"}
								itemName={translate("Menu.LogOut")}
								onClick={async () => {
									await logOut();
								}}
							/>,
						]}
						onClick={() => expandItem("Profile")}
						expand={expandedItem === "Profile"}
						menuCollapsed={menuCollapsed}
					/>,
				]}
			/>
		</div>
	);
}
