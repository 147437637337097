import gql from "graphql-tag";
import { useQueryData } from "../../../hooks";

export const useGetIntegrations = (context, organizationId) => {
	const {
		data: { getIntegrations: integrations },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query ($context: IntegrationContext!, $organizationId: Int) {
				getIntegrations(context: $context, organizationId: $organizationId) {
					IntegrationID
					IntegrationType
					EventType
					AuthType
					FetchRequest {
						RequestID
						Name
						URL
						Method
					}
					Sites {
						SiteID
						Name
					}
					Cameras {
						CameraID
						SerialNumber
					}
					Metadata {
						Interval
						Expiry
						Directory
						CSVConfig {
							Headers {
								Header
								Required
							}
							ExternalUserCode {
								Column
							}
							SiteID {
								Column
							}
							GroupName {
								Column
							}
							MappedValues {
								Column
								From
								To
							}
						}
					}
					IsEnabled
					IsDeleted
					LoggingEnabled
				}
			}
		`,
		{
			context,
			organizationId,
		}
	);

	return {
		integrations,
		isLoading,
		refetch,
	};
};

export const useGetRequests = (context, organizationId) => {
	const {
		data: { getRequests: requests },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query ($context: IntegrationContext!, $organizationId: Int) {
				getRequests(context: $context, organizationId: $organizationId) {
					RequestID
					Name
					URL
					Method
					Params {
						Key
						Value
						Description
					}
					AuthorizationType
					Headers {
						Key
						Value
						Description
					}
					Transformations {
						Variable {
							Name
							Label
							Type
						}
						Name
						Label
						Transformation
						Template
						Format
						Hours
						CharacterLength
						Key
						Find
						Replace
					}
					Body
					BodyLanguage
					Response {
						Path
						Variable
						Type
						Label
						Description
					}
					Rules {
						PreRequest {
							Type
							Variable
							ResponseCode
							Condition
							Outcome
							Request
							UnsetVariable
						}
						PostRequest {
							Type
							Variable
							ResponseCode
							Condition
							Outcome
							Request
							UnsetVariable
						}
					}
					BasicAuthCredentials {
						Username
						Password
					}
					BearerToken
					SFTPConnection {
						Username
						Password
						PrivateKey
						Port
					}
				}
			}
		`,
		{
			context,
			organizationId,
		}
	);

	return {
		requests,
		isLoading,
		refetch,
	};
};

export const useGetVariables = (context, organizationId) => {
	const {
		data: { getVariables: variableGroups },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query ($context: IntegrationContext!, $organizationId: Int) {
				getVariables(context: $context, organizationId: $organizationId) {
					Name
					Variables {
						VariableID
						Name
						Label
						Value
						Description
						Type
						IsSecret
					}
				}
			}
		`,
		{
			context,
			organizationId,
		}
	);

	return {
		variableGroups,
		isLoading,
		refetch,
	};
};
