export const TIME_ZONES = [
	{
		value: "America/Los_Angeles",
		label: "United States - Pacific (PT)",
	},
	{
		value: "America/Phoenix",
		label: "United States - Mountain (MT)",
	},
	{
		value: "America/Chicago",
		label: "United States - Central (CT)",
	},
	{
		value: "America/New_York",
		label: "United States - Eastern (ET)",
	},
	{
		value: "Europe/London",
		label: "United Kingdom (GMT)",
	},
	{
		value: "Australia/Perth",
		label: "Australia - Western Australia (AWST)",
	},
	{
		value: "Australia/Darwin",
		label: "Australia - Northern Territory (ACST)",
	},
	{
		value: "Australia/Adelaide",
		label: "Australia - South Australia (ACST)",
	},
	{
		value: "Australia/Brisbane",
		label: "Australia - Queensland (AEST)",
	},
	{
		value: "Australia/Sydney",
		label: "Australia - New South Wales (AEST)",
	},
	{
		value: "Australia/Melbourne",
		label: "Australia - Victoria (AEST)",
	},
	{
		value: "Australia/Hobart",
		label: "Australia - Tasmania (AEST)",
	},
	{
		value: "Pacific/Auckland",
		label: "New Zealand (NZST)",
	},
];

export const CURRENCIES = [
	{
		value: "USD",
		label: "United States Dollar (USD)",
	},
	{
		value: "GBP",
		label: "British Pound (GBP)",
	},
	{
		value: "AUD",
		label: "Australian Dollar (AUD)",
	},
	{
		value: "NZD",
		label: "New Zealand Dollar (NZD)",
	},
];

export const TAX_RATES = [
	{
		value: "None",
		label: "Tax Free",
	},
	{
		value: "US-CA",
		label: "United States - California (VAT)",
	},
	{
		value: "US-TX",
		label: "United States - Houston, Texas (VAT)",
	},
	{
		value: "GB",
		label: "United Kingdom (VAT)",
	},
	{
		value: "AU",
		label: "Australia (GST)",
	},
	{
		value: "NZ",
		label: "New Zealand (GST)",
	},
];

export const SITE_TYPES = [
	{
		value: "Gated",
		label: "Gated",
	},
	{
		value: "Gateless",
		label: "Ungated",
	},
];

export const T2_REGION_NAMES = [
	{
		value: "Inugo Test Location",
		label: "Test Site",
	},
];

export const INFOS = [
	{
		value: 1,
		label: "Inugo NZ",
	},
	{
		value: 2,
		label: "Inugo US",
	},
	{
		value: 3,
		label: "Secure Parking NZ",
	},
	{
		value: 4,
		label: "Secure Parking AU",
	},
];

export const SITE_CATEGORIES = [
	{
		value: "Parking",
		label: "Parking",
	},
	{
		value: "BoatRamp",
		label: "BoatRamp",
	},
	{
		value: "Mooring",
		label: "Mooring",
	},
	{
		value: "Pier",
		label: "Pier",
	},
	{
		value: "ToiletBlock",
		label: "ToiletBlock",
	},
];
