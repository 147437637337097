import React, { useState } from "react";
import { fetchDataCallback, paginatedState } from "../../../helpers/pagination";
import { useLazyQueryData, useQueryData } from "../../../hooks";
import Card from "../../layout/Card";
import FlexWrapper from "../../layout/FlexWrapper";
import LocalLink from "../../layout/LocalLink";
import MultiSelect from "../../layout/MultiSelect";
import ReportWrapperBeta from "../ReportWrapperBeta";
import StatCard from "../../layout/StatCard";
import TransactionDetails from "../../details-pages/users/TransactionDetails";
import gql from "graphql-tag";

const defaultSorting = [
	{
		id: "CreatedDate",
		desc: true,
	},
];

export default function ValidationHistory(props) {
	const [state, setState] = useState({
		options: {
			sites: [],
			"start-date-time": null,
			"end-date-time": null,
		},
		search: "",
		type: ["Promotion", "Validation"],
		selectableType: [
			{ label: "Promotion", value: "Promotion" },
			{ label: "Validation", value: "Validation" },
		],
		selectedSiteId: null,
		selectedParkingSessionId: null,
		selectedParkingSessionUser: null,
		reportStyleOverride: {},
	});

	const [paginate, setPaginate] = useState(paginatedState);

	const fetchPaginatedData = fetchDataCallback(setPaginate);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, search, options }));
	}

	const columns = [
		{
			id: "Status",
			Header: "Status",
			accessor: "Status",
		},
		{
			id: "ParkingSessionID",
			Header: "Session ID",
			accessor: "ParkingSessionID",
			Cell: (cellProps) => (
				<LocalLink
					to={`#${cellProps.value}`}
					onClick={() =>
						showParkingSessionCharges(
							cellProps.row.original.SiteID,
							cellProps.row.original.ParkingSessionID,
							{
								Email: cellProps.row.original.Email,
								Name: cellProps.row.original.FullName,
							}
						)
					}
				>
					{cellProps.row.original.ParkingSessionIDFormatted}
				</LocalLink>
			),
		},
		{
			id: "SiteName",
			Header: "Site",
			accessor: "SiteName",
			highlightCell: (cellProps) => cellProps.row.original.SiteName,
		},
		{
			id: "UserName",
			Header: "User",
			accessor: "UserName",
			hiddenExport: true,
			highlightCell: (cellProps) => cellProps.row.original.UserName,
		},
		{
			id: "FirstName",
			Header: "First Name",
			accessor: "FirstName",
			hiddenWeb: true,
			highlightCell: (cellProps) => cellProps.row.original.FirstName,
		},
		{
			id: "LastName",
			Header: "Last Name",
			accessor: "LastName",
			hiddenWeb: true,
			highlightCell: (cellProps) => cellProps.row.original.LastName,
		},
		{
			id: "Email",
			Header: "Email",
			accessor: "Email",
			hiddenWeb: true,
			highlightCell: (cellProps) => cellProps.row.original.Email,
		},
		{
			id: "Phone",
			Header: "Phone Number",
			accessor: "Phone",
			hiddenWeb: true,
			highlightCell: (cellProps) => cellProps.row.original.Phone,
		},
		{
			id: "Type",
			Header: "Type",
			accessor: "Type",
			highlightCell: (cellProps) => cellProps.row.original.Type,
		},
		{
			id: "ValidationBeaconLocation",
			Header: "Validation Location",
			accessor: "ValidationBeaconLocation",
			highlightCell: (cellProps) =>
				cellProps.row.original.ValidationBeaconLocation || "",
		},
		{
			id: "DiscountType",
			Header: "Discount",
			accessor: "DiscountType",
			highlightCell: (cellProps) => cellProps.row.original.DiscountType || "",
		},
		{
			id: "DiscountAmount",
			Header: "Amount",
			accessor: "DiscountAmount",
			highlightCell: (cellProps) => cellProps.row.original.DiscountAmount,
		},
		{
			id: "CreatedDate",
			Header: "Time",
			accessor: "CreatedOnFormatted",
			Cell: (cellProps) => {
				return cellProps.row.original.CreatedOnFormatted;
			},
		},
		{
			id: "MarketingEmailConsent",
			Header: "Marketing Consent",
			accessor: "MarketingEmailConsent",
			hiddenWeb: true,
			highlightCell: (cellProps) =>
				cellProps.row.original.MarketingEmailConsent,
		},
	];

	async function getDownloadableData() {
		const {
			data: { getDiscountsHistory: downloadData },
		} = await getDownloadableValidations();

		return downloadData.discounts;
	}

	const getDownloadableValidations = useLazyQueryData(
		gql`
			query (
				$start: Timestamp
				$end: Timestamp
				$sites: [Int!]
				$discountType: [String!]
				$page: PageInfo!
				$cursor: Int
			) {
				getDiscountsHistory(
					start: $start
					end: $end
					sites: $sites
					discountType: $discountType
					page: $page
					cursor: $cursor
				) {
					discounts {
						DiscountID
						ParkingSessionID
						ParkingSessionIDFormatted
						SiteName
						SiteID
						Email
						FirstName
						LastName
						Phone
						MarketingEmailConsent
						FullName
						UserName
						Phone
						DiscountType
						DiscountAmount
						Type
						IsActive
						ValidationBeaconLocation
						CreatedOn
						CreatedOnFormatted
						Status
						RankingScore
					}
					previousEvent
					validationTrueLength
					validationCurrentInteractions
					promotionTrueLength
					promotionCurrentInteractions
				}
			}
		`,
		{
			start: state.options["start-date-time"],
			end: state.options["end-date-time"],
			sites: state.options.sites || [],
			discountType: state.type || [],
			page: {
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		}
	);

	const skipDataQuery =
		!state.options["start-date-time"] || !state.options["end-date-time"];

	const { data, isLoading } = useQueryData(
		gql`
			query (
				$start: Timestamp
				$end: Timestamp
				$sites: [Int!]
				$discountType: [String!]
				$page: PageInfo!
				$cursor: Int
			) {
				getDiscountsHistory(
					start: $start
					end: $end
					sites: $sites
					discountType: $discountType
					page: $page
					cursor: $cursor
				) {
					discounts {
						DiscountID
						ParkingSessionID
						ParkingSessionIDFormatted
						SiteName
						SiteID
						Email
						FirstName
						LastName
						Phone
						MarketingEmailConsent
						FullName
						UserName
						DiscountType
						DiscountAmount
						Type
						IsActive
						ValidationBeaconLocation
						CreatedOn
						CreatedOnFormatted
						Status
						RankingScore
					}
					previousEvent
					validationTrueLength
					validationCurrentInteractions
					promotionTrueLength
					promotionCurrentInteractions
				}
			}
		`,
		{
			start: state.options["start-date-time"],
			end: state.options["end-date-time"],
			sites: state.options.sites,
			discountType: state.type || [],
			page: {
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		},
		skipDataQuery
	);

	let discounts = data?.getDiscountsHistory?.discounts || [];
	const validationTrueLength =
		data?.getDiscountsHistory?.validationTrueLength || 0;
	const validationCurrentInteractions =
		data?.getDiscountsHistory?.validationCurrentInteractions || 0;
	const promotionTrueLength =
		data?.getDiscountsHistory?.promotionTrueLength || 0;
	const promotionCurrentInteractions =
		data?.getDiscountsHistory?.promotionCurrentInteractions || 0;

	const csvHeaders = () => {
		return columns
			.filter((column) => !column.hiddenExport)
			.map((column) => {
				return {
					label: column.Header,
					key: column.accessor,
				};
			});
	};

	function showParkingSessionCharges(siteId, parkingSessionId, user) {
		setState((_state) => ({
			..._state,
			selectedSiteId: siteId,
			selectedParkingSessionId: parkingSessionId,
			selectedParkingSessionUser: user,
			reportStyleOverride: { display: "none" },
		}));
	}

	function hideParkingSessionCharges() {
		setState((_state) => ({
			..._state,
			selectedSiteId: null,
			selectedParkingSessionId: null,
			selectedParkingSessionUser: null,
			reportStyleOverride: {},
		}));
	}

	return (
		<div>
			<ReportWrapperBeta
				{...props}
				style={state.reportStyleOverride}
				title="History"
				data={discounts}
				columns={columns}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				loading={false}
				csvHeaders={csvHeaders()}
				csvName={"inugo-validation-report.csv"}
				stats={
					<FlexWrapper style={{ marginBottom: -16 }}>
						<Card style={{ flex: 0.25 }}>
							<StatCard
								value={validationCurrentInteractions}
								title="Current Validated Sessions"
								size="medium"
							/>
						</Card>
						<Card style={{ flex: 0.25 }}>
							<StatCard
								value={validationTrueLength}
								title="Total Validated Sessions"
								size="medium"
							/>
						</Card>
						<Card style={{ flex: 0.25 }}>
							<StatCard
								value={promotionCurrentInteractions}
								title="Current Promotions on Sessions"
								size="medium"
							/>
						</Card>
						<Card style={{ flex: 0.25 }}>
							<StatCard
								value={promotionTrueLength}
								title="Total Promotions on Sessions"
								size="medium"
							/>
						</Card>
					</FlexWrapper>
				}
				leftActions={
					<div style={{ width: "250px", marginLeft: "16px" }}>
						<MultiSelect
							options={state.selectableType}
							selected={state.type.filter((option) =>
								state.selectableType.map((o) => o.value).includes(option)
							)}
							onSelectedChanged={(values) => {
								setState((_state) => ({ ..._state, type: values }));
							}}
							overrideStrings={{
								selectSomeItems: `Select Types`,
								allItemsAreSelected: `All Types`,
								selectAll: `All Types`,
								search: "Search",
							}}
							valueRenderer={(selected, options) => {
								if (selected.length === 0) {
									return `Select Types`;
								}

								if (selected.length === options.length) {
									return `All Types`;
								}

								if (selected.length === 1) {
									return selected.name;
								}

								return `${selected.length} types selected`;
							}}
						/>
					</div>
				}
				dataTrueLength={validationTrueLength + promotionTrueLength}
				cursorColumn="parkingSessionValidationID"
				paginationIsLoading={isLoading || skipDataQuery || props.isLoading}
				pageSize={state.pageSize}
				previousEvent={data?.getDiscountsHistory?.previousEvent}
				searchTokens={paginate.searchTokens}
				fetchPaginatedData={fetchPaginatedData}
				getDownloadableData={getDownloadableData}
			/>

			{state.selectedParkingSessionId && (
				<TransactionDetails
					{...props}
					siteId={state.selectedSiteId}
					session={{ ParkingSessionID: state.selectedParkingSessionId }}
					user={state.selectedParkingSessionUser}
					onClose={hideParkingSessionCharges}
				/>
			)}
		</div>
	);
}
