import "react-image-crop/dist/ReactCrop.css";
import React, { useCallback, useEffect, useState } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { colours } from "../../styles";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";

const Wrapper = styled.div`
	a {
		color: ${colours.blue};
		text-decoration: none;
		transition: 0.2s ease;
		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const StatusText = styled.div`
	color: ${(props) => props.color || "black"};
	font-size: 18px;
	font-weight: 600;
	margin: 16px 0 32px;
	text-align: center;
`;

const Label = styled.div`
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 36px;
	text-align: center;
`;

const DropBox = styled.div`
	background: ${colours.highlightGrey};
	border: 4px dashed ${colours.borderGrey};
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 60px 10px;
	text-align: center;
	width: 50%;
	p {
		font-size: 18px;
		font-weight: 600;
	}
	&.dragover-err {
		border: 5px dashed $red;
	}
	&.dragover {
		border: 5px dashed $green;
	}
`;

export default function ImportImageBox({
	key,
	title,
	description,
	onChange,
	url,
	maxSize,
	acceptableFiles = ["image/jpeg", "image/png"],
	aspect = 1,
	shouldCrop = true,
	initFile,
}) {
	const [images, setImages] = useState([
		initFile
			? {
					url: URL.createObjectURL(initFile),
					name: key,
					file: initFile,
			  }
			: {
					url,
					name: "",
					file: null,
			  },
	]);

	const [crop, setCrop] = useState({});

	const onDrop = useCallback(async (acceptedFiles) => {
		const newImages = acceptedFiles.map((file) => ({
			url: URL.createObjectURL(file),
			file: file,
			name: file.name,
		}));

		setImages(newImages);
		onChange(newImages.map((i) => i.file));
	}, []);

	const { getRootProps, getInputProps, isDragActive, fileRejections } =
		useDropzone({
			onDrop,
			accept: {
				"image/*": acceptableFiles,
			},
			maxSize,
		});

	useEffect(
		() => () => {
			images.forEach((image) => URL.revokeObjectURL(image.url));
		},
		[images]
	);

	const onImageLoad = useCallback((img) => {
		if (shouldCrop) {
			const { width, height } = img.currentTarget;

			const _crop = centerCrop(
				makeAspectCrop(
					{
						unit: "%",
						width: 100,
					},
					aspect,
					width,
					height
				),
				width,
				height
			);

			setCrop(_crop);
		}
	}, []);

	return (
		<Wrapper>
			<Label>{title}</Label>

			<DropBox {...getRootProps()}>
				{images.map((image, index) => (
					<div key={key ? `${key}${index}` : index}>
						{image.url && (
							<>
								<ReactCrop
									crop={shouldCrop ? crop : null}
									onChange={shouldCrop ? (c) => setCrop(c) : null}
									locked
									disabled
								>
									<img src={image.url} onLoad={onImageLoad} />
								</ReactCrop>
								{!isDragActive && image.name && (
									<p>Image chosen: {image.name}</p>
								)}
							</>
						)}
					</div>
				))}
				<input {...getInputProps()} multiple={false} />
				{fileRejections.length > 0 && (
					<>
						{fileRejections.map(({ errors }, index) => (
							<StatusText color={colours.red} key={index}>
								{errors.map((error) => error.message)}
							</StatusText>
						))}
					</>
				)}

				{isDragActive && <p>Drop the file here...</p>}
				{!images.file && !isDragActive && (
					<p>Click to choose an image or drag one here...</p>
				)}
			</DropBox>

			{description && <div>{description}</div>}
		</Wrapper>
	);
}
