import { lowerFirst } from "lodash";

export function rebuildGroups(leaseRate, isPrivate) {
	const publicGroup = isPrivate
		? [{ label: "No Group", value: -1, isFixed: true }]
		: [{ label: "No Group (Public)", value: -1, isFixed: true }];

	if (!leaseRate || !leaseRate.organizationAccessGroups) {
		return publicGroup;
	}

	if (!leaseRate || leaseRate.organizationAccessGroups.length === 0) {
		return publicGroup;
	}

	return leaseRate.organizationAccessGroups.map((item) => ({
		value: item.OrganizationAccessGroupID,
		label: item.Name,
	}));
}

export function summaryDurationString(
	amount,
	unit,
	minMax,
	hasHalfHourBookings
) {
	//Make sure it covers the '1 Day' and '1 Hour' situation
	if (amount) {
		if (unit === "Hours" && amount === 1) {
			if (hasHalfHourBookings) {
				return `${minMax} half hour`;
			} else {
				return `${minMax} 1 hour`;
			}
		} else {
			if (amount > 1) {
				return `${minMax} ${
					hasHalfHourBookings ? parseInt(amount / 2) : amount
				} ${getUnitLabel(unit, "plural", "lower")}`;
			} else {
				return `${minMax} ${amount} ${getUnitLabel(unit, "single", "lower")}`;
			}
		}
	} else {
		return `No ${minMax} limit`;
	}
}

export function getUnitLabel(durationType, type, casing) {
	const mappings = {
		plural: {
			Months: "Months",
			Days: "Days",
			Hours: "Hours",
		},
		single: { Months: "Month", Days: "Day", Hours: "Hour" },
		universal: {
			Months: "Month(s)",
			Days: "Day(s)",
			Hours: "Hour(s)",
		},
		period: {
			Months: "Monthly",
			Days: "Daily",
			Hours: "Hourly",
		},
	};

	if (casing === "lower") {
		return lowerFirst(mappings[type][durationType]);
	}

	return mappings[type][durationType];
}

export function durationTypeToUnitLabel(durationType) {
	return getUnitLabel(durationType, "universal");
}
