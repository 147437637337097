import * as Yup from "yup";
import * as installationApi from "../../../api/installation";
import * as steps from "./steps";
import { cloneDeep, find, some } from "lodash";
import Alert from "react-s-alert";
import React from "react";
import WizardLayout from "../WizardLayout";
import { useTranslation } from "react-i18next";

const initialGroup = {
	OverridesOpeningHours: 0,
	SundayOpen: 1,
	MondayOpen: 1,
	TuesdayOpen: 1,
	WednesdayOpen: 1,
	ThursdayOpen: 1,
	FridayOpen: 1,
	SaturdayOpen: 1,

	SundayOpeningTimestamp: null,
	SundayClosingTimestamp: null,
	MondayOpeningTimestamp: null,
	MondayClosingTimestamp: null,
	TuesdayOpeningTimestamp: null,
	TuesdayClosingTimestamp: null,
	WednesdayOpeningTimestamp: null,
	WednesdayClosingTimestamp: null,
	ThursdayOpeningTimestamp: null,
	ThursdayClosingTimestamp: null,
	FridayOpeningTimestamp: null,
	FridayClosingTimestamp: null,
	SaturdayOpeningTimestamp: null,
	SaturdayClosingTimestamp: null,
};

const days = [
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
	"Sunday",
];

function _restoreSites(_group) {
	if (!_group || !_group.sites || _group.sites.length === 0) {
		return [{ value: -1, label: "No site" }];
	}

	return _group.sites.map((site) => ({
		value: site.SiteID,
		label: site.Name,
	}));
}

function _restoreAccount(_group, spaceAccounts, props) {
	if (props.spaceAccountOwnerOnly) {
		const defaultAccount = props.user.SpaceAccountOwners.find(
			(e) => e.OrganizationID === props.selectedOrganization.OrganizationID
		);

		if (!_group || !_group.SpaceAccountID) {
			return {
				value: defaultAccount.SpaceAccountID,
				label: defaultAccount.Name,
			};
		}
	} else {
		if (!_group || !_group.SpaceAccountID) {
			return null;
		}
	}

	const account = find(
		spaceAccounts,
		(_account) => _account.SpaceAccountID === _group.SpaceAccountID
	);

	if (account) {
		return {
			value: account.SpaceAccountID,
			label: account.Name,
		};
	} else {
		return null;
	}
}

function _restoreLeaseRates(_group) {
	if (_group && _group.leaseRates && _group.leaseRates.length > 0) {
		return _group.leaseRates.map((leaseRate) => ({
			value: leaseRate.LeaseRateID,
			label: leaseRate.Name,
		}));
	} else {
		return [{ value: -1, label: "No rate" }];
	}
}

function _restoreLocationBasedNodes(_group) {
	if (_group && _group.nodes && _group.nodes.length > 0) {
		return _group.nodes
			.filter((node) => node.LocationBasedAccess)
			.map((node) => ({
				value: node.NodeID,
				label: node.Name,
			}));
	} else {
		return null;
	}
}

function _restoreRestrictedAccessNodes(_group) {
	if (_group && _group.nodes && _group.nodes.length > 0) {
		return _group.nodes
			.filter((node) => node.GroupBasedAccess)
			.map((node) => ({
				value: node.NodeID,
				label: node.Name,
			}));
	} else {
		return null;
	}
}

/**
 * Groups wizard component.
 *
 * @param {object} props
 * @param {object} props.group
 */
export default function GroupsWizard(props) {
	const { t: translate } = useTranslation();

	const organizationId = props.selectedOrganization.OrganizationID;
	let _steps = [];

	if (props.mode === "add") {
		_steps = [steps.editStep];
	} else if (props.mode === "update") {
		_steps = [steps.editStep];
	} else if (props.mode === "remove") {
		_steps = [steps.removeStep];
	}

	return (
		<WizardLayout
			close={props.close}
			title={(values) => {
				if (values.mode === "add") return "Create group";
				if (values.mode === "update") return "Update group";
				if (values.mode === "remove") return "Remove group";
			}}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "name",
					value: props.group && props.group.Name ? props.group.Name : "",
					validator: Yup.string()
						.max(
							100,
							"The group name should be no more than 100 characters long."
						)
						.required("Please supply a name for this group."),
				},
				{
					name: "group",
					value: props.group ? props.group : initialGroup,
					validator: Yup.object(),
				},
				{
					name: "sites",
					value: props.group ? _restoreSites(props.group) : null,
					validator: Yup.object().nullable(),
				},
				{
					name: "account",
					value: _restoreAccount(props.group, props.spaceAccounts, props),
					validator: Yup.object().nullable(),
				},
				{
					name: "leaseRates",
					value: props.group ? _restoreLeaseRates(props.group) : null,
					validator: Yup.object().nullable(),
				},
				{
					name: "locationBasedNodes",
					value: props.group ? _restoreLocationBasedNodes(props.group) : null,
					validator: Yup.object().nullable(),
				},
				{
					name: "restrictedAccessNodes",
					value: props.group
						? _restoreRestrictedAccessNodes(props.group)
						: null,
					validator: Yup.object().nullable(),
				},
				{
					name: "sessionlessAccess",
					value:
						props.group && props.group.ForceSessionlessAccessOnSites
							? props.group.ForceSessionlessAccessOnSites
							: false,
					validator: Yup.boolean(),
				},
				{
					name: "overridesOpeningHours",
					value:
						props.group && props.group.OverridesOpeningHours
							? props.group.OverridesOpeningHours
							: false,
					validator: Yup.boolean(),
				},
			]}
			onSubmit={async (values) => {
				if (values.mode === "add" || values.mode === "update") {
					let accountId = values.account ? values.account.value : null;

					//Reduce sites to site IDs and filter out the placeholder
					let siteIds = [];

					if (values.sites) {
						siteIds = values.sites.reduce((arr, item) => {
							if (item.value === -1) {
								return arr;
							} //Do not include the 'no site' item
							arr.push(item.value);
							return arr;
						}, []);
					}

					//Reduce lease rates to lease rate IDs and filter out the placeholder
					let leaseRateIds = [];

					if (values.leaseRates) {
						leaseRateIds = values.leaseRates.reduce((arr, item) => {
							if (item.value === -1) {
								return arr;
							} //Do not include the 'no rate' item
							arr.push(item.value);
							return arr;
						}, []);
					}

					let locationBasedNodeIds = [];
					if (values.locationBasedNodes) {
						locationBasedNodeIds = values.locationBasedNodes.map(
							(item) => item.value
						);
					}

					let restrictedAccessNodeIds = [];
					if (values.restrictedAccessNodes) {
						restrictedAccessNodeIds = values.restrictedAccessNodes.map(
							(item) => item.value
						);
					}

					//if group doesn't have overrides opening hours, pass the initial value to backend. Otherwise, pass the updated value
					let hoursData = cloneDeep(initialGroup);
					let data;
					if (values.overridesOpeningHours) {
						days.forEach((day) => {
							hoursData[`${day}Open`] = values.group[`${day}Open`];
							hoursData[`${day}OpeningTimestamp`] =
								values.group[`${day}OpeningTimestamp`];
							hoursData[`${day}ClosingTimestamp`] =
								values.group[`${day}ClosingTimestamp`];
						});
						hoursData.OverridesOpeningHours = 1;
					}

					if (values.mode === "add") {
						data = {
							Name: values.name,
							ForceSessionlessAccessOnSites: values.sessionlessAccess,
							SpaceAccountID: accountId,
							siteIds,
							leaseRateIds,
							locationBasedNodeIds,
							restrictedAccessNodeIds,
							...hoursData,
						};

						try {
							await installationApi.createOrganizationAccessGroup(
								organizationId,
								data
							);

							Alert.success("Access group created");
							props.close(true);
						} catch (error) {
							Alert.error(translate("Errors.Generic"));
						}
					} else if (values.mode === "update") {
						data = {
							Name: values.name,
							ForceSessionlessAccessOnSites: values.sessionlessAccess,
							SpaceAccountID: accountId,
							siteIds,
							leaseRateIds,
							locationBasedNodeIds,
							restrictedAccessNodeIds,
							HasANPRAccess: values.group.HasANPRAccess,
							WaiveConvenienceFee: values.group.WaiveConvenienceFee,
							...hoursData,
						};

						try {
							await installationApi.updateOrganizationAccessGroup(
								organizationId,
								props.group.OrganizationAccessGroupID,
								data
							);

							Alert.success("Access group updated");
							props.close(true);
						} catch (error) {
							if (
								some(
									error?.errors || [],
									(e) => e.code === "InvalidSharedInstallationViaGroup"
								)
							) {
								Alert.error(
									"Cannot add group to site. This group contains users that have access to another site on the shared installation."
								);
								return;
							}
							Alert.error(translate("Errors.Generic"));
						}
					}
				} else if (props.mode === "remove") {
					try {
						await installationApi.deleteOrganizationAccessGroupById(
							organizationId,
							props.group.OrganizationAccessGroupID
						);

						Alert.success("Access group removed");
						props.close(true);
					} catch (error) {
						if (error.errors[0].code === "GroupHasAssociatedRate") {
							Alert.error("Cannot remove group, group has associated rate.");
						} else {
							Alert.error(translate("Errors.Generic"));
						}
					}
				}
			}}
			steps={_steps}
			initialStep={0}
			wizardProps={props}
		/>
	);
}
