import * as Yup from "yup";
import * as installationApi from "../../../api/installation";
import React, { useCallback } from "react";
import { find, intersection, sortBy } from "lodash";
import Button from "../../layout/Button";
import Dropdown from "../../layout/Dropdown";
import ErrorBoundary from "../../../containers/ErrorBoundary";
import TableLayout from "../../layout/TableLayout";
import WizardLayout from "../WizardLayout";
import WizardNavigation from "../WizardNavigation";
import { colours } from "../../../styles";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";

const Wrapper = styled.div`
	a {
		color: ${colours.blue};
		text-decoration: none;
		transition: 0.2s ease;
		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
`;

const FormatLink = styled.div`
	font-size: 16px;
	margin: 16px 0;
`;

const ErrorText = styled.div`
	color: ${colours.red};
	font-size: 18px;
	font-weight: 600;
	margin: 16px 0 32px;
	text-align: center;
`;

const DropBox = styled.div`
	background: ${colours.highlightGrey};
	border: 4px dashed ${colours.borderGrey};
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 60px 10px;
	text-align: center;
	width: 100%;
	cursor: pointer;
	p {
		font-size: 18px;
		font-weight: 600;
	}
	&.dragover-err {
		border: 5px dashed $red;
	}
	&.dragover {
		border: 5px dashed $green;
	}
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 16px;
	margin-top: 16px;
`;

const Spacer = styled.div`
	height: 16px;
`;

const schema = [
	{ name: "Name", required: true },
	{ name: "Promotion Code", required: true },
	{ name: "Type (Fixed/Percentage)", required: true },
	{ name: "Value", required: true },
	{ name: "Start Date (DD/MM/YYYY)", required: false },
	{ name: "End Date (DD/MM/YYYY)", required: false },
	{ name: "User Limit", required: false },
	{ name: "Code Limit", required: false },
];

function getButton(name, color, onClick, isDisabled, keyStrokeHandler) {
	let label = `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
	return (
		<Button
			key={name}
			color={color}
			onClick={onClick}
			disabled={isDisabled}
			keyStrokeHandler={keyStrokeHandler}
		>
			{label}
		</Button>
	);
}

async function importCSV(data, setSuccess, setError, organizationId) {
	try {
		const results = await installationApi.importPromotionsForOrganization(
			organizationId,
			data
		);
		setSuccess(results);
	} catch (error) {
		setError();
	}
}

export function resultsTable({ close, values }) {
	const columns = [
		{
			id: "Row",
			Header: "Row",
			accessor: "index",
		},
		{
			id: "Name",
			Header: "Name",
			accessor: "Name",
		},
		{
			id: "Promotion Code",
			Header: "Promotion Code",
			accessor: "Promotion Code",
		},
		{
			id: "Value",
			Header: "Value",
			accessor: "Value",
		},
		{
			id: "Start Date (DD/MM/YYYY)",
			Header: "Start Date",
			accessor: "Start Date (DD/MM/YYYY)",
		},
		{
			id: "End Date (DD/MM/YYYY)",
			Header: "End Date",
			accessor: "End Date (DD/MM/YYYY)",
		},
		{
			id: "User Limit",
			Header: "User Limit",
			accessor: "User Limit",
		},
		{
			id: "Code Limit",
			Header: "Code Limit",
			accessor: "Code Limit",
		},
	];

	return {
		id: "results",
		label: "Results",
		render: () => (
			<Wrapper>
				<Label>Promotions Import Results</Label>
				{values.results &&
					[
						["Passed", "added"],
						["Failed", "failed"],
						["Duplicates", "duplicates"],
					].map((e) => {
						if (!values.results[e[1]] || values.results[e[1]].length === 0)
							return null;

						return (
							<div key={e[1]}>
								<h3 style={{ marginTop: 48 }}>{e[0]}</h3>
								<TableLayout
									data={sortBy(values.results[e[1]], "index")}
									columns={columns}
									sortable={false}
									resizable={false}
									showResultsLength={true}
									showPagination={
										values.results[e[1]] && values.results[e[1]].length > 10
									}
									defaultPageSize={10}
								/>
							</div>
						);
					})}
			</Wrapper>
		),
		footer: () => (
			<WizardNavigation rightItems={getButton("Close", "blue", close)} />
		),
	};
}

function PromotionDropBox(props) {
	const { values, setFieldValue } = props;

	const onDrop = useCallback((acceptedFiles) => {
		setFieldValue("file", acceptedFiles ? acceptedFiles[0] : null);
		setFieldValue("error", false);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
	});

	let defaultAvailableOptions = [{ label: "All Sites", value: 0 }].concat(
		values.availableSites.map((site) => ({
			label: site.name,
			value: site.id,
		}))
	);
	let availableOptions = [];
	let dropdownValue = [];
	if (values.onAllSites) {
		availableOptions = [{ label: "All Sites", value: 0 }];
		dropdownValue = [{ label: "All Sites", value: 0 }];
	} else {
		availableOptions = defaultAvailableOptions;
		dropdownValue = values.sites.map((d) => ({
			label: d.Name,
			value: d.SiteID,
		}));
	}

	const handleSiteUpdates = (options) => {
		const isAllSites = find(options, ({ value }) => value === 0);
		dropdownValue = [];
		if (isAllSites?.value === 0) {
			availableOptions = [{ label: "All Sites", value: 0 }];
			dropdownValue = [{ label: "All Sites", value: 0 }];
			setFieldValue("onAllSites", true);
			setFieldValue("sites", []);
		} else {
			availableOptions = defaultAvailableOptions;
			options.forEach((d) => {
				dropdownValue.push({
					value: d.value || d.id,
					label: d.label || d.name,
				});
			});
			setFieldValue("onAllSites", false);
			setFieldValue(
				"sites",
				dropdownValue.map((value) => ({
					SiteID: value.value || value.id,
					Name: value.label || value.name,
				}))
			);
		}
	};

	return (
		<Wrapper>
			<Label>Import Promotions</Label>
			<FormatLink>
				The file must be in the following{" "}
				<a
					href={`data:text/csv;charset=utf-8,${encodeURI(
						schema.map((column) => column.name).join(",")
					)}`}
					download="format.csv"
				>
					CSV format
				</a>
				.
			</FormatLink>
			<DropBox {...getRootProps()}>
				<input {...getInputProps()} multiple={false} accept="text/csv" />
				{isDragActive && <p>Drop the file here...</p>}
				{!values.file && !isDragActive && (
					<p>Click to choose a file or drag one here...</p>
				)}
				{values.file && !isDragActive && <p>File chosen: {values.file.name}</p>}
			</DropBox>
			{values.error && (
				<ErrorText>The file does not match the required format</ErrorText>
			)}

			<Spacer />

			<Label>Sites to add promotions to</Label>
			<Dropdown
				isMulti={true}
				options={availableOptions}
				value={dropdownValue}
				onChange={(options) => {
					handleSiteUpdates(options);
				}}
			/>
		</Wrapper>
	);
}

const validateCSV = (next, values, setFieldValue, organizationId) => {
	setFieldValue("error", false);
	const data = {
		file: values.file,
		onAllSites: values.onAllSites,
		sites: `[${values.sites.map((e) => e.SiteID).join(", ")}]`,
	};
	const setError = () => {
		setFieldValue("error", true);
	};

	let ext = data.file.name.split(".").pop();

	// Need to check extension when dropping a file
	if (ext === "csv") {
		let reader = new FileReader();

		// Validate that at least the required headers are present
		reader.onload = () => {
			const headers = reader.result.split(/\r\n|\n/gm)[0].split(",");

			const required = schema
				.filter((column) => column.required)
				.map((column) => column.name);

			const intersect = intersection(headers, required);

			// If intersect and required differ at all, then not all required fields have been provided
			if (intersect.length !== required.length) {
				return setError();
			}
			importCSV(
				data,
				(results) => {
					setFieldValue("error", false);
					setFieldValue("results", results);
					next();
				},
				setError(),
				organizationId
			);
		};

		reader.onerror = () => setError();

		reader.readAsText(data.file);
	} else {
		setError();
	}
};

export function importPromotions({
	close,
	next,
	values,
	setFieldValue,
	keyStrokeHandler,
	wizardProps,
}) {
	let isIncomplete = values.error || !values.file;
	if (!values.onAllSites && values.sites.length === 0) {
		isIncomplete = true;
	} else {
		isIncomplete = false;
	}

	return {
		id: "importPromotions",
		label: "Import Promotions",
		render: () => (
			<PromotionDropBox values={values} setFieldValue={setFieldValue} />
		),
		footer: () => (
			<WizardNavigation
				leftItems={getButton("Cancel", "blue", close)}
				rightItems={getButton(
					"import",
					"green",
					() =>
						validateCSV(
							next,
							values,
							setFieldValue,
							wizardProps.selectedOrganization
						),
					isIncomplete,
					keyStrokeHandler
				)}
			/>
		),
	};
}

export default function ImportUsersWizard(props) {
	const fields = [
		{
			name: "file",
			value: null,
			validator: Yup.mixed(),
		},
		{
			name: "onAllSites",
			value: false,
			validator: Yup.boolean(),
		},
		{
			name: "error",
			value: null,
			validator: Yup.mixed(),
		},
		{
			name: "sites",
			value: [],
			validator: Yup.array(Yup.object()),
		},
		{
			name: "availableSites",
			value: props.availableSites,
			validator: Yup.array(),
		},
	];

	const wizard = {
		title: "Import Promotions",
		initialStep: 0,
		steps: [importPromotions, resultsTable],
	};

	return (
		<ErrorBoundary>
			<WizardLayout
				close={props.close}
				title={wizard.title}
				values={fields}
				onSubmit={async () => {}}
				steps={wizard.steps}
				initialStep={wizard.initialStep}
				wizardProps={props}
			/>
		</ErrorBoundary>
	);
}
