import { find } from "lodash";

export function getOperatorRoute(selectedOperator, route) {
	if (selectedOperator) {
		return `/operator/${selectedOperator.OperatorID}${
			route ? `/${route}` : ""
		}`;
	}

	return "/";
}

export function getOrganizationRoute(
	selectedOperator,
	selectedOrganization,
	route
) {
	if (selectedOperator && selectedOrganization) {
		return `/operator/${selectedOperator.OperatorID}/organization/${selectedOrganization.OrganizationID}/${route}`;
	}

	return "/";
}

export function goToRoot(history) {
	return history.push(`/`);
}

export function goToOrganization(
	history,
	operatorId,
	organizationId,
	page = ""
) {
	return history.push(
		`/operator/${operatorId}/organization/${organizationId}/${page}`
	);
}

export function goToSite(
	history,
	selectedOperator,
	selectedOrganization,
	siteId,
	tab = ""
) {
	return history.push(
		`/operator/${selectedOperator.OperatorID}/organization/${selectedOrganization.OrganizationID}/site/${siteId}/${tab}`
	);
}

export function loadOperator({
	availableOperators,
	history,
	setOperator,
	match,
	selectedOperator,
	page,
}) {
	if (match?.params?.operatorId) {
		const matchingOperator = find(
			availableOperators,
			(op) => op.OperatorID === parseInt(match.params.operatorId)
		);

		if (
			matchingOperator &&
			selectedOperator &&
			matchingOperator.OperatorID === selectedOperator.OperatorID
		) {
			// don't load the selected operator if it hasn't changed
			return;
		}

		if (!matchingOperator) {
			// if no match then load the first available organization
			const defaultOperator = availableOperators[0];

			setOperator(defaultOperator);
			history.push(
				`/operator/${defaultOperator.OperatorID}${page ? `/${page}` : ""}`
			);
			return;
		}

		setOperator(matchingOperator);
		history.push(
			`/operator/${matchingOperator.OperatorID}${page ? `/${page}` : ""}`
		);
		return;
	}
}

//the intention of this method is to select the necessary top level context
//by updating the selected operator and or organization based on the URL
export function loadContext(args) {
	const {
		history,
		operatorId,
		organizationId,
		firstOrganization,
		selectedOperator,
		selectedOrganization,
		matchingOperator,
		matchingOrganization,
		setOperator,
		setOrganization,
		page,
		shouldLoadFirstOrg,
	} = args;
	const operatorPath = `/operator/${operatorId}`;
	const orgPath = `/organization/${organizationId}`;
	const route = page ? `/${page}` : "";

	// try to match the operator & organization from the url
	if (operatorId) {
		const contextUnchanged = (matched, selected, id) =>
			matched && selected ? matched[id] === selected[id] : false;

		const operatorUnchanged = contextUnchanged(
			matchingOperator,
			selectedOperator,
			"OperatorID"
		);

		const organizationUnchanged = contextUnchanged(
			matchingOrganization,
			selectedOrganization,
			"OrganizationID"
		);

		const validOperatorRoutes = [
			"settings",
			"integrations",
			"integrations/requests",
			"integrations/variables",
		];

		if (
			operatorUnchanged &&
			(organizationUnchanged ||
				(!organizationId &&
					((!matchingOrganization && selectedOrganization) ||
						validOperatorRoutes.includes(page))))
		) {
			//there's no point updating if the context is unchanged
			return;
		}

		if (matchingOperator && matchingOrganization) {
			setOperator(matchingOperator);
			setOrganization(matchingOrganization);
			history.push(`${operatorPath}${orgPath}${route}`);
			return;
		} else if (matchingOperator && !organizationId) {
			setOperator(matchingOperator);

			if (firstOrganization && shouldLoadFirstOrg) {
				setOrganization(firstOrganization);
				history.push(
					`${operatorPath}/organization/${firstOrganization.OrganizationID}${route}`
				);
				return;
			}

			history.push(`${operatorPath}${route}`);
			return;
		} else if (matchingOperator && !selectedOperator) {
			setOperator(matchingOperator);
		}

		return;
	}
}

export function loadSite({
	availableSites,
	history,
	setSite,
	match,
	selectedOperator,
	selectedOrganization,
}) {
	//try to match the site from the url
	if (match.params && match.params.siteId) {
		const matchingSite = find(
			availableSites,
			(site) => site.SiteID === parseInt(match.params.siteId)
		);

		if (matchingSite) {
			setSite(matchingSite);
			return;
		}
	}

	//if no match then load the first site
	const defaultSite = availableSites[0];

	setSite(defaultSite);

	history.push(
		`/operator/${selectedOperator.OperatorID}/organization/${selectedOrganization.OrganizationID}/site/${defaultSite.SiteID}`
	);
}
