import Highlighter from "react-highlight-words";
import LoadingPlaceholder from "../report/LoadingPlaceholder";
import React from "react";
import ReactTable from "react-table-legacy";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	.ReactTable {
		border: 0;

		.rt-thead * {
			outline: none;
		}

		.rt-thead .rt-resizable-header {
			align-self: flex-end;
		}

		.rt-thead .rt-resizable-header-content {
			overflow: visible;
			white-space: normal;
		}

		.rt-thead.-header {
			box-shadow: none;
			border-bottom: 2px solid ${colours.borderGrey};
		}

		.rt-thead.-filters {
			border-bottom: 0;
		}

		.rt-thead .rt-th.-sort-asc,
		.rt-thead .rt-th.-sort-desc {
			box-shadow: none;
			color: ${colours.green};
			padding-right: 24px;
			position: relative;

			&::before {
				content: "";
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				height: 0;
				position: absolute;
				right: 8px;
				top: 50%;
				transform: translateY(-50%);
				width: 0;
			}
		}

		.rt-thead .rt-th.-sort-asc {
			&::before {
				border-bottom: 4px solid ${colours.green};
			}
		}

		.rt-thead .rt-th.-sort-desc {
			&::before {
				border-top: 4px solid ${colours.green};
			}
		}

		.rt-thead .rt-th,
		.rt-tbody .rt-td {
			border-bottom: 0;
			border-left: 0;
			border-right: 0;
			padding: 16px 8px;
			white-space: unset;
		}

		.rt-thead .rt-th {
			font-weight: 600;
			text-align: left;
		}

		.rt-tbody .rt-td {
			font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial,
				sans-serif;
			font-size: inherit;
		}

		.rt-tbody .rt-tr-group {
			border-bottom: 0;
			font-size: 14px;
		}

		&.-striped .rt-tr.-odd {
			background: ${colours.offWhite};
		}

		&.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
			background: ${colours.lightGrey};
		}

		.pagination-bottom {
			.-pagination {
				border: 0;
				box-shadow: none;
				margin-top: 16px;
			}

			.-previous,
			.-next {
				button {
					background-color: ${colours.lightGrey};
					border-radius: 0;
					color: ${colours.darkGrey};
					font-size: 18px;
					font-weight: 600;
					height: 40px;
					line-height: 24px;
					max-width: 200px;
					padding: 8px 32px;
					position: relative;
					text-align: center;
					transition: 0.2s ease;

					&::after {
						background-color: inherit;
						bottom: 0;
						content: "";
						filter: brightness(75%);
						height: 2px;
						left: 0;
						position: absolute;
						width: 100%;
					}
				}
			}

			.-previous button {
				margin-left: auto;
			}

			.-center {
			}
		}

		.rt-resizer {
			z-index: 0;
		}

		.rt-noData {
			display: none;
		}

		.bold {
			font-weight: 700;
		}

		.boolean {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 24px;

			.cross {
				height: 12px;
				left: 8px;
				position: absolute;
				top: 6px;
				width: 12px;

				&::before,
				&::after {
					background-color: ${colours.darkGrey};
					content: "";
					height: 12px;
					left: 6px;
					position: absolute;
					width: 2px;
				}

				&::before {
					transform: rotate(45deg);
				}

				&::after {
					transform: rotate(-45deg);
				}

				&.colour {
					&::before,
					&::after {
						background-color: ${colours.red};
					}
				}
			}

			.tick {
				height: 16px;
				left: 8px;
				position: absolute;
				top: 6px;
				width: 16px;

				&::before {
					background-color: ${colours.darkGrey};
					content: "";
					height: 2px;
					position: absolute;
					width: 6px;
					top: 7px;
					transform: rotate(45deg);
				}

				&::after {
					background-color: ${colours.darkGrey};
					content: "";
					height: 12px;
					left: 7px;
					position: absolute;
					transform: rotate(45deg);
					width: 2px;
				}

				&.colour {
					&::before,
					&::after {
						background-color: ${colours.green};
					}
				}
			}
		}
	}
`;

const Results = styled.div`
	font-size: 16px;
	font-weight: 600;
	padding: 16px 0;
	text-align: center;
`;

const NoData = styled.div`
	font-size: 20px;
	font-weight: 600;
	margin: 64px 0;
	text-align: center;
`;

export function booleanValueFormatter(props, onlyShowTick = false) {
	return (
		<div className="boolean">
			<div className={props.value ? "tick" : onlyShowTick ? "" : "cross"} />
		</div>
	);
}

export function booleanValueFormatterColour(props) {
	return (
		<div className="boolean">
			<div className={props.value ? "tick colour" : "cross colour"} />
		</div>
	);
}

export function highlightedCell(value, search) {
	if (React.isValidElement(value) && value.props.children) {
		return React.Children.map(value.props.children, (child) =>
			React.cloneElement(child, {
				children: (
					<Highlighter
						searchWords={[search]}
						autoEscape={true}
						textToHighlight={String(child.props.children || "")}
					/>
				),
			})
		);
	}

	return (
		<Highlighter
			searchWords={[search]}
			autoEscape={true}
			textToHighlight={String(value) || ""}
		/>
	);
}

//TODO: only use lodash sorts in react table

export default function TableLayout(props) {
	return (
		<>
			{props.isLoading ? (
				<LoadingPlaceholder noCardWrapper={true} />
			) : (
				<Wrapper>
					{props.data && props.data.length > 0 && (
						<ReactTable
							data={props.data}
							defaultSortMethod={(a, b) => {
								a = a === null || a === undefined ? -Infinity : a;
								b = b === null || b === undefined ? -Infinity : b;
								a = typeof a === "string" ? a.trim().toLocaleLowerCase() : a;
								b = typeof b === "string" ? b.trim().toLocaleLowerCase() : b;

								if (typeof a === "string" && typeof b === "string") {
									return a.localeCompare(b);
								}

								if (a > b) return 1;
								if (a < b) return -1;
								return 0;
							}}
							defaultFilterMethod={(filter, row) => {
								const id = filter.pivotId || filter.id;
								const filterValue =
									filter.value !== undefined
										? String(filter.value).trim().toLocaleLowerCase()
										: "";

								return row[id] !== undefined
									? String(row[id])
											.trim()
											.toLocaleLowerCase()
											.includes(filterValue)
									: true;
							}}
							minRows={1}
							defaultPageSize={100}
							showPagination={props.data && props.data.length > 100}
							className="-striped -highlight"
							{...props}
						/>
					)}
					{props.showResultsLength && props.data && props.data.length > 0 && (
						<Results>
							{`Showing ${props.data.length} result${
								props.data.length > 1 ? "s" : ""
							}`}
						</Results>
					)}
					{(!props.data || !props.data.length) && (
						<NoData>No data found</NoData>
					)}
				</Wrapper>
			)}
		</>
	);
}
