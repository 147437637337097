import { Redirect, Route, Switch } from "react-router-dom";
import ActivityContainer from "./ActivityContainer";
import BillingContainer from "./BillingContainer";
import BookingsContainer from "./BookingsContainer";
import BrandsContainer from "./BrandsContainer";
import Breadcrumb from "../../components/layout/Breadcrumb";
import ContextMenu from "../../components/menu/ContextMenu";
import ContextMenuLink from "../../components/menu/ContextMenuLink";
import DashboardContainer from "./DashboardContainer";
import ErrorBoundary from "../ErrorBoundary";
import HistoryContainer from "./HistoryContainer";
import IntegrationContainer from "./IntegrationContainer";
import PermissionsContainer from "./PermissionsContainer";
import RatesContainer from "./RatesContainer";
import React from "react";
import RevenueContainer from "./RevenueContainer";
import SettingsContainer from "./SettingsContainer";
import SignageContainer from "./SignageContainer";
import SiteContainer from "../site/SiteContainer";
import SpaceContainer from "./SpaceContainer";
import TransientContainer from "./TransientContainer";
import TransientContainerV2 from "./TransientContainerV2";
import UsersContainer from "./UsersContainer";
import ValidationContainer from "./ValidationContainer";
import { usePermissions, useHasClientAdmin } from "../../hooks";
import { useTranslation } from "react-i18next";

export default function OrganizationContainer(props) {
	const { t: translate } = useTranslation();
	const isAdmin = usePermissions("IsAdmin");
	const hasClientAdmin = useHasClientAdmin(
		props.selectedOrganization?.ClientID
	);

	return (
		<Switch>
			<Route
				path={`${props.match.url}/site/:siteId`}
				render={(routeProps) =>
					props.availableSites.length ? (
						<ErrorBoundary>
							<SiteContainer {...props} {...routeProps} />
						</ErrorBoundary>
					) : null
				}
			/>
			<Route>
				<Switch>
					<Route path={`${props.match.url}/permissions`}>
						<ContextMenu menuCollapsed={props.menuCollapsed}>
							<ContextMenuLink
								key="roles"
								path={`${props.match.url}/permissions/roles`}
								name={translate("ContextMenu.Organization.Permissions.Roles")}
								location={props.location}
							/>
							<ContextMenuLink
								key="groups"
								path={`${props.match.url}/permissions/groups`}
								name={translate("ContextMenu.Organization.Permissions.Groups")}
								location={props.location}
							/>
						</ContextMenu>
					</Route>

					<Route path={`${props.match.url}/transient`}>
						<ContextMenu menuCollapsed={props.menuCollapsed}>
							<ContextMenuLink
								key="sessions"
								path={`${props.match.url}/transient/sessions`}
								name={translate("ContextMenu.Organization.Transient.Sessions")}
								location={props.location}
							/>
							{!!props.availableSites.filter((site) => site.HasANPR).length && (
								<>
									<ContextMenuLink
										key="permitted"
										path={`${props.match.url}/transient/permitted`}
										name={translate(
											"ContextMenu.Organization.Transient.Permitted"
										)}
										location={props.location}
									/>
									<ContextMenuLink
										key="unpermitted"
										path={`${props.match.url}/transient/unpermitted`}
										name={translate(
											"ContextMenu.Organization.Transient.Unpermitted"
										)}
										location={props.location}
									/>
								</>
							)}
						</ContextMenu>
					</Route>

					<Route path={`${props.match.url}/validation`}>
						<ContextMenu menuCollapsed={props.menuCollapsed}>
							<ContextMenuLink
								key="history"
								path={`${props.match.url}/validation/history`}
								name={translate("ContextMenu.Organization.Validation.History")}
								location={props.location}
							/>
							<ContextMenuLink
								key="beacons"
								path={`${props.match.url}/validation/beacons`}
								name={translate("ContextMenu.Organization.Validation.Beacons")}
								location={props.location}
							/>
							<ContextMenuLink
								key="accounts"
								path={`${props.match.url}/validation/accounts`}
								name={translate("ContextMenu.Organization.Validation.Accounts")}
								location={props.location}
							/>
							<ContextMenuLink
								key="promotions"
								path={`${props.match.url}/validation/promotions`}
								name={translate(
									"ContextMenu.Organization.Validation.Promotions"
								)}
								location={props.location}
							/>
						</ContextMenu>
					</Route>

					<Route path={`${props.match.url}/rates`}>
						<ContextMenu menuCollapsed={props.menuCollapsed}>
							<ContextMenuLink
								key="transient"
								path={`${props.match.url}/rates/transient`}
								name={translate(
									"ContextMenu.Organization.Rates.TransientRates"
								)}
								location={props.location}
							/>
							<ContextMenuLink
								key="monthly"
								path={`${props.match.url}/rates/monthly`}
								name={translate("ContextMenu.Organization.Rates.BookingRates")}
								location={props.location}
							/>
							{isAdmin || hasClientAdmin ? (
								<ContextMenuLink
									key="products"
									path={`${props.match.url}/rates/products`}
									name={translate("ContextMenu.Organization.Rates.Products")}
									location={props.location}
								/>
							) : null}
						</ContextMenu>
					</Route>

					<Route path={`${props.match.url}/space-management`}>
						<ContextMenu menuCollapsed={props.menuCollapsed}>
							<ContextMenuLink
								key="spaces"
								path={`${props.match.url}/space-management/spaces`}
								name={translate(
									"ContextMenu.Organization.SpaceManagement.Spaces"
								)}
								location={props.location}
							/>
							<ContextMenuLink
								key="bays"
								path={`${props.match.url}/space-management/bays`}
								name={translate(
									"ContextMenu.Organization.SpaceManagement.Bays"
								)}
								location={props.location}
							/>
							<ContextMenuLink
								key="accounts"
								path={`${props.match.url}/space-management/accounts`}
								name={translate(
									"ContextMenu.Organization.SpaceManagement.Accounts"
								)}
								location={props.location}
							/>
						</ContextMenu>
					</Route>

					<Route path={`${props.match.url}/signage`}>
						<ContextMenu menuCollapsed={props.menuCollapsed}>
							<ContextMenuLink
								key="generate"
								path={`${props.match.url}/signage/generate`}
								name={translate("ContextMenu.Organization.Signage.Generate")}
								location={props.location}
							/>
							<ContextMenuLink
								key="unique-qr-codes"
								path={`${props.match.url}/signage/unique-qr-codes`}
								name={translate(
									"ContextMenu.Organization.Signage.UniqueQRCodes"
								)}
								location={props.location}
							/>
							<ContextMenuLink
								key="history"
								path={`${props.match.url}/signage/history`}
								name={translate("ContextMenu.Organization.Signage.History")}
								location={props.location}
							/>
						</ContextMenu>
					</Route>
					<Route path={`${props.match.url}/integrations`}>
						<ContextMenu menuCollapsed={props.menuCollapsed}>
							<ContextMenuLink
								key="integrations"
								path={`${props.match.url}/integrations`}
								name={translate(
									"ContextMenu.Organization.Integration.Integrations"
								)}
								location={props.location}
							/>
							<ContextMenuLink
								key="requests"
								path={`${props.match.url}/integrations/requests`}
								name={translate(
									"ContextMenu.Organization.Integration.Requests"
								)}
								location={props.location}
							/>
							<ContextMenuLink
								key="variables"
								path={`${props.match.url}/integrations/variables`}
								name={translate(
									"ContextMenu.Organization.Integration.Variables"
								)}
								location={props.location}
							/>
						</ContextMenu>
					</Route>
				</Switch>

				<Breadcrumb
					items={[
						{
							text: props.selectedOrganization
								? props.selectedOrganization.Name
								: "",
							onClick: () => props.history.push(props.match.url),
						},
					]}
				/>

				<Switch>
					<Route
						path={`${props.match.url}/dashboard`}
						render={(routeProps) => (
							<ErrorBoundary>
								<DashboardContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/revenue`}
						render={(routeProps) => (
							<ErrorBoundary>
								<RevenueContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/users`}
						render={(routeProps) => (
							<ErrorBoundary>
								<UsersContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/activity`}
						render={(routeProps) => (
							<ErrorBoundary>
								<ActivityContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/transient`}
						render={(routeProps) => (
							<ErrorBoundary>
								<Switch>
									<Route path={`${props.match.url}/transient/sessions`}>
										<TransientContainer {...props} {...routeProps} />
									</Route>
									{props.availableSites.filter((site) => site.HasANPR)
										.length && [
										<Route
											key={"permitted"}
											path={`${props.match.url}/transient/permitted`}
										>
											<TransientContainerV2 {...props} {...routeProps} />
										</Route>,
										<Route
											key={"unpermitted"}
											path={`${props.match.url}/transient/unpermitted`}
										>
											<TransientContainerV2 {...props} {...routeProps} />
										</Route>,
									]}
									<Redirect to={`${props.match.url}/transient/sessions`} />
								</Switch>
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/bookings`}
						render={(routeProps) => (
							<ErrorBoundary>
								<BookingsContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/validation`}
						render={(routeProps) => (
							<ErrorBoundary>
								<ValidationContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/rates`}
						render={(routeProps) => (
							<ErrorBoundary>
								<RatesContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/space-management`}
						render={(routeProps) => (
							<ErrorBoundary>
								<SpaceContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/permissions`}
						render={(routeProps) => (
							<ErrorBoundary>
								<PermissionsContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/history`}
						render={(routeProps) => (
							<ErrorBoundary>
								<HistoryContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/billing`}
						render={(routeProps) => (
							<ErrorBoundary>
								<BillingContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/integrations`}
						render={(routeProps) => (
							<ErrorBoundary>
								<IntegrationContainer
									{...props}
									{...routeProps}
									context="Client"
								/>
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/brands`}
						render={(routeProps) => (
							<ErrorBoundary>
								<BrandsContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/signage`}
						render={(routeProps) => (
							<ErrorBoundary>
								<SignageContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Route
						path={`${props.match.url}/settings`}
						render={(routeProps) => (
							<ErrorBoundary>
								<SettingsContainer {...props} {...routeProps} />
							</ErrorBoundary>
						)}
					/>
					<Redirect to={`${props.match.url}/dashboard`} />
				</Switch>
			</Route>
		</Switch>
	);
}
