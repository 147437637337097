import * as Yup from "yup";
import * as api from "./api";
import * as steps from "./billing-steps";
import { CREDIT_CARD_TYPES } from "../../../helpers/constants";
import React from "react";
import WizardLayout from "../WizardLayout";
import { map } from "lodash";

const defaultConvenienceFee = [
	{ min: 0.01, max: 5, fee: 0.3 },
	{ min: 5.01, max: 20, fee: 0.35 },
	{ min: 20.01, max: 50, fee: 0.65 },
	{ min: 50.01, max: 100, fee: 1 },
	{ min: 100.01, max: 200, fee: 3 },
	{ min: 200.01, max: null, fee: 5 },
];

const constructWizard = (props) => {
	switch (props.mode) {
		case "add":
			return {
				initialStep: 0,
				steps: [
					steps.companyInfo,
					steps.paymentProvider,
					steps.acceptedCreditCardTypes,
					steps.convenienceFees,
					steps.paymentProcessingFees,
					steps.webPay,
					steps.chooseSites,
					steps.summary,
				],
			};
		case "edit":
			return {
				initialStep: 7,
				steps: [
					steps.companyInfo,
					steps.paymentProvider,
					steps.acceptedCreditCardTypes,
					steps.convenienceFees,
					steps.paymentProcessingFees,
					steps.webPay,
					steps.chooseSites,
					steps.summary,
				],
			};
		case "delete":
			return {
				initialStep: 0,
				steps: [steps.deleteStep],
			};
	}
};

export default function BillingWizard(props) {
	const wizard = constructWizard(props);

	const selectedBrand = props.billingDetails
		? (props.brands || []).find(
				(brand) => brand.BrandID === props.billingDetails.BrandID
		  )
		: null;

	const selectedDomains =
		props.billingDetails && props.billingDetails.Domains
			? (props.webPayTypes || []).filter((domain) =>
					props.billingDetails.Domains.split(",").includes(domain.Type)
			  )
			: null;

	const selectedSiteType = props.billingDetails
		? (props.siteTypes || []).find(
				(siteType) => siteType.Name === props.billingDetails.SiteType
		  )
		: null;

	return (
		<WizardLayout
			close={props.close}
			title={(_props) => {
				if (_props.mode === "add") return "Add Billing Details";
				if (_props.mode === "edit") return "Edit Billing Details";
				if (_props.mode === "delete") return "Delete Billing Details";
			}}
			values={[
				{ name: "mode", value: props.mode, validator: Yup.string() },
				{
					name: "BillingDetailsID",
					value: props.billingDetails
						? props.billingDetails.BillingDetailsID
						: null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "OrganizationID",
					value: props.organizationId,
					validator: Yup.number().integer(),
				},
				{
					name: "CompanyName",
					value: props.billingDetails ? props.billingDetails.CompanyName : null,
					validator: Yup.string(),
				},
				{
					name: "TaxRegistration",
					value: props.billingDetails
						? props.billingDetails.TaxRegistration
						: null,
					validator: Yup.string(),
				},
				{
					name: "BillingAddress",
					value: props.billingDetails
						? props.billingDetails.BillingAddress
						: null,
					validator: Yup.string(),
				},
				{
					name: "PaymentProvider",
					value: props.billingDetails
						? props.billingDetails.PaymentProvider
						: null,
					validator: Yup.string().nullable(),
				},
				{
					name: "BillingAccountID",
					value: props.billingDetails
						? props.billingDetails.BillingAccountID
						: null,
					validator: Yup.number().integer().nullable(),
				},
				{
					name: "billingAccountEmail",
					value: props.billingDetails
						? props.billingDetails.BillingAccountEmail
						: null,
					validator: Yup.string().email().nullable(),
				},
				{
					name: "PXPostUsername",
					value: props.billingDetails
						? props.billingDetails.PXPostUsername
						: null,
					validator: Yup.string().nullable(),
				},
				{
					name: "PXPostSecretKey",
					value: props.billingDetails
						? props.billingDetails.PXPostSecretKey
						: null,
					validator: Yup.string().nullable(),
				},
				{
					name: "PxPayUsername",
					value: props.billingDetails
						? props.billingDetails.PxPayUsername
						: null,
					validator: Yup.string().nullable(),
				},
				{
					name: "PxPaySecretKey",
					value: props.billingDetails
						? props.billingDetails.PxPaySecretKey
						: null,
					validator: Yup.string().nullable(),
				},
				{
					name: "MerchantAccountSettlement",
					value: props.billingDetails
						? props.billingDetails.MerchantAccountSettlement
						: null,
					validator: Yup.string().nullable(),
				},
				/* ADVAM Account ID */
				{
					name: "ADVAMAccountID",
					value: props.billingDetails
						? props.billingDetails.ADVAMAccountID
						: null,
					validator: Yup.string().nullable(),
				},
				{
					name: "IsDefault",
					value: props.billingDetails ? props.billingDetails.IsDefault : false,
					validator: Yup.boolean().nullable(),
				},
				{
					name: "ConvenienceFee",
					value: props.billingDetails
						? JSON.parse(props.billingDetails.ConvenienceFee)
						: defaultConvenienceFee,
					validator: Yup.array(Yup.object()).nullable(),
				},
				{
					name: "ConvenienceFeePaidBy",
					value: props.billingDetails
						? props.billingDetails.ConvenienceFeePaidBy
						: null,
					validator: Yup.string().nullable(),
				},
				{
					name: "PaymentProcessingFixedFee",
					value: props.billingDetails
						? props.billingDetails.PaymentProcessingFixedFee
						: null,
					validator: Yup.number().nullable(),
				},
				{
					name: "PaymentProcessingPercentageFee",
					value: props.billingDetails
						? props.billingDetails.PaymentProcessingPercentageFee
						: null,
					validator: Yup.number().nullable(),
				},
				{
					name: "UserPaysCreditCardFee",
					value: props.billingDetails
						? props.billingDetails.UserPaysCreditCardFee
						: 0,
					validator: Yup.number().nullable(),
				},
				{
					name: "AcceptedCreditCardTypes",
					value: props.billingDetails
						? props.billingDetails.AcceptedCreditCardTypes
						: [
								CREDIT_CARD_TYPES.VISA,
								CREDIT_CARD_TYPES.MASTERCARD,
								CREDIT_CARD_TYPES.AMERICAN_EXPRESS,
						  ],
				},
				{ name: "userNewBillingAccount", value: false },
				{ name: "paymentProviderChanged", value: false },
				{ name: "merchantAccountSettlementChanged", value: false },
				{
					name: "siteIds",
					value: props.billingDetails
						? map(props.billingDetails.Sites, (site) => site.SiteID)
						: [],
					validator: Yup.array(),
				},
				{
					name: "allWebPayTypes",
					value: props.webPayTypes.map((t) => ({
						value: t.Type,
						label: t.Domain,
					})),
					validator: Yup.array(Yup.object()).nullable(),
				},
				{
					name: "allSiteTypes",
					value: props.siteTypes.map((t) => ({
						value: t.Type,
						label: t.Name,
					})),
					validator: Yup.array(Yup.object()).nullable(),
				},
				{
					name: "brands",
					value: (props.brands || []).map((b) => ({
						value: b.BrandID,
						label: b.CompanyName,
					})),
					validator: Yup.array(Yup.object()).nullable(),
				},
				{
					name: "WebPayEnabled",
					value: props.billingDetails
						? !!props.billingDetails.WebPayConfigID
						: false,
					validator: Yup.boolean(),
				},
				{
					name: "brand",
					value: selectedBrand
						? { value: selectedBrand.BrandID, label: selectedBrand.CompanyName }
						: null,
					validator: Yup.object().nullable(),
				},
				{
					name: "webPayTypes",
					value: selectedDomains
						? selectedDomains.map((d) => ({
								value: d.Type,
								label: d.Domain,
						  }))
						: [],
					validator: Yup.array().nullable(),
				},
				{
					name: "siteType",
					value: selectedSiteType
						? { value: selectedSiteType.Type, label: selectedSiteType.Name }
						: null,
					validator: Yup.object().nullable(),
				},
			]}
			onSubmit={(values, { setSubmitting }) => {
				setSubmitting(true);

				if (values.mode === "add") {
					api.createBillingDetails(props, values, setSubmitting);
				} else if (values.mode === "edit") {
					api.updateBillingDetails(props, values, setSubmitting);
				} else if (values.mode === "delete") {
					api.deleteBillingDetails(props, values, setSubmitting);
				}
			}}
			steps={wizard.steps}
			initialStep={wizard.initialStep}
			wizardProps={props}
			hideStepIndicator={true}
		/>
	);
}
