import * as rateHelper from "../booking-rate-wizard/helper";
import {
	BookInAdvanceStep,
	DurationStep,
	GroupStep,
	MinimumDurationStep,
	RateTypeStep,
} from "../booking-rate-wizard/shared-steps";
import { StepText, StepTitle } from "../WizardLayout";
import { find, map, sortBy } from "lodash";
import Button from "../../layout/Button";
import DatePicker from "../../layout/DatePicker";
import Dropdown from "../../layout/Dropdown";
import EditableInputField from "../../layout/EditableInputField";
import MultiChoiceButton from "../MultiChoiceButton";
import React from "react";
import SummaryTable from "../SummaryTable";
import Warning from "../../layout/Warning";
import WizardNavigation from "../WizardNavigation";
import { colours } from "../../../styles";
import moment from "moment";
import styled from "styled-components";

const reviewAction = { key: "review", text: "Review", next: "summary" };
const footer = (
	values,
	previous,
	goTo,
	next,
	isFirstStep,
	disableNext = () => {
		return false;
	},
	keyStrokeHandler
) => {
	const showNavigation = !values.editFromSummary && values.mode === "add";
	let right = showNavigation
		? { key: "next", text: "Next", next: next }
		: reviewAction;
	return (
		<WizardNavigation
			leftItems={[
				showNavigation && !isFirstStep && (
					<Button key="back" onClick={previous} color="blue">
						Back
					</Button>
				),
			]}
			rightItems={[
				<Button
					disabled={disableNext()}
					key={right.key}
					onClick={() => goTo(right.next)}
					color="blue"
					keyStrokeHandler={keyStrokeHandler}
				>
					{right.text}
				</Button>,
			]}
		/>
	);
};

export const nameStep = ({
	goTo,
	setFieldValue,
	values,
	errors,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "name",
	label: "Name",
	render: () => {
		return (
			<div>
				<StepTitle>
					What is the name for this block of {values.spaceType.toLowerCase()}{" "}
					spaces?
				</StepTitle>
				<EditableInputField
					type="text"
					maxLength="30"
					name="name"
					onChange={(event) => setFieldValue("name", event.target.value)}
					value={values.name}
					useLabel="Name"
					error={errors.name ? errors.name : ""}
				/>
			</div>
		);
	},
	footer: () => {
		const showNavigation = !values.editFromSummary && values.mode === "add";
		let right = showNavigation
			? { key: "next", text: "Next", next: "sites" }
			: reviewAction;

		let backOptions = [];

		if (values.mode === "add") {
			backOptions = [
				<Button
					key="back"
					onClick={() => wizardProps.clearSpaceType()}
					color="blue"
				>
					Back
				</Button>,
			];
		}

		return (
			<WizardNavigation
				leftItems={backOptions}
				rightItems={[
					<Button
						disabled={errors.name}
						key={right.key}
						onClick={() => goTo(right.next)}
						color="blue"
						keyStrokeHandler={keyStrokeHandler}
					>
						{right.text}
					</Button>,
				]}
			/>
		);
	},
});

export const sitesStep = ({
	errors,
	setFieldValue,
	values,
	wizardProps,
	previous,
	goTo,
	keyStrokeHandler,
}) => ({
	id: "sites",
	label: "Sites",
	render: () => {
		//Transform site data into data for the form
		let siteOptions = [];
		wizardProps.availableSites.forEach((site) => {
			if (site.HasLeaseParking) {
				siteOptions.push({ value: site.SiteID, label: site.Name });
			}
		});

		return (
			<div>
				<StepTitle>Which sites are included in this block?</StepTitle>
				<Dropdown
					isMulti={true}
					options={siteOptions}
					value={values.sites}
					onChange={(value) => {
						setFieldValue("sites", value || []);
					}}
				/>
			</div>
		);
	},
	footer: () =>
		footer(
			values,
			previous,
			goTo,
			"spaces",
			false,
			() => {
				return !values.sites || values.sites.length < 1 || errors.sites;
			},
			keyStrokeHandler
		),
});

export const spacesStep = ({
	errors,
	setFieldValue,
	values,
	wizardProps,
	previous,
	goTo,
	keyStrokeHandler,
}) => ({
	id: "spaces",
	label: "Spaces",
	render: () => {
		//Get SiteIDs applicable; values array only contains limited data
		const usedSiteIds = values.sites.map((site) => {
			return site.value;
		});

		//Calculate the maximum capacities for all selected sites
		let smallestCapacity = 0;

		for (let site of wizardProps.availableSites) {
			if (usedSiteIds.includes(site.SiteID)) {
				smallestCapacity += site.MaximumCapacity;
			}
		}

		return (
			<div>
				<StepTitle>How many spaces for bookings are in this block?</StepTitle>
				<EditableInputField
					type="number"
					name="spaces"
					onChange={(event) => {
						let spaces = event.target.value
							.replace(/[^0-9.]/g, "")
							.replace(/(\..*)\./g, "$1");
						setFieldValue("spaces", spaces);
					}}
					value={values.spaces}
					useLabel="Spaces"
					error={errors.spaces ? errors.spaces : ""}
				/>
				<div>
					{values.spaces > smallestCapacity && (
						<Warning
							text={`Note that the number of booking spaces supplied exceeds the total of unassigned spaces across all selected sites (${smallestCapacity} total)`}
						/>
					)}
				</div>
			</div>
		);
	},
	footer: () => {
		return footer(
			values,
			previous,
			goTo,
			values.spaceType === "Private" ? "bookInAdvance" : "signage",
			false,
			() => {
				return !values.spaces || values.spaces < 0 || errors.spaces;
			},
			keyStrokeHandler
		);
	},
});

export const bookInAdvanceStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	keyStrokeHandler,
}) => ({
	id: "bookInAdvance",
	label: "Book In Advance",
	render: () => (
		<BookInAdvanceStep
			bookInAdvanceDuration={values.bookInAdvanceDuration}
			bookInAdvanceUnitType={values.bookInAdvanceUnitType}
			errors={errors}
			setFieldValue={setFieldValue}
		/>
	),
	footer: () => {
		return footer(
			values,
			previous,
			goTo,
			"signage",
			false,
			() => {
				return (
					errors.bookInAdvanceDuration ||
					errors.bookInAdvanceUnitType ||
					(values.bookInAdvanceDuration && !values.bookInAdvanceUnitType) ||
					(!values.bookInAdvanceDuration && values.bookInAdvanceUnitType)
				);
			},
			keyStrokeHandler
		);
	},
});

export const signageStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => {
	let nextStep = "availabilityDates";

	if (values.spaceType === "Private") {
		nextStep = "durationType";
	}

	return {
		id: "signage",
		label: "Signage",
		render: () => {
			return (
				<div>
					<StepTitle>
						Will these parking spaces have &apos;reserved&apos; signs displayed?
					</StepTitle>

					<div>
						By selecting &apos;Yes&apos;, parkers without bookings should not
						park in these spaces and the total available parks for on demand
						parkers has increased accuracy.
					</div>

					<MultiChoiceButton
						selected={values.hasSignage}
						onClick={() => {
							setFieldValue("hasSignage", true);
						}}
					>
						Yes
					</MultiChoiceButton>

					<MultiChoiceButton
						selected={!values.hasSignage}
						onClick={() => {
							setFieldValue("hasSignage", false);
						}}
					>
						No
					</MultiChoiceButton>
				</div>
			);
		},
		footer: () =>
			footer(
				values,
				previous,
				goTo,
				nextStep,
				false,
				() => {
					return false;
				},
				keyStrokeHandler
			),
	};
};

export const durationType = ({
	values,
	setFieldValue,
	goTo,
	previous,
	keyStrokeHandler,
}) => {
	return {
		id: "durationType",
		label: "Booking Type",
		render: () => (
			<RateTypeStep
				setFieldValue={setFieldValue}
				durationType={values.durationType}
				title="What type of booking does this space provide?"
			/>
		),
		footer: () =>
			footer(
				values,
				previous,
				goTo,
				"durations",
				false,
				() => !values.durationType,
				keyStrokeHandler
			),
	};
};

export const durations = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	keyStrokeHandler,
}) => {
	return {
		id: "durations",
		label: "Duration",
		render: () => {
			if (values.durationType === "Hours") {
				return (
					<DurationStep
						rateType={rateHelper.getUnitLabel(
							values.durationType,
							"period",
							"lower"
						)}
						setFieldValue={setFieldValue}
						minimumDurationAmount={values.minimumDurationAmount}
						maximumDurationAmount={values.maximumDurationAmount}
						durationType={values.durationType}
						maximumDurationType={values.maximumDurationType}
						errors={errors.exceedsMaxDuration || null}
					/>
				);
			} else {
				return (
					<MinimumDurationStep
						durationType={values.durationType}
						setFieldValue={setFieldValue}
						errors={errors}
						minimumDurationAmount={values.minimumDurationAmount}
						maximumDurationAmount={values.maximumDurationAmount}
					/>
				);
			}
		},
		footer: () =>
			footer(
				values,
				previous,
				goTo,
				"groups",
				false,
				() =>
					errors.exceedsMaxDuration ||
					errors.minimumDurationMonths ||
					!values.minimumDurationAmount,
				keyStrokeHandler
			),
	};
};

export const groups = ({
	goTo,
	previous,
	setFieldValue,
	values,
	errors,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "groups",
	label: "Groups",
	render: () => (
		<GroupStep
			organizationAccessGroups={wizardProps.organizationAccessGroups}
			selectGroups={values.organizationAccessGroups}
			setFieldValue={setFieldValue}
			durationType={values.durationType}
			errors={errors.minimumDurationMonths}
			isPrivate={true}
		/>
	),
	footer: () =>
		footer(
			values,
			previous,
			goTo,
			"availabilityDates",
			false,
			() =>
				!values.organizationAccessGroups ||
				values.organizationAccessGroups.length === 0 ||
				errors.minimumDurationMonths,
			keyStrokeHandler
		),
});

export const availabilityStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	keyStrokeHandler,
}) => ({
	id: "availabilityDates",
	label: "Available Dates",
	render: () => {
		return (
			<div>
				<StepTitle>Please select the available dates for this space</StepTitle>
				<h3>Available from:</h3>
				<DatePicker
					options={{ pickerMode: "datePicker", showClearButton: true }}
					value={values.startDate}
					onChange={([date]) => {
						setFieldValue("startDate", date);

						if (
							date &&
							values.endDate &&
							moment(values.endDate).isBefore(moment(date))
						) {
							setFieldValue("endDate", null);
						}
					}}
					whenCleared={() => setFieldValue("startDate", null)}
				/>
				<h3>Available to:</h3>
				<DatePicker
					options={{ pickerMode: "datePicker", showClearButton: true }}
					value={values.endDate}
					onChange={([date]) => {
						setFieldValue("endDate", date);

						if (
							values.startDate &&
							date &&
							moment(date).isBefore(moment(values.startDate))
						) {
							setFieldValue("startDate", null);
						}
					}}
					whenCleared={() => setFieldValue("endDate", null)}
				/>

				{values.mode === "update" && (
					<Warning
						text={
							"Changing availability for these spaces will not affect active bookings"
						}
					/>
				)}
			</div>
		);
	},
	footer: () =>
		footer(
			values,
			previous,
			goTo,
			"billing",
			false,
			() => {
				return false;
			},
			keyStrokeHandler
		),
});

const getSites = (values) => {
	if (values.sites.length < 1) {
		return "None";
	}

	const siteNames = values.sites.map((e) => e.label);

	return siteNames.length ? sortBy(siteNames).join(", ") : "None";
};

const goToEdit = ({ operator, organization, history, leasePark }) => {
	history.push(
		`/operator/${operator.OperatorID}/organization/${organization.OrganizationID}/rates/monthly?lease-rate-id=${leasePark.pairedLeaseRate.LeaseRateID}`
	);
};

export const billingStep = ({
	goTo,
	previous,
	setFieldValue,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "billing",
	label: "Billing",
	render: () => {
		const options = map(wizardProps.billingDetails, (billing) => ({
			label: `${billing.CompanyName} - ${billing.TaxRegistration}`,
			value: billing.BillingDetailsID,
		}));

		return (
			<div>
				<StepTitle>
					Please choose the default billing details that apply to this space
				</StepTitle>
				<Dropdown
					isMulti={false}
					options={options}
					value={options.find((e) => e.value === values.billingDetailsId)}
					onChange={(value) => {
						setFieldValue("billingDetailsId", value.value);
					}}
				/>
			</div>
		);
	},
	footer: () =>
		footer(
			values,
			previous,
			goTo,
			"summary",
			false,
			() => !values.billingDetailsId,
			keyStrokeHandler
		),
});

export const summaryStep = ({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	goTo,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "summary",
	label: "Summary",
	render: () => {
		const EditBlock = styled.div`
			text-align: center;
			color: ${colours.white};
			background-color: ${colours.blue};
			display: block;
			padding: 10px;

			a {
				text-decoration: underline;
				cursor: pointer;
			}
		`;

		//Additional values
		let additionalValues = [];

		if (values.mode === "add" && values.spaceType === "Private") {
			additionalValues = [
				{
					title: "Book In Advance",
					value: values.bookInAdvanceDuration
						? `${values.bookInAdvanceDuration} ${
								values.bookInAdvanceDuration > 1
									? rateHelper.getUnitLabel(
											values.bookInAdvanceUnitType,
											"plural",
											"lower"
									  )
									: rateHelper.getUnitLabel(
											values.bookInAdvanceUnitType,
											"single",
											"lower"
									  )
						  }`
						: "No limit",
					key: "bookInAdvance",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("bookInAdvance");
					},
				},
				{
					title: "Duration",
					value:
						rateHelper.summaryDurationString(
							values.minimumDurationAmount,
							values.durationType,
							"Minimum"
						) +
						", " +
						rateHelper.summaryDurationString(
							values.maximumDurationAmount,
							values.maximumDurationType,
							"Maximum"
						),
					key: "durations",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("durations");
					},
				},
				{
					title: "Groups",
					value:
						values.organizationAccessGroups &&
						values.organizationAccessGroups.length > 0
							? values.organizationAccessGroups
									.map((item) => item.label)
									.join(", ")
							: "Public",
					key: "groups",
					edit: () => {
						setFieldValue("editFromSummary", true);
						goTo("groups");
					},
				},
			];
		}

		let availableDates = "";
		if (!values.startDate & !values.endDate) {
			availableDates = "Any time";
		} else {
			let startDate = values.startDate
				? `From ${moment(values.startDate).format("DD MMM YYYY")}`
				: "";
			let endDate = values.endDate
				? `To ${moment(values.endDate).format("DD MMM YYYY")}`
				: "";

			availableDates = `${startDate} ${endDate}`;
		}

		let billing = find(
			wizardProps.billingDetails,
			(_billing) => _billing.BillingDetailsID === values.billingDetailsId
		);

		return (
			<div>
				<StepTitle>Summary</StepTitle>

				{wizardProps.leasePark.pairedLeaseRate && (
					<EditBlock>
						<div>
							<a onClick={() => goToEdit(wizardProps)}>Click here</a> to go to
							rate page in order to edit minimum and maximum booking time.
						</div>
					</EditBlock>
				)}

				<SummaryTable
					items={[
						{
							title: "Type",
							value: values.spaceType,
							key: "type",
						},
						{
							title: "Name",
							value: values.name,
							key: "name",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("name");
							},
						},
						{
							title: "Sites",
							value: getSites(values),
							key: "sites",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("sites");
							},
						},
						{
							title: "Spaces",
							value: values.spaces,
							key: "spaces",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("spaces");
							},
						},
						{
							title: "Signage",
							value: values.hasSignage ? "Yes" : "No",
							key: "signage",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("signage");
							},
						},
						...additionalValues,
						{
							title: "Availability Range",
							value: availableDates,
							key: "availabilityDates",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("availabilityDates");
							},
						},
						{
							title: "Billing Details",
							value: billing
								? `${billing.CompanyName} - ${billing.TaxRegistration}`
								: "",
							key: "billing",
							edit: () => {
								setFieldValue("editFromSummary", true);
								goTo("billing");
							},
						},
					]}
				/>
			</div>
		);
	},
	footer: () => (
		<WizardNavigation
			rightItems={[
				<Button
					key="submit"
					color="green"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					{values.mode === "update" ? "Update" : "Create"} Space
				</Button>,
			]}
		/>
	),
});

export const removeStep = ({
	handleSubmit,
	isSubmitting,
	values,
	wizardProps,
	keyStrokeHandler,
}) => ({
	id: "remove",
	label: "Remove",
	render: () => (
		<div>
			<StepText>
				Are you sure you want to remove {values.name}? This will also delete all
				associated bays. This action CANNOT be undone.
			</StepText>
		</div>
	),
	footer: () => (
		<WizardNavigation
			leftItems={[
				<Button key="cancel" color="blue" onClick={wizardProps.close}>
					Cancel
				</Button>,
			]}
			rightItems={[
				<Button
					key="submit"
					color="red"
					onClick={handleSubmit}
					disabled={isSubmitting}
					keyStrokeHandler={keyStrokeHandler}
				>
					Remove
				</Button>,
			]}
		/>
	),
});
