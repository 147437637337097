import React, { useState } from "react";
import { fetchDataCallback, paginatedState } from "../../../helpers/pagination";
import { usePermissions, useQueryData } from "../../../hooks";
import AddCameraWizard from "../../wizards/camera-wizard/AddCameraWizard";
import Button from "../../layout/Button";
import { Plus } from "react-feather";
import ReportWrapperBeta from "../ReportWrapperBeta";
import { booleanValueFormatter } from "../../../components/layout/TableLayout";
import { format } from "../../../helpers";
import gql from "graphql-tag";

const defaultSorting = [
	{
		id: "SerialNumber",
		desc: true,
	},
];

export default function Cameras(props) {
	let cameras = [];

	const [state, setState] = useState({
		initialLoad: true,
		search: "",
		node: "",
		wizardOpen: false,
		submitting: false,
	});

	const isAdmin = usePermissions("IsAdmin");
	const isCSAdmin = usePermissions("IsCSAdmin");
	const isSuperAdmin = isAdmin && !isCSAdmin;

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({ ..._state, initialLoad: false, search, options }));
	}

	const openWizard = () => {
		setState((_state) => ({
			..._state,
			wizardOpen: true,
		}));
	};

	const columns = [
		{
			id: "SerialNumber",
			Header: "Serial Number",
			accessor: (d) => d.SerialNumber || "",
			highlightCell: (cellProps) => cellProps.row.original.SerialNumber || "",
			className: "bold",
		},
		{
			id: "Manufacturer",
			Header: "Manufacturer",
			accessor: (d) => d.Manufacturer || "",
			highlightCell: (cellProps) => cellProps.row.original.Manufacturer || "",
		},
		{
			id: "ManufacturerID",
			Header: "Manufacturer ID",
			accessor: (d) => d.ManufacturerID || "",
			highlightCell: (cellProps) => cellProps.row.original.ManufacturerID || "",
		},
		{
			id: "LastActivity",
			Header: "Last Activity",
			accessor: (d) => d.LastActivity || 0,
			Cell: (cellProps) => cellProps.row.original._lastActivity || "",
		},
		{
			id: "Sites",
			Header: "Sites",
			accessor: (d) => d.Sites || "",
			Cell: (cellProps) => cellProps.row.original.Sites || "",
		},
		{
			id: "LaneAndGate",
			Header: "Lane & Gate",
			accessor: "_laneAndGate",
			Cell: ({
				row: {
					original: { Lane, Node },
				},
			}) => (
				<>
					<div>{`${format.formatLane(Lane, Node)}`}</div>
					<div style={{ fontWeight: 700 }}>{`${format.formatGate(Node)}`}</div>
				</>
			),
		},
		{
			id: "IsActive",
			Header: "Active",
			accessor: (d) => d.IsActive || 0,
			Cell: (cellProps) =>
				booleanValueFormatter({ value: cellProps.row.original.IsActive }, true),
		},
	];

	const [paginate, setPaginate] = useState(paginatedState);

	const fetchPaginatedData = fetchDataCallback(setPaginate);

	const skipQuery =
		!paginate.pageSize || !paginate.sortBy || !paginate.sortOrder;

	const { data, isLoading, refetch } = useQueryData(
		gql`
			query ($page: PageInfo!, $cursor: Int) {
				getAdminCameras(page: $page, cursor: $cursor) {
					trueLength
					previousEvent
					Cameras {
						CameraID
						SerialNumber
						Manufacturer
						ManufacturerID
						LastActivity
						IsActive
						Sites
						RankingScore
						Lane {
							Name
							GroupName
							GroupOrder
						}
						Node {
							Name
							SerialNumber
							AccessType
						}
					}
				}
			}
		`,
		{
			page: {
				next: paginate.next,
				pageOffset: paginate.pageOffset,
				pageSize: paginate.pageSize,
				sortBy: paginate.sortBy,
				sortOrder: paginate.sortOrder,
				searchTokens: paginate.searchTokens,
				eventType: paginate.eventType,
				sortedVal: paginate.cursor.sortedVal,
			},
			cursor: paginate.cursor.cursor,
		},
		skipQuery
	);

	if (data.getAdminCameras && data.getAdminCameras.Cameras) {
		cameras = data.getAdminCameras.Cameras.map((_camera) => {
			return {
				..._camera,
				_lastActivity:
					_camera.LastActivity && format.localDate(_camera.LastActivity),
				_laneAndGate: `${format.formatLane(
					_camera.Lane,
					_camera.Node
				)} ${format.formatGate(_camera.Node)}`,
			};
		});
	}

	// const filteredData = useSearchFilter(cameras, state.search, [
	// 	"SerialNumber",
	// 	"Manufacturer",
	// 	"ManufacturerID",
	// 	"Sites",
	// 	"_laneAndGate",
	// ]);

	const trueLength = data?.getAdminCameras?.trueLength || 0;

	return (
		<div>
			{state.wizardOpen && (
				<AddCameraWizard
					close={() => {
						refetch();
						setState((_state) => ({
							..._state,
							wizardOpen: false,
						}));
					}}
				/>
			)}
			<ReportWrapperBeta
				{...props}
				title="Cameras"
				data={cameras}
				columns={columns}
				defaultSortBy={defaultSorting}
				updateOptions={updateOptions}
				loading={props.isLoading}
				showDateRangePicker={false}
				showSitePicker={false}
				rightActions={
					isSuperAdmin ? (
						<Button
							color="blue"
							style={{ marginLeft: 8 }}
							onClick={() => openWizard()}
						>
							<Plus size={20} /> Add Cameras
						</Button>
					) : null
				}
				dataTrueLength={trueLength}
				cursorColumn="CameraID"
				paginationIsLoading={isLoading || skipQuery || props.isLoading}
				pageSize={state.pageSize}
				previousEvent={data?.getAdminCameras?.previousEvent}
				searchTokens={paginate.searchTokens}
				fetchPaginatedData={fetchPaginatedData}
			/>
		</div>
	);
}
