import { appliesToStep, nameStep, typeStep, validationStep } from "../steps";
import Button from "../../../layout/Button";
import React from "react";
import { StepTitle } from "../../WizardLayout";
import SummaryTable from "../../SummaryTable";
import WizardNavigation from "../../WizardNavigation";
import rules from "./rules";

export default ({
	handleSubmit,
	isSubmitting,
	setFieldValue,
	values,
	goTo,
	wizardProps,
	previous,
	keyStrokeHandler,
}) => {
	const stepProps = { values, setFieldValue, goTo, wizardProps, previous };
	const previousSteps = [nameStep, appliesToStep, typeStep, validationStep];
	const configSteps = rules(stepProps).execute();

	const steps = previousSteps
		.map((step) => ({
			step: step(stepProps),
		}))
		//join config steps to the previous steps
		.concat(configSteps)
		//only keep steps that have a summary defined
		.filter(({ step }) => step.summary);

	let expandedSteps = [
		{
			id: "type",
			label: "Type",
			summary: { value: values.type.proper },
		},
	];

	for (const { step } of steps) {
		const summary =
			typeof step.summary === "function" ? step.summary() : step.summary;
		if (Array.isArray(summary)) {
			//has multiple summaries in one step
			for (const _summary of summary) {
				expandedSteps.push({
					...step,
					summary: _summary,
					id: _summary.id,
					label: _summary.label,
				});
			}
		} else {
			expandedSteps.push(step);
		}
	}
	let summaries = expandedSteps
		//return the steps
		.map(({ id, label, summary }) => ({
			key: id,
			title: label,
			...summary,
		}));

	//filter out edit function if user doesn't have edit permission
	if (!wizardProps.canManageRates || wizardProps.site.IsDeactivated) {
		summaries = summaries.map((item) => ({
			...item,
			moreInfo: true,
		}));
	}
	return {
		id: "summary",
		label: "Summary",
		render: () => (
			<div>
				<StepTitle>Summary</StepTitle>
				<SummaryTable items={summaries} />
			</div>
		),
		footer: () =>
			wizardProps.canManageRates && (
				<WizardNavigation
					leftItems={
						values.mode === "update"
							? [
									<Button
										key="submit"
										color="red"
										onClick={() => {
											setFieldValue("mode", "remove");
											wizardProps.remove();
										}}
										disabled={isSubmitting}
									>
										Delete Rate
									</Button>,
							  ]
							: []
					}
					rightItems={[
						!wizardProps.site.IsDeactivated && (
							<Button
								key="submit"
								color="green"
								onClick={handleSubmit}
								disabled={isSubmitting}
								keyStrokeHandler={keyStrokeHandler}
							>
								{values.mode === "update" ? "Update" : "Create"} Rate
							</Button>
						),
					]}
				/>
			),
	};
};
