import * as authenticationApi from "../../api/authentication";
import * as installationApi from "../../api/installation";
import { MoreHorizontal, Plus, Upload, UserPlus } from "react-feather";
import React, { useContext, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { filter, find, forEach, map } from "lodash";
import {
	useFetchData,
	usePermissions,
	useQueryData,
	useSearchFilter,
} from "../../hooks";
import { AppContext } from "../../context/app-context";
import Button from "../../components/layout/Button";
import DropdownMenu from "../../components/layout/DropdownMenu";
import GroupsWizard from "../../components/wizards/groups-wizard/GroupsWizard";
import ImportUsersWizard from "../../components/wizards/groups-wizard/ImportUsersWizard";
import ReportWrapper from "../../components/report/ReportWrapper";
import UserWizard from "../../components/wizards/user-wizard/UserWizard";
import { booleanValueFormatter } from "../../components/layout/TableLayout";
import gql from "graphql-tag";

const usersColumns = (props) =>
	[
		{
			id: "UserID",
			Header: "Status",
			accessor: (d) => d.status,
			width: 200,
		},
		{
			id: "FirstName",
			Header: "First Name",
			accessor: (d) => d.FirstName || "",
			highlightCell: (cellProps) => cellProps.original.FirstName || "",
			className: "bold",
		},
		{
			id: "LastName",
			Header: "Last Name",
			accessor: (d) => d.LastName || "",
			highlightCell: (cellProps) => cellProps.original.LastName || "",
			className: "bold",
		},
		{
			id: "Email",
			Header: "Email",
			accessor: (d) => d.Email,
			highlightCell: (cellProps) => cellProps.original.Email,
		},
		{
			id: "displayOrganizationAccessGroups",
			Header: "Assigned Groups",
			accessor: (d) => d.displayOrganizationAccessGroups,
			highlightCell: (cellProps) =>
				cellProps.original.displayOrganizationAccessGroups,
		},
		!props.spaceAccountOwnerOnly && {
			id: "hasPermissions",
			Header: "Special Permissions",
			accessor: (d) => d.hasPermissions,
			Cell: booleanValueFormatter,
			filterMethod: (_filter, row) => {
				if (_filter.value === "all") {
					return true;
				}
				if (_filter.value === "true") {
					return row[_filter.id];
				}
				return !row[_filter.id];
			},
			width: 200,
		},
	].filter((e) => e);

const canManageUsersColumns = (props) => [
	...usersColumns(props),
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) => (
			<DropdownMenu
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="update"
						onClick={() => {
							props.openUserWizard(
								cellProps.original,
								cellProps.original._isUserInvitation
									? "invite-update"
									: "update"
							);
						}}
					>
						Edit
					</div>,
					<div
						key="remove"
						onClick={() =>
							props.openUserWizard(
								cellProps.original,
								cellProps.original._isUserInvitation ||
									cellProps.original.notRedeemed
									? "invite-remove"
									: "remove"
							)
						}
					>
						Remove
					</div>,
				]}
			/>
		),
		resizable: false,
		width: 50,
		sortable: false,
	},
];

const usersDefaultSorting = [
	{
		id: "Email",
		desc: false,
	},
];

const groupsColumns = [
	{
		id: "Name",
		Header: "Group Name",
		accessor: (d) => d.Name,
		highlightCell: (cellProps) => cellProps.original.Name,
		className: "bold",
	},
	{
		id: "displaySites",
		Header: "Sites",
		accessor: (d) => d.displaySites,
		highlightCell: (props) =>
			props.original.sites.length > 0 ? props.original.displaySites : "",
	},
	{
		id: "accountName",
		Header: "Account",
		accessor: (d) => d.accountName,
		highlightCell: (cellProps) => cellProps.original.accountName,
	},
	{
		id: "displayLeaseRates",
		Header: "Booking Rates",
		accessor: (d) => d.leaseRates,
		highlightCell: (props) =>
			props.original.leaseRates.length > 0
				? props.original.leaseRates
						.map((leaseRate) => leaseRate.Name)
						.join(", ")
				: "",
	},
	{
		id: "locationBasedAccessNodes",
		Header: "Location Based Access Gates",
		accessor: (d) => d.locationBasedAccessNodes,
		highlightCell: (props) =>
			props.original.nodes.length > 0
				? props.original.locationBasedAccessNodes
				: "",
	},
	{
		id: "restrictedAccessNodes",
		Header: "Restricted Access Gates",
		accessor: (d) => d.restrictedAccessNodes,
		highlightCell: (props) =>
			props.original.nodes.length > 0
				? props.original.restrictedAccessNodes
				: "",
	},
	{
		id: "sessionlessAccess",
		Header: "Sessionless Access",
		accessor: (d) => d.ForceSessionlessAccessOnSites,
		Cell: (props) => booleanValueFormatter(props, true),
	},
	{
		id: "overridesOpeningHours",
		Header: "Overrides Opening Hours",
		accessor: (d) => d.OverridesOpeningHours,
		Cell: (props) => booleanValueFormatter(props, true),
	},
	{
		id: "anprAccess",
		Header: "ANPR Access",
		accessor: (d) => d.HasANPRAccess,
		Cell: (props) => booleanValueFormatter(props, true),
	},
	{
		id: "waiveConvenienceFee",
		Header: "Waive Convenience Fee",
		accessor: (d) => d.WaiveConvenienceFee,
		Cell: (props) => booleanValueFormatter(props, true),
	},
];

const canManageUserGroupsColumns = (props) => [
	...groupsColumns,
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) => (
			<DropdownMenu
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="update"
						onClick={() => props.openGroupsWizard(cellProps.original, "update")}
					>
						Edit
					</div>,
					<div
						key="remove"
						onClick={() => props.openGroupsWizard(cellProps.original, "remove")}
					>
						Remove
					</div>,
				]}
			/>
		),
		resizable: false,
		width: 50,
		sortable: false,
	},
];

const groupsDefaultSorting = [
	{
		id: "Name",
		desc: false,
	},
];

export default function PermissionsContainer(props) {
	const [state, setState] = useState({
		search: "",
		userWizardOpen: false,
		userWizardUser: null,
		userWizardMode: "add",
		groupsWizardOpen: false,
		groupsWizardGroup: null,
		groupsWizardMode: "add",
		importUsersWizardOpen: false,
	});

	const {
		state: { currentUser, spaceAccountOwnerOnly },
	} = useContext(AppContext);

	function openUserWizard(user, mode) {
		setState((_state) => ({
			..._state,
			userWizardOpen: true,
			userWizardUser: user,
			userWizardMode: mode,
		}));
	}

	function openGroupsWizard(group, mode) {
		setState((_state) => ({
			..._state,
			groupsWizardOpen: true,
			groupsWizardGroup: group,
			groupsWizardMode: mode,
		}));
	}

	async function updateOptions({ search }) {
		setState((_state) => ({ ..._state, initialLoad: false, search }));
	}

	const isAdmin = usePermissions("IsAdmin");
	const canManageUsers =
		usePermissions(null, "ManageUsers", true) || spaceAccountOwnerOnly;
	const clientId =
		currentUser && currentUser.ClientAdmin && currentUser.ClientAdmin.ClientID
			? currentUser.ClientAdmin.ClientID
			: null;

	const organizationId = props.selectedOrganization
		? props.selectedOrganization.OrganizationID
		: null;

	const { data, isLoading } = useFetchData(
		[],
		[
			authenticationApi.getUserReportForOrganization,
			installationApi.getOrganizationAccessGroupsWithSites,
			authenticationApi.getRolesForOrganization,
			installationApi.getLeaseRatesForOrganizationId,
		],
		[[organizationId], [organizationId], [organizationId], [organizationId]],
		[
			organizationId,
			state.userWizardOpen,
			state.groupsWizardOpen,
			state.importUsersWizardOpen,
		]
	);

	const {
		data: { getNodesForOrganization: nodes, getSpaceAccounts: spaceAccounts },
	} = useQueryData(
		gql`
			query ($organizationId: Int!) {
				getNodesForOrganization(organizationId: $organizationId) {
					NodeID
					Name
					SerialNumber
					RestrictedAccess
					Sites {
						SiteID
						Name
						OrganizationID
					}
				}

				getSpaceAccounts(organizationId: $organizationId) {
					SpaceAccountID
					Name
					OrganizationID
					TotalSpaces
					OrganizationAccessGroups {
						OrganizationAccessGroupID
						OrganizationID
						Name
					}
				}
			}
		`,
		{
			organizationId,
		}
	);

	const [users, organizationAccessGroups, roles, leaseRates] = data;

	// display group names
	forEach(users, (user) => {
		user.displayOrganizationAccessGroups = map(
			user.organizationAccessGroups,
			(group) => {
				let accessGroup = find(organizationAccessGroups, {
					OrganizationAccessGroupID: group.OrganizationAccessGroupID,
				});

				return accessGroup ? accessGroup.Name : "(deleted)";
			}
		).join(", ");

		if (user.notRedeemed) {
			user.hasPermissions = "Not Redeemed";
		} else {
			user.hasPermissions = user._hasPermissions;
		}

		user.status = !isNaN(user.UserID)
			? user.notRedeemed
				? "Not Redeemed"
				: "Registered"
			: user.UserID;
	});

	// display site names
	forEach(organizationAccessGroups, (group) => {
		const account = find(
			spaceAccounts,
			(_account) => _account.SpaceAccountID === group.SpaceAccountID
		);

		group.displaySites = map(group.sites, (site) => site.Name).join(", ");
		group.accountName = account ? account.Name : "";

		group.locationBasedAccessNodes = map(
			filter(group.nodes, (node) => node.LocationBasedAccess),
			(node) => node.Name || node.SerialNumber
		).join(", ");

		group.restrictedAccessNodes = map(
			filter(group.nodes, (node) => node.GroupBasedAccess),
			(node) => node.Name || node.SerialNumber
		).join(", ");
	});

	const filteredUsers = useSearchFilter(users, state.search, [
		"Email",
		"FirstName",
		"LastName",
		"displayOrganizationAccessGroups",
		"displayOrganizationAccessGroupsExpirations",
	]);

	const filteredGroups = useSearchFilter(
		organizationAccessGroups || [],
		state.search,
		["Name", "displaySites"]
	);

	if (state.userWizardOpen) {
		return (
			<UserWizard
				close={() => {
					setState((_state) => ({ ..._state, userWizardOpen: false }));
				}}
				mode={state.userWizardMode}
				user={state.userWizardUser}
				users={users}
				groups={organizationAccessGroups}
				roles={roles}
				selectedOrganization={props.selectedOrganization}
				sites={props.availableSites}
				currentUserIsAdmin={isAdmin}
				currentUserClientId={clientId}
				spaceAccountOwnerOnly={spaceAccountOwnerOnly}
			/>
		);
	}

	if (state.groupsWizardOpen) {
		return (
			<GroupsWizard
				close={() => {
					setState((_state) => ({ ..._state, groupsWizardOpen: false }));
				}}
				mode={state.groupsWizardMode}
				selectedOrganization={props.selectedOrganization}
				group={state.groupsWizardGroup}
				groups={organizationAccessGroups}
				sites={props.availableSites}
				leaseRates={leaseRates}
				nodes={nodes}
				spaceAccounts={spaceAccounts}
				spaceAccountOwnerOnly={spaceAccountOwnerOnly}
				user={currentUser}
			/>
		);
	}

	if (state.importUsersWizardOpen) {
		return (
			<ImportUsersWizard
				close={() => {
					setState((_state) => ({ ..._state, importUsersWizardOpen: false }));
				}}
				mode={state.groupsWizardMode}
				groups={organizationAccessGroups}
				selectedOrganization={props.selectedOrganization}
			/>
		);
	}

	return (
		<Switch>
			<Route path={`${props.match.url}/roles`}>
				<ReportWrapper
					{...props}
					title={"User Roles"}
					data={filteredUsers}
					columns={
						canManageUsers
							? canManageUsersColumns({ openUserWizard, spaceAccountOwnerOnly })
							: usersColumns({ spaceAccountOwnerOnly })
					}
					defaultSorted={usersDefaultSorting}
					updateOptions={updateOptions}
					loading={isLoading}
					showDateRangePicker={false}
					showSitePicker={false}
					rightActions={
						canManageUsers ? (
							<React.Fragment>
								<Button
									color="blue"
									onClick={() => openUserWizard(null, "add")}
								>
									<UserPlus size={20} /> Add User
								</Button>
								<Button
									style={{ marginLeft: 10 }}
									color="blue"
									onClick={() =>
										setState((_state) => ({
											..._state,
											importUsersWizardOpen: true,
										}))
									}
								>
									<Upload size={20} /> Import Users
								</Button>
							</React.Fragment>
						) : null
					}
				/>
			</Route>
			<Route path={`${props.match.url}/groups`}>
				<ReportWrapper
					{...props}
					title={"User Groups"}
					data={filteredGroups}
					columns={
						canManageUsers
							? canManageUserGroupsColumns({ openGroupsWizard })
							: groupsColumns
					}
					defaultSorted={groupsDefaultSorting}
					updateOptions={updateOptions}
					loading={isLoading}
					showDateRangePicker={false}
					showSitePicker={false}
					rightActions={
						canManageUsers ? (
							<Button
								color="blue"
								onClick={() => openGroupsWizard(null, "add")}
							>
								<Plus size={20} /> Create Group
							</Button>
						) : null
					}
				/>
			</Route>
			<Redirect to={`${props.match.url}/roles`} />
		</Switch>
	);
}
