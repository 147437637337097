import React, { useState } from "react";
import { filter, map } from "lodash";
import {
	useMutateData,
	usePermissions,
	useQueryData,
	useSearchFilter,
} from "../../hooks";
import DropdownMenu from "../../components/layout/DropdownMenu";
import Lightbox from "react-image-lightbox";
import { MoreHorizontal } from "react-feather";
import ReportWrapper from "../../components/report/ReportWrapper";
import TabSelector from "../../components/layout/TabSelector";
import VoidSessionWizard from "../../components/wizards/camera-events-wizard/VoidSessionWizard";
import { format } from "../../helpers";
import gql from "graphql-tag";
import moment from "moment";
import { useRouteMatch } from "react-router-dom";

const columns = (props) => [
	{
		id: "Status",
		Header: "Status",
		accessor: "Status",
		highlightCell: (cellProps) => cellProps.original.Status,
		width: 80,
	},
	{
		id: "User",
		Header: "User",
		accessor: "User",
		highlightCell: (cellProps) => cellProps.original.User,
		className: "bold",
	},
	{
		id: "Plate",
		Header: "Vehicle",
		accessor: "Plate",
		highlightCell: (cellProps) => cellProps.original.Plate || "",
		width: 120,
	},
	{
		id: "SiteName",
		Header: "Site",
		accessor: "SiteName",
		highlightCell: (cellProps) => cellProps.original.SiteName,
	},
	{
		id: "Lane",
		Header: "Lane & Gate",
		accessor: "_laneAndGate",
		highlightCell: ({ original: { Lane, Node } }) => (
			<>
				<div>{`${format.formatLane(Lane, Node)}`}</div>
				<div style={{ fontWeight: 700 }}>{`${format.formatGate(Node)}`}</div>
			</>
		),
	},
	{
		id: "StartDate",
		Header: "Start Time",
		accessor: "StartDate",
		highlightCell: ({ original: d }) => d._startDate,
	},
	{
		id: "EndDate",
		Header: "End Time",
		accessor: "EndDate",
		highlightCell: ({ original: d }) => d._endDate,
	},
	{
		id: "Duration",
		Header: "Duration",
		accessor: "Duration",
		Cell: ({ original: d }) => d._duration,
	},
	{
		id: "PermissionGranted",
		Header: "Reason",
		accessor: "PermissionGranted",
		highlightCell: (cellProps) => cellProps.original.PermissionGranted,
		show: props.permitted,
	},
	{
		id: "tasks",
		Header: "",
		accessor: null,
		Cell: (cellProps) => (
			<DropdownMenu
				triggerContent={<MoreHorizontal size={24} />}
				items={[
					<div
						key="view-image"
						onClick={() => props.openLightbox(cellProps.original)}
					>
						View Image
					</div>,
					props.canManageSessions &&
					cellProps.original.Status !== "Voided" &&
					!props.permitted ? (
						<div
							key="void-session"
							onClick={async () => props.openSessionWizard(cellProps.original)}
						>
							Mark as Void
						</div>
					) : null,
				]}
			/>
		),
		resizable: false,
		width: 50,
		sortable: false,
	},
];

const defaultSorting = [
	{
		id: "StartDate",
		desc: true,
	},
];

const csvHeaders = (permitted) => {
	let _csvHeaders = map(columns(permitted), (c) => {
		let key = c.accessor;
		if (c.accessor === "StartDate") key = "_csvStartDate";
		if (c.accessor === "EndDate") key = "_csvEndDate";
		if (c.accessor === "Duration") key = "_duration";
		return {
			label: c.Header,
			key: key,
		};
	});

	_csvHeaders.pop();
	if (permitted) {
		_csvHeaders.pop();
	}

	return _csvHeaders;
};

export default function TransientContainerV2(props) {
	const [state, setState] = useState({
		initialLoad: true,
		options: {
			sites: [],
			"start-date-time": null,
			"end-date-time": null,
			start: null,
			end: null,
		},
		search: "",
		sessionFilter: "all",
		wizardOpen: false,
		sessionPreviewFees: {},
		lightboxOpen: false,
		images: [],
		photoIndex: 0,
	});

	const canManageSessions = usePermissions(null, "SessionAdmin", true);

	async function updateOptions({ search, ...options }) {
		setState((_state) => ({
			..._state,
			initialLoad: false,
			search,
			options: {
				...options,
				start: moment(options["start-date-time"]).startOf("day").unix(),
				end: moment(options["end-date-time"]).endOf("day").unix(),
			},
		}));
	}

	const permitted = !!useRouteMatch({
		path: "*/transient/permitted",
		strict: true,
		sensitive: true,
	});

	if (permitted && state.sessionFilter === "voided") {
		setState((_state) => ({
			..._state,
			sessionFilter: "all",
		}));
	}

	const voidSession = useMutateData(gql`
		mutation ($siteIds: [Int!], $startEventId: String, $endEventId: String) {
			voidCameraSession(
				siteIds: $siteIds
				startEventId: $startEventId
				endEventId: $endEventId
			)
		}
	`);

	const skipQuery =
		!state.options["start-date-time"] ||
		!state.options["end-date-time"] ||
		(state.options.sites && !state.options.sites.length);

	const {
		data: { getCameraReport: cameraReport },
		isLoading,
		refetch,
	} = useQueryData(
		gql`
			query (
				$siteIds: [Int!]
				$startDateTime: String!
				$endDateTime: String!
				$permitted: Boolean!
				$limit: Int
				$offset: Int
			) {
				getCameraReport(
					siteIds: $siteIds
					startDateTime: $startDateTime
					endDateTime: $endDateTime
					permitted: $permitted
					limit: $limit
					offset: $offset
				) {
					Status
					User
					Plate
					SiteName
					StartDate
					EndDate
					Duration
					StartEventId
					EndEventId
					StartImageUrl
					EndImageUrl
					PermissionGranted
					Node {
						Name
						SerialNumber
						AccessType
					}
					Lane {
						Name
						GroupName
						GroupOrder
					}
				}
			}
		`,
		{
			siteIds: state.options.sites || [],
			startDateTime: state.options["start-date-time"],
			endDateTime: state.options["end-date-time"],
			permitted: permitted,
		},
		skipQuery
	);

	const cameraData = map(cameraReport, (d) => {
		const _startDate = d.StartDate ? format.localDate(d.StartDate) : "Unknown";
		const _csvStartDate = d.StartDate
			? format.csvLocalDate(d.StartDate)
			: "Unknown";
		const _csvEndDate = d.EndDate ? format.csvLocalDate(d.EndDate) : "Unknown";
		const _endDate = d.EndDate
			? format.localDate(d.EndDate)
			: d.Duration
			? ""
			: "Unknown";
		const _duration = d.Duration
			? `${format.duration(d.Duration, true, true)}`
			: "Unknown";
		const _laneAndGate = `${format.formatLane(
			d.Lane,
			d.Node
		)} ${format.formatGate(d.Node)}`;

		return {
			...d,
			_startDate,
			_csvStartDate,
			_csvEndDate,
			_endDate,
			_duration,
			_laneAndGate,
		};
	});

	let filteredSessions = useSearchFilter(cameraData || [], state.search, [
		"User",
		"Plate",
		"SiteName",
		"Status",
		"_startDate",
		"_endDate",
		"_laneAndGate",
	]);

	filteredSessions = filteredSessions.filter((e) => {
		if (state.sessionFilter === "all") return true;
		if (state.sessionFilter === "active") return e.Status === "Active";
		if (state.sessionFilter === "closed") return e.Status === "Closed";
		if (state.sessionFilter === "voided") return e.Status === "Voided";
	});

	let tabSelectorItems = [
		{ value: "all", label: "All" },
		{ value: "active", label: "Active" },
		{ value: "closed", label: "Closed" },
	];

	if (!permitted) {
		tabSelectorItems.push({ value: "voided", label: "Voided" });
	}

	function openSessionWizard(session) {
		setState((_state) => ({
			..._state,
			wizardOpen: true,
			wizardOpenSession: session,
		}));
	}

	function openLightbox(session) {
		setState((_state) => ({
			..._state,
			lightboxOpen: true,
			images: [
				session.StartImageUrl && { img: session.StartImageUrl, label: "Entry" },
				session.EndImageUrl && { img: session.EndImageUrl, label: "Exit" },
			].filter((x) => x != null),
		}));
	}

	async function onSessionFilterChange(sessionFilter) {
		setState((_state) => ({ ..._state, sessionFilter }));
	}

	if (state.wizardOpen) {
		return (
			<VoidSessionWizard
				close={() => {
					setState((_state) => ({ ..._state, wizardOpen: false }));
				}}
				session={state.wizardOpenSession}
				voidSession={voidSession}
				sites={state.options.sites || []}
				refetch={refetch}
			/>
		);
	}

	function setPhotoIndex(newIndex) {
		setState((_state) => ({
			..._state,
			photoIndex: newIndex,
		}));
	}

	return (
		<div>
			<ReportWrapper
				{...props}
				title={
					permitted
						? "Permitted Transient Parking"
						: "Unpermitted Transient Parking"
				}
				data={filteredSessions}
				csvHeaders={csvHeaders(permitted)}
				csvName={"inugo-transient-report.csv"}
				availableSites={filter(props.availableSites, (site) => site.HasANPR)}
				columns={columns({
					searchFilter: state.searchFilter,
					data: filteredSessions,
					setState: setState,
					openSessionWizard: openSessionWizard,
					canManageSessions: canManageSessions,
					openLightbox,
					permitted,
				})}
				defaultSorted={defaultSorting}
				updateOptions={updateOptions}
				loading={isLoading}
				stats={
					<div>
						<div style={{ margin: 32 }}>
							<TabSelector
								items={tabSelectorItems}
								value={state.sessionFilter}
								onChange={onSessionFilterChange}
							/>
						</div>
					</div>
				}
			/>
			{state.lightboxOpen && (
				<Lightbox
					mainSrc={state.images[state.photoIndex].img}
					nextSrc={
						state.images.length > 1
							? state.images[(state.photoIndex + 1) % state.images.length].img
							: null
					}
					prevSrc={
						state.images.length > 1
							? state.images[
									(state.photoIndex + state.images.length - 1) %
										state.images.length
							  ].img
							: null
					}
					onCloseRequest={() =>
						setState((_state) => ({
							..._state,
							lightboxOpen: false,
							photoIndex: 0,
						}))
					}
					onMovePrevRequest={() =>
						setPhotoIndex(
							(state.photoIndex + state.images.length - 1) % state.images.length
						)
					}
					onMoveNextRequest={() =>
						setPhotoIndex((state.photoIndex + 1) % state.images.length)
					}
					imageTitle={state.images[state.photoIndex].label}
				/>
			)}
		</div>
	);
}
