export const paginatedState = {
	cursor: {},
	pageSize: 0,
	pageOffset: 1,
	sortBy: "",
	sortOrder: "",
	searchTokens: "",
	eventType: null,
};

export const PaginationEventTypes = {
	CLEAR_SEARCH: "CLEAR_SEARCH",
	NEXT: "NEXT",
	PAGE_SIZE: "PAGE_SIZE",
	PREVIOUS: "PREVIOUS",
	SEARCH: "SEARCH",
	SORT: "SORT",
};

export function fetchDataCallback(_setState) {
	return function ({
		cursor,
		pageSize,
		sortBy,
		sortOrder,
		searchTokens,
		pageOffset,
		eventType,
	}) {
		_setState((_state) => ({
			cursor: cursor || {},
			pageOffset,
			eventType,
			pageSize: pageSize || _state.pageSize,
			sortBy: sortBy || _state.sortBy,
			sortOrder: sortOrder || _state.sortOrder,
			searchTokens:
				searchTokens != null ? searchTokens.trim() : _state.searchTokens,
		}));
	};
}
