import { AlertCircle } from "react-feather";
import React from "react";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	color: ${colours.red};

	.field-error-text {
		display: inline;
	}

	.field-error-icon {
		color: ${colours.red};
		display: inline-block;
		height: 20px;
		padding-left: 4px;
		position: relative;
		top: 3px;
		width: 24px;
	}
`;

export default function FieldError(props) {
	return (
		<Wrapper>
			{props.showError && (
				<div>
					<div className="field-error-icon">
						<AlertCircle size={16} />
					</div>
					<div className="field-error-text">{props.errorText}</div>
				</div>
			)}
		</Wrapper>
	);
}
