import { getJSON, postJSON } from "../index";

const basePath = "/system/api";

export function getGlobalStatistics() {
	return getJSON(`${basePath}/v1/admin/global-statistics`);
}

export function syncDeviceService() {
	return postJSON(`${basePath}/v1/sync-device-info`);
}
