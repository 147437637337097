import * as Yup from "yup";
import * as installationApi from "../../api/installation";
import React, { useState } from "react";
import { cloneDeep, keys } from "lodash";
import {
	useHasClientAdmin,
	useMutateData,
	usePermissions,
	useQueryData,
} from "../../hooks";
import Accordion from "../../components/layout/Accordion";
import Alert from "react-s-alert";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import Dropdown from "../../components/layout/Dropdown";
import EditableInputField from "../../components/layout/EditableInputField";
import ErrorBoundary from "../ErrorBoundary";
import FormLayout from "../../components/layout/FormLayout";
import LoadingPlaceholder from "../../components/report/LoadingPlaceholder";
import ModalDialog from "../../components/layout/ModalDialog";
import PageTitle from "../../components/layout/PageTitle";
import gql from "graphql-tag";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const FieldWrapper = styled.div`
	margin: 16px 32px;

	> * {
		margin: 0 10px;
	}
`;

const FieldContent = styled.div`
	> * {
		margin: 0;
		max-width: 600px;
		min-height: 32px;
	}
`;

const Label = styled.div`
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 4px;
`;

export default function SettingsContainer(props) {
	const { t: translate } = useTranslation();

	const [state, setState] = useState({
		reloadToggle: false,
		deleteConfirmationShow: false,
		deletingOrganization: false,
	});

	const isAdmin = usePermissions("IsAdmin");
	const hasClientAdmin = useHasClientAdmin(
		props.selectedOrganization?.ClientID
	);

	if (!(isAdmin || hasClientAdmin)) {
		props.history.push("/");
	}

	const {
		data: { getClients },
		isLoading,
	} = useQueryData(
		gql`
			query {
				getClients {
					ClientID
					Name
				}
			}
		`,
		{ organizationId: props.organizationId },
		!isAdmin
	);

	const mutation = useMutateData(gql`
		mutation (
			$organizationId: Int!
			$name: String
			$companyId: String
			$clientId: Int
			$operatorId: Int
		) {
			updateOrganization(
				organizationId: $organizationId
				name: $name
				companyId: $companyId
				clientId: $clientId
				operatorId: $operatorId
			)
		}
	`);

	const settingsSchema = Yup.object().shape({
		Name: Yup.string().required(),
		ClientID: Yup.number().integer().nullable(),
	});

	let initialValues = cloneDeep({
		Name: props.selectedOrganization?.Name,
		ClientID: props.selectedOrganization?.ClientID,
		CompanyID: props.selectedOrganization?.CompanyID,
		OperatorID: props.selectedOrganization?.OperatorID,
	});

	const clientOptions = (getClients || []).map((client) => ({
		value: client.ClientID,
		label: client.Name,
	}));

	const operatorOptions = (props.availableOperators || []).map((operator) => ({
		value: operator.OperatorID,
		label: operator.Name,
	}));

	async function deleteOrganization(organizationId) {
		try {
			setState((_state) => ({ ..._state, deletingOrganization: true }));
			await installationApi.deleteOrganizationById(organizationId);
			Alert.success("Account deleted.");

			setTimeout(() => {
				window.location.reload();
			}, 1000);
		} catch (error) {
			setState((_state) => ({ ..._state, deletingOrganization: false }));
			Alert.error(translate("Errors.Generic"));
		}
	}

	function confirmationText() {
		return (
			<div>
				Are you sure you want to DELETE this account
				<div style={{ fontWeight: "bold" }}>{initialValues.Name}?</div>
				This action CANNOT be undone.
			</div>
		);
	}

	return (
		<ErrorBoundary>
			<ModalDialog
				isOpen={state.deleteConfirmationShow}
				close={() => {
					setState((_state) => ({ ..._state, deleteConfirmationShow: false }));
				}}
				dialogText={confirmationText()}
				leftButtonText="Cancel"
				leftButtonColor="blue"
				leftButtonDisabled={state.deletingOrganization}
				onLeftButtonClick={() => {
					setState((_state) => ({ ..._state, deleteConfirmationShow: false }));
				}}
				rightButtonText="Delete"
				rightButtonColor="red"
				rightButtonDisabled={state.deletingOrganization}
				onRightButtonClick={() => {
					deleteOrganization(props.selectedOrganization.OrganizationID);
				}}
			/>
			<PageTitle>Client</PageTitle>
			<FormLayout
				enableReinitialize={true}
				initialValues={initialValues}
				validationSchema={settingsSchema}
				onSubmit={async (values) => {
					try {
						await mutation({
							variables: {
								organizationId: props.selectedOrganization.OrganizationID,
								name: values.Name,
								companyId: values.CompanyID,
								clientId: values.ClientID,
								operatorId: values.OperatorID,
							},
						});

						setState((_state) => ({
							..._state,
							reloadToggle: !state.reloadToggle,
						}));

						props.getAvailableOrganizations();

						props.history.push(
							`/operator/${values.OperatorID}/organization/${props.selectedOrganization.OrganizationID}/settings`
						);

						Alert.success("Account settings updated");
					} catch (error) {
						Alert.error(translate("Errors.Generic"));
					}
				}}
				render={({
					values,
					errors,
					touched,
					handleBlur,
					handleChange,
					handleSubmit,
					isSubmitting,
					setFieldValue,
				}) => {
					if (isLoading) {
						return <LoadingPlaceholder />;
					}

					return (
						<form
							className="form"
							onSubmit={(event) => {
								event.preventDefault();
							}}
						>
							<Card>
								<Accordion title="Client Info" expanded={true}>
									<FieldWrapper>
										<Label>Name</Label>
										<FieldContent>
											<EditableInputField
												name="Name"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.Name || ""}
												error={errors.Name && touched.Name ? errors.Name : null}
											/>
										</FieldContent>
									</FieldWrapper>

									<FieldWrapper>
										<Label>External Client ID</Label>
										<FieldContent>
											<EditableInputField
												name="CompanyID"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.CompanyID || ""}
												error={
													errors.CompanyID && touched.CompanyID
														? errors.CompanyID
														: null
												}
											/>
										</FieldContent>
									</FieldWrapper>
								</Accordion>
							</Card>

							{isAdmin && (
								<Card>
									<Accordion title="Account" expanded={true}>
										<FieldWrapper>
											<FieldContent style={{ marginLeft: "0" }}>
												<Dropdown
													isClearable={true}
													onChange={(value) =>
														setFieldValue(
															"ClientID",
															value ? value.value : null
														)
													}
													options={clientOptions}
													value={clientOptions.find(
														(e) => e.value === values.ClientID
													)}
												/>
											</FieldContent>
										</FieldWrapper>
									</Accordion>
								</Card>
							)}

							{isAdmin && (
								<Card>
									<Accordion title="Operator" expanded={true}>
										<FieldWrapper>
											<FieldContent style={{ marginLeft: "0" }}>
												<Dropdown
													isClearable={true}
													onChange={(value) =>
														setFieldValue(
															"OperatorID",
															value ? value.value : null
														)
													}
													options={operatorOptions}
													value={operatorOptions.find(
														(e) => e.value === values.OperatorID
													)}
												/>
											</FieldContent>
										</FieldWrapper>
									</Accordion>
								</Card>
							)}

							<div>
								<Button
									color="red"
									onClick={() => {
										setState((_state) => ({
											..._state,
											deleteConfirmationShow: true,
										}));
									}}
									disabled={isSubmitting || keys(errors).length > 0}
								>
									Delete Client
								</Button>
								<Button
									style={{ float: "right" }}
									color="green"
									onClick={handleSubmit}
									disabled={isSubmitting || keys(errors).length > 0}
								>
									Update Client
								</Button>
							</div>
						</form>
					);
				}}
			/>
		</ErrorBoundary>
	);
}
