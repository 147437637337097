import FlexWrapper from "../layout/FlexWrapper";
import { Info } from "react-feather";
import React from "react";
import Tooltip from "../layout/Tooltip";
import { colours } from "../../styles";
import styled from "styled-components";

const Wrapper = styled.div`
	padding: 32px;
	margin: 0 auto;
	max-width: 800px;
`;

const Flex = styled(FlexWrapper)`
	margin: 0;
	padding: 0;
`;

const RowItem = styled.div`
	border-bottom: 1px solid ${colours.borderGrey};
	margin: 0;
	padding: 8px;
`;

const Icon = styled(RowItem)`
	flex-grow: 1;
	max-width: 40px;
	position: relative;

	.tooltip-icon {
		position: absolute;
		right: 8px;
		top: 12px;
	}
`;

const Label = styled(RowItem)`
	flex-grow: 3;
	font-weight: 600;
`;

const Value = styled(RowItem)`
	flex-grow: 7;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const Edit = styled(RowItem)`
	flex-grow: ${(props) => (props.wider ? 2 : 1)};

	a {
		color: ${colours.blue};
		text-decoration: none;
		transition: 0.2s ease;

		&:hover {
			cursor: pointer;
			opacity: 0.8;
		}
	}
`;

export default function SummaryTable(props) {
	return (
		<Wrapper {...props}>
			{props.items.map((item) => (
				<React.Fragment key={item.key}>
					{!item.hide && (
						<Flex>
							<Icon>
								{item.tooltip && (
									<Tooltip
										tooltipContent={item.tooltip}
										render={<Info size={18} />}
									/>
								)}
							</Icon>
							<Label>{item.title}</Label>
							<Value>{item.value}</Value>
							<Edit wider={item.moreInfo}>
								{item.edit && (
									<a onClick={item.edit}>
										{item.moreInfo ? "More Info" : "Edit"}
									</a>
								)}
							</Edit>
						</Flex>
					)}
				</React.Fragment>
			))}
		</Wrapper>
	);
}
