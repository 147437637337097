import { colours } from "../styles";
import imageDark from "../images/inugo-dark.png";
import imageLight from "../images/inugo-light.png";
import imperiumImage from "../images/imperium.jpg";
import secureDark from "../images/secure-dark.png";
import secureLight from "../images/secure-light.png";
import sippiDark from "../images/sippi-dark.png";
import sippiLight from "../images/sippi-light.png";

export const VALIDATION_TYPES = {
	FIXED: "Fixed",
	PERCENTAGE: "Percentage",
	RATE: "Rate",
};

export const PROMOTION_TYPES = {
	FIXED: "Fixed",
	PERCENTAGE: "Percentage",
	RATE: "Rate",
	DURATIONDAYS: "DurationDays",
	DURATIONHOURS: "DurationHours",
};

export const DURATION_TYPES = {
	MONTHS: "Months",
	DAYS: "Days",
	HOURS: "Hours",
};

export const PAYMENT_PROCESSING_FIXED_FEE = {
	Stripe: 0.3,
	PaymentExpress: { NZ: 0.1, US: 0.07 },
};

export const PAYMENT_PROCESSING_PERCENTAGE_FEE = {
	Stripe: 2.9,
	PaymentExpress: { NZ: 2.5, US: 3.5 },
};

export const DEFAULT_CONVENIENCE_FEE = {
	Stripe: [
		{ min: 0.01, max: 5, fee: 0.3 },
		{ min: 5.01, max: 20, fee: 0.35 },
		{ min: 20.01, max: 50, fee: 0.65 },
		{ min: 50.01, max: 100, fee: 1 },
		{ min: 100.01, max: 200, fee: 3 },
		{ min: 200.01, max: null, fee: 5 },
	],
	PaymentExpress: [
		{ min: 0.01, max: 5, fee: 0.2 },
		{ min: 5.01, max: 20, fee: 0.35 },
		{ min: 20.01, max: 50, fee: 0.65 },
		{ min: 50.01, max: 100, fee: 1 },
		{ min: 100.01, max: 200, fee: 3 },
		{ min: 200.01, max: null, fee: 5 },
	],
};

export const HARDWARE_STATUSES = {
	Development: "Development",
	Inventory: "Inventory",
	AllocatedForInstall: "Allocated for install",
	Installed: "Installed",
	Uninstalled: "Uninstalled",
	Unknown: "Unknown",
};

export const ACCESS_TYPES = {
	Entry: "Entry",
	Exit: "Exit",
	EntryAndExit: "Entry/Exit",
};

export const ACCESS_TYPE_VALUES = {
	Entry: "Entry",
	Exit: "Exit",
	EntryAndExit: "EntryAndExit",
};

export const PARCS_MANUFACTURER = {
	Amano: "Amano",
	DataPark: "DataPark",
	FlashParking: "FlashParking",
	Magnetic: "Magnetic",
	ScheidtBachmann: "Scheidt & Bachmann",
	SKIDATA: "SKIDATA",
	TIBA: "TIBA",
	WPS: "WPS",
	Other: "Other",
};

export const NODE_POSITIONS = {
	ONE: "ONE",
	TWO: "TWO",
};

export const NODE_POSITION_VALUES = {
	ONE: 1,
	TWO: 2,
};

export const LANE_STATUSES = {
	INSTALLED: "Installed",
	DID_NOT_INSTALL: "DidNotInstall",
};

export const NODE_STATUSES = {
	DEVELOPMENT: "Development",
	INVENTORY: "Inventory",
	ALLOCATED_FOR_INSTALL: "AllocatedForInstall",
	INSTALLED: "Installed",
	UNINSTALLED: "Uninstalled",
	UNKNOWN: "Unknown",
};

export const MOCK = {
	INUGO: "INUGO",
};

export const BEACON_MANUFACTURERS = {
	KONTAKT: "Kontakt",
	INUGO: "Inugo",
};

export const TX_POWER = {
	TO_VALUE: {
		0: -20,
		1: -16,
		2: -12,
		3: -8,
		4: -4,
		5: 0,
		6: 4,
		7: 8,
	},
	get TO_DISPLAY() {
		return Object.keys(TX_POWER.TO_VALUE).reduce(
			(total, currentValue, currentIndex) => {
				total[TX_POWER.TO_VALUE[currentIndex]] = currentValue;
				return total;
			},
			{}
		);
	},
};

export const CLIENTS = {
	INUGO: "IN",
	SECURE: "SE",
};

export const CLIENT_UI_CONFIG = {
	IN: {
		image: { dark: imageDark, light: imageLight },
		colours: { background: colours.darkerBlue, button: "green" },
		logo: { size: { large: "80px", small: "60px" }, padding: "8px" },
	},
	SE: {
		image: { dark: secureDark, light: secureLight },
		colours: { background: colours.secureBlue, button: "secureBlue" },
		logo: { size: { large: "50px", small: "30px" }, padding: "40px" },
	},
	IQ: {
		image: { dark: imperiumImage, light: imperiumImage },
		colours: { background: colours.white, button: "black" },
		logo: { size: { large: "80px", small: "60px" }, padding: "8px" },
	},
	SI: {
		image: { dark: sippiDark, light: sippiLight },
		colours: {
			background: colours.sippiPurple,
			backgroundSecondary: colours.sippiRed,
			button: "sippiPurple",
		},
		logo: { size: { large: "80px", small: "60px" }, padding: "8px" },
	},
};

export const IMPORT_TYPES = {
	WIZARD: "Wizard",
	IMPORT: "Import",
};

export const IMPORT_TYPES_TRANSLATIONS = {
	Wizard: "Wizard",
	Import: "Import via CSV",
};

export const INTEGRATION_TYPES = {
	WEBHOOK: "Webhook",
	POLLING: "Polling",
	FILE_TRANSFER: "FileTransfer",
};

export const INTEGRATION_TYPES_TRANSLATIONS = {
	Webhook: "Webhook",
	Polling: "Polling",
	FileTransfer: "File Transfer",
};

export const EVENT_TYPES = {
	LPR: "LPR",
	START_PARKING_SESSION: "StartParkingSession",
	UPDATE_PARKING_SESSION: "UpdateParkingSession",
	UPDATE_PARKING_SESSION_PLATE: "UpdateParkingSessionPlate",
	UPDATE_PARKING_SESSION_EXPIRY: "UpdateParkingSessionExpiry",
	END_PARKING_SESSION: "EndParkingSession",
	INVITE_USERS_BY_CSV: "InviteUsersByCSV",
	EXPIRE_PARKING_SESSION: "ExpireParkingSession",
	PAID_PARKING_SESSION: "PaidParkingSession",
	REFUND_PARKING_SESSION: "RefundParkingSession",
	CREATE_BOOKING: "CreateBooking",
	CANCEL_BOOKING: "CancelBooking",
	UPDATE_BOOKING: "UpdateBooking",
	PAID_BOOKING: "PaidBooking",
	START_WEB_PAY_SESSION: "StartWebPaySession",
	CREATE_PERMIT: "CreatePermit",
	UPDATE_PERMIT: "UpdatePermit",
	CANCEL_PERMIT: "CancelPermit",
	CREATE_PERMIT_VEHICLE: "CreatePermitVehicle",
	DELETE_PERMIT_VEHICLE: "DeletePermitVehicle",
	CANCEL_PERMIT_VEHICLE_REQUEST: "CancelPermitVehicleRequest",
};

export const EVENT_TYPES_TRANSLATIONS = {
	LPR: "A camera event is received",
	StartParkingSession: "A parking session starts",
	EndParkingSession: "A parking session ends",
	UpdateParkingSession: "A parking session is updated",
	UpdateParkingSessionPlate: "A parking session plate is updated",
	UpdateParkingSessionExpiry: "A parking session expiry is updated",
	InviteUsersByCSV: "Invite users by CSV file",
	ExpireParkingSession: "A parking session expires",
	PaidParkingSession: "A parking session is paid successfully",
	RefundParkingSession: "A parking session is refunded",
	CreateBooking: "A booking is created",
	CancelBooking: "A booking is cancelled",
	UpdateBooking: "A booking is updated",
	PaidBooking: "A booking is paid successfully",
	StartWebPaySession: "A web pay session starts",
	CreatePermit: "A permit is created",
	UpdatePermit: "A permit is updated",
	CancelPermit: "A permit is cancelled",
	CreatePermitVehicle: "A permit vehicle is created",
	DeletePermitVehicle: "A permit vehicle is deleted",
	CancelPermitVehicleRequest: "A permit vehicle request is cancelled",
};
const toEventTypeOptions = (o) => ({
	value: o,
	label: EVENT_TYPES_TRANSLATIONS[o],
});
export const EVENT_TYPES_BY_INTEGRATION_TYPE = {
	[INTEGRATION_TYPES.WEBHOOK]: [
		{
			label: "Camera",
			options: [EVENT_TYPES.LPR],
		},
		{
			label: "Session",
			options: [
				EVENT_TYPES.START_PARKING_SESSION,
				EVENT_TYPES.END_PARKING_SESSION,
				EVENT_TYPES.UPDATE_PARKING_SESSION,
				EVENT_TYPES.UPDATE_PARKING_SESSION_PLATE,
				EVENT_TYPES.UPDATE_PARKING_SESSION_EXPIRY,
				EVENT_TYPES.EXPIRE_PARKING_SESSION,
				EVENT_TYPES.PAID_PARKING_SESSION,
				EVENT_TYPES.REFUND_PARKING_SESSION,
			],
		},
		{
			label: "Booking",
			options: [
				EVENT_TYPES.CREATE_BOOKING,
				EVENT_TYPES.CANCEL_BOOKING,
				EVENT_TYPES.UPDATE_BOOKING,
			],
		},
		{
			label: "Permit",
			options: [
				EVENT_TYPES.CREATE_PERMIT,
				EVENT_TYPES.UPDATE_PERMIT,
				EVENT_TYPES.CANCEL_PERMIT,
				EVENT_TYPES.CREATE_PERMIT_VEHICLE,
				EVENT_TYPES.DELETE_PERMIT_VEHICLE,
				EVENT_TYPES.CANCEL_PERMIT_VEHICLE_REQUEST,
			],
		},
	].map((group) => ({
		...group,
		options: group.options.map(toEventTypeOptions),
	})),
	[INTEGRATION_TYPES.POLLING]: [
		{
			label: "Camera",
			options: [
				{
					value: EVENT_TYPES.LPR,
					label: "Retreive and cache camera events",
				},
			],
		},
	],
	[INTEGRATION_TYPES.FILE_TRANSFER]: [
		{
			label: "Users",
			options: [
				{
					value: EVENT_TYPES.INVITE_USERS_BY_CSV,
					label: "Invite users by CSV file",
				},
			],
		},
	],
};

export const CREDIT_CARD_TYPES = {
	UNKNOWN: "Unknown",
	VISA: "Visa",
	MASTERCARD: "MasterCard",
	AMERICAN_EXPRESS: "American Express",
};

export const SITE_TYPES = {
	GATELESS: "Gateless",
	GATED: "Gated",
};

export const WEBHOOK_PARAM_TIMEZONES = {
	SITE: "site",
	UTC: "UTC",
};

export const FILE_TRANSFER_REQUEST_METHODS = {
	GET: "GET",
};

export const COLORS_BY_REQUEST_METHOD = { POST: "orange", GET: "green" };

export const AUTHORIZATION_TYPES = {
	BASIC: "Basic",
	BEARER: "Bearer",
	CUSTOM: "Custom",
};
export const AUTHORIZATION_OPTIONS = [
	{ value: AUTHORIZATION_TYPES.BASIC, label: "Basic Auth" },
	{ value: AUTHORIZATION_TYPES.BEARER, label: "Bearer Token" },
	{ value: AUTHORIZATION_TYPES.CUSTOM, label: "Custom" },
];
export const RESPONSE_METHOD_LABELS = {
	Nothing: "Do Nothing",
	Map: "Map to Variables",
	Cache: "Cache Response",
};
export const RULE_OPTIONS_BY_PROP = {
	WHEN: [{ value: "Before", label: "Before Run" }],
	TYPE: [
		{ value: "Variable", label: "Variable" },
		{ value: "ResponseCode", label: "Response Code" },
		{ value: "True", label: "True" },
	],
	CONDTION: [
		{ value: "NotSet", label: "Not Set" },
		{ value: "Equals", label: "Equals" },
	],
	OUTCOME: [
		{ value: "Request", label: "Run Request" },
		{ value: "UnsetVariable", label: "Unset Variable" },
	],
};

export const TRANSFORMATIONS = {
	Float: [
		{ value: "ConvertToString", label: "Convert to String" },
		{ value: "Cents", label: "Dollars to Cents" },
	],
	Timestamp: [
		{ value: "Format", label: "Format" },
		{ value: "AddHours", label: "Add Hours" },
	],
	Integer: [{ value: "ConvertToString", label: "Convert to String" }],
	Action: [
		{ value: "GenerateRandomString", label: "Generate Random String" },
		{ value: "FromTemplate", label: "From Template" },
	],
	String: [
		{ value: "FindAndReplace", label: "Find and Replace" },
		{ value: "Base64Encode", label: "Base 64 Encode" },
		{ value: "SHA256", label: "Hash using SHA256" },
	],
};

export const TIMESTAMP_FORMAT_OPTIONS = [
	{ value: "UTC", label: "UTC" },
	{ value: "Timezone", label: "Site Timezone" },
];
