import {
	APPLIES_TO,
	FEE_CALCULATION_TYPES,
	YES_OR_NO,
} from "../../../../helpers/organization";
import Dropdown from "../../../layout/Dropdown";
import React from "react";
import { StepTitle } from "../../WizardLayout";
import multiChoiceStep from "../helpers/multi-choice";

//Normal steps
export { default as nameStep } from "../steps/name";
export { default as typeStep } from "../steps/type";
export { default as configurationStep } from "../steps/configuration";
export { default as summaryStep } from "../steps/summary";
export { default as removeStep } from "../steps/remove";

export const appliesToStep = multiChoiceStep({
	id: "appliesTo",
	label: "Applies To",
	summary: (values) =>
		values.appliesTo === "Groups"
			? values.groups
				? values.groups.map((group) => group.label).join(", ")
				: "No groups selected"
			: values.appliesTo,
	edit: (setFieldValue, goTo) => {
		setFieldValue("editFromSummary", true);
		goTo("appliesTo");
	},
	title: "Who does this rate apply to?",
	choices: APPLIES_TO,
	next: "type",
	validator: (values) =>
		values.appliesTo === "Groups"
			? values.groups
				? values.groups.length
				: 0
			: values.appliesTo,
	extra: (values, wizardProps, setFieldValue) => (
		<React.Fragment>
			{values.appliesTo === "Groups" && (
				<div>
					<StepTitle>Which groups?</StepTitle>
					<Dropdown
						isMulti={true}
						options={wizardProps.groups}
						value={values.groups}
						onChange={(groups) => {
							setFieldValue("groups", groups);
						}}
					/>
				</div>
			)}
		</React.Fragment>
	),
});

export const validationStep = multiChoiceStep({
	id: "isValidation",
	label: "Validation",
	summary: (values) => (values.isValidation ? "Yes" : "No"),
	edit: (setFieldValue, goTo) => {
		setFieldValue("editFromSummary", true);
		goTo("isValidation");
	},
	title: "Will this rate apply when a parking session is validated?",
	choices: YES_OR_NO,
	next: "configuration",
	validator: (values) => values.isValidation === 0 || values.isValidation === 1,
});

//configuration steps
export const feeCalculationStep = multiChoiceStep({
	id: "feeCalculationType",
	label: "Fee Calculation Type",
	title: "What type of fee calculation will this rate use?",
	choices: FEE_CALCULATION_TYPES,
	next: () => {},
});

export const flatRate = multiChoiceStep({
	id: "forceBlocksToUnitSize",
	label: "Flat Rate",
	summary: (values) => (values.forceBlocksToUnitSize ? "Yes" : "No"),
	edit: (setFieldValue, goTo) => {
		setFieldValue("editFromSummary", true);
		setFieldValue("configuration", "forceBlocksToUnitSize");
		goTo("configuration");
	},
	title: (values) =>
		`Should a charge using this rate be fixed${
			values.feeCalculationType !== "Varied"
				? ` for ${
						values.unitStaggeringScale.proper ||
						values.unitStaggeringScale.label.toLowerCase()
				  }`
				: " for the duration"
		}?`,
	choices: YES_OR_NO,
	next: () => {},
	validator: (values) => values.isValidation === 0 || values.isValidation === 1,
});
